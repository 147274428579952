import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import propTypes from 'prop-types';

// Level signification
// 1 : Z-INDEX : 1000 (Au dessus de tout)
// 2 : Z-INDEX : 900 (Laisse apparaitre le header et le menu)
// 3 : Z-INDEX : 800
// 4 : Z-INDEX : 700
// 5 : Z-INDEX : 600

const BackgroundPopup = (props) => {
    const nodeRef = useRef(null);

    return (
        <CSSTransition nodeRef={nodeRef} in={props.enabled} mountOnEnter unmountOnExit timeout={200} classNames='fade'>
            <div ref={nodeRef} onClick={props.onClick} className={`background-popup ${props.level ? `level-${props.level}` : ''} ${props.translucid ? 'translucid' : ''}`} ></div>
        </CSSTransition>
    );
}

BackgroundPopup.propTypes = {
    enabled: propTypes.bool.isRequired,
    level: propTypes.number.isRequired,
    onClick: propTypes.func
}

export default BackgroundPopup;