import React from 'react';
import PhoneInput from 'react-phone-input-2';
import InputHOC from './HOC/InputHOC';
import Input from './Input';
import { useTranslation } from 'react-i18next';

const InputPhone = (props) => {
    const { t } = useTranslation()
    
    return (
        <Input {...props}>
            <PhoneInput preferredCountries={['be', 'fr', 'lu']} searchNotFound={t("DIAL_PHONE_NOT_FOUND")} placeholder={props.placeholder} enableSearch={true} value={props.value} onChange={props.onChange} disabled={props.disabled} />
        </Input>
    );
}

export default InputHOC(InputPhone);