import { SURVEY_ADMIN_POPUP_RESET, SURVEY_ADMIN_POPUP_SET_ENABLED, SURVEY_ADMIN_POPUP_SET_LOADING, SURVEY_ADMIN_POPUP_SET_POPUP_TITLE, SURVEY_ADMIN_POPUP_SET_RESULTS, SURVEY_ADMIN_POPUP_SET_SURVEY_ID, SURVEY_ADMIN_POPUP_SET_ACTION_LOADING } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: true,
    isActionLoading: false,

    surveyId: null,
    popupTitle: 'Chargement',

    results: null,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case SURVEY_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case SURVEY_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case SURVEY_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case SURVEY_ADMIN_POPUP_SET_ACTION_LOADING: {
            return { ...state, isActionLoading: action.payload.isLoading };
        }
        case SURVEY_ADMIN_POPUP_SET_POPUP_TITLE: {
            return { ...state, popupTitle: action.payload.title };
        }

        case SURVEY_ADMIN_POPUP_SET_SURVEY_ID: {
            return { ...state, surveyId: action.payload.id };
        }
        case SURVEY_ADMIN_POPUP_SET_RESULTS: {
            return { ...state, results: action.payload.results };
        }

        default:
            return state;
    }
}