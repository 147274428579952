import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { LECTURE_PANEL_SURVEY_SET_QUESTIONS } from '../../../../redux/actions/ActionTypes';
import { addNewQuestionSurvey, loadUserCourseSurveyResults, updateSurveyQuestionPosition } from '../../../../redux/actions/dashboard/LectureSurveyActions';
import LectureAddResource from '../LectureAddResource';
import LectureSurveyAdminQuestion from './LectureSurveyAdminQuestion';
import LectureSurveyAdminResult from './LectureSurveyAdminResult';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Button';
import SurveyAdminPDF from '../../../../services/surveyAdminPDF';

const LectureSurveyAdmin = () => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { t } = useTranslation()

    const onUpdatePosition = (e) => {
        const movedItem = lectureStore.survey?.surveyQuestion[e.newIndex];
        dispatch(updateSurveyQuestionPosition(movedItem.id, e.newIndex));
    }

    useEffect(() => {
        dispatch(loadUserCourseSurveyResults(lectureStore.survey.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadPDF = () => {
        console.log(lectureStore.lecture, lectureStore.survey);
        SurveyAdminPDF(lectureStore.lecture, lectureStore.survey)
    }

    return (
        <div className='step-run'>
            <ReactSortable className='admin-questions' chosenClass={'disabled'} delay={200} animation={200} list={lectureStore.survey?.surveyQuestion} setList={(questions) => dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTIONS, payload: { questions } })} onEnd={onUpdatePosition}>
                {lectureStore.survey?.surveyQuestion?.map((q, index) => <LectureSurveyAdminQuestion key={q.id} index={index} question={q} />)}
            </ReactSortable>

            <div className={`question new rounded-m transition ${lectureStore.isQuestionCreating ? 'disabled' : ''}`} onClick={lectureStore.isQuestionCreating ? null : () => dispatch(addNewQuestionSurvey(lectureStore.survey.id))}>
                Ajouter une question
            </div>

            <LectureSurveyAdminResult />
            
            <div className='right-alignment'>
                <Button onClick={downloadPDF} title={'Télécharger le PDF'} btnStyle={'primary autofit'} />
            </div>

            <LectureAddResource onlyDelete={true} />
        </div>
    );
}

export default LectureSurveyAdmin;