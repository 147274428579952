import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SortableTree from 'react-sortable-tree';
import Button from '../../Button';
import AdminPanelHOC from '../../HOC/AdminPanelHOC';
import Input from '../../Input';
import BasicLoader from '../../loader/BasicLoader';
import { CATEGORY_ADMIN_PANEL_SET_CATEGORIES } from '../../redux/actions/ActionTypes';
import { loadCategoryAdmin, updateCategoryPositionAdmin } from '../../redux/actions/admin/CategoryAdminActions';
import { openCategoryAdminPopup } from '../../redux/actions/popup/admin/CategoryPopupActions';
import CategoryItemAdminTree from './category/CategoryItemAdminTree';

const CategoryAdminPanel = () => {
    const dispatch = useDispatch();
    const categoryAdminStore = useSelector(store => store.categoryAdminPanel);

    useEffect(() => {
        dispatch(loadCategoryAdmin());

        return () => {
            dispatch({ type: CATEGORY_ADMIN_PANEL_SET_CATEGORIES, payload: { categories: [] } });
        }
    }, [dispatch]);

    const onNodeMoved = (e) => {
        dispatch(updateCategoryPositionAdmin(e.node.id, e.treeIndex, e.nextParentNode?.id));
    }

    return (
        <div className='content categories-panel'>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} disabled={true} />
                <Button title='Créer une catégorie' btnStyle='primary' onClick={() => dispatch(openCategoryAdminPopup())} />
            </div>

            <div className='tree'>
                {categoryAdminStore.isLoading ? <BasicLoader width={'100%'} height={500} radius={10} /> :
                    <SortableTree
                        treeData={categoryAdminStore.categories}
                        onChange={categories => dispatch({ type: CATEGORY_ADMIN_PANEL_SET_CATEGORIES, payload: { categories } })}
                        isVirtualized={false}
                        rowHeight={150}
                        generateNodeProps={({ node }) => {
                            return {
                                title: <CategoryItemAdminTree category={node} />
                            }
                        }}
                        onMoveNode={onNodeMoved}
                        hand
                    />}
            </div>
        </div>
    );
}

export default AdminPanelHOC(CategoryAdminPanel, 'Categories', 'Gestion des catégories');