import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import CategoryItemCourseAdminPopup from './CategoryItemCourseAdminPopup';
import { useTranslation } from 'react-i18next';

const CategoryListCourseAdminPopup = () => {
    const courseAdminStore = useSelector(store => store.courseAdminPopup);
    const { t } = useTranslation();

    if (courseAdminStore.isCategoriesLoading)
        return (
            <div className='list'>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
            </div>
        );

    if (!courseAdminStore.categories?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_CATEGORIES_FOUND")}
            </div>
        )

    return (
        <div className='list'>
            {courseAdminStore.categories.map(category => <CategoryItemCourseAdminPopup key={category.id} category={category} />)}
        </div>
    );
}

export default CategoryListCourseAdminPopup;