import Axios from 'axios';
import { REGISTER_POPUP_STEP } from '../../../resources/constants/signPopupType';
import AuthenticationService from '../../../services/Authentication';
import { createNotification, isValidEmail, isValidPassword } from '../../../Utils';
import { SIGN_POPUP_COMPANY_CHECK, SIGN_POPUP_EMAIL_CHECK, SIGN_POPUP_FIRSTNAME_CHECK, SIGN_POPUP_LASTNAME_CHECK, SIGN_POPUP_PASSWORD_CHECK, SIGN_POPUP_PHONE_CHECK, SIGN_POPUP_SET_ENABLED, SIGN_POPUP_SET_LOADING, SIGN_POPUP_SET_REGISTER_STEP, SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK, SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK } from '../ActionTypes';
import { isValidPhoneNumber } from 'react-phone-number-input';
import i18n from 'i18next';

// LOGIN
export const connectWithEmail = (signStore) => async dispatch => {
    const isEmailValid = isValidEmail(signStore.email);
    const isValidPassword = signStore.password && signStore.password.length >= 6 && signStore.password.length <= 100;

    if (!isEmailValid)
        dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false, text: i18n.t("HELPER_EMAIL") } });

    if (!isValidPassword)
        dispatch({ type: SIGN_POPUP_PASSWORD_CHECK, payload: { isValid: false, text: i18n.t("HELPER_PASSWORD_WRONG") } });

    if (isEmailValid && isValidPassword) {
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } });

        Axios.post(`user/connect/email`, {
            email: signStore.email,
            password: signStore.password,
            ...AuthenticationService.GetDeviceInformations()
        }).then(({ data }) => {
            AuthenticationService.Login(data.user, data.token);
            window.location.href = '/dashboard';
        }).catch(({ response }) => {
            dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false } });
            dispatch({ type: SIGN_POPUP_PASSWORD_CHECK, payload: { isValid: false, text: (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE") } });
        }).finally(() => {
            dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}

export const connectWithFacebook = (data) => async dispatch => {
    Axios.post(`user/connect/facebook`,
        { token: data.accessToken, ...AuthenticationService.GetDeviceInformations() }
    ).then(({ data }) => {
        AuthenticationService.Login(data.user, data.token);
        window.location.href = '/dashboard';
    }).finally(() => {
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const connectWithMicrosoft = (err, authData) => async dispatch => {
    if (err) {
        alert('Ce navigateur bloque les popup. Désactiver cette restriction dans les préférences ou changer de navigateur.');
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
    } else {
        Axios.post(`user/connect/microsoft`, {
            token: authData.accessToken, ...AuthenticationService.GetDeviceInformations()
        }).then(({ data }) => {
            AuthenticationService.Login(data.user, data.token);
            window.location.href = '/dashboard';
        }).finally(() => {
            dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}

// REGISTER
export const registerEmailFree = (email) => async dispatch => {
    Axios.get(`user/email/free`, {
        params: {
            email
        }
    }).then(({ data }) => {
        if (data.isFree) {
            dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: true, isEmailFree: true } });
        } else {
            dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false, isEmailFree: false, text: `Cette adresse email a déjà été utilisée.` } });
        }
    })
}

const registerGeneralChecking = (signStore) => async dispatch => {
    const isFirstnameValid = signStore.firstname && signStore.firstname.length >= 3 && signStore.firstname.length <= 50;
    const isLastnameValid = signStore.lastname && signStore.lastname.length >= 3 && signStore.lastname.length <= 50;

    dispatch({ type: SIGN_POPUP_FIRSTNAME_CHECK, payload: { isValid: isFirstnameValid } });
    dispatch({ type: SIGN_POPUP_LASTNAME_CHECK, payload: { isValid: isLastnameValid } });

    if (!signStore.isEmailFree) {
        dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false, text: `Cette adresse email n'est pas correcte.` } });
    }

    return isFirstnameValid && isLastnameValid && signStore.isEmailFree;
}

const registerPasswordChecking = (signStore) => async dispatch => {
    const isPasswordValid = isValidPassword(signStore.password);

    dispatch({ type: SIGN_POPUP_PASSWORD_CHECK, payload: { isValid: isPasswordValid, text: `Le mot de de passe doit être plus sécurisé.` } });

    return isPasswordValid;
}

const registerExtraStepChecking = (signStore) => async dispatch => {
    const isPhoneValid = !signStore.phone || isValidPhoneNumber(`+${signStore.phone}`);
    const isCompanyValid = !signStore.company || (signStore.company.length >= 3 && signStore.company.length <= 50);

    dispatch({ type: SIGN_POPUP_PHONE_CHECK, payload: { isValid: isPhoneValid } });
    dispatch({ type: SIGN_POPUP_COMPANY_CHECK, payload: { isValid: isCompanyValid } });

    if (isPhoneValid && isCompanyValid) {
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } });
        Axios.post(`user/register/email`, {
            email: signStore.email,
            firstname: signStore.firstname,
            lastname: signStore.lastname,
            password: signStore.password,
            phone: signStore.phone || null,
            company: signStore.company || null,
            ...AuthenticationService.GetDeviceInformations()
        }).then(({ data }) => {
            AuthenticationService.Login(data.user, data.token);
            window.location.href = '/dashboard';
        }).finally(() => {
            dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}

export const registerNextStep = (signStore) => async dispatch => {
    switch (signStore.registerStep) {
        case REGISTER_POPUP_STEP.GENERAL:
            if (await dispatch(registerGeneralChecking(signStore)))
                dispatch({ type: SIGN_POPUP_SET_REGISTER_STEP, payload: { step: REGISTER_POPUP_STEP.PASSWORD } });
            break;
        case REGISTER_POPUP_STEP.PASSWORD:
            if (await dispatch(registerPasswordChecking(signStore)))
                dispatch({ type: SIGN_POPUP_SET_REGISTER_STEP, payload: { step: REGISTER_POPUP_STEP.EXTRA } });
            break;
        case REGISTER_POPUP_STEP.EXTRA:
            await dispatch(registerExtraStepChecking(signStore));
            break;
        default:
            break;
    }
}

export const registerPreviousStep = signStore => async dispatch => {
    if (signStore.registerStep > 0)
        dispatch({ type: SIGN_POPUP_SET_REGISTER_STEP, payload: { step: signStore.registerStep - 1 } });
}

// RESET
export const resetPopupSendPassword = (signStore) => async dispatch => {
    const isEmailValid = isValidEmail(signStore.email);

    if (!isEmailValid)
        dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false, text: i18n.t("HELPER_EMAIL") } });

    if (isEmailValid) {
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } });

        Axios.post(`user/password/recovery`, {
            email: signStore.email
        }).then(() => {
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("RESET_PASSWORD_EMAIL_SENT"), 'success');
            dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: null } });
        }).catch(({ response }) => {
            dispatch({ type: SIGN_POPUP_EMAIL_CHECK, payload: { isValid: false, text: (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE") } });
        }).finally(() => {
            dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}

export const resetPopupUpdatePassword = (signStore, token) => async dispatch => {
    const isNewPasswordValid = isValidPassword(signStore.newPassword);
    const isRenewPasswordValid = signStore.newPassword === signStore.renewPassword;

    dispatch({ type: SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK, payload: { isValid: isNewPasswordValid } });
    dispatch({ type: SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK, payload: { isValid: isRenewPasswordValid } });

    if (isNewPasswordValid && isRenewPasswordValid) {
        dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } });

        Axios.post(`user/password/recovery/change`, {
            token,
            newPassword: signStore.newPassword
        }).then(({ data }) => {
            AuthenticationService.Login(data.user, data.token);
            window.location.href = '/dashboard';
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}