import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const TermsPanel = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Helmet>
                <title>{t("LEGAL_MENTIONS")} · FormationAcademy</title>
                <meta name="description" content="Retrouvez nos mentions légales." />
            </Helmet>

            <div className='page-container-wrapper basic-panel other-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations rounded-xl'>
                            <h1>{t("LEGAL_MENTIONS")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        <h2>{t("COMPANY")}</h2>
                        <p>
                            REUNIT SA<br /><br />
                            RCSL B 91.973<br /><br />
                            12, rue de bastogne<br />
                            L-1217 Luxembourg<br />
                            <b>Grand-duché du Luxembourg</b>
                        </p>

                        <h2>Conditions d’utilisation</h2>
                        <p>
                            Le site et chacun des éléments, y compris mais sans limitation les marques, les logos, icônes, infographies, photographies, qui le composent sont protégés au titre de la législation internationale de la propriété intellectuelle. Les contenus figurant sur le site sont la propriété de Reunit ou d’autres entreprises. Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit, et sur quelque support que ce soit, de tout ou partie du site et/ou des éléments qui le composent n’est pas autorisée sans le consentement expresse de Reunit.<br />
                        Veuillez trouver ci-dessous la liste des marques déposées et enregistrées par REUNIT. Veuillez noter que cette liste n’est pas exhaustive et l’absence d’une marque sur cette liste ne constitue pas une renonciation de la part de Reunit sur ses droits de propriété intellectuelle.<br />
                            <br />
                        Formation.academy<br />
                        Entreprises.academy<br />
                        Janssens.academy<br />
                        Sage.lu<br />
                        Reunit.eu<br />
                        Reunit.net<br />
                        Reunit.org<br />
                        Reunit.fr<br />
                        Reunit.be<br />
                        Reunit.lu<br />
                            <br />
                        Les autres marques et logos apparaissant sur le site sont la propriété exclusive des parties tiers et REUNIT ne détient aucun droit dessus. Ils sont soumis à la protection du droit de la propriété intellectuelle. Vous ne pouvez pas utiliser ou reproduire les marques de ces partenaires sans leur autorisation expresse, nous vous prions de vous référer aux différentes directives des parties tiers sur l’utilisation de ces marques.
                    </p>

                        <h2>Données personnelles</h2>
                        <p>
                            D’une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre identité et à fournir des informations personnelles vous concernant. Cependant, nous pouvons parfois vous demander des informations. Par exemple, pour traiter une commande, établir une correspondance, fournir un abonnement ou soumettre une candidature à un poste. Nous pouvons compléter ces informations pour conclure une transaction ou offrir un meilleur service.
                    </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsPanel;