import Axios from "axios";
import { LECTURE_TYPE } from "../../../../resources/constants/lectureType";
import { createNotification } from "../../../../Utils";
import { ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING, ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK, ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK, LECTURE_PANEL_ADMIN_ADD_DOCUMENTATION } from "../../ActionTypes";
import i18n from 'i18next';

const checkInformations = (url, title) => async dispatch => {
    const isUrlValid = url && url.length <= 30000;
    const isTitleValid = title && title.length >= 3 && title.length <= 500;

    dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK, payload: { isValid: isUrlValid } });
    dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK, payload: { isValid: isTitleValid } });

    return isUrlValid && isTitleValid;
}

export const openDocumentationAdminPopup = (lectureId) => async dispatch => {
    dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID, payload: { id: lectureId } });
}

export const addDocumentationAdminPopup = (assignDocumentationAdminStore) => async dispatch => {
    if (await dispatch(checkInformations(assignDocumentationAdminStore.url, assignDocumentationAdminStore.title))) {
        dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

        Axios.post(`resource/add/admin`, {
            lectureId: assignDocumentationAdminStore.lectureId,
            title: assignDocumentationAdminStore.title,
            link: assignDocumentationAdminStore.url,
            type: LECTURE_TYPE.DOCUMENTATION
        }).then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_ADMIN_ADD_DOCUMENTATION, payload: { documentation: data } });
            dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_RESOURCE_ADDED"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });
        });
    }
}