import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markLectureAsDone } from '../../../redux/actions/dashboard/LectureActions';
import { LECTURE_SURVEY_STEP } from '../../../resources/constants/lectureSurvey';
import LectureSurveyAdmin from './survey/LectureSurveyAdmin';
import LectureSurveyResult from './survey/LectureSurveyResult';
import LectureSurveyRun from './survey/LectureSurveyRun';
import LectureSurveyStart from './survey/LectureSurveyStart';
import { createNotification } from '../../../Utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button';

const LectureSurvey = ({ admin, lectureId, userCourseId }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();
    const { t } = useTranslation();

    const onDone = async () => {
        const nextLectureId = await dispatch(markLectureAsDone(userCourseId, lectureStore.currentLectureId));

        if (nextLectureId) {
            history.push(`${nextLectureId}`);
        } else {
            history.push('/dashboard');
            createNotification(t("NOTIFICATION_SUCCESS_TITLE"), t("LECTURE_DONE"), 'success');
        }
    }

    return (
        <div className='lecture-survey'>
            {lectureStore.surveyStep === LECTURE_SURVEY_STEP.START && <LectureSurveyStart />}
            {lectureStore.surveyStep === LECTURE_SURVEY_STEP.RUN && <LectureSurveyRun />}
            {lectureStore.surveyStep === LECTURE_SURVEY_STEP.RESULT && <LectureSurveyResult admin={admin} lectureId={lectureId} userCourseId={userCourseId} />}
            {lectureStore.surveyStep === LECTURE_SURVEY_STEP.ADMIN && <LectureSurveyAdmin />}

            <div className='right-alignment'>
                {!admin && !lectureStore.lecture.isDone && <Button onClick={onDone} title={t("SKIP_SURVEY")} btnStyle={'secondary autofit'} />}
            </div>
        </div>
    );
}

export default LectureSurvey;