import { ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO, ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS, ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO, ASSIGN_VIDEO_ADMIN_POPUP_RESET, ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED, ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID, ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING, ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS, ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: true,

    lectureId: null,
    videos: [],
    selectedVideos: [],

    hasNext: true,
    pagingPage: 0,

    searchValue: ''
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_VIDEO_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID: {
            return { ...state, lectureId: action.payload.id };
        }

        case ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS: {
            return { ...state, videos: action.payload.videos };
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS: {
            return { ...state, videos: state.videos.concat(action.payload.videos) };
        }

        case ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO: {
            return { ...state, selectedVideos: [...state.selectedVideos, action.payload.id] }
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING: {
            return {
                ...state, videos: state.videos.map(l => {
                    if (l.guid !== action.payload.id)
                        return l;

                    return { ...l, isLoading: action.payload.isLoading };
                })
            }
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO: {
            return {
                ...state, videos: state.videos.filter(v => v.guid !== action.payload.id)
            }
        }

        case ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}