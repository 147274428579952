import React, { useRef } from 'react';
import propTypes from 'prop-types';
import BackgroundPopup from '../BackgroundPopup';
import { CSSTransition } from 'react-transition-group';

const PopupHOC = (WrappedComponent, classNames) => {
    const HOC = (props) => {
        const nodeRef = useRef(null);

        const onClickPopupContainer = (e) => {
            if (e.target.getAttribute('data-role') === 'close-popup')
                props.onClose();
        }

        return (
            <>
                <BackgroundPopup enabled={props.enabled} translucid level={1} onClick={props.onClose} />

                <CSSTransition nodeRef={nodeRef} in={props.enabled} mountOnEnter unmountOnExit timeout={200} classNames='slideUp'>
                    <div ref={nodeRef} className={`popup-container ${classNames || ''}`} onClick={onClickPopupContainer} data-role='close-popup'>
                        <WrappedComponent {...props} />
                    </div>
                </CSSTransition>
            </>
        );
    }

    HOC.propTypes = {
        enabled: propTypes.bool.isRequired,
        onClose: propTypes.func.isRequired
    };

    return HOC;
}

export default PopupHOC