import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { ASSIGN_FILE_ADMIN_POPUP_RESET } from '../../redux/actions/ActionTypes';
import { uploadFileAdminPopup } from '../../redux/actions/popup/admin/AssignFilePopupActions';
import FileItemAssignFileAdminPopup from './assignFile/FileItemAssignFileAdminPopup';

const AssignFileAdminPopup = () => {
    const dispatch = useDispatch();
    const assignFileAdminStore = useSelector(store => store.assignFileAdminPopup);

    const onDrop = useCallback(acceptedFiles => {
        dispatch(uploadFileAdminPopup(assignFileAdminStore.lectureId, acceptedFiles, assignFileAdminStore.files?.length));
    }, [dispatch, assignFileAdminStore.lectureId, assignFileAdminStore.files]);

    useEffect(() => {
        return () => {
            dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className={`file-admin ${assignFileAdminStore.isLoading ? 'disabled' : ''}`}>
            <div className='section'>
                <div className='file-dropzone rounded-m' {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div className='content'>
                        <span className='transition'>{!isDragActive ? 'Cliquez ou glissez les éléments à uploader' : 'Lachez...'}</span>
                    </div>
                </div>
            </div>

            <div className='section'>
                <div className='list'>
                    {assignFileAdminStore.files?.map((file) => <FileItemAssignFileAdminPopup key={file.id} file={file} />)}
                </div>
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignFileAdminPopup, 'Ajouter des fichiers', 'Création de fichiers supplémentaires');