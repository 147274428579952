import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { USER_ADMIN_POPUP_SET_CERTIFICATES } from '../../../redux/actions/ActionTypes';
import { openAssignCertificateAdminPopup } from '../../../redux/actions/popup/admin/AssignCertificatePopupActions';
import { loadUserCertificatesAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import CertificateUserListAdminPopup from './certificate/CertificateUserListAdminPopup';

const CertificateUserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);

    useEffect(() => {
        dispatch(loadUserCertificatesAdminPopup(userAdminStore.userId));

        return () => {
            dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATES, payload: { certificates: [] } });
        }
    }, [dispatch, userAdminStore.userId]);

    return (
        <div className='certificates-users'>
            <div>
                <div className='content-title'>
                    <h2>Certificats</h2>
                    <Button title='Générer un certificat' btnStyle={'primary autofit'} onClick={() => dispatch(openAssignCertificateAdminPopup(userAdminStore.userId))} />
                </div>

                <div className='section'>
                    <CertificateUserListAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default CertificateUserAdminPopup;