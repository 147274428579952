import propTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { addCourseCategoryAdminPopup } from '../../../redux/actions/popup/admin/AssignCategoryPopupActions';
// Images
import NoImage from '../../../resources/images/no-image.png';

const CategoryItemAssignCategoryAdminPopup = ({ category, isSelected }) => {
    const dispatch = useDispatch();
    const assignCategoryAdminStore = useSelector(store => store.assignCategoryAdminPopup);

    return (
        <div className={`item white shadow rounded-m thumbnail large ${category.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition'>Modifier la catégorie</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={category.picture || NoImage} alt={category.title} />

            <div className='info'>
                <span>{category.title}</span>

                {assignCategoryAdminStore.courseId && <Button title={isSelected ? 'Ajouté' : 'Ajouter'} btnStyle={`${isSelected ? 'success' : 'primary'}`} isLoading={category.isLoading} onClick={isSelected ? null : () => dispatch(addCourseCategoryAdminPopup(assignCategoryAdminStore.courseId, category.id))} />}
            </div>
        </div>
    );
}

CategoryItemAssignCategoryAdminPopup.propTypes = {
    category: propTypes.object.isRequired,
    isSelected: propTypes.bool
};

export default CategoryItemAssignCategoryAdminPopup;