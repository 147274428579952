import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import TracerAdminItem from './TracerAdminItem';
import { useTranslation } from 'react-i18next';

const TracerAdminList = () => {
    const tracerAdminStore = useSelector(store => store.tracerAdminPanel);
    const { t } = useTranslation()

    if (tracerAdminStore.isLoading)
        return (
            <div className='list'>
                <div className='item'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item'><BasicLoader width={'100%'} height={300} radius={10} /></div>
            </div>
        );

    if (!tracerAdminStore.tracers?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_TRACERS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {tracerAdminStore.tracers.map(tracer => <TracerAdminItem key={tracer.id} tracer={tracer} />)}
        </div>
    );
}

export default TracerAdminList;