import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { homePanelClear, homePanelLoadCoursesCategory } from '../../redux/actions/shop/HomePanelActions';
import { generateArrayRandomValue } from '../../Utils';
import HomeCategoryList from './home/HomeCategoryList';
import HomeCourseList from './home/HomeCourseList';
import HomeTabbar from './home/HomeTabbar';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

// Images
import { ReactComponent as Arrow1 } from '../../resources/images/home/arrow-1.svg';
import { ReactComponent as Arrow2 } from '../../resources/images/home/arrow-2.svg';
// import { ReactComponent as Arrow3 } from '../../resources/images/home/arrow-3.svg';

const HomePanel = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const homeStore = useSelector(store => store.homePanel);

    // Objects ref
    const objectBookRef = useRef();
    const objectMarkerRef = useRef();
    const objectCubeRef = useRef();
    const objectHandRef = useRef();
    const objectBatteryRef = useRef();
    const objectRulerRef = useRef();
    let velocities = generateArrayRandomValue(6, 5, 20);

    useEffect(() => {
        if (!homeStore.isCategoriesLoading)
            dispatch(homePanelLoadCoursesCategory(homeStore.selectedCategoryId, 0));

        return () => {
            dispatch(homePanelClear());
        }
    }, [dispatch, homeStore.isCategoriesLoading, homeStore.selectedCategoryId, i18n.language]);

    const onMouseMoving = (e) => {
        const HalfScreenWidth = (window.innerWidth / 2);
        const HalfScreenHeight = (window.innerHeight / 2);
        const ratioScreenX = (e.screenX - HalfScreenWidth) / HalfScreenWidth
        const ratioScreenY = (e.screenY - HalfScreenHeight) / HalfScreenHeight;

        if (objectBookRef && objectBookRef.current) objectBookRef.current.style.transform = `translateX(${ratioScreenX * velocities[0]}px) translateY(${ratioScreenY * velocities[0]}px)`;
        if (objectMarkerRef && objectMarkerRef.current) objectMarkerRef.current.style.transform = `translateX(${ratioScreenX * velocities[1]}px) translateY(${ratioScreenY * velocities[1]}px)`;
        if (objectCubeRef && objectCubeRef.current) objectCubeRef.current.style.transform = `translateX(${ratioScreenX * velocities[2]}px) translateY(${ratioScreenY * velocities[2]}px)`;
        if (objectHandRef && objectHandRef.current) objectHandRef.current.style.transform = `translateX(${ratioScreenX * velocities[3]}px) translateY(${ratioScreenY * velocities[3]}px)`;
        if (objectBatteryRef && objectBatteryRef.current) objectBatteryRef.current.style.transform = `translateX(${ratioScreenX * velocities[4]}px) translateY(${ratioScreenY * velocities[4]}px)`;
        if (objectRulerRef && objectRulerRef.current) objectRulerRef.current.style.transform = `translateX(${ratioScreenX * velocities[5]}px) translateY(${ratioScreenY * velocities[5]}px)`;
    }

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMoving);

        return () => {
            document.removeEventListener('mousemove', onMouseMoving);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>Centre de formation virtuel · FormationAcademy</title>
                <meta name="description" content="FormationAcademy est un centre de formation en ligne et à distance proposant des formations personnalisées & accompagnées par des experts de l'IT. Passez les certifications Microsoft, VMWare, AWS, et bien plus encore." />
            </Helmet>

            <div className='page-container-wrapper home-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl home-banner'>
                        <div className='top'>
                            <div className='banner-content'>
                                <h1>{t("HOME_TITLE")}</h1>

                                <div className='cta'>
                                    <Link to='/centre'>
                                        <div className='button rounded-s'>
                                            <Arrow1 />
                                            <Arrow2 />
                                            {/* <Arrow3 /> */}

                                            <span className='main-text'>{t("HOME_CTA")}</span>
                                        </div>
                                    </Link>

                                    <span className='second' onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })}>
                                        {t("HOME_CTA2")}
                                        <div className='underline transition'></div>
                                    </span>
                                </div>
                            </div>

                            <div className='illustration'></div>
                            <div className='objects-container'>
                                <div className='object marker transition'></div>
                                <div className='object calendar transition' ref={objectMarkerRef}></div>
                                <div className='object book transition' ref={objectBookRef}></div>
                                <div className='object ruler transition'></div>
                                <div className='object computer transition' ref={objectRulerRef}></div>
                                <div className='object cube transition' ref={objectCubeRef}></div>
                                <div className='object potion transition'></div>
                                <div className='object hand transition' ref={objectHandRef}></div>
                                <div className='object battery transition' ref={objectBatteryRef}></div>
                            </div>
                        </div>

                        <div className='advantages'>
                            <div className='item'>
                                <div className='content'>
                                    <div className='icon-container rounded-l transition'>
                                        <div className='object telescope transition'></div>
                                    </div>

                                    <div className='text'>
                                        <span className='title'>{t("HOME_ARG_1_TITLE")}</span>
                                        <span className='description'>{t("HOME_ARG_1_SUB")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='content'>
                                    <div className='icon-container rounded-l transition'>
                                        <div className='object calendar transition'></div>
                                    </div>

                                    <div className='text'>
                                        <span className='title'>{t("HOME_ARG_2_TITLE")}</span>
                                        <span className='description'>{t("HOME_ARG_2_SUB")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='content'>
                                    <div className='icon-container rounded-l transition'>
                                        <div className='object ruler transition'></div>
                                    </div>

                                    <div className='text'>
                                        <span className='title'>{t("HOME_ARG_3_TITLE")}</span>
                                        <span className='description'>{t("HOME_ARG_3_SUB")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='section'>
                        <div className='title'>
                            <h1>{t("CATEGORIES")}</h1>
                            <h2>{t("HOME_DOMAIN")}</h2>
                        </div>

                        <HomeCategoryList />
                    </div>

                    <div className='section'>
                        <div className='title'>
                            <h1>{t("COURSES")}</h1>
                            <h2>{t("HOME_COURSES")}</h2>
                        </div>

                        <HomeTabbar />
                        <HomeCourseList />
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomePanel;