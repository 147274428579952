import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import PreferenceSecuritySessionItem from './PreferenceSecuritySessionItem';
import { useTranslation } from 'react-i18next';

const PreferenceSecuritySession = () => {
    const preferenceStore = useSelector(store => store.preferencePanel);
    const { t } = useTranslation();

    if (preferenceStore.isSessionLoading)
        return (
            <div className='list'>
                <div className='danger-item'><BasicLoader width={'100%'} height={50} radius={5} /></div>
                <div className='danger-item'><BasicLoader width={'100%'} height={50} radius={5} /></div>
            </div>
        )

    if (!preferenceStore.sessions || !preferenceStore.sessions.length)
        return (<div className='notification-banner rounded-m'>{t("ERROR_MESSAGE")}</div>);

    return (
        <div className='list'>
            {preferenceStore.sessions.map(session => <PreferenceSecuritySessionItem key={session.id} session={session} />)}
        </div>
    );
}

export default PreferenceSecuritySession;