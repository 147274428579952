import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BasicLoader from '../../loader/BasicLoader';
import { previewPanelLoadCourse } from '../../redux/actions/shop/PreviewPanelActions';
import { COURSE_STATUS } from '../../resources/constants/courseStatus';
import { convertHourToDuration, convertStringForUrl } from '../../Utils';
import PreviewMoreInformation from './preview/PreviewMoreInformation';
import PreviewSpecification from './preview/PreviewSpecification';
import { DURATION_LBL } from '../../resources/constants/durationType';
import PreviewDetail from './preview/PreviewDetail';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useHistory } from 'react-router-dom';

// Images
import NoImage from '../../resources/images/no-image.png';

const PreviewPanel = (props) => {
    const dispatch = useDispatch();
    const previewStore = useSelector(store => store.previewPanel);
    const [isVisibleVideo, setVisibleVideo] = useState(false);
    const playerRef = useRef();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        const fetch = async () => {
            try {
                await dispatch(previewPanelLoadCourse(props.match.params.id));
            } catch {
                props.history.push('/');
            }
        }
        fetch();
    }, [dispatch, props.history, props.match.params.id]);

    useEffect(() => {
        console.log(i18n.language, previewStore.course);
        if (i18n.language === 'en' && previewStore.course?.frenchLanguageLinkedCourse?.englishCourseId) {
            history.push(`/course/${previewStore.course.frenchLanguageLinkedCourse?.englishCourseId}-${convertStringForUrl(previewStore.course.title)}/preview`);
        } else if(i18n.language === 'fr' && previewStore.course?.englishLanguageLinkedCourse?.frenchCourseId) {
            history.push(`/course/${previewStore.course.englishLanguageLinkedCourse?.frenchCourseId}-${convertStringForUrl(previewStore.course.title)}/preview`);
        }
    }, [i18n.language])

    const getDuration = () => {
        if (previewStore.course.expiryDurationHour < 24)
            return 'Moins d\'une journée';

        const duration = convertHourToDuration(previewStore.course.expiryDurationHour)
        return `${duration.value} ${DURATION_LBL.filter(lbl => lbl.value === duration.type)[0].label}`;
    }

    if (previewStore.isLoading || !previewStore.course)
        return (
            <div className='page-container-wrapper basic-panel loading'>
                <div className='container'>
                    <BasicLoader width={'100%'} height={250} radius={10} />
                </div>

                <div className='container page'>
                    <BasicLoader width={'100%'} height={500} radius={10} />
                </div>
            </div>
        );

    return (
        <>
            <Helmet>
                <title>{previewStore.course.title} · {t("COURSE")} · FormationAcademy</title>
                <meta name="description" content={`Formation ${previewStore.course.title} - ${previewStore.course?.seoKeyword.map(k => k.label).join(', ')}. Formation en ligne et à distance personnalisée & coachée par des experts de l'IT. Passez & réussissez la certification.`} />
            </Helmet>

            <div className='page-container-wrapper basic-panel preview-panel'>
                <div className='container'>
                    <div className={`top-banner rounded-xl transition ${isVisibleVideo ? 'playing' : ''}`} style={{ background: `url(${previewStore.course.picture || NoImage})`, backgroundSize: 'cover' }}>
                        <div className='informations blur transition rounded-xl'>
                            <div className='infos'>
                                <div className='categories'>
                                    {previewStore.course.categories.map(category => <Link key={category.id} to={`/category/${category.id}-${convertStringForUrl(category.title)}`} className='shadow white'>{category.title}</Link>)}
                                </div>
                                {previewStore.course.status && previewStore.course.status === COURSE_STATUS[0].id ? <span className='status success'>{t("NEW")}</span> : null}
                                {previewStore.course.status && previewStore.course.status === COURSE_STATUS[2].id ? <span className='status warning'>{t("SOON_EXPIRED_SHORT")}</span> : null}
                            </div>

                            <h1>{previewStore.course.title}</h1>

                            {previewStore.course?.previewVideo && <div className='btn-play-video white shadow rounded-s' onClick={() => setVisibleVideo(!isVisibleVideo)}>
                                <div className='icon play'></div>
                                <span className='transition'>Voir la présentation</span>
                            </div>}
                        </div>

                        {isVisibleVideo && previewStore.course?.previewVideo && <div className='video-container' ref={playerRef}>
                            <ReactPlayer
                                url={previewStore.course.previewVideo}
                                width={'100%'}
                                height={'100%'}
                                playing={true}
                                config={{
                                    youtube: {
                                        playerVars: { controls: 0 }
                                    },
                                }}
                            />
                        </div>}
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        {previewStore.course.status === COURSE_STATUS[2].id ?
                            <div className='notification-banner warning rounded-m'>
                                <span className='title'>{t("SOON_EXPIRED_TITLE")}</span>
                                <p>{t("SOON_EXPIRED_DESC")}</p>
                            </div> : null}

                        <div className='specs rounded-m'>
                            <div className='col-main'>
                                {previewStore.course?.objectives.length ? <PreviewSpecification title={t("PREVIEW_OBJECTIVES")} icon={'check'} items={previewStore.course.objectives} /> : null}
                            </div>

                            <div className='separator'></div>

                            <div className='col-main'>
                                {previewStore.course?.publics.length ? <PreviewSpecification title={t("PREVIEW_PUBLIC")} icon={'star'} items={previewStore.course.publics} /> : null}
                                {previewStore.course?.prerequisites.length ? <PreviewSpecification title={t("PREVIEW_PREREQUISITES")} icon={'bag'} items={previewStore.course.prerequisites} /> : null}
                            </div>
                        </div>

                        <div className='description'>
                            <h2>{t("DESCRIPTION")}</h2>
                            <p>{previewStore.course.description}</p>
                        </div>

                        <div className='details'>
                            <div className='structure'>
                                <div className='global shadow white padding rounded-l'>
                                    <div className='course-title'>
                                        <h2>{t("COURSE_CONTENT")}</h2>
                                        <div className='duration'>
                                            <div className='icon time'></div> {getDuration()}
                                        </div>
                                    </div>

                                    <PreviewDetail />
                                </div>

                                {/* <div className='linked-courses'>
                                <h2>Sur le même thème</h2>

                                <div className='courses horizontal-scroller'>
                                    <div className='wrapper'>
                                        <div className='white shadow rounded-m thumbnail'>
                                            <img className='img-main rounded-s' src={'https://formationacademy.blob.core.windows.net/thumbnails/courses/azure.jpg'} alt='META' />

                                            <div className='info'>
                                                <span>Microsoft Azure SQL Database Administration</span>

                                                <div className='or'>
                                                    <hr />
                                                </div>

                                                <ul className='stats'>
                                                    <li><div className='icon time'></div>34 heures</li>
                                                    <li><div className='icon player'></div>10 vidéos</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='white shadow rounded-m thumbnail'>
                                            <img className='img-main rounded-s' src={'https://formationacademy.blob.core.windows.net/thumbnails/courses/azure.jpg'} alt='META' />

                                            <div className='info'>
                                                <span>Microsoft Azure SQL Database Administration</span>

                                                <div className='or'>
                                                    <hr />
                                                </div>

                                                <ul className='stats'>
                                                    <li><div className='icon time'></div>34 heures</li>
                                                    <li><div className='icon player'></div>10 vidéos</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='white shadow rounded-m thumbnail'>
                                            <img className='img-main rounded-s' src={'https://formationacademy.blob.core.windows.net/thumbnails/courses/azure.jpg'} alt='META' />

                                            <div className='info'>
                                                <span>Microsoft Azure SQL Database Administration</span>

                                                <div className='or'>
                                                    <hr />
                                                </div>

                                                <ul className='stats'>
                                                    <li><div className='icon time'></div>34 heures</li>
                                                    <li><div className='icon player'></div>10 vidéos</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='white shadow rounded-m thumbnail'>
                                            <img className='img-main rounded-s' src={'https://formationacademy.blob.core.windows.net/thumbnails/courses/azure.jpg'} alt='META' />

                                            <div className='info'>
                                                <span>Microsoft Azure SQL Database Administration</span>

                                                <div className='or'>
                                                    <hr />
                                                </div>

                                                <ul className='stats'>
                                                    <li><div className='icon time'></div>34 heures</li>
                                                    <li><div className='icon player'></div>10 vidéos</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>

                            <PreviewMoreInformation courseId={props.match.params.id} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PreviewPanel;