// Images
import Radhouen from "../../resources/images/team/Radhouen.JPG";
import Saber from "../../resources/images/team/Saber.JPG";
import Yassine from "../../resources/images/team/Yassine.JPG";
import Freddy from "../../resources/images/team/freddy-janssens.png";

export const TEAM = [
  {
    picture: Freddy,
    name: "Freddy",
    status: "Architecte de Solution IT & Responsable pédagogique",
    description: [
      "✨ Expert certifié Microsoft et VMware",
      "👨🏼‍💻 6 ans d'expérience en formation à distance",
      "🗣 Mentor Cloud auprès de OpenClassRoom",
      "🧠 7 ans d'expérience dans les neurosciences et la neuropédagogie",
      "👨🏼‍🏫 30 ans d'expérience en tant que formateur",
      "🏅 Marathonien, Triathlète IRONMAN (🏊🏻 3,8km, 🚴🏻‍♂️ 180km, 🏃 42km)",
    ],
    linkedin: "https://www.linkedin.com/in/freddy-janssens/",
    style: {
      background:
        "linear-gradient(125deg, rgba(47,234,168,0.1) 0%, rgba(2,140,243,0.1) 100%)",
    },
  },
  {
    picture: Radhouen,
    name: "Radhouen",
    status: "Architecte de Solution IT et formateur certifié Microsoft",
    description: [
      "✨ 15 ans d'expériences",
      "👨🏼‍💻 Expert certifié Microsoft",
      "👨🏼‍🏫 4 ans d'expérience en tant que formateur",
      "🏅 Amateur astronomie",
    ],
    style: {
      background:
        "linear-gradient(125deg, rgba(251,171,126,0.1) 0%, rgba(247,206,104,0.1) 100%)",
    },
  },
  {
    picture: Yassine,
    name: "Yassine",
    status: "Consultant et formateur certifié Microsoft",
    description: [
      "✨ Expert certifié Microsoft",
      "👨🏼‍💻 5 ans d'expérience en formation à distance",
      "👨🏼‍🏫 10 ans d'expérience en tant que formateur",
      "🏅 Basketball",
    ],
    style: {
      background:
        "linear-gradient(125deg, rgba(242, 146, 237, 0.1) 0%, rgba(243, 99, 100, 0.1) 100%)",
    },
  },
  {
    picture: Saber,
    name: "Saber",
    status: "Microsoft Office Specialist",
    description: [
      "👨🏼‍🏫 5 ans d'expérience en tant que formateur bureautique",
      "🏅 Gastronomie",
    ],
    style: {
      background:
        "linear-gradient(125deg, rgba(149,236,176,0.1) 0%, rgba(243,249,138,0.1) 100%)",
    },
  },
];
