import { HOME_PANEL_ADD_COURSES, HOME_PANEL_SET_CATEGORIES, HOME_PANEL_SET_CATEGORIES_LOADING, HOME_PANEL_SET_COURSES, HOME_PANEL_SET_COURSES_LOADING, HOME_PANEL_SET_CURRENT_PAGE, HOME_PANEL_SET_HAS_NEXT, HOME_PANEL_SET_SELECTED_CATEGORY_ID } from "../../actions/ActionTypes";

const defaultState = {
    isCategoriesLoading: true,
    categories: [],
    selectedCategoryId: null,

    isCoursesLoading: true,
    courses: [],
    hasNext: true,
    currentPage: 0
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case HOME_PANEL_SET_CATEGORIES_LOADING: {
            return { ...state, isCategoriesLoading: action.payload.isLoading };
        }
        case HOME_PANEL_SET_COURSES_LOADING: {
            return { ...state, isCoursesLoading: action.payload.isLoading };
        }

        case HOME_PANEL_SET_CATEGORIES: {
            return { ...state, categories: action.payload.categories };
        }
        case HOME_PANEL_SET_SELECTED_CATEGORY_ID: {
            return { ...state, selectedCategoryId: action.payload.id };
        }
        case HOME_PANEL_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case HOME_PANEL_ADD_COURSES: {
            return { ...state, courses: state.courses.concat(action.payload.courses) };
        }
        case HOME_PANEL_SET_CURRENT_PAGE: {
            return { ...state, currentPage: action.payload.currentPage };
        }
        case HOME_PANEL_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }

        default:
            return state;
    }
}