import Axios from "axios";
import { createNotification } from "../../../Utils";
import { VOUCHER_ADMIN_PANEL_ADD_VOUCHERS, VOUCHER_ADMIN_PANEL_SET_HAS_NEXT, VOUCHER_ADMIN_PANEL_SET_LOADING, VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE, VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE, VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING } from "../ActionTypes";
import i18n from 'i18next';

export const loadVoucherAdmin = (pageIndex = null, name = null) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`voucher/list/all/admin`, {
        params: {
            pageIndex,
            name
        }
    }).then(({ data }) => {
        dispatch({ type: VOUCHER_ADMIN_PANEL_ADD_VOUCHERS, payload: { vouchers: data.paging } });
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
    }).finally(() => {
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: false } });
    });
}

export const updateEndDateVoucherAdminPopup = (voucherId, date) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, payload: { id: voucherId, isLoading: true } });

    Axios.put(`voucher/${voucherId}/change/dates/admin`, {
        endDate: date
    }).then(() => {
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE, payload: { id: voucherId, date } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, payload: { id: voucherId, isLoading: false } });
    });
}