import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import { VOUCHER_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { convertStringForUrl } from '../../Utils';
import VoucherAddedItem from './VoucherAddedItem';
import { useTranslation } from 'react-i18next';

const VoucherAddedList = () => {
    const dispatch = useDispatch();
    const voucherStore = useSelector(store => store.voucherPopup);
    const { t } = useTranslation();

    const uniqueVoucher = voucherStore.vouchers[0];

    const hidePopup = () => {
        dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: false } });
    }

    if (voucherStore.vouchers.length === 0)
        return null;

    return (
        <div className='voucher'>
            {voucherStore.vouchers.map(voucher => <VoucherAddedItem key={voucher.id} voucher={voucher} />)}

            {voucherStore.vouchers.length > 1 ? <Link onClick={hidePopup} to='/dashboard'><Button title={t("SEE_COURSES")} btnStyle={'primary'} /></Link> : <Link onClick={hidePopup} to={`/course/${uniqueVoucher.id}-${convertStringForUrl(uniqueVoucher.title)}/lecture`}><Button title={t("START_COURSE")} btnStyle={'primary'} /></Link>}
        </div>
    );
}

export default VoucherAddedList;