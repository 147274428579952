import React from 'react';
import IsLoggedHOC from '../../HOC/IsLoggedHOC';
import { PREFERENCE_PANEL } from '../../resources/constants/preferencePanel';
// import PreferenceDisableAccount from './preference/PreferenceDisableAccount';
import PreferenceInformation from './preference/PreferenceInformation';
import PreferenceMenu from './preference/PreferenceMenu';
import PreferencePhoto from './preference/PreferencePhoto';
import PreferenceSecurity from './preference/PreferenceSecurity';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PreferencePanel = (props) => {
    const { menu } = props.match.params;
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("PREFERENCES")} · FormationAcademy</title>
            </Helmet>

            <div className='page-container-wrapper basic-panel preference-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations rounded-xl'>
                            <h1>{t("PREFERENCES")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content'>
                        <PreferenceMenu selectedMenu={menu} />

                        <div className='sub-container content white shadow rounded-l'>
                            {menu === PREFERENCE_PANEL.PHOTO && <PreferencePhoto />}
                            {menu === PREFERENCE_PANEL.INFORMATIONS && <PreferenceInformation />}
                            {menu === PREFERENCE_PANEL.SECURITY && <PreferenceSecurity />}
                            {/* {menu === PREFERENCE_PANEL.DISABLE_ACCOUNT && <PreferenceDisableAccount />} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IsLoggedHOC(PreferencePanel);