import React from 'react';
import { MENU } from '../resources/constants/menu';
import MenuItem from './MenuItem';

const MenuList = () => {
    return (
        <div className='menu'>
            {MENU.map(menu => <MenuItem key={menu.id} menu={menu} />)}
        </div>
    );
}

export default MenuList;