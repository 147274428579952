import React, { useState } from 'react';
import InputHOC from './HOC/InputHOC';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Input = (props) => {
    const [isPasswordHidden, setPasswordHidden] = useState(true);
    const [typingTimer, setTypingTimer] = useState(null);
    const { t } = useTranslation();

    const onChange = (e) => {
        props.onChange(e.target.value)

        if (props.onTypingDelay) {
            clearTimeout(typingTimer);

            const value = e.target.value;
            setTypingTimer(setTimeout(() => props.onTypingDelay(value), 500));
        }
    }

    const onClick = () => {
        if(props.onClick)
            props.onClick()
    }

    return (
        <div className={`input default ${props.disabled ? 'disabled' : ''} ${props.error ? 'error' : ''}`} onClick={onClick}>
            <div className='labels'>
                {props.title && <span className='title'>{props.title}</span>}
                {props.type && props.passwordToggle && <span className='show-password' onClick={!props.disabled ? () => setPasswordHidden(!isPasswordHidden) : null}>{isPasswordHidden ? t("SHOW") : t("HIDE")}</span>}
            </div>
            <div className='field rounded-s transition'>
                {props.children ? props.children : <input ref={props.inputRef || null} placeholder={props.placeholder} type={(!isPasswordHidden || props.type) || 'text'} value={props.value || ''} onChange={!props.isCalendarInput ? onChange : props.onChange} autoComplete={props.autocomplete} disabled={props.disabled} maxLength={props.maxLength || null} />}
                {props.error && <div className='icon error'><div className='icon close white'></div></div>}
                {props.success && <div className='icon success'><div className='icon check-bold white'></div></div>}
            </div>
            {props.error && props.helperText && <span className='error'>{props.helperText}</span>}
        </div>
    );
}

Input.propTypes = {
    passwordToggle: propTypes.bool,
    onTypingDelay: propTypes.func,
    isCalendarInput: propTypes.bool
};

export default InputHOC(Input);