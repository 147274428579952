import React from 'react';
import propTypes from 'prop-types';
import Button from '../../../../Button';
import { useDispatch } from 'react-redux';
import { deleteCategoryCourseAdminPopup } from '../../../../redux/actions/popup/admin/CoursePopupActions';

// Images
import NoImage from '../../../../resources/images/no-image.png';
import { openCategoryAdminPopup } from '../../../../redux/actions/popup/admin/CategoryPopupActions';

const CategoryItemCourseAdminPopup = ({ category }) => {
    const dispatch = useDispatch();

    return (
        <div className={`item white shadow rounded-m thumbnail large ${category.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition' onClick={() => dispatch(openCategoryAdminPopup(category.categoryId))}>Modifier la catégorie</div>
                            <div className='item transition'><Button title={'Supprimer le lien'} isLoading={category.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteCategoryCourseAdminPopup(category.id))} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={category.picture || NoImage} alt={category.title} />

            <div className='info'>
                <span>{category.title}</span>
            </div>
        </div>
    );
}

CategoryItemCourseAdminPopup.propTypes = {
    category: propTypes.object.isRequired
};

export default CategoryItemCourseAdminPopup;