import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import SignPopupHOC from '../../HOC/SignPopupHOC';
import { SIGN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { REGISTER_POPUP_STEP, SIGN_POPUP_POPUP_TYPE } from '../../resources/constants/signPopupType';
import RegisterPopupGeneral from './RegisterPopupGeneral';
import RegisterPopupPassword from './RegisterPopupPassword';
import RegisterPopupExtra from './RegisterPopupExtra';
import { registerNextStep, registerPreviousStep } from '../../redux/actions/popup/SignActions';
import { useTranslation } from 'react-i18next';

const RegisterPopup = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(registerNextStep(signStore));
    }

    return (
        <form onSubmit={onSubmit} className='register-popup'>
            {signStore.registerStep === REGISTER_POPUP_STEP.GENERAL && <RegisterPopupGeneral />}
            {signStore.registerStep === REGISTER_POPUP_STEP.PASSWORD && <RegisterPopupPassword />}
            {signStore.registerStep === REGISTER_POPUP_STEP.EXTRA && <RegisterPopupExtra />}

            <div className='step-buttons'>
                {signStore.registerStep > REGISTER_POPUP_STEP.GENERAL && <Button title={t("BACK")} btnStyle={'secondary previous no-shadow'} isLoading={signStore.isLoading} onClick={() => dispatch(registerPreviousStep(signStore))} />}
                <Button title={t("NEXT")} btnStyle={'primary next'} isLoading={signStore.isLoading} type={'submit'} />
            </div>
        </form>
    );
}

const SignUpSubTitle = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    return (<span>{t("SIGN_ALREADY_REGISTERED")} <em onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.LOGIN } })}>{t("LOGIN")}</em></span>)
}

export default SignPopupHOC(RegisterPopup, 'REGISTRATION', SignUpSubTitle);