import React from 'react';
import { useSelector } from 'react-redux';
import UserAdminPanelItemLoader from '../../../../loader/UserAdminPanelItemLoader';
import UserItemCourseAdminPopup from './UserItemCourseAdminPopup';
import { useTranslation } from 'react-i18next';

const UserListCourseAdminPopup = () => {
    const courseAdminStore = useSelector(store => store.courseAdminPopup);
    const { t } = useTranslation();

    if (!courseAdminStore.isUsersLoading && !courseAdminStore.users?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_USERS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {courseAdminStore.users.map(userCourse => <UserItemCourseAdminPopup key={userCourse.userCourseId} userCourse={userCourse} />)}

            {courseAdminStore.isUsersLoading && <>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
            </>}
        </div>
    );
}

export default UserListCourseAdminPopup;