import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_VIDEO_ADMIN_POPUP_RESET, ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS } from '../../redux/actions/ActionTypes';
import { loadVideosAdminPopup } from '../../redux/actions/popup/admin/AssignVideoPopupActions';
import VideoListAssignVideoAdminPopup from './assignVideo/VideoListAssignVideoAdminPopup';

const AssignVideoAdminPopup = () => {
    const dispatch = useDispatch();
    const assignVideoAdminStore = useSelector(store => store.assignVideoAdminPopup);

    useEffect(() => {
        dispatch(loadVideosAdminPopup(0, null));

        return () => {
            dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const onTyping = (value) => {
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS, payload: { videos: [] } });
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: 0 } });
        dispatch(loadVideosAdminPopup(0, value));
    }

    return (
        <div className={`assign-category-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignVideoAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
            </div>

            <div className='section'>
                <VideoListAssignVideoAdminPopup />

                {assignVideoAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={assignVideoAdminStore.isLoading} onClick={() => dispatch(loadVideosAdminPopup(assignVideoAdminStore.pagingPage + 1, assignVideoAdminStore.searchValue))} /></div>}
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignVideoAdminPopup, 'Assigner une vidéo', 'Insertion d\'une vidéo', null, null, null, {
    storeName: 'assignVideoAdminPopup',
    completion: (dispatch, store) => {
        if (!store.isLoading && store.hasNext) {
            dispatch(loadVideosAdminPopup(store.pagingPage + 1, store.searchValue));
        }
    }
});