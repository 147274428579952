import { ASSIGN_LECTURE_ADMIN_POPUP_RESET, ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING, ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID, ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES, ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES, ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING, ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE, ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: true,
    isAddNewLoading: false,

    sectionId: null,
    lectures: [],
    selectedLectures: [],

    hasNext: true,
    pagingPage: 0,

    searchValue: '',
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_LECTURE_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING: {
            return { ...state, isAddNewLoading: action.payload.isLoading };
        }

        case ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID: {
            return { ...state, sectionId: action.payload.id };
        }

        case ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES: {
            return { ...state, lectures: action.payload.lectures };
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES: {
            return { ...state, lectures: state.lectures.concat(action.payload.lectures) };
        }

        case ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE: {
            return { ...state, selectedLectures: [...state.selectedLectures, action.payload.id] }
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING: {
            return {
                ...state, lectures: state.lectures.map(l => {
                    if(l.id !== action.payload.id)
                        return l;

                    return {...l, isLoading: action.payload.isLoading};
                })
            }
        }

        case ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}