import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { VOUCHER_ADMIN_POPUP_RESET, VOUCHER_ADMIN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { loadUserVoucherAdminPopup, loadVoucherAdminPopup } from '../../redux/actions/popup/admin/VoucherPopupActions';
import { ADMIN_VOUCHER_MENU_TYPE } from '../../resources/constants/adminPanel';
import CourseVoucherAdminPopup from './voucher/CourseVoucherAdminPopup';
import OverviewVoucherAdminPopup from './voucher/OverviewVoucherAdminPopup';
import UserVoucherAdminPopup from './voucher/UserVoucherAdminPopup';

const VoucherAdminPopup = () => {
    const dispatch = useDispatch();
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);

    useEffect(() => {
        if (voucherAdminStore.voucherId)
            dispatch(loadVoucherAdminPopup(voucherAdminStore.voucherId));

        return () => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_RESET });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={`voucher-admin ${voucherAdminStore.isLoading ? 'disabled' : ''}`}>
            {voucherAdminStore.popupEnabled === ADMIN_VOUCHER_MENU_TYPE.OVERVIEW && <OverviewVoucherAdminPopup />}
            {voucherAdminStore.popupEnabled === ADMIN_VOUCHER_MENU_TYPE.COURSES && <CourseVoucherAdminPopup />}
            {voucherAdminStore.popupEnabled === ADMIN_VOUCHER_MENU_TYPE.USERS && <UserVoucherAdminPopup />}
        </div>
    );
}

export default AdminPopupHOC(VoucherAdminPopup, null, 'Opération sur un bon d\'accès', 'voucherAdminPopup', VOUCHER_ADMIN_POPUP_SET_ENABLED, null, {
    storeName: 'voucherAdminPopup',
    completion: (dispatch, store) => {
        if (store.popupEnabled === ADMIN_VOUCHER_MENU_TYPE.USERS) {
            if (!store.isUsersLoading && store.hasNext) {
                dispatch(loadUserVoucherAdminPopup(store.voucherId, store.pagingPage + 1, store.userSearchValue));
            }
        }
    }
});