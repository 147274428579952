import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { PREFERENCES_MENU } from '../../../resources/constants/menu';
import AuthenticationService from '../../../services/Authentication';

// Images
import Profile from '../../../resources/images/profile.png';

const PreferenceMenu = ({ selectedMenu }) => {
    const user = AuthenticationService.GetSession();

    return (
        <div className='sub-container menu white shadow rounded-l'>
            <div className='info'>
                <img src={user.picture || Profile} alt={`${user.firstname} ${user.lastname}`} />
                <span>{user.firstname} {user.lastname}</span>
            </div>

            <div className='menu'>
                {PREFERENCES_MENU.map(item => <Link key={item.id} to={item.link} className={`item transition ${item.warning ? 'warning' : ''} ${selectedMenu === item.id ? 'selected' : ''}`}>{item.name}</Link>)}
            </div>
        </div>
    );
}

PreferenceMenu.propTypes = {
    selectedMenu: propTypes.string.isRequired
};

export default PreferenceMenu;