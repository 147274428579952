import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import { deleteUserCertificateAdminPopup } from '../../../../redux/actions/popup/admin/UserPopupActions';

// Images
import NoImage from '../../../../resources/images/no-image.png';

const CertificateUserItemAdminPopup = ({ certificate }) => {
    const dispatch = useDispatch();

    return (
        <div className={`white shadow rounded-m thumbnail horizontal ${certificate.isLoading ? 'disabled' : ''}`}>
            <div className='left'>
                <img className='img-main rounded-s' src={certificate.picture || NoImage} alt={certificate.title} />

                <div className='info'>
                    <span>{certificate.title}</span>
                </div>
            </div>

            <div className='actions-banner'>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition'><Button title={'Supprimer'} isLoading={certificate.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteUserCertificateAdminPopup(certificate.id))} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CertificateUserItemAdminPopup.propTypes = {
    certificate: propTypes.object.isRequired
};

export default CertificateUserItemAdminPopup;