import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Element } from 'react-scroll';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import MainViewFive from './solution/MainViewFive';
import MainViewFour from './solution/MainViewFour';
import MainViewOne from './solution/MainViewOne';
import MainViewThree from './solution/MainViewThree';
import MainViewTwo from './solution/MainViewTwo';
import SolutionFixedView from './solution/SolutionFixedView';
import TransitionViewFour from './solution/TransitionViewFour';
import TransitionViewOne from './solution/TransitionViewOne';
import TransitionViewThree from './solution/TransitionViewThree';
import TransitionViewTwo from './solution/TransitionViewTwo';

const SolutionPanel = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    // CONSTANTS
    const VIEWS = [
        'view-1', 'transition-1',
        'view-2', 'transition-2',
        'view-3', 'transition-3',
        'view-4', 'transition-4',
        'view-5'
    ];

    const scrollableContainerRef = useRef();
    const arrowsContainerRef = useRef();
    const [previousView, setPreviewView] = useState(null);
    const [nextView, setNextView] = useState(VIEWS[1]);

    // Fixed panel
    const makeAppointmentBtnRef = useRef();
    const mainPanelRef = useRef();
    const mainIllustrationRef = useRef();
    const objectBookRef = useRef();
    const objectClockRef = useRef();
    const objectGamepadRef = useRef();
    const objectKeyRef = useRef();
    const objectBatteryRef = useRef();
    const objectSpiral1Ref = useRef();

    // SubPanel1
    const mainView1Ref = useRef();
    const mainTitlemainView1Ref = useRef();
    const overlayTitlemainView1Ref = useRef();

    // TransitionView1
    const transitionView1Ref = useRef();
    const transitionView1PathRef = useRef();
    const transitionView1Advantage1Ref = useRef();
    const transitionView1Advantage2Ref = useRef();
    const transitionView1Advantage3Ref = useRef();

    // SubPanel2
    const mainView2Ref = useRef();
    const mainTitlemainView2Ref = useRef();
    const overlayTitlemainView2Ref = useRef();

    // TransitionView2
    const transitionView2Ref = useRef();
    const transitionView2PathRef = useRef();
    const transitionView2Advantage1Ref = useRef();
    const transitionView2Advantage2Ref = useRef();
    const transitionView2Advantage3Ref = useRef();

    // SubPanel3
    const mainView3Ref = useRef();
    const mainTitlemainView3Ref = useRef();
    const overlayTitlemainView3Ref = useRef();

    // TransitionView3
    const transitionView3Ref = useRef();
    const transitionView3PathRef = useRef();
    const transitionView3Advantage1Ref = useRef();
    const transitionView3Advantage2Ref = useRef();
    const transitionView3Advantage3Ref = useRef();

    // SubPanel4
    const mainView4Ref = useRef();
    const mainTitlemainView4Ref = useRef();
    const overlayTitlemainView4Ref = useRef();

    // TransitionView3
    const transitionView4Ref = useRef();
    const transitionView4PathRef = useRef();
    const transitionView4Advantage1Ref = useRef();
    const transitionView4Advantage2Ref = useRef();
    const transitionView4Advantage3Ref = useRef();

    // SubPanel5
    const mainView5Ref = useRef();
    const mainTitlemainView5Ref = useRef();
    const overlayTitlemainView5Ref = useRef();

    const onVerticalScrolling = () => {
        // Fixed panel
        const mainPanelProgress = 1 - Math.min(Math.max((mainPanelRef.current.getBoundingClientRect().top / window.innerHeight), 0), 1);
        if (mainPanelProgress >= 0 && mainPanelProgress <= 1) {
            const transition1Progress = Math.min(Math.max(-(transitionView1Ref.current.getBoundingClientRect().left - window.innerWidth) / transitionView1Ref.current.getBoundingClientRect().width, 0), 1);
            if (mainPanelProgress >= 1 || transition1Progress > 0) {
                mainPanelRef.current.style.overflowY = 'auto';
                mainPanelRef.current.style.borderRadius = '0';
                arrowsContainerRef.current.style.opacity = '1';
                makeAppointmentBtnRef.current.style.opacity = '0.5';
            } else {
                mainPanelRef.current.style.borderRadius = '40px';
                mainPanelRef.current.style.overflowY = 'hidden';
                arrowsContainerRef.current.style.opacity = '0';
                makeAppointmentBtnRef.current.style.opacity = '0';
            }

            mainIllustrationRef.current.style.transform = `translateX(${mainPanelProgress * 1000}px) translateY(${mainPanelProgress * 500}px)`;
            objectBookRef.current.style.transform = `translateY(-${mainPanelProgress * 500}px)`;
            objectClockRef.current.style.transform = `translateY(-${mainPanelProgress * 300}px)`;
            objectGamepadRef.current.style.transform = `translateY(-${mainPanelProgress * 400}px)`;
            objectKeyRef.current.style.transform = `translateY(-${mainPanelProgress * 500}px)`;
            objectBatteryRef.current.style.transform = `translateY(-${mainPanelProgress * 300}px)`;
            objectSpiral1Ref.current.style.transform = `translateY(-${mainPanelProgress * 200}px)`;

            // SubPanel1
            const overlayTitleMainPanelProgress = Math.pow(mainPanelProgress, 2);
            mainTitlemainView1Ref.current.style.width = `${(overlayTitleMainPanelProgress * 100)}%`;
            overlayTitlemainView1Ref.current.style.width = `${(1 / overlayTitleMainPanelProgress) * 100}%`;
        }
    }

    const onHorizontalScrolling = (e) => {
        // Transition 1
        const transition1Progress = Math.min(Math.max(-(transitionView1Ref.current.getBoundingClientRect().left - window.innerWidth) / transitionView1Ref.current.getBoundingClientRect().width, 0), 1);

        if (transition1Progress > 0) {
            mainPanelRef.current.style.marginTop = 0;

            // Reset illustrations
            mainIllustrationRef.current.style.transform = `translateX(0px) translateY(0px)`;
            objectBookRef.current.style.transform = `translateY(0px)`;
            objectClockRef.current.style.transform = `translateY(0px)`;
            objectGamepadRef.current.style.transform = `translateY(0px)`;
            objectKeyRef.current.style.transform = `translateY(0px)`;
            objectBatteryRef.current.style.transform = `translateY(0px)`;
            objectSpiral1Ref.current.style.transform = `translateY(0px)`;
        } else {
            mainPanelRef.current.style.marginTop = `${window.innerHeight}px`;
            scrollableContainerRef.current.scrollTop = window.innerHeight;
            arrowsContainerRef.current.style.opacity = '0';
            makeAppointmentBtnRef.current.style.opacity = '0';
        }

        if (transition1Progress >= 0 && transition1Progress <= 1) {
            var pathLength = transitionView1PathRef.current.getTotalLength();
            transitionView1PathRef.current.style.strokeDasharray = pathLength + ' ' + pathLength;
            var drawLength = pathLength * (Math.pow(transition1Progress, 2));
            transitionView1PathRef.current.style.strokeDashoffset = pathLength - drawLength;

            if (transition1Progress >= 0.25) transitionView1Advantage1Ref.current.classList.add('visible');
            if (transition1Progress >= 0.50) transitionView1Advantage2Ref.current.classList.add('visible');
            if (transition1Progress >= 0.75) transitionView1Advantage3Ref.current.classList.add('visible');
        }

        // SubPanel 2
        const subPanel2Progress = 1 - Math.min(Math.max((mainView2Ref.current.getBoundingClientRect().left / e.target.getBoundingClientRect().width), 0), 1);
        const transition2Progress = Math.min(Math.max(-(transitionView2Ref.current.getBoundingClientRect().left - window.innerWidth) / transitionView2Ref.current.getBoundingClientRect().width, 0), 1);
        if (subPanel2Progress >= 0 && subPanel2Progress <= 1) {
            const overlayTitleSubPanel2Progress = Math.pow(subPanel2Progress, 3);
            mainTitlemainView2Ref.current.style.width = `${(overlayTitleSubPanel2Progress * 100)}%`;
            overlayTitlemainView2Ref.current.style.width = `${(1 / overlayTitleSubPanel2Progress) * 100}%`;

            // Transition 2
            var pathLength2 = transitionView2PathRef.current.getTotalLength();
            transitionView2PathRef.current.style.strokeDasharray = pathLength2 + ' ' + pathLength2;
            var drawLength2 = pathLength2 * (Math.pow(transition2Progress, 2));
            transitionView2PathRef.current.style.strokeDashoffset = pathLength2 - drawLength2;

            if (transition2Progress >= 0.25) transitionView2Advantage1Ref.current.classList.add('visible');
            if (transition2Progress >= 0.50) transitionView2Advantage2Ref.current.classList.add('visible');
            if (transition2Progress >= 0.75) transitionView2Advantage3Ref.current.classList.add('visible');
        }

        // SubPanel 3
        const subPanel3Progress = 1 - Math.min(Math.max((mainView3Ref.current.getBoundingClientRect().left / e.target.getBoundingClientRect().width), 0), 1);
        const transition3Progress = Math.min(Math.max(-(transitionView3Ref.current.getBoundingClientRect().left - window.innerWidth) / transitionView3Ref.current.getBoundingClientRect().width, 0), 1);
        if (subPanel3Progress >= 0 && subPanel3Progress <= 1) {
            const overlayTitleSubPanel3Progress = Math.pow(subPanel3Progress, 3);
            mainTitlemainView3Ref.current.style.width = `${(overlayTitleSubPanel3Progress * 100)}%`;
            overlayTitlemainView3Ref.current.style.width = `${(1 / overlayTitleSubPanel3Progress) * 100}%`;

            // Transition 3
            var pathLength3 = transitionView3PathRef.current.getTotalLength();
            transitionView3PathRef.current.style.strokeDasharray = pathLength3 + ' ' + pathLength3;
            var drawLength3 = pathLength3 * (Math.pow(transition3Progress, 2));
            transitionView3PathRef.current.style.strokeDashoffset = pathLength3 - drawLength3;

            if (transition3Progress >= 0.20) transitionView3Advantage1Ref.current.classList.add('visible');
            if (transition3Progress >= 0.50) transitionView3Advantage2Ref.current.classList.add('visible');
            if (transition3Progress >= 0.75) transitionView3Advantage3Ref.current.classList.add('visible');
        }

        // SubPanel 4
        const subPanel4Progress = 1 - Math.min(Math.max((mainView4Ref.current.getBoundingClientRect().left / e.target.getBoundingClientRect().width), 0), 1);
        const transition4Progress = Math.min(Math.max(-(transitionView4Ref.current.getBoundingClientRect().left - window.innerWidth) / transitionView4Ref.current.getBoundingClientRect().width, 0), 1);
        if (subPanel4Progress >= 0 && subPanel4Progress <= 1) {
            const overlayTitleSubPanel4Progress = Math.pow(subPanel4Progress, 3);
            mainTitlemainView4Ref.current.style.width = `${(overlayTitleSubPanel4Progress * 100)}%`;
            overlayTitlemainView4Ref.current.style.width = `${(1 / overlayTitleSubPanel4Progress) * 100}%`;

            // Transition 4
            var pathLength4 = transitionView4PathRef.current.getTotalLength();
            transitionView4PathRef.current.style.strokeDasharray = pathLength4 + ' ' + pathLength4;
            var drawLength4 = pathLength4 * (Math.pow(transition4Progress, 2));
            transitionView4PathRef.current.style.strokeDashoffset = pathLength4 - drawLength4;

            if (transition4Progress >= 0.25) transitionView4Advantage1Ref.current.classList.add('visible');
            if (transition4Progress >= 0.50) transitionView4Advantage2Ref.current.classList.add('visible');
            if (transition4Progress >= 0.75) transitionView4Advantage3Ref.current.classList.add('visible');
        }

        // SubPanel 5
        const subPanel5Progress = 1 - Math.min(Math.max((mainView5Ref.current.getBoundingClientRect().left / e.target.getBoundingClientRect().width), 0), 1);
        if (subPanel5Progress >= 0 && subPanel5Progress <= 1) {
            const overlayTitleSubPanel5Progress = Math.pow(subPanel5Progress, 3);
            mainTitlemainView5Ref.current.style.width = `${(overlayTitleSubPanel5Progress * 100)}%`;
            overlayTitlemainView5Ref.current.style.width = `${(1 / overlayTitleSubPanel5Progress) * 100}%`;
        }

        if (transition1Progress < 1) {
            setPreviewView(null);
            setNextView(VIEWS[1]);
        } else if (subPanel2Progress < 1) {
            setPreviewView(VIEWS[0]);
            setNextView(VIEWS[2]);
        } else if (transition2Progress < 1) {
            setPreviewView(VIEWS[1]);
            setNextView(VIEWS[3]);
        } else if (subPanel3Progress < 1) {
            setPreviewView(VIEWS[2]);
            setNextView(VIEWS[4]);
        } else if (transition3Progress < 1) {
            setPreviewView(VIEWS[3]);
            setNextView(VIEWS[5]);
        } else if (subPanel4Progress < 1) {
            setPreviewView(VIEWS[4]);
            setNextView(VIEWS[6]);
        } else if (transition4Progress < 1) {
            setPreviewView(VIEWS[5]);
            setNextView(VIEWS[7]);
        } else if (subPanel5Progress < 1) {
            setPreviewView(VIEWS[6]);
            setNextView(VIEWS[8]);
        } else {
            setPreviewView(VIEWS[7]);
            setNextView(null);
        }
    }

    const onMouseMoving = (e) => {
        const HalfScreenWidth = (window.innerWidth / 2);
        const HalfScreenHeight = (window.innerHeight / 2);
        const ratioScreenX = (e.screenX - HalfScreenWidth) / HalfScreenWidth
        const ratioScreenY = (e.screenY - HalfScreenHeight) / HalfScreenHeight;
        const velocity = 15;

        objectClockRef.current.style.transform = `translateX(${ratioScreenX * velocity}px) translateY(${ratioScreenY * velocity}px)`;
        objectGamepadRef.current.style.transform = `translateX(${ratioScreenX * velocity}px) translateY(${ratioScreenY * velocity}px)`;
        objectKeyRef.current.style.transform = `translateX(${ratioScreenX * velocity}px) translateY(${ratioScreenY * velocity}px)`;
        objectKeyRef.current.style.transform = `translateX(${ratioScreenX * velocity}px) translateY(${ratioScreenY * velocity}px)`;
        objectSpiral1Ref.current.style.transform = `translateX(${ratioScreenX * velocity}px) translateY(${ratioScreenY * velocity}px)`;
    }

    useEffect(() => {
        document.addEventListener('scroll', onVerticalScrolling);
        document.addEventListener('mousemove', onMouseMoving);

        return () => {
            document.removeEventListener('scroll', onVerticalScrolling);
            document.removeEventListener('mousemove', onMouseMoving);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("TRAINING_CENTER")} · FormationAcademy</title>
                <meta name="description" content="FormationAcademy est un centre de formation en ligne et à distance proposant des formations personnalisées & accompagnées par des experts de l'IT. Passez les certifications Microsoft, VMWare, AWS, et bien plus encore." />
            </Helmet>

            <div className='solution-panel'>
                <div ref={makeAppointmentBtnRef} className='make-appointment-btn transition shadow' onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })}>
                    {t("MAKE_APPOINTMENT_WITH_COACH")}
                </div>

                <div className='arrows transition' ref={arrowsContainerRef}>
                    <a href={`#${previousView || 'disabled'}`} className={`${!previousView ? 'disabled' : ''}`}><div className='icon transition arrow'></div></a>
                    <div className='separate'></div>
                    <a href={`#${nextView || 'disabled'}`} className={`${!nextView ? 'disabled' : ''}`}><div className='icon transition arrow'></div></a>
                </div>

                <SolutionFixedView
                    mainIllustrationRef={mainIllustrationRef}
                    objectBookRef={objectBookRef}
                    objectClockRef={objectClockRef}
                    objectGamepadRef={objectGamepadRef}
                    objectKeyRef={objectKeyRef}
                    objectBatteryRef={objectBatteryRef}
                    objectSpiral1Ref={objectSpiral1Ref} />

                <Element name="discover-start">
                    <div className='main main-scrollable' ref={scrollableContainerRef}>
                        <div className={`main shadow transition`} ref={mainPanelRef} onScroll={onHorizontalScrolling} containerId="containerElement">
                            <MainViewOne
                                mainViewRef={mainView1Ref}
                                mainTitlemainViewRef={mainTitlemainView1Ref}
                                overlayTitlemainViewRef={overlayTitlemainView1Ref} />

                            <TransitionViewOne
                                transitionViewRef={transitionView1Ref}
                                transitionViewPathRef={transitionView1PathRef}
                                transitionViewAdvantage1Ref={transitionView1Advantage1Ref}
                                transitionViewAdvantage2Ref={transitionView1Advantage2Ref}
                                transitionViewAdvantage3Ref={transitionView1Advantage3Ref} />

                            <MainViewTwo
                                mainViewRef={mainView2Ref}
                                mainTitlemainViewRef={mainTitlemainView2Ref}
                                overlayTitlemainViewRef={overlayTitlemainView2Ref} />

                            <TransitionViewTwo
                                transitionViewRef={transitionView2Ref}
                                transitionViewPathRef={transitionView2PathRef}
                                transitionViewAdvantage1Ref={transitionView2Advantage1Ref}
                                transitionViewAdvantage2Ref={transitionView2Advantage2Ref}
                                transitionViewAdvantage3Ref={transitionView2Advantage3Ref} />

                            <MainViewThree
                                mainViewRef={mainView3Ref}
                                mainTitlemainViewRef={mainTitlemainView3Ref}
                                overlayTitlemainViewRef={overlayTitlemainView3Ref} />

                            <TransitionViewThree
                                transitionViewRef={transitionView3Ref}
                                transitionViewPathRef={transitionView3PathRef}
                                transitionViewAdvantage1Ref={transitionView3Advantage1Ref}
                                transitionViewAdvantage2Ref={transitionView3Advantage2Ref}
                                transitionViewAdvantage3Ref={transitionView3Advantage3Ref} />

                            <MainViewFour
                                mainViewRef={mainView4Ref}
                                mainTitlemainViewRef={mainTitlemainView4Ref}
                                overlayTitlemainViewRef={overlayTitlemainView4Ref} />

                            <TransitionViewFour
                                transitionViewRef={transitionView4Ref}
                                transitionViewPathRef={transitionView4PathRef}
                                transitionViewAdvantage1Ref={transitionView4Advantage1Ref}
                                transitionViewAdvantage2Ref={transitionView4Advantage2Ref}
                                transitionViewAdvantage3Ref={transitionView4Advantage3Ref}
                                mainPanelRef={mainPanelRef} />

                            <MainViewFive
                                mainViewRef={mainView5Ref}
                                mainTitlemainViewRef={mainTitlemainView5Ref}
                                overlayTitlemainViewRef={overlayTitlemainView5Ref} />
                        </div>
                    </div>
                </Element>
            </div>
        </>
    );
}

export default SolutionPanel;