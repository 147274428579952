import { combineReducers } from 'redux';
import CourseAdminPanelReducer from './admin/CourseAdminPanelReducer';
import UserAdminPanelReducer from './admin/UserAdminPanelReducer';
import DashboardReducer from './dashboard/DashboardPanelReducer';
import LecturePanelReducer from './dashboard/LecturePanelReducer';
import PreferencePanelReducer from './dashboard/PreferencePanelReducer';
import MenuReducer from './MenuReducer';
import AssignAdditionalAdminPopupReducer from './popup/admin/AssignAdditionalAdminPopupReducer';
import AssignCategoryAdminPopupReducer from './popup/admin/AssignCategoryAdminPopupReducer';
import AssignLectureAdminPopupReducer from './popup/admin/AssignLectureAdminPopupReducer';
import AssignSectionAdminPopupReducer from './popup/admin/AssignSectionAdminPopupReducer';
import AssignVideoAdminPopupReducer from './popup/admin/AssignVideoAdminPopupReducer';
import CourseAdminPopupReducer from './popup/admin/CourseAdminPopupReducer';
import AssignDocumentationAdminPopupReducer from './popup/admin/AssignDocumentationAdminPopupReducer';
import AssignEmbedPageAdminPopupReducer from './popup/admin/AssignEmbedPageAdminPopupReducer';
import UserAdminPopupReducer from './popup/admin/UserAdminPopupReducer';
import PreviewPopupReducer from './popup/PreviewPopupReducer';
import InboundPopupReducer from './popup/InboundPopupReducer';
import InterviewPopupReducer from './popup/InterviewPopupReducer';
import SignPopupReducer from './popup/SignPopupReducer';
import VoucherPopupReducer from './popup/VoucherPopupReducer';
import CategoryPanelReducer from './shop/CategoryPanelReducer';
import HomePanelReducer from './shop/HomePanelReducer';
import PreviewPanelReducer from './shop/PreviewPanelReducer';
import CategoryAdminPanelReducer from './admin/CategoryAdminPanelReducer';
import CategoryAdminPopupReducer from './popup/admin/CategoryAdminPopupReducer';
import AssignCourseAdminPopupReducer from './popup/admin/AssignCourseAdminPopupReducer';
import VoucherAdminPanelReducer from './admin/VoucherAdminPanelReducer';
import AssignFileAdminPopupReducer from './popup/admin/AssignFileAdminPopupReducer';
import AssignUserAdminPopupReducer from './popup/admin/AssignUserAdminPopupReducer';
import VoucherAdminPopupReducer from './popup/admin/VoucherAdminPopupReducer';
import AssignCertificateAdminPopupReducer from './popup/admin/AssignCertificateAdminPopupReducer';
import SurveyAdminPopupReducer from './popup/admin/SurveyAdminPopupReducer';
import TracerAdminPanelReducer from './admin/TracerAdminPanelReducer';

const rootReducer = combineReducers({
    menu: MenuReducer,

    signPopup: SignPopupReducer,
    voucherPopup: VoucherPopupReducer,
    previewPopup: PreviewPopupReducer,
    interviewPopup: InterviewPopupReducer,
    inboundPopup: InboundPopupReducer,

    userAdminPopup: UserAdminPopupReducer,
    courseAdminPopup: CourseAdminPopupReducer,
    categoryAdminPopup: CategoryAdminPopupReducer,
    voucherAdminPopup: VoucherAdminPopupReducer,
    assignAdditionalAdminPopup: AssignAdditionalAdminPopupReducer,
    assignFileAdminPopup: AssignFileAdminPopupReducer,
    assignCategoryAdminPopup: AssignCategoryAdminPopupReducer,
    assignSectionAdminPopup: AssignSectionAdminPopupReducer,
    assignLectureAdminPopup: AssignLectureAdminPopupReducer,
    assignVideoAdminPopup: AssignVideoAdminPopupReducer,
    AssignEmbedPageAdminPopup: AssignEmbedPageAdminPopupReducer,
    assignDocumentationAdminPopup: AssignDocumentationAdminPopupReducer,
    assignCourseAdminPopup: AssignCourseAdminPopupReducer,
    assignUserAdminPopup: AssignUserAdminPopupReducer,
    assignCertificateAdminPopup: AssignCertificateAdminPopupReducer,
    surveyAdminPopup: SurveyAdminPopupReducer,

    homePanel: HomePanelReducer,
    categoryPanel: CategoryPanelReducer,
    previewPanel: PreviewPanelReducer,
    dashboardPanel: DashboardReducer,
    preferencePanel: PreferencePanelReducer,
    lecturePanel: LecturePanelReducer,

    userAdminPanel: UserAdminPanelReducer,
    courseAdminPanel: CourseAdminPanelReducer,
    categoryAdminPanel: CategoryAdminPanelReducer,
    voucherAdminPanel: VoucherAdminPanelReducer,
    tracerAdminPanel: TracerAdminPanelReducer,
});

export default rootReducer;
