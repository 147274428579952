import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { USER_ADMIN_POPUP_RESET, USER_ADMIN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { loadUserAdminPopup } from '../../redux/actions/popup/admin/UserPopupActions';
import { ADMIN_USER_MENU_TYPE } from '../../resources/constants/adminPanel';
import CertificateUserAdminPopup from './user/CertificateUserAdminPopup';
import CourseUserAdminPopup from './user/CourseUserAdminPopup';
import OverviewUserAdminPopup from './user/OverviewUserAdminPopup';
import SecurityUserAdminPopup from './user/SecurityUserAdminPopup';
import SurveyUserAdminPopup from './user/SurveyUserAdminPopup';

const UserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);

    useEffect(() => {
        if (userAdminStore.userId)
            dispatch(loadUserAdminPopup(userAdminStore.userId));

        return () => {
            dispatch({ type: USER_ADMIN_POPUP_RESET });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={`user-admin ${userAdminStore.isLoading ? 'disabled' : ''}`}>
            {userAdminStore.popupEnabled === ADMIN_USER_MENU_TYPE.OVERVIEW && <OverviewUserAdminPopup />}
            {userAdminStore.popupEnabled === ADMIN_USER_MENU_TYPE.SECURITY && <SecurityUserAdminPopup />}
            {userAdminStore.popupEnabled === ADMIN_USER_MENU_TYPE.COURSES && <CourseUserAdminPopup />}
            {userAdminStore.popupEnabled === ADMIN_USER_MENU_TYPE.CERTIFICATES && <CertificateUserAdminPopup />}
            {userAdminStore.popupEnabled === ADMIN_USER_MENU_TYPE.SURVEYS && <SurveyUserAdminPopup />}
        </div>
    );
}

export default AdminPopupHOC(UserAdminPopup, null, 'Opération sur un utilisateur', 'userAdminPopup', USER_ADMIN_POPUP_SET_ENABLED);