import { COURSE_ADMIN_PANEL_SET_LOADING, COURSE_ADMIN_PANEL_SET_COURSES, COURSE_ADMIN_PANEL_ADD_COURSES, COURSE_ADMIN_PANEL_SET_SEARCH_VALUE, COURSE_ADMIN_PANEL_SET_HAS_NEXT, COURSE_ADMIN_PANEL_SET_PAGING_PAGE, COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS, COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL, COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE, COURSE_ADMIN_PANEL_DELETE_COURSE, COURSE_ADMIN_PANEL_UPDATE_COURSE, COURSE_ADMIN_PANEL_ADD_USER_COURSE, COURSE_ADMIN_PANEL_DELETE_USER_COURSE } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    courses: [],
    pagingPage: 0,
    hasNext: true,

    searchValue: ''
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case COURSE_ADMIN_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case COURSE_ADMIN_PANEL_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case COURSE_ADMIN_PANEL_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        case COURSE_ADMIN_PANEL_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case COURSE_ADMIN_PANEL_ADD_COURSES: {
            return { ...state, courses: [...state.courses.concat(action.payload.courses)] };
        }
        case COURSE_ADMIN_PANEL_DELETE_COURSE: {
            return { ...state, courses: state.courses.filter(c => c.id !== action.payload.id) };
        }
        case COURSE_ADMIN_PANEL_UPDATE_COURSE: {
            return {
                ...state, courses: [...state.courses.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return action.payload.course;
                })]
            }
        }
        case COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS: {
            const { id, enabled } = action.payload;
            return {
                ...state, courses: [...state.courses.map(c => {
                    if (c.id !== id)
                        return c;

                    return { ...c, enabled };
                })]
            }
        }
        case COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL: {
            const { id, model } = action.payload;
            return {
                ...state, courses: [...state.courses.map(c => {
                    if (c.id !== id)
                        return c;

                    return { ...c, model };
                })]
            }
        }
        case COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE: {
            const { id, picture } = action.payload;
            return {
                ...state, courses: [...state.courses.map(c => {
                    if (c.id !== id)
                        return c;

                    return { ...c, picture };
                })]
            }
        }

        case COURSE_ADMIN_PANEL_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }

        case COURSE_ADMIN_PANEL_ADD_USER_COURSE: {
            const { courseId, user } = action.payload;
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== courseId)
                        return c;

                    return { ...c, activesUsers: [...c.activesUsers, user] };
                })
            }
        }
        case COURSE_ADMIN_PANEL_DELETE_USER_COURSE: {
            const { courseId, userId } = action.payload;
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== courseId)
                        return c;

                    return { ...c, activesUsers: c.activesUsers.filter(u => u.id !== userId) };
                })
            }
        }

        default:
            return state;
    }
}