import React from 'react';
import propTypes from 'prop-types';

const InputHOC = (WrappedComponent) => {
    const HOC = (props) => {
        return <WrappedComponent {...props} />
    };

    HOC.propTypes = {
        value: propTypes.string,
        onChange: propTypes.func,
        placeholder: propTypes.string,
        title: propTypes.string,
        type: propTypes.string,
        autocomplete: propTypes.string,
        disabled: propTypes.bool,
        error: propTypes.bool,
        helperText: propTypes.string,
        success: propTypes.bool,
        inputRef: propTypes.any
    }

    return HOC;
}


export default InputHOC;