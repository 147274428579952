import { INBOUND_POPUP_EMAIL_CHECK, INBOUND_POPUP_SET_EMAIL, INBOUND_POPUP_SET_ENABLED, INBOUND_POPUP_SET_LOADING, INBOUND_POPUP_SET_SAVED, INBOUND_POPUP_SET_COURSE_TITLE, INBOUND_POPUP_SET_COMPLETION, INBOUND_POPUP_RESET, INBOUND_POPUP_SET_FIRSTNAME, INBOUND_POPUP_SET_LASTNAME, INBOUND_POPUP_SET_PHONE, INBOUND_POPUP_SET_COMPANY, INBOUND_POPUP_FIRSTNAME_CHECK, INBOUND_POPUP_LASTNAME_CHECK, INBOUND_POPUP_PHONE_CHECK, INBOUND_POPUP_COMPANY_CHECK, INBOUND_POPUP_SET_JOB_STATUS } from "../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: false,

    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    company: '',
    jobStatus: null,

    isEmailValid: true,
    isFirstnameValid: true,
    isLastnameValid: true,
    isPhoneValid: true,
    isCompanyValid: true,

    saved: false,
    courseTitle: '',
    completion: null,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case INBOUND_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.isEnabled };
        }
        case INBOUND_POPUP_RESET: {
            return defaultState
        }

        case INBOUND_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case INBOUND_POPUP_SET_EMAIL: {
            return { ...state, email: action.payload.email, isEmailValid: true };
        }
        case INBOUND_POPUP_SET_FIRSTNAME: {
            return { ...state, firstname: action.payload.firstname, isFirstnameValid: true };
        }
        case INBOUND_POPUP_SET_LASTNAME: {
            return { ...state, lastname: action.payload.lastname, isLastnameValid: true };
        }
        case INBOUND_POPUP_SET_PHONE: {
            return { ...state, phone: action.payload.phone, isPhoneValid: true };
        }
        case INBOUND_POPUP_SET_COMPANY: {
            return { ...state, company: action.payload.company, isCompanyValid: true };
        }
        case INBOUND_POPUP_SET_JOB_STATUS: {
            console.log(action.payload.jobStatus);
            return { ...state, jobStatus: action.payload.jobStatus };
        }

        case INBOUND_POPUP_EMAIL_CHECK: {
            return { ...state, isEmailValid: action.payload.isValid };
        }
        case INBOUND_POPUP_FIRSTNAME_CHECK: {
            return { ...state, isFirstnameValid: action.payload.isValid };
        }
        case INBOUND_POPUP_LASTNAME_CHECK: {
            return { ...state, isLastnameValid: action.payload.isValid };
        }
        case INBOUND_POPUP_PHONE_CHECK: {
            return { ...state, isPhoneValid: action.payload.isValid };
        }
        case INBOUND_POPUP_COMPANY_CHECK: {
            return { ...state, isCompanyValid: action.payload.isValid };
        }

        case INBOUND_POPUP_SET_SAVED: {
            return { ...state, saved: action.payload.saved };
        }
        case INBOUND_POPUP_SET_COURSE_TITLE: {
            return { ...state, courseTitle: action.payload.title };
        }
        case INBOUND_POPUP_SET_COMPLETION: {
            return { ...state, completion: action.payload.completion };
        }

        default:
            return state;
    }
}