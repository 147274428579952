import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { COURSE_ADMIN_POPUP_RESET, COURSE_ADMIN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { ADMIN_COURSE_MENU_TYPE } from '../../resources/constants/adminPanel';
import DetailedInformationCourseAdminPopup from './course/DetailedInformationCourseAdminPopup';
import OverviewCourseAdminPopup from './course/OverviewCourseAdminPopup';
import UserCourseAdminPopup from './course/UserCourseAdminPopup';
import SwitchButton from '../../SwitchButton';
import { loadCourseAdminPopup, loadUsersCourseAdminPopup, updateStatusCourseAdminPopup } from '../../redux/actions/popup/admin/CoursePopupActions';
import Button from '../../Button';
import { Link } from 'react-router-dom';
import { convertStringForUrl } from '../../Utils';

const ExtraComponentCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPopup);

    if (!courseAdminStore.courseId)
        return null;

    return (
        <div className='course'>
            <div className={`switch ${courseAdminStore.isLoading ? 'disabled' : ''}`}>
                <label className={`${courseAdminStore.enabled ? 'enabled' : ''}`}>
                    <div className='dot'></div>
                    <span>{courseAdminStore.enabled ? 'Activé' : 'Désactivé'}</span>
                </label>
                <SwitchButton disabled={courseAdminStore.isLoading} onChange={(enabled) => dispatch(updateStatusCourseAdminPopup(courseAdminStore.courseId, enabled))} checked={courseAdminStore.enabled} />
            </div>
            <Link to={`/course/${courseAdminStore.courseId}-${convertStringForUrl(courseAdminStore.title)}/lecture/admin`} onClick={() => dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: null } })}><Button btnStyle={'white autofit'} title={'Editer'} /></Link>
        </div>
    )
}

const CourseAdminPopup = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPopup);

    useEffect(() => {
        if (courseAdminStore.courseId)
            dispatch(loadCourseAdminPopup(courseAdminStore.courseId));

        return () => {
            dispatch({ type: COURSE_ADMIN_POPUP_RESET });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={`course-admin ${courseAdminStore.isLoading ? 'disabled' : ''}`}>
            {courseAdminStore.showAdvertisement && <div className='advertisement'>⚠️ Certaines modifications peuvent ne pas apparaitre sur la page d'édition mais alors qu'elles ont bien été effectuées. ⚠️</div>}
            {courseAdminStore.popupEnabled === ADMIN_COURSE_MENU_TYPE.OVERVIEW && <OverviewCourseAdminPopup />}
            {courseAdminStore.popupEnabled === ADMIN_COURSE_MENU_TYPE.DETAILS && <DetailedInformationCourseAdminPopup />}
            {courseAdminStore.popupEnabled === ADMIN_COURSE_MENU_TYPE.USERS && <UserCourseAdminPopup />}
        </div>
    );
}

export default AdminPopupHOC(CourseAdminPopup, null, 'Opération sur une formation', 'courseAdminPopup', COURSE_ADMIN_POPUP_SET_ENABLED, ExtraComponentCourseAdminPopup, {
    storeName: 'courseAdminPopup',
    completion: (dispatch, store) => {
        if (store.popupEnabled === ADMIN_COURSE_MENU_TYPE.USERS) {
            if (!store.isUsersLoading && store.hasNext) {
                dispatch(loadUsersCourseAdminPopup(store.courseId, store.pagingPage + 1, store.userSearchValue));
            }
        }
    }
});