import React, { useRef, useState } from 'react';
import Button from '../../../Button';
import Input from '../../../Input';
import InputPhone from '../../../InputPhone';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ADMIN_POPUP_SET_COMPANY, USER_ADMIN_POPUP_SET_EMAIL, USER_ADMIN_POPUP_SET_FIRSTNAME, USER_ADMIN_POPUP_SET_LASTNAME, USER_ADMIN_POPUP_SET_PHONE } from '../../../redux/actions/ActionTypes';
import { confirmUserAdminPopup, createUserAdminPopup, deleteUserPictureAdminPopup, deleteUserAdminPopup, updateUserAdminPopup, uploadUserPictureAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import { createNotification } from '../../../Utils';
import BasicLoader from '../../../loader/BasicLoader';
import { useTranslation } from 'react-i18next';

// Images
import Profile from '../../../resources/images/profile.png';

const OverviewUserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const filePickerRef = useRef(null);
    const [isHiddenConfirmButton, setHiddenConfirmButton] = useState(false);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        if (userAdminStore.userId) {
            dispatch(updateUserAdminPopup(userAdminStore));
        } else {
            dispatch(createUserAdminPopup(userAdminStore))
        }
    }

    const onImageChoosen = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            reader.onloadend = () => {
                if (file.size < 2000000) {
                    dispatch(uploadUserPictureAdminPopup(userAdminStore.userId, file));
                } else {
                    createNotification(t("NOTIFICATION_ERROR_TITLE"), `${t("IMAGE_TOO_WEIGHT")}. (Maximum 2MB)`, 'danger');
                }
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <div className='overview'>
            <div>
                <form onSubmit={onSubmit}>
                    <div className='content-title'>
                        <h2>Présentation</h2>
                    </div>


                    {userAdminStore.userId &&
                        <div className='section'>
                            <h3>Image</h3>
                            <div className='picture'>
                                <div className='image'>
                                    {userAdminStore.isPictureLoading ? <BasicLoader width={'100%'} height={'100%'} radius={'50%'} /> : <img className={'shadow'} src={userAdminStore.photoLink || Profile} alt={`${userAdminStore.firstname} ${userAdminStore.lastname}`} />}
                                </div>

                                <div className='actions'>
                                    <Button title={t("DASHBOARD_DOWNLOAD_FILE")} btnStyle={'primary'} isLoading={userAdminStore.isPictureLoading} onClick={() => filePickerRef.current.click()}></Button>
                                    <input style={{ display: 'none' }} accept='image/*' type='file' ref={filePickerRef} onChange={onImageChoosen} />
                                    {!userAdminStore.isPictureLoading && userAdminStore.photoLink && <Button title={t("DASHBOARD_DELETE_PHOTO")} btnStyle={'cancel'} confirmationRequired onClick={() => dispatch(deleteUserPictureAdminPopup(userAdminStore.userId))} isLoading={userAdminStore.isPictureLoading} />}
                                </div>
                            </div>
                        </div>}

                    <div className='section'>
                        <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={userAdminStore.email} onChange={(email) => dispatch({ type: USER_ADMIN_POPUP_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={userAdminStore.isLoading || userAdminStore.isInformationsLoading} error={!userAdminStore.isEmailValid} helperText={t("HELPER_EMAIL")} />

                        <div className='col-2 first'>
                            <Input title={t("FIRSTNAME")} placeholder={t("FIRSTNAME")} value={userAdminStore.firstname} onChange={(firstname) => dispatch({ type: USER_ADMIN_POPUP_SET_FIRSTNAME, payload: { firstname } })} type={'text'} autocomplete={'given-name'} disabled={userAdminStore.isLoading || userAdminStore.isInformationsLoading} error={!userAdminStore.isFirstnameValid} helperText={t("HELPER_FIRSTNAME")} />
                            <Input title={t("LASTNAME")} placeholder={t("LASTNAME")} value={userAdminStore.lastname} onChange={(lastname) => dispatch({ type: USER_ADMIN_POPUP_SET_LASTNAME, payload: { lastname } })} type={'text'} autocomplete={'family-name'} disabled={userAdminStore.isLoading || userAdminStore.isInformationsLoading} error={!userAdminStore.isLastnameValid} helperText={t("HELPER_LASTNAME")} />
                        </div>

                        <div className='col-2'>
                            <InputPhone title={`${t("PHONE")} (${t("OPTIONAL")})`} placeholder={t("PLACEHOLDER_PHONE")} value={userAdminStore.phone} onChange={(phone) => dispatch({ type: USER_ADMIN_POPUP_SET_PHONE, payload: { phone } })} error={!userAdminStore.isPhoneValid} helperText={t("HELPER_PHONE")} disabled={userAdminStore.isLoading || userAdminStore.isInformationsLoading} />
                            <Input title={`${t("COMPANY")} (${t("OPTIONAL")})`} placeholder={t("COMPANY")} value={userAdminStore.company} onChange={(company) => dispatch({ type: USER_ADMIN_POPUP_SET_COMPANY, payload: { company } })} type={'text'} autocomplete={'organization'} disabled={userAdminStore.isLoading || userAdminStore.isInformationsLoading} error={!userAdminStore.isCompanyValid} helperText={t("HELPER_COMPANY")} />
                        </div>
                    </div>

                    <div className='right-alignment'>
                        {userAdminStore.userId ? <Button title={t("UPDATE")} btnStyle='primary autofit' disabled={userAdminStore.isLoading} isLoading={userAdminStore.isInformationsLoading} type={'submit'} /> : <Button title={'Créer l\'utilisateur'} btnStyle='primary autofit' disabled={userAdminStore.isLoading} isLoading={userAdminStore.isInformationsLoading} type={'submit'} />}
                    </div>
                </form>
            </div>

            {userAdminStore.userId &&
                <>
                    <div className='or'>
                        <hr />
                    </div>

                    <div>
                        <div className='content-title'>
                            <h2>Zone de danger</h2>
                        </div>
                        <div className='section'>
                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Membre confirmé</h3>
                                    <span>Modification le statut du compte</span>
                                </div>

                                {!isHiddenConfirmButton && <Button title={userAdminStore.confirmed ? 'Marquer comme non confirmé' : 'Marquer comme confirmé'} btnStyle={`autofit ${userAdminStore.confirmed ? 'cancel' : 'primary'}`} confirmationRequired onClick={async () => {
                                    if (await dispatch(confirmUserAdminPopup(userAdminStore.userId, !userAdminStore.confirmed)))
                                        setHiddenConfirmButton(true);
                                }} isLoading={userAdminStore.isLoading} />}
                            </div>

                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Supprimer l'utilisateur</h3>
                                    <span>Cette opération est irréversible</span>
                                </div>

                                <Button title={'Supprimer l\'utilisateur'} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteUserAdminPopup(userAdminStore.userId))} isLoading={userAdminStore.isLoading} />
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default OverviewUserAdminPopup;