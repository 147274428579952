import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MainViewTwo = ({ mainViewRef, mainTitlemainViewRef, overlayTitlemainViewRef }) => {
    const { t } = useTranslation()

    return (
        <div className='sub-main' ref={mainViewRef} id='view-2'>
            <div className='container'>
                <div className='animate help'></div>
                <div className='title'>
                    <div className='main-title'>
                        <h3>
                            {t("CUSTOM_COURSE")}

                            <div className='overlay' ref={mainTitlemainViewRef}>
                                <h3 ref={overlayTitlemainViewRef}>{t("CUSTOM_COURSE")}</h3>
                            </div>
                        </h3>
                    </div>

                    <Link to='/' className='cto-button-container'>
                        <div className='btn'>{t("SEE_CATALOG")}</div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MainViewTwo;