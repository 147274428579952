import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthenticationService from '../services/Authentication'

const IsLoggedHOC = (WrappedComponent, mustBeLogged = true) => {
    return (props) => {
        if (mustBeLogged && !AuthenticationService.GetSession()) {
            return <Redirect to='/' />
        }

        if (!mustBeLogged && AuthenticationService.GetSession())
            return <Redirect to='/dashboard' />

        return (
            <WrappedComponent {...props} />
        );
    };
};

export default IsLoggedHOC;