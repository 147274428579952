import Axios from "axios";
import { USER_ADMIN_PANEL_ADD_USERS, USER_ADMIN_PANEL_SET_HAS_NEXT, USER_ADMIN_PANEL_SET_LOADING, USER_ADMIN_PANEL_SET_PAGING_PAGE } from "../ActionTypes";

export const loadUserAdmin = (pageIndex = null, name = null, showActiveUsersOnly) => async dispatch => {
    dispatch({ type: USER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`user/list/all/admin`, {
        params: {
            pageIndex,
            name: name || null,
            showActiveUsersOnly
        }
    }).then(({ data }) => {
        dispatch({ type: USER_ADMIN_PANEL_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
        dispatch({ type: USER_ADMIN_PANEL_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: USER_ADMIN_PANEL_ADD_USERS, payload: { users: data.paging } });
    }).finally(() => {
        dispatch({ type: USER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: false } });
    });
}