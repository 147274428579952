import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';

const MainViewThree = ({ mainViewRef, mainTitlemainViewRef, overlayTitlemainViewRef }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    return (
        <div className='sub-main' ref={mainViewRef} id='view-3'>
            <div className='container'>
                <div className='animate practise'></div>
                <div className='title'>
                    <div className='main-title'>
                        <h3>
                            {t("PRO_LABS")}

                            <div className='overlay' ref={mainTitlemainViewRef}>
                                <h3 ref={overlayTitlemainViewRef}>{t("PRO_LABS")}</h3>
                            </div>
                        </h3>
                    </div>

                    <div onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })} className='cto-button-container'>
                        <div className='btn'>{t("SEE_MORE_LABS")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainViewThree;