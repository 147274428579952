import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHOC from '../HOC/PopupHOC';
import { VOUCHER_POPUP_RESET } from '../redux/actions/ActionTypes';
import VoucherAddedList from './voucher/VoucherAddedList';
import VoucherForm from './voucher/VoucherForm';
import { useTranslation } from 'react-i18next';

const VoucherPopup = (props) => {
    const dispatch = useDispatch();
    const voucherStore = useSelector(store => store.voucherPopup);
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch({ type: VOUCHER_POPUP_RESET });
        }
    }, [dispatch]);


    return (
        <div className='popup white rounded-l shadow padding voucher'>
            <div className='header'>
                <div className='title'>
                    <div className='icon-container'></div>
                    <h1>{t("JOIN_COURSE")}</h1>
                    <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                </div>
                <span>
                    {!voucherStore.vouchers ? t("VOUCHER_INSERT_CODE") : t("VOUCHER_CODE_INSERTED")}
                </span>
            </div>

            <div className='content'>
                {!voucherStore.vouchers ? <VoucherForm /> : <VoucherAddedList />}
            </div>
        </div>
    );
}

export default PopupHOC(VoucherPopup);