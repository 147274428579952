import jsPDF from 'jspdf';
import Logo from '../resources/images/logo/logo.png';
import Banner from '../resources/images/pdf/banner.png';
import ContactBtn from '../resources/images/pdf/contact.png';
import Check from '../resources/images/icon/check.png';
import Star from '../resources/images/icon/star.png';
import Bag from '../resources/images/icon/bag.png';
import '../resources/fonts/Raleway-Bold-normal';
import '../resources/fonts/Raleway-Light-normal';
import '../resources/fonts/Raleway-SemiBold-normal';
import { convertSecondToAverageTime, convertSecondToTime, convertStringForUrl } from '../Utils';
import i18n from 'i18next';

export default function CoursePreviewPDF(course, completion = null) {
    let doc = new jsPDF();

    const defineSpecsHeight = (specs) => {
        CURRENT_Y += 13;
        doc.setFont('Raleway-SemiBold');
        doc.setFontSize(10);

        doc.setFont('Raleway-Light');
        doc.setFontSize(8);
        specs.forEach(o => {
            CURRENT_Y += doc.getTextDimensions(o.label, {
                fontSize: 8,
                maxWidth: MAX_WIDTH - 10
            }).h + 3;
        });
    }

    const writeSpecs = (title, specs, icon) => {
        CURRENT_Y += 5;
        doc.setFont('Raleway-SemiBold');
        doc.setFontSize(10);
        doc.text(title, MARGIN + 5, CURRENT_Y);
        CURRENT_Y += 8;

        doc.setFont('Raleway-Light');
        doc.setFontSize(8);
        specs?.forEach(o => {
            doc.addImage(icon, 'png', MARGIN + 5, CURRENT_Y - 2, 3, 3);
            doc.text(doc.splitTextToSize(o.label, MAX_WIDTH - 18), MARGIN + 12, CURRENT_Y);
            CURRENT_Y += doc.getTextDimensions(o.label, {
                fontSize: 8,
                maxWidth: MAX_WIDTH - 10
            }).h + 3;
        });
    }

    const getLectureHeight = (lecture) => {
        const savePostion = Number(CURRENT_Y);
        CURRENT_Y += 5;
        doc.setFontSize(8);
        const lectureTitleHeight = doc.getTextDimensions(lecture.title, {
            fontSize: 8,
            maxWidth: MAX_WIDTH - 20
        }).h

        doc.setFont("Raleway-Light");
        doc.setFontSize(6);

        CURRENT_Y += lectureTitleHeight + 2;

        // Objectifs
        if (lecture.description) {
            doc.setFont("Raleway-Bold");
            doc.setFontSize(5);
            CURRENT_Y += 5;
            doc.setFontSize(7);
            doc.setFont("Raleway-Light");
            const objectivesHeight = doc.getTextDimensions(lecture.description, {
                fontSize: 7,
                maxWidth: MAX_WIDTH - 20
            }).h;
            CURRENT_Y += objectivesHeight;
            CURRENT_Y += 2;
        }

        const finalHeight = Number(CURRENT_Y - savePostion);
        CURRENT_Y = savePostion;
        return finalHeight;
    }

    const newPage = () => {
        doc.addPage();
        CURRENT_Y = MARGIN * 2;
    }

    function addFooter(doc) {
        let totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.addImage(Logo, 'png', PAGE_WIDTH - MARGIN - 35, PAGE_HEIGHT - 15, 27, 10);
            doc.link(PAGE_WIDTH - MARGIN - 27, PAGE_HEIGHT - 15, 27, 10, { url: 'https://formation.academy/' });
            doc.setFontSize(5);
            doc.setFont("Raleway-Bold");
            doc.text(`https://formation.academy/course/${course.id}-${convertStringForUrl(course.title)}/preview`, MARGIN, PAGE_HEIGHT - 10);

            doc.setFontSize(8);
            doc.setFont("Raleway-Light");
            doc.text(`${i}/${totalPages}`, PAGE_WIDTH - MARGIN, PAGE_HEIGHT - 10, { align: 'right' });
        }

        return doc;
    }

    const MARGIN = 10;
    const MAX_WIDTH = doc.internal.pageSize.getWidth() - (2 * MARGIN);
    const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
    const PAGE_WIDTH = doc.internal.pageSize.getWidth();
    let CURRENT_Y = MARGIN;

    doc.addImage(Logo, 'png', MARGIN, CURRENT_Y, 27, 10);
    CURRENT_Y += 20;

    doc.setFont("Raleway-Bold");
    doc.setFontSize(18);
    doc.text(course.title, MARGIN, CURRENT_Y, { maxWidth: MAX_WIDTH });

    CURRENT_Y += doc.getTextDimensions(course.title, {
        fontSize: 10,
        maxWidth: MAX_WIDTH
    }).h + 2;

    // Objectifs
    const positionObjectivesContainerTop = Number(CURRENT_Y);
    if (course.objectives && course.objectives.length) defineSpecsHeight(course.objectives, Check);
    if (course.publics && course.publics.length) defineSpecsHeight(course.publics, Star);
    if (course.prerequisites && course.prerequisites.length) defineSpecsHeight(course.prerequisites, Bag);

    doc.setFillColor('#edeff2');
    doc.roundedRect(MARGIN, positionObjectivesContainerTop, MAX_WIDTH, CURRENT_Y - positionObjectivesContainerTop + 4, 5, 5, "F");

    CURRENT_Y = positionObjectivesContainerTop;
    CURRENT_Y += 4
    if (course.objectives && course.objectives.length) writeSpecs('Ce que vous allez apprendre', course.objectives, Check);
    if (course.publics && course.publics.length) writeSpecs('Public', course.publics, Star);
    if (course.publics && course.prerequisites.length) writeSpecs('Prérequis', course.prerequisites, Bag);
    CURRENT_Y += 12;

    // Description
    const descriptionHeight = doc.getTextDimensions(course.description, {
        fontSize: 9,
        maxWidth: MAX_WIDTH
    }).h;

    if (CURRENT_Y + descriptionHeight + 25 > PAGE_HEIGHT)
        newPage()

    doc.setFont("Raleway-Bold");
    doc.setFontSize(16);
    doc.text("Description", MARGIN, CURRENT_Y);
    CURRENT_Y += 8;

    doc.setFont("Raleway-Light");
    doc.setFontSize(9);
    doc.text(doc.splitTextToSize(course.description, MAX_WIDTH), MARGIN, CURRENT_Y);
    CURRENT_Y += descriptionHeight;

    CURRENT_Y += 25;
    if (CURRENT_Y + 30 > PAGE_HEIGHT)
        newPage();

    doc.setFont("Raleway-Bold");
    doc.setFontSize(16);
    doc.text("Contenu du cours", MARGIN, CURRENT_Y);
    CURRENT_Y += 5;

    for (const section of course.section) {
        if (CURRENT_Y + 25 > PAGE_HEIGHT)
            newPage();

        doc.setFont("Raleway-SemiBold");
        doc.setFontSize(10);
        const sectionTitleDimensions = doc.getTextDimensions(section.name, {
            fontSize: 10,
            maxWidth: MAX_WIDTH - 30
        });

        if (section.lecture?.length) {
            doc.setFillColor('#edeff2');
            doc.roundedRect(MARGIN, CURRENT_Y, MAX_WIDTH, sectionTitleDimensions.h + 8, 5, 5, "F");
            doc.rect(MARGIN, CURRENT_Y + ((sectionTitleDimensions.h + 8) / 2), MAX_WIDTH, (sectionTitleDimensions.h + 8) / 2, "F");
            doc.text(doc.splitTextToSize(section.name, MAX_WIDTH - 30), MARGIN + 5, CURRENT_Y + 7);
        } else {
            if (CURRENT_Y + 20 > PAGE_HEIGHT)
                newPage();

            doc.setDrawColor("#e2e6ea");
            const lineWidth = ((PAGE_WIDTH - sectionTitleDimensions.w) / 2) - 5
            doc.line(MARGIN, CURRENT_Y + 6, lineWidth, CURRENT_Y + 6);
            doc.line(PAGE_WIDTH - lineWidth, CURRENT_Y + 6, PAGE_WIDTH - MARGIN, CURRENT_Y + 6);
            doc.text(doc.splitTextToSize(section.name, MAX_WIDTH - 30), (PAGE_WIDTH - sectionTitleDimensions.w) / 2, CURRENT_Y + 7);
        }

        doc.setFont("Raleway-Light");
        doc.setFontSize(6);
        const totalMinutesSection = section.lecture.map(lecture => parseInt(lecture.secondDuration)).reduce((a, b) => a + b, 0);
        if (section.lecture.length) doc.text(`${section.lecture.length} ${section.lecture.length > 1 ? i18n.t("LECTURES") : i18n.t("LECTURE")} ${totalMinutesSection ? `- ${convertSecondToAverageTime(totalMinutesSection)}` : ''}`, PAGE_WIDTH - MARGIN - 5, CURRENT_Y + 7, { align: 'right' });

        CURRENT_Y += sectionTitleDimensions.h + 8;

        for (const lecture of section.lecture) {
            const lectureHeight = getLectureHeight(lecture);
            if (CURRENT_Y + lectureHeight + 15 > PAGE_HEIGHT) {
                newPage();
                doc.line(MARGIN, CURRENT_Y, PAGE_WIDTH - MARGIN, CURRENT_Y);
            }

            doc.setDrawColor("#e2e6ea");
            doc.line(MARGIN + 0.15, CURRENT_Y, MARGIN + 0.15, CURRENT_Y + lectureHeight);
            doc.line(PAGE_WIDTH - (MARGIN + 0.15), CURRENT_Y, PAGE_WIDTH - (MARGIN + 0.15), CURRENT_Y + lectureHeight);

            CURRENT_Y += 5;
            doc.setFontSize(8);
            const lectureTitleHeight = doc.getTextDimensions(lecture.title, {
                fontSize: 8,
                maxWidth: MAX_WIDTH - 20
            }).h
            doc.text(doc.splitTextToSize(lecture.title, MAX_WIDTH - 20), MARGIN + 5, CURRENT_Y);

            doc.setFont("Raleway-Light");
            doc.setFontSize(6);
            if (lecture.secondDuration) doc.text(convertSecondToTime(lecture.secondDuration), PAGE_WIDTH - MARGIN - 5, CURRENT_Y, { align: 'right' });

            CURRENT_Y += lectureTitleHeight + 2;

            // Objectifs
            doc.setFont("Raleway-Bold");
            doc.setFontSize(5);
            const startPosition = Number(CURRENT_Y - 1.5);
            let objectivesHeight = 0;
            if (lecture.description) {
                doc.text("OBJECTIFS DE LA LEÇON", MARGIN + 8, CURRENT_Y);
                CURRENT_Y += 5;
                doc.setFont("Raleway-Light");
                doc.setFontSize(7);
                objectivesHeight = doc.getTextDimensions(lecture.description, {
                    fontSize: 7,
                    maxWidth: MAX_WIDTH - 20
                }).h;
                doc.text(doc.splitTextToSize(lecture.description, MAX_WIDTH - 20), MARGIN + 8, CURRENT_Y)
                CURRENT_Y += objectivesHeight;
                CURRENT_Y += 2;

                doc.setDrawColor("#21A6C1");
                doc.line(MARGIN + 6, startPosition, MARGIN + 6, startPosition + objectivesHeight + 5);
            }

            doc.setDrawColor("#e2e6ea");
            doc.line(MARGIN, CURRENT_Y, PAGE_WIDTH - MARGIN, CURRENT_Y);
        }


        CURRENT_Y += 10;
    }

    const imageHeight = (MAX_WIDTH * 741) / 1900;
    if (CURRENT_Y + imageHeight + 35 > PAGE_HEIGHT)
        newPage();

    doc.addImage(Banner, 'png', MARGIN, CURRENT_Y, MAX_WIDTH, (MAX_WIDTH * 741) / 1900);
    doc.link(MARGIN, CURRENT_Y, MAX_WIDTH, (MAX_WIDTH * 741) / 1900, { url: 'https://formation.academy/' });
    CURRENT_Y += imageHeight + 5;

    doc.addImage(ContactBtn, 'png', (PAGE_WIDTH - 75) / 2, CURRENT_Y, 75, 12.62);
    doc.link((PAGE_WIDTH - 75) / 2, CURRENT_Y, 75, 12.62, { url: 'https://calendly.com/janssens/meet' });

    doc = addFooter(doc);
    doc.save(`Programme complet ${course.title}`);
    if (completion) completion();
}