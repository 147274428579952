import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import BasicLoader from '../../../loader/BasicLoader';
import { deletePreferencePhoto, loadPreferencePhoto, uploadPreferencePhoto } from '../../../redux/actions/dashboard/PreferenceActions';
import AuthenticationService from '../../../services/Authentication';
import { createNotification } from '../../../Utils';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Images
import Profile from '../../../resources/images/profile.png';

const PreferencePhoto = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const preferenceStore = useSelector(store => store.preferencePanel);
    const user = AuthenticationService.GetSession();
    const filePickerRef = useRef(null);

    useEffect(() => {
        dispatch(loadPreferencePhoto());
    }, [dispatch]);

    const onImageChoosen = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            reader.onloadend = () => {
                if (file.size < 2000000) {
                    dispatch(uploadPreferencePhoto(file));
                } else {
                    createNotification(t("NOTIFICATION_ERROR_TITLE"), `${t("IMAGE_TOO_WEIGHT")}. (Maximum 2MB)`, 'danger');
                }
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("PHOTO")} · {t("PREFERENCES")} · FormationAcademy</title>
            </Helmet>

            <div className='container-title'>
                <h2>{t("PHOTO")}</h2>
                <span>Modifiez votre image</span>
            </div>

            <div className='picture-panel'>
                <div className='image'>
                    {preferenceStore.isPictureLoading ? <BasicLoader width={'100%'} height={'100%'} radius={'50%'} /> : <img className={'shadow'} src={preferenceStore.photoLink || Profile} alt={`${user.firstname} ${user.lastname}`} />}
                </div>

                <div className='actions'>
                    <Button title={t("DASHBOARD_DOWNLOAD_FILE")} btnStyle={'primary'} isLoading={preferenceStore.isPictureLoading} onClick={() => filePickerRef.current.click()} />
                    <input style={{ display: 'none' }} accept='image/*' type='file' ref={filePickerRef} onChange={onImageChoosen} />
                    {!preferenceStore.isPictureLoading && preferenceStore.photoLink && <Button title={t("DASHBOARD_DELETE_PHOTO")} btnStyle={'cancel'} confirmationRequired onClick={() => dispatch(deletePreferencePhoto())} />}
                </div>
            </div>
        </>
    );
}

export default PreferencePhoto;