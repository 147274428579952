import { PREFERENCE_PANEL_COMPANY_CHECK, PREFERENCE_PANEL_CURRENT_PASSWORD_CHECK, PREFERENCE_PANEL_FIRSTNAME_CHECK, PREFERENCE_PANEL_HAS_CURRENT_PASSWORD, PREFERENCE_PANEL_LASTNAME_CHECK, PREFERENCE_PANEL_NEW_PASSWORD_CHECK, PREFERENCE_PANEL_PHONE_CHECK, PREFERENCE_PANEL_RENEW_PASSWORD_CHECK, PREFERENCE_PANEL_SET_COMPANY, PREFERENCE_PANEL_SET_CURRENT_PASSWORD, PREFERENCE_PANEL_SET_EMAIL, PREFERENCE_PANEL_SET_FIRSTNAME, PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, PREFERENCE_PANEL_SET_LASTNAME, PREFERENCE_PANEL_SET_NEW_PASSWORD, PREFERENCE_PANEL_SET_PHONE, PREFERENCE_PANEL_SET_RENEW_PASSWORD, PREFERENCE_PANEL_SET_INFORMATIONS, PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING, PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS, PREFERENCE_PANEL_SET_SECURITY_LOADING, PREFERENCE_PANEL_SET_PICTURE_LOADING, PREFERENCE_PANEL_SET_PICTURE, PREFERENCE_PANEL_SET_SESSIONS, PREFERENCE_PANEL_SET_SESSION_LOADING, PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING, PREFERENCE_PANEL_DELETE_SESSION } from "../../actions/ActionTypes";

const defaultState = {
    panel: null,
    isPanelLoading: true,

    user: null,

    // Informations
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    company: '',
    isConfirmedAccount: true,
    isConfirmationEmailLoading: false,
    isConfirmationEmailSent: false,

    isFirstnameValid: true,
    isLastnameValid: true,
    isPhoneValid: true,
    isCompanyValid: true,
    isInformationsLoading: false,

    // Password
    hasCurrentPassword: false,
    currentPassword: '',
    newPassword: '',
    renewPassword: '',

    isCurrentPasswordValid: true,
    isNewPasswordValid: true,
    isRenewPasswordValid: true,

    isSecurityLoading: false,

    // Session,
    isSessionLoading: true,
    sessions: [],
    isSessionActionLoading: false,

    // Photo
    isPictureLoading: false,
    photoLink: null
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case PREFERENCE_PANEL_SET_INFORMATIONS_LOADING: {
            return { ...state, isInformationsLoading: action.payload.isLoading };
        }

        case PREFERENCE_PANEL_SET_INFORMATIONS: {
            const { email, firstname, lastname, phone, company, confirmed } = action.payload.user;
            return {
                ...state,
                email: email || '',
                firstname: firstname || '',
                lastname: lastname || '',
                phone: phone || '',
                company: company || '',
                isConfirmedAccount: confirmed
            };
        }
        case PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING: {
            return { ...state, isConfirmationEmailLoading: action.payload.isLoading };
        }
        case PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS: {
            return { ...state, isConfirmationEmailSent: action.payload.isSent };
        }

        case PREFERENCE_PANEL_SET_EMAIL: {
            return { ...state, email: action.payload.email };
        }
        case PREFERENCE_PANEL_SET_FIRSTNAME: {
            return { ...state, firstname: action.payload.firstname, isFirstnameValid: true };
        }
        case PREFERENCE_PANEL_SET_LASTNAME: {
            return { ...state, lastname: action.payload.lastname, isLastnameValid: true };
        }
        case PREFERENCE_PANEL_SET_PHONE: {
            return { ...state, phone: action.payload.phone, isPhoneValid: true };
        }
        case PREFERENCE_PANEL_SET_COMPANY: {
            return { ...state, company: action.payload.company, isCompanyValid: true };
        }

        case PREFERENCE_PANEL_SET_SECURITY_LOADING: {
            return { ...state, isSecurityLoading: action.payload.isLoading };
        }

        case PREFERENCE_PANEL_HAS_CURRENT_PASSWORD: {
            return { ...state, hasCurrentPassword: action.payload.hasCurrent };
        }
        case PREFERENCE_PANEL_SET_CURRENT_PASSWORD: {
            return { ...state, currentPassword: action.payload.password, isCurrentPasswordValid: true };
        }
        case PREFERENCE_PANEL_SET_NEW_PASSWORD: {
            return { ...state, newPassword: action.payload.password, isNewPasswordValid: true };
        }
        case PREFERENCE_PANEL_SET_RENEW_PASSWORD: {
            return { ...state, renewPassword: action.payload.password, isRenewPasswordValid: true };
        }

        case PREFERENCE_PANEL_SET_SESSION_LOADING: {
            return { ...state, isSessionLoading: action.payload.isLoading };
        }
        case PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING: {
            return { ...state, isSessionActionLoading: action.payload.isLoading };
        }
        case PREFERENCE_PANEL_SET_SESSIONS: {
            return { ...state, sessions: action.payload.sessions };
        }
        case PREFERENCE_PANEL_DELETE_SESSION: {
            return { ...state, sessions: state.sessions.filter(s => s.id !== action.payload.id) };
        }

        case PREFERENCE_PANEL_SET_PICTURE_LOADING: {
            return { ...state, isPictureLoading: action.payload.isLoading };
        }
        case PREFERENCE_PANEL_SET_PICTURE: {
            return { ...state, photoLink: action.payload.link };
        }


        // CHECK
        case PREFERENCE_PANEL_FIRSTNAME_CHECK: {
            return { ...state, isFirstnameValid: action.payload.isValid };
        }
        case PREFERENCE_PANEL_LASTNAME_CHECK: {
            return { ...state, isLastnameValid: action.payload.isValid };
        }
        case PREFERENCE_PANEL_PHONE_CHECK: {
            return { ...state, isPhoneValid: action.payload.isValid };
        }
        case PREFERENCE_PANEL_COMPANY_CHECK: {
            return { ...state, isCompanyValid: action.payload.isValid };
        }

        case PREFERENCE_PANEL_CURRENT_PASSWORD_CHECK: {
            return { ...state, isCurrentPasswordValid: action.payload.isValid };
        }
        case PREFERENCE_PANEL_NEW_PASSWORD_CHECK: {
            return { ...state, isNewPasswordValid: action.payload.isValid };
        }
        case PREFERENCE_PANEL_RENEW_PASSWORD_CHECK: {
            return { ...state, isRenewPasswordValid: action.payload.isValid };
        }

        default:
            return state;
    }
}