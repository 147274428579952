import React, { useEffect, useRef, useState } from 'react';
import PlayerFetchLoader from './services/PlayerFetchLoader';
import ReactPlayer from 'react-player';
import Slider from 'react-input-slider';
import { convertSecondToRemainingTime } from './Utils';
import { CSSTransition } from 'react-transition-group';
import Button from './Button';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { LECTURE_PANEL_SET_CURRENT_VIDEO_ID } from './redux/actions/ActionTypes';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { deleteResource } from './redux/actions/admin/LectureAdminActions';
import { LECTURE_TYPE } from './resources/constants/lectureType';
import { useTranslation } from 'react-i18next';

// Images
import { ReactComponent as Loading } from './resources/images/loading.svg';

const VideoPlayer = ({ isSelected, video, admin, disableKeyEvent = false, autoPlay = false, disableHLS = false }) => {
    const { t } = useTranslation()
    
    // Refs
    const playerContainerRef = useRef(null);
    const progressBarRef = useRef(null);
    const nodeRef = useRef(null);

    // State
    const [player, setPlayer] = useState(null);
    const [isPlaying, setPlaying] = useState(autoPlay);
    const [isLoading, setLoading] = useState(true);
    const [percentPlayed, setPercentPlayed] = useState(0);
    const [percentLoaded, setPercentLoaded] = useState(0);
    const [volume, setVolume] = useState(100);
    const [volumeIcon, setVolumeIcon] = useState('high');
    const [remainingDuration, setRemainingDuration] = useState(null);
    const [progressCursorWidth, setProgressCursor] = useState(null);
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [overlayIcon, setOverlayIcon] = useState(null);
    const [isFullScreen, setFullScreen] = useState(false);
    const [isConflict, setConflict] = useState(false);
    const [videoHeight, setVideoHeight] = useState(400);
    const [mouseMovingTimer, setMouseMovingTimer] = useState(null);
    const [cursorMoving, setCursorMoving] = useState(false);
    const [speed, setSpeed] = useState(1);

    // Others
    const dispatch = useDispatch();
    const history = useHistory();
    const keyEffect = ['Space', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft', 'm', 'f'];
    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const speeds = [0.5, 0.75, 1, 1.25, 1.5];

    useEffect(() => {
        if (isSelected) {
            document.addEventListener('fullscreenchange', onFullScreenModeChange);
            document.addEventListener('keydown', onKeyDown);

            if (isFullScreen)
                document.addEventListener('mousemove', onCursorMoving);
        }

        return () => {
            if (isSelected) {
                document.removeEventListener('fullscreenchange', onFullScreenModeChange);
                document.removeEventListener('keydown', onKeyDown);
                document.removeEventListener('mousemove', onCursorMoving);
            }
        }
    });

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    useEffect(() => {
        if (volume === 0) {
            setVolumeIcon('off');
        } else if (volume > 60) {
            setVolumeIcon('high');
        } else if (volume > 30) {
            setVolumeIcon('medium');
        } else {
            setVolumeIcon('low');
        }
    }, [volume]);

    const onResize = () => {
        setVideoHeight(playerContainerRef.current.getBoundingClientRect().width * 0.427631578947368);
    }

    const onFullScreenModeChange = () => {
        setFullScreen(document.fullscreenElement);
    }

    const onShowOverlay = (overlay) => {
        setOverlayIcon(overlay);
        setOverlayVisible(true);

        setTimeout(() => {
            setOverlayVisible(false);
        }, 500);
    }

    const updateVolume = (value) => {
        if (value > 100) {
            setVolume(100);
        } else if (value < 0) {
            setVolume(0);
        } else {
            setVolume(value);
        }
    }

    const onToggleVolume = () => {
        setVolume(volume > 0 ? 0 : 50);
    }

    const onProgress = (progress) => {
        setPercentPlayed(progress.played * 100);
        setPercentLoaded(progress.loaded * 100);
        setRemainingDuration(player.getDuration() - progress.playedSeconds);
    }

    const onError = (_, c) => {
        if (c && c.response && c.response.type === 409)
            setConflict(true);
    }

    const onToggleFullScreen = (open = true) => {
        setFullScreen(open);

        if (open) {
            const elem = playerContainerRef.current;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    const onForward = () => {
        player.seekTo(player.getCurrentTime() + 10, 'seconds');
        onShowOverlay('forward');
    }

    const onBackward = () => {
        player.seekTo(player.getCurrentTime() - 10, 'seconds');
        onShowOverlay('backward');
    }

    const onProgressClick = (e) => {
        const left = e.clientX - progressBarRef.current.getBoundingClientRect().left;
        const width = progressBarRef.current.getBoundingClientRect().width;

        player.seekTo((left / width) * player.getDuration(), 'seconds');
    }

    const onProgressCursorMove = (e) => {
        setProgressCursor(e.clientX - progressBarRef.current.getBoundingClientRect().left);
    }

    const onProgressEnter = () => {
        progressBarRef.current.addEventListener('mousemove', onProgressCursorMove);
    }

    const onProgressLeave = () => {
        setProgressCursor(null);
        progressBarRef.current.removeEventListener('mousemove', onProgressCursorMove);
    }

    const onTogglePlaying = () => {
        setPlaying(!isPlaying);
        onShowOverlay(!isPlaying ? 'play' : 'pause');
    }

    const onKeyDown = (key) => {
        if (disableKeyEvent)
            return;

        if (key.code === 'Space') {
            onTogglePlaying();
        }

        switch (key.key) {
            case 'ArrowUp':
                updateVolume(volume + 10);
                onShowOverlay(`volume high`);
                break;
            case 'ArrowDown':
                updateVolume(volume - 10);
                onShowOverlay(volume - 10 <= 0 ? `volume off` : `volume medium`);
                break;
            case 'ArrowRight':
                onForward();
                break;
            case 'ArrowLeft':
                onBackward();
                break;
            case 'm':
                onToggleVolume();
                onShowOverlay(volume > 0 ? 'volume medium' : 'volume off');
                break;
            case 'f':
                onToggleFullScreen(!isFullScreen);
                onShowOverlay(!isFullScreen ? 'full-screen white' : 'full-screen exit white');
                break;
            default:
                break;
        }

        if ((keyEffect.includes(key.code) || keyEffect.includes(key.key)) && key.target === document.body)
            key.preventDefault();
    }

    const onCursorMoving = () => {
        setCursorMoving(true);
        clearTimeout(mouseMovingTimer);

        setMouseMovingTimer(setTimeout(() => setCursorMoving(false), 1500));
    }

    return (
        <div className={`video-player lecture-dynamic-content-container rounded-xl shadow`} style={{ height: isFullScreen ? '100%' : `${videoHeight}px` }} ref={playerContainerRef} onClick={() => dispatch({ type: LECTURE_PANEL_SET_CURRENT_VIDEO_ID, payload: { id: video.id } })}>
            {admin ? <div className='title white shadow rounded-xl'>{video.title}</div> : null}
            {!isMobile && <div className={`overlay ${isFullScreen ? 'fullscreen' : ''} ${cursorMoving || !isPlaying ? 'moving' : ''} ${isConflict ? 'player-blocked' : ''}`} onClick={onTogglePlaying} onDoubleClick={() => {
                onShowOverlay(!isFullScreen ? 'full-screen white' : 'full-screen exit white');
                onToggleFullScreen(!isFullScreen);
            }}>
                <div className='content'>
                    {isConflict && <div className='message'>
                        <h1>{t("MAX_SCREEN_REACHED")}</h1>
                        <p>{t("MAX_SCREEN_REACHED_SUBTITLE")}</p>

                        <div className='buttons'>
                            <Button title={t("LEAVE")} btnStyle={'white autofit'} />
                            <Button title={t("RETRY")} btnStyle={'white autofit'} onClick={() => history.go(0)} />
                            <Link to='/dashboard/preference/security'><Button title={t("DISCONNECT_DEVICES")} btnStyle={'cancel autofit'} /></Link>
                        </div>
                    </div>}

                    <CSSTransition nodeRef={nodeRef} in={isOverlayVisible && !isLoading} mountOnEnter unmountOnExit timeout={200} classNames='bounce-fast'>
                        <div ref={nodeRef} className='action'>
                            <div className={`icon ${overlayIcon} white`}></div>
                        </div>
                    </CSSTransition>

                    {isLoading && !isConflict && <div className='loading'><Loading /></div>}
                </div>
            </div>}

            <ReactPlayer
                ref={(player) => setPlayer(player)}
                url={`${process.env.REACT_APP_API_URL}/video/${video.id}`}
                playing={isPlaying}
                controls={isMobile}
                onProgress={onProgress}
                onError={onError}
                onBuffer={() => setLoading(true)}
                onBufferEnd={() => setLoading(false)}
                onReady={() => setLoading(false)}
                volume={volume / 100}
                config={disableHLS ? undefined :
                    {
                        file: {
                            forceHLS: true,
                            hlsOptions: {
                                loader: PlayerFetchLoader,
                            },
                            hlsVersion: '0.13.1'
                        }
                    }
                }
                width={'100%'}
                height={'100%'}
                playsinline={true}
                playbackRate={speed}
            />

            {!isMobile && <div className={`controls white rounded-s transition ${!isPlaying || (isFullScreen && cursorMoving) ? 'force-visibility' : ''} ${isFullScreen ? 'fullscreen-mode' : ''}`}>
                <div className='left'>
                    <div className={`icon ${isPlaying ? 'pause' : 'play'}`} onClick={onTogglePlaying}></div>

                    <div className='goto-container'>
                        <div className={`icon backward`} onClick={onBackward}></div>
                        <div className={`icon forward`} onClick={onForward}></div>
                    </div>

                    <div className='volume-container'>
                        <div className={`icon volume ${volumeIcon}`} onClick={onToggleVolume}></div>
                        <div className='volume-picker white'>
                            <Slider onChange={({ y }) => {
                                if (!Number.isNaN(y))
                                    setVolume(y)
                            }} axis={'y'} ymin={0} ymax={100} y={volume} yreverse />
                        </div>
                    </div>
                </div>

                <div className='progress transition' onClick={onProgressClick} ref={progressBarRef} onPointerEnter={onProgressEnter} onPointerLeave={onProgressLeave}>
                    {progressCursorWidth && <div className='thumbnail shadow white rounded-s' style={{ marginLeft: `${progressCursorWidth - 60}px` }}>
                        <span>{convertSecondToRemainingTime((progressCursorWidth / progressBarRef.current.getBoundingClientRect().width) * player.getDuration())}</span>
                    </div>}

                    <div className='container-bar loaded transition'>
                        <div className='bar transition' style={{ width: `${percentLoaded}%` }}></div>
                    </div>

                    {progressCursorWidth &&
                        <div className='container-bar cursor'>
                            <div className='bar' style={{ width: `${(progressCursorWidth / progressBarRef.current.getBoundingClientRect().width) * 100}%` }}></div>
                        </div>}

                    <div className='container-bar played transition'>
                        <div className='bar transition' style={{ width: `${percentPlayed}%` }}></div>
                    </div>
                </div>

                <div className='right'>
                    <span>- {convertSecondToRemainingTime(remainingDuration)}</span>
                    <div className='speed-container'>
                        <div className={`icon speed`}></div>
                        <div className='speed-picker white'>
                            <ul>
                                {speeds.map((s, index) => <li key={index} className={`transition ${speed === s ? 'selected' : ''}`} onClick={() => setSpeed(s)}><sub>x</sub> {s}</li>)}
                            </ul>
                        </div>
                    </div>
                    <div className={`icon full-screen ${isFullScreen ? 'exit' : ''}`} onClick={() => onToggleFullScreen(!isFullScreen)}></div>

                    {admin && <Button title={'Supprimer le lien'} btnStyle={'delete autofit cancel'} confirmationRequired onClick={() => dispatch(deleteResource(video.id, LECTURE_TYPE.VIDEO))} />}
                </div>
            </div>}
        </div>
    );
}

VideoPlayer.propTypes = {
    isSelected: propTypes.bool,
    video: propTypes.object.isRequired,
    disableKeyEvent: propTypes.bool
};

export default VideoPlayer;