import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import CourseItemAssignCertificateAdminPopup from './CourseItemAssignCertificateAdminPopup';
import { useTranslation } from 'react-i18next';

const CourseListAssignCertificateAdminPopup = () => {
    const assignCertificateAdminStore = useSelector(store => store.assignCertificateAdminPopup);
    const { t } = useTranslation();

    if (assignCertificateAdminStore.isLoading)
        return (
            <div className='list'>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
            </div>
        );

    if (!assignCertificateAdminStore.courses || !assignCertificateAdminStore.courses.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        )

    return (
        <div className='list'>
            {assignCertificateAdminStore.courses?.map((course) => <CourseItemAssignCertificateAdminPopup key={course.id} course={course} isSelected={assignCertificateAdminStore.selectedCourses.includes(course.id)} />)}
        </div>
    );
}

export default CourseListAssignCertificateAdminPopup;