import React from 'react';
import { useSelector } from 'react-redux';
import HeaderResultItemLoader from '../loader/HeaderResultItemLoader';
import { SEARCH_RESULT_TYPE } from '../resources/constants/menu';
import HeaderResultItem from './HeaderResultItem';

const HeaderResultList = () => {
    const menuStore = useSelector(store => store.menu);

    if (menuStore.isSearchingLoading)
        return (
            <div className='search-result white rounded-m shadow'>
                <div className='list'>
                    <div className='item loading'><HeaderResultItemLoader /></div>
                    <div className='item loading'><HeaderResultItemLoader /></div>
                    <div className='item loading'><HeaderResultItemLoader /></div>
                </div>
            </div>
        );

    
    if (!menuStore.searchingResults || !menuStore.searchingResults.categories || !menuStore.searchingResults.courses || (!menuStore.searchingResults.categories.length && !menuStore.searchingResults.courses.length))
        return null;

    return (
        <div className='search-result white rounded-m shadow'>
            <div className='list'>
                {menuStore.searchingResults.courses.map(item => <HeaderResultItem key={item.id} type={SEARCH_RESULT_TYPE.COURSE} result={item} />)}
                {menuStore.searchingResults.categories.map(item => <HeaderResultItem key={item.id} type={SEARCH_RESULT_TYPE.CATEGORY} result={item} />)}
            </div>
        </div>
    );
}

export default HeaderResultList;