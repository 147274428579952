import { ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY, ASSIGN_CATEGORY_ADMIN_POPUP_RESET, ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES, ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING, ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING, ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: true,

    categories: [],

    courseId: null,
    selectedCategories: [],

    searchValue: ''
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_CATEGORY_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }

        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES: {
            return { ...state, categories: action.payload.categories };
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES: {
            return { ...state, selectedCategories: action.payload.categories };
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY: {
            return { ...state, selectedCategories: [...state.selectedCategories, action.payload.id] };
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID: {
            return { ...state, courseId: action.payload.id };
        }
        case ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING: {
            return {
                ...state, categories: state.categories.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, isLoading: action.payload.isLoading };
                })
            }
        }

        default:
            return state;
    }
}