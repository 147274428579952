import Axios from "axios";
import { createNotification } from "../../../Utils";
import { PREVIEW_PANEL_SET_COURSE, PREVIEW_PANEL_SET_LOADING, PREVIEW_PANEL_TOGGLE_SECTION } from "../ActionTypes";
import i18n from 'i18next';

export const previewPanelLoadCourse = (courseId) => async dispatch => {
    dispatch({ type: PREVIEW_PANEL_SET_LOADING, payload: { isLoading: true } });

    return Axios.get(`course/${courseId}/preview`)
        .then(({ data }) => {
            dispatch({ type: PREVIEW_PANEL_SET_COURSE, payload: { course: data } });

            if (data.section && data.section[0])
                dispatch({ type: PREVIEW_PANEL_TOGGLE_SECTION, payload: { id: data.section[0].id } });

        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
            throw Error();
        }).finally(() => {
            dispatch({ type: PREVIEW_PANEL_SET_LOADING, payload: { isLoading: false } });
        })
}