import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import PasswordStrength from '../../../PasswordStrength';
import { USER_ADMIN_POPUP_SET_NEW_PASSWORD, USER_ADMIN_POPUP_SET_RENEW_PASSWORD } from '../../../redux/actions/ActionTypes';
import { unlinkSocialAdminPopup, updatePasswordUserAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import { useTranslation } from 'react-i18next';

const SecurityUserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(updatePasswordUserAdminPopup(userAdminStore));
    }

    return (
        <div className='security'>
            <div>
                <div className='content-title'>
                    <h2>Définir un mot de passe</h2>
                </div>

                <div className='section'>
                    <form onSubmit={onSubmit}>
                        <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={userAdminStore.newPassword} onChange={(password) => dispatch({ type: USER_ADMIN_POPUP_SET_NEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'new-password'} disabled={userAdminStore.isSecurityLoading} error={!userAdminStore.isNewPasswordValid} helperText={t("HELPER_PASSWORD_MUST_BE_STRONGER")} passwordToggle />
                        <PasswordStrength password={userAdminStore.newPassword} />

                        <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={userAdminStore.renewPassword} onChange={(password) => dispatch({ type: USER_ADMIN_POPUP_SET_RENEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'new-password'} disabled={userAdminStore.isSecurityLoading} error={!userAdminStore.isRenewPasswordValid} helperText={t("HELPER_PASSWORD_SAME")} passwordToggle />

                        <div className='right-alignment'>
                            <Button title={t("UPDATE")} btnStyle='primary autofit' type={'submit'} isLoading={userAdminStore.isSecurityLoading} />
                        </div>
                    </form>
                </div>
            </div>

            <div className='or'>
                <hr />
            </div>

            <div>
                <div className='content-title'>
                    <h2>Réseaux sociaux</h2>
                </div>

                <div className='section'>
                    <div className='danger-item'>
                        <div className='labels'>
                            <h3>Facebook</h3>
                            <span>{userAdminStore.facebook ? 'Connecté' : 'Non connecté'}</span>
                        </div>

                        {userAdminStore.facebook && <Button title={'Dissocier'} btnStyle={'cancel autofit'} confirmationRequired isLoading={userAdminStore.isSecurityLoading} onClick={() => dispatch(unlinkSocialAdminPopup(userAdminStore.userId, false, null))} />}
                    </div>

                    <div className='danger-item'>
                        <div className='labels'>
                            <h3>Microsoft</h3>
                            <span>{userAdminStore.microsoft ? 'Connecté' : 'Non connecté'}</span>
                        </div>

                        {userAdminStore.microsoft && <Button title={'Dissocier'} btnStyle={'cancel autofit'} confirmationRequired isLoading={userAdminStore.isSecurityLoading} onClick={() => dispatch(unlinkSocialAdminPopup(userAdminStore.userId, null, false))} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecurityUserAdminPopup;