import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL } from '../../redux/actions/ActionTypes';
import { addDocumentationAdminPopup } from '../../redux/actions/popup/admin/AssignDocumentationPopupActions';
import { useTranslation } from 'react-i18next';

const AssignDocumentationAdminPopup = () => {
    const dispatch = useDispatch();
    const assignDocumentationAdminStore = useSelector(store => store.assignDocumentationAdminPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(addDocumentationAdminPopup(assignDocumentationAdminStore));
    }

    useEffect(() => {
        return () => {
            dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    return (
        <div className={`documentation-admin ${assignDocumentationAdminStore.isLoading ? 'disabled' : ''}`}>
            <div className='section'>
                <form onSubmit={onSubmit}>
                    <Input title={'Lien de la documentation'} placeholder={'https://docs.microsoft.com'} value={assignDocumentationAdminStore.url} onChange={(url) => dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL, payload: { url } })} type={'text'} disabled={assignDocumentationAdminStore.isLoading} error={!assignDocumentationAdminStore.isUrlValid} helperText={t("HELPER_URL")} />
                    <Input title={'Titre du lien'} placeholder={'App Service overview'} value={assignDocumentationAdminStore.title} onChange={(title) => dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE, payload: { title } })} type={'text'} disabled={assignDocumentationAdminStore.isLoading} error={!assignDocumentationAdminStore.isTitleValid} helperText={t("HELPER_TITLE_RESOURCE")} />

                    <div className='right-alignment'>
                        <Button title={'Ajouter le lien'} btnStyle='primary autofit' isLoading={assignDocumentationAdminStore.isLoading} type={'submit'} />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignDocumentationAdminPopup, 'Référencer une documentation', 'Ajout d\'une page de documentation');