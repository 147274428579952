import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../Button';
import { startSurvey } from '../../../../redux/actions/dashboard/LectureSurveyActions';
import { useTranslation } from 'react-i18next';

const LectureSurveyStart = () => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { t } = useTranslation();

    const onStart = () => {
        dispatch(startSurvey(lectureStore.lecture.surveyId, lectureStore.menu.id));
    }

    return (
        <div className='step-start center'>
            <Button title={t("START_TEST")} btnStyle={'primary autofit center'} isLoading={lectureStore.isSurveyLoading} onClick={onStart} />
        </div>
    );
}

export default LectureSurveyStart;