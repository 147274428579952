import Axios from "axios";
import { DASHBOARD_PANEL_SET_CERTIFICATES, DASHBOARD_PANEL_SET_CERTIFICATES_LOADING, DASHBOARD_PANEL_SET_COURSES, DASHBOARD_PANEL_SET_COURSES_LOADING } from "../ActionTypes";

export const loadDashboardCourses = () => async dispatch => {
    dispatch({ type: DASHBOARD_PANEL_SET_COURSES_LOADING, payload: { isLoading: true } });

    Axios.get(`usercourse/myCourses`)
        .then(({ data }) => {
            dispatch({ type: DASHBOARD_PANEL_SET_COURSES, payload: { courses: data } });
        }).finally(() => {
            dispatch({ type: DASHBOARD_PANEL_SET_COURSES_LOADING, payload: { isLoading: false } });
        })
}

export const loadDashboardCertificates = () => async dispatch => {
    dispatch({ type: DASHBOARD_PANEL_SET_CERTIFICATES_LOADING, payload: { isLoading: true } });

    Axios.get(`certificate/myCertificates`)
        .then(({ data }) => {
            dispatch({ type: DASHBOARD_PANEL_SET_CERTIFICATES, payload: { certificates: data } });
        }).finally(() => {
            dispatch({ type: DASHBOARD_PANEL_SET_CERTIFICATES_LOADING, payload: { isLoading: false } });
        })
}