import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openUserAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import { convertStringToDate } from '../../../Utils';

// Images
import Facebook from '../../../resources/images/authentication/facebook-blue.png';
import Key from '../../../resources/images/authentication/key.png';
import Microsoft from '../../../resources/images/authentication/microsoft.png';
import Profile from '../../../resources/images/profile.png';

const UserAdminItem = ({ user }) => {
    const dispatch = useDispatch();

    return (
        <div className='item user-card'>
            <div className='box shadow rounded-l white'>
                <div className='options'>
                    <div className='more-button shadow white' onClick={() => dispatch(openUserAdminPopup(user.id))}><div className='icon more'></div></div>
                </div>

                <div className='general-informations'>
                    <div className='main'>
                        <img className='shadow' src={user.picture || Profile} alt={`${user.firstname} ${user.lastname}`} />

                        <div className='info'>
                            <div className='name'>
                                {user.confirmed && <div className='status'><div className='icon check-bold white' /></div>}
                                <span>{user.firstname} {user.lastname}</span>
                            </div>
                            {user.business && <span className='company'>{user.business}</span>}

                            <div className='login-method'>
                                {user.facebook && <img src={Facebook} alt='facebook' />}
                                {user.microsoft && <img src={Microsoft} alt='microsoft' />}
                                {user.formationAcademy && <img src={Key} alt='email' />}
                            </div>
                        </div>
                    </div>

                    <ul className='informations'>
                        {user.email && <li><div className='icon mail'></div><span>{user.email}</span></li>}
                        {user.phone && <li><div className='icon phone'></div><span>{user.phone}</span></li>}
                        <li><div className='icon datetime'></div><span>Inscrit le {convertStringToDate(user.creationDate)}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

UserAdminItem.propTypes = {
    user: propTypes.object.isRequired
};

export default UserAdminItem;