import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import IsAdminHOC from '../../HOC/IsAdminHOC';
import { ADMIN_PANEL } from '../../resources/constants/adminPanel';
import { ADMIN_MENU } from '../../resources/constants/menu';
import CategoryAdminPanel from './CategoryAdminPanel';
import CourseAdminPanel from './CourseAdminPanel';
import TracerAdminPanel from './TracerAdminPanel';
import UserAdminPanel from './UserAdminPanel';
import VoucherAdminPanel from './VoucherAdminPanel';
import { useTranslation } from 'react-i18next';

const AdminPanel = (props) => {
    const { menu } = props.match.params;
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t("ADMINISTRATION")} · FormationAcademy</title>
            </Helmet>

            <div className='page-container-wrapper basic-panel admin-panel'>
                <div className='container'>
                    <div className={`course-list`}>
                        <div className='detail shadow rounded-l white menu'>
                            <h1>{t("ADMINISTRATION")}</h1>

                            <div className='list'>
                                {ADMIN_MENU.map(item => <Link key={item.id} to={item.link} className={`transition rounded-s ${menu === item.id ? 'selected' : ''}`}>{t(item.name)}</Link>)}
                            </div>
                        </div>
                    </div>

                    <div className='panel-content shadow white rounded-xl padding'>
                        {menu === ADMIN_PANEL.COURSES && <CourseAdminPanel />}
                        {menu === ADMIN_PANEL.USERS && <UserAdminPanel />}
                        {menu === ADMIN_PANEL.CATEGORIES && <CategoryAdminPanel />}
                        {menu === ADMIN_PANEL.VOUCHERS && <VoucherAdminPanel />}
                        {menu === ADMIN_PANEL.TRACER && <TracerAdminPanel />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default IsAdminHOC(AdminPanel);