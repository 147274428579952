import React from 'react';
import { useSelector } from 'react-redux';
import PopupHOC from '../HOC/PopupHOC';
import BasicLoader from '../loader/BasicLoader';
import VideoPlayer from '../VideoPlayer';
import { useTranslation } from 'react-i18next';

const PreviewPopup = (props) => {
    const previewStore = useSelector(store => store.previewPopup);
    const { t } = useTranslation();

    if (previewStore.isLoading || !previewStore.lecture)
        return (
            <div className='popup white rounded-l shadow padding large preview'>
                <div className='header'>
                    <BasicLoader width={'100%'} height={100} radius={10} />
                </div>

                <div className='content'>
                    <div className='player rounded-xl'>
                        <BasicLoader width={'100%'} height={500} radius={10} />
                    </div>
                </div>
            </div>
        );

    return (
        <div className='popup white rounded-l shadow padding large preview'>
            <div className='header'>
                <span className='subtitle'>{t("PREVIEW_LECTURE")}</span>
                <div className='title'>
                    <div className='icon-container'></div>
                    <h1>{previewStore.lecture.title}</h1>
                    <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                </div>
            </div>

            {previewStore.lecture?.description ? <div className='notification-banner rounded-xl large'>
                <span className='title'>Objectifs</span>

                <p>{previewStore.lecture.description}</p>
            </div> : null}

            {previewStore.lecture?.videoResourceId ? <VideoPlayer isSelected={true} video={{ id: `preview/${previewStore.lecture.videoResourceId}.m3u8` }} disableHLS={true} autoPlay={true} disableKeyEvent={true} /> : null}
        </div>
    );
}

export default PopupHOC(PreviewPopup);