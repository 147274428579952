import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO, ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS, ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO, ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING, ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING, LECTURE_PANEL_ADMIN_ADD_VIDEO } from "../../ActionTypes";
import i18n from 'i18next';

export const loadVideosAdminPopup = (pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`videoblob/list/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS, payload: { videos: data.paging } });
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const addVideoAdminPopup = (lectureId, videoGuid) => async dispatch => {
    dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING, payload: { id: videoGuid, isLoading: true } });

    Axios.post(`resource/video/add/admin`, {
        lectureId,
        videoGuid
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_ADMIN_ADD_VIDEO, payload: { video: data } });
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO, payload: { id: videoGuid } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING, payload: { id: videoGuid, isLoading: false } });
    })
}

export const deleteVideoAdminPopup = (videoGuid) => async dispatch => {
    dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING, payload: { id: videoGuid, isLoading: true } });

    Axios.delete(`videoblob/${videoGuid}/delete`)
        .then(() => {
            dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO, payload: { id: videoGuid } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING, payload: { id: videoGuid, isLoading: false } });
        });

}