import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convertStringForUrl } from '../Utils';
import BasicLoader from '../loader/BasicLoader';
import { MENU_SET_MOBILE_MENU } from '../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';

const MenuItem = ({ menu }) => {
    const dispatch = useDispatch();
    const menuStore = useSelector(store => store.menu);
    const { t } = useTranslation()

    if (menu.dynamicSubCategories)
        return (
            <div className='item'>
                <div className='cell'>
                    {t(menu.name)}
                    <div className='icon arrow'></div>
                </div>
                <div className='sub'>
                    <div className={`dropdown-list shadow rounded-m white ${menuStore.isCategoriesLoading ? 'loading' : ''}`} role='listbox'>
                        {menuStore.isCategoriesLoading ?
                            <>
                                <div className='item loading'><BasicLoader width={150} height={20} radius={2} /></div>
                                <div className='item loading'><BasicLoader width={150} height={20} radius={2} /></div>
                                <div className='item loading'><BasicLoader width={150} height={20} radius={2} /></div>
                            </> : menuStore.categories.map((category, i) => <Link className='item transition' to={`/category/${category.id}-${convertStringForUrl(category.title)}`} key={i} onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: null } })}>{category.title}</Link>)
                        }
                    </div>
                </div>
            </div>
        );

    if (menu.subCategories)
        return (
            <div className='item'>
                <div className='cell'>
                    {t(menu.name)}
                    <div className='icon arrow transition'></div>
                </div>
                <div className='sub'>
                    <div className='dropdown-list shadow rounded-m white'>
                        {menu.subCategories.map((category, i) => <Link className='item transition' to={category.link} key={i} onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: null } })}>{t(category.name)}</Link>)}
                    </div>
                </div>
            </div>
        );

    return (
        <Link to={menu.link} className='item' onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: null } })}>{t(menu.name)}</Link>
    );
}

MenuItem.propTypes = {
    menu: propTypes.object.isRequired
};

export default MenuItem