import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../Button';
import { LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILES } from '../../../redux/actions/ActionTypes';
import { deleteResource, updateResourcePositionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { LECTURE_TYPE } from '../../../resources/constants/lectureType';
import { useTranslation } from 'react-i18next';

const LectureDownloadFile = ({ admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { t } = useTranslation();

    const onPositionUpdate = (e) => {
        const movedItem = lectureStore.lecture.downloads[e.newIndex];
        dispatch(updateResourcePositionAdmin(movedItem.id, e.newIndex));
    }

    return (
        <div className='resource'>
            <h5>{t("FILES_TO_DOWNLOAD")}</h5>

            <ul className='links'>
                <ReactSortable chosenClass={'disabled'} disabled={!admin} animation={200} delay={200} list={lectureStore.lecture.downloads} setList={(downloads) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILES, payload: { downloads } })} onEnd={onPositionUpdate}>
                    {lectureStore.lecture.downloads.map(file =>
                        <li key={file.id}>
                            <a href={file.link} target={'_blank'} rel="noopener noreferrer">
                                <div className={`icon ${admin ? 'more' : 'download blue'}`}></div>
                                {file.title}
                            </a>

                            {admin && <Button title={'Supprimer le lien'} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteResource(file.id, LECTURE_TYPE.DOWNLOAD))} />}
                        </li>)}
                </ReactSortable>
            </ul>
        </div>
    );
}

LectureDownloadFile.propTypes = {
    admin: propTypes.bool
};

export default LectureDownloadFile;