import Axios from "axios"
import { createNotification } from "../../Utils";
import { HOME_PANEL_SET_CATEGORIES, HOME_PANEL_SET_CATEGORIES_LOADING, HOME_PANEL_SET_SELECTED_CATEGORY_ID, MENU_SET_CATEGORIES, MENU_SET_CATEGORIES_LOADING, MENU_SET_LOADED, MENU_SET_SEARCHING_LOADING, MENU_SET_SEARCHING_RESULT } from "./ActionTypes";
import i18n from 'i18next';

export const loadCategories = () => async dispatch => {
    dispatch({ type: MENU_SET_CATEGORIES_LOADING, payload: { isLoading: true } });
    dispatch({ type: HOME_PANEL_SET_CATEGORIES_LOADING, payload: { isLoading: true } });

    Axios.get(`category/main?lang=${i18n.language}`).then(({ data }) => {
        dispatch({ type: MENU_SET_CATEGORIES, payload: { categories: data } });
        dispatch({ type: HOME_PANEL_SET_CATEGORIES, payload: { categories: data } });
        dispatch({ type: MENU_SET_LOADED, payload: { isLoaded: true } });

        if (data[0] && data[0].id)
            dispatch({ type: HOME_PANEL_SET_SELECTED_CATEGORY_ID, payload: { id: data[0].id } });

    }).finally(() => {
        dispatch({ type: MENU_SET_CATEGORIES_LOADING, payload: { isLoading: false } });
        dispatch({ type: HOME_PANEL_SET_CATEGORIES_LOADING, payload: { isLoading: false } });
    });
}

export const loadSearchBarResult = (value) => async dispatch => {
    dispatch({ type: MENU_SET_SEARCHING_LOADING, payload: { isLoading: true } });
    Axios.get(`category/search`, {
        params: {
            name: value,
            lang: i18n.language
        }
    }).then(({ data }) => {
        dispatch({ type: MENU_SET_SEARCHING_RESULT, payload: { results: data } });
    }).finally(() => {
        dispatch({ type: MENU_SET_SEARCHING_LOADING, payload: { isLoading: false } });
    });
}

export const confirmAccount = (email, code) => async dispatch => {
    return Axios.put(`user/email/confirm`, {
        email, code
    }).then(() => {
        return true
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("CONFIRM_ACCOUNT_CODE_ERROR"), 'danger');
        return false;
    });
}