import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminPanelHOC from '../../HOC/AdminPanelHOC';
import Input from '../../Input';
import { TRACER_ADMIN_PANEL_SET_SEARCH_VALUE, TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY } from '../../redux/actions/ActionTypes';
import { loadTracerAdmin } from '../../redux/actions/admin/TracerAdminActions';
import TracerAdminList from './tracer/TracerAdminList';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash'
import Axios from 'axios';

const TracerAdminPanel = () => {
    const dispatch = useDispatch();
    const tracerAdminStore = useSelector(store => store.tracerAdminPanel);
    const [coursesCode, setCoursesCode] = useState([])

    const onTyping = (value) => {
        // dispatch(loadUserAdmin(0, value, userAdminStore.showActiveUsersOnly));
    }

    useEffect(() => {
        dispatch(loadTracerAdmin(tracerAdminStore.showActiveUsersOnly));
        Axios.get(`course/list/all/code`).then(({ data }) => {
            setCoursesCode(data.map(d => {
                return { value: d.id, label: d.title }
            }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(loadTracerAdmin(tracerAdminStore.showActiveUsersOnly));
    }, [tracerAdminStore.showActiveUsersOnly])

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            resolve(coursesCode.filter(c => c.label.includes(inputValue)))
        });

    const onCoursesCodeChange = (e) => {
        console.log(e);
        dispatch(loadTracerAdmin(tracerAdminStore.showActiveUsersOnly, e?.map(e => e.value) || []));
    }

    return (
        <div className='content tracer-panel'>
            <div className='search-bar'>
                <div style={{ flex: '1' }}>
                    <Input disabled={true} placeholder={'Recherche'} value={tracerAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: TRACER_ADMIN_PANEL_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                </div>
                <div className={`filter-button rounded-s transition ${tracerAdminStore.showActiveUsersOnly ? 'enabled' : ''}`} onClick={() => dispatch({ type: TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY, payload: { showActiveUsersOnly: !tracerAdminStore.showActiveUsersOnly } })}>
                    <span>Voir uniquement les non-administrateurs</span>
                </div>
                <AsyncSelect className='tag-select-input' isMulti cacheOptions defaultOptions loadOptions={promiseOptions} onChange={onCoursesCodeChange} />
            </div>

            <TracerAdminList />
        </div>
    );
}

export default AdminPanelHOC(TracerAdminPanel, 'Suivi', 'Progression des élèves en apprentissage');