import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Input from '../../../Input';
import BasicLoader from '../../../loader/BasicLoader';
import { LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS } from '../../../redux/actions/ActionTypes';
import { updateLectureTitleAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { loadLecture } from '../../../redux/actions/dashboard/LectureActions';
import { LECTURE_SURVEY_STEP } from '../../../resources/constants/lectureSurvey';
import { convertSecondToAverageTime } from '../../../Utils';
import LectureDefaultContent from './LectureDefaultContent';
import LectureDescription from './LectureDescription';
import LectureSurvey from './LectureSurvey';
import LectureSurveyDescription from './LectureSurveyDescription';
import { useTranslation } from 'react-i18next';

const LectureContent = ({ lectureId, userCourseId, admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        const fetch = async () => {
            try {
                await dispatch(loadLecture(lectureStore, userCourseId, lectureId, admin));
            } catch {
                history.push('/dashboard');
            }
        }

        if (lectureId && userCourseId)
            fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lectureId, userCourseId, history]);

    const onLectureTitleEditing = () => {
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS, payload: { isEditing: true } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE, payload: { title: lectureStore.lecture.title } });
    }

    const updateLectureTitle = (e) => {
        e.preventDefault();

        dispatch(updateLectureTitleAdmin(lectureId, lectureStore.editingLectureTitle));
    }

    if (lectureStore.isLectureLoading || lectureStore.isMenuLoading || !lectureId)
        return (
            <div className='lecture shadow white rounded-xl'>
                <BasicLoader width={'100%'} radius={10} height={120} />
                <br /><br />
                <BasicLoader width={'100%'} radius={10} height={600} />
                <BasicLoader width={'100%'} radius={10} height={600} />
            </div>
        );

    if (!lectureStore.lecture)
        return null;

    return (
        <>
            <Helmet>
                <title>{lectureStore.lecture.title} · FormationAcademy</title>
            </Helmet>

            <div className={`lecture shadow white rounded-xl transition ${admin && lectureStore.isMenuVisible ? 'shrink-for-admin' : ''}`}>
                <div className='title'>
                    <div className='title-content' style={lectureStore.isEditingTitle ? { width: '100%', marginBottom: '20px' } : null}>
                        {!lectureStore.isEditingTitle ?
                            <>
                                <h2>{lectureStore.lecture.title}</h2>
                                {admin && <span className='cancel-button' onClick={onLectureTitleEditing}>Modifier</span>}
                            </> :
                            <div className='admin-lecture-input-editor'>
                                <div className='editor'>
                                    <form onSubmit={updateLectureTitle}>
                                        <Input placeholder={'Titre de la leçon'} value={lectureStore.editingLectureTitle} onChange={(title) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE, payload: { title } })} error={!lectureStore.isEditingLectureTitleValid} helperText={t("HELPER_TITLE_LECTURE")} />
                                    </form>
                                    <div className='icon check hover-to-blue transition' onClick={updateLectureTitle}></div>
                                </div>

                                <span className='cancel-button' onClick={() => dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS, payload: { isEditing: false } })}>Annuler</span>
                            </div>}
                    </div>

                    {!admin && lectureStore.lecture.totalTime > 0 ? <span className='spec'><div className='icon time'></div>{convertSecondToAverageTime(lectureStore.lecture.totalTime)}</span> : null}
                </div>

                {lectureStore.lecture.type === 0 && <>
                    <LectureDescription admin={admin} />
                    <LectureDefaultContent admin={admin} lectureId={lectureId} userCourseId={userCourseId} />
                </>}

                {lectureStore.lecture.type === 1 && <>
                    {!admin && lectureStore.surveyStep === LECTURE_SURVEY_STEP.START && <LectureSurveyDescription />}
                    <LectureSurvey admin={admin} lectureId={lectureId} userCourseId={userCourseId} />
                </>}
            </div>
        </>
    );
}

LectureContent.propTypes = {
    lectureId: propTypes.string,
    userCourseId: propTypes.string,
    admin: propTypes.bool,
};

export default LectureContent;