import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import CourseItemAssignCourseAdminPopup from './CourseItemAssignCourseAdminPopup';
import { useTranslation } from 'react-i18next';

const CourseListAssignCourseAdminPopup = () => {
    const assignCourseAdminStore = useSelector(store => store.assignCourseAdminPopup);
    const { t } = useTranslation();

    if (!assignCourseAdminStore.isLoading && !assignCourseAdminStore.courses?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {assignCourseAdminStore.courses.map(course => <CourseItemAssignCourseAdminPopup key={course.id} course={course} isSelected={assignCourseAdminStore.selectedCourses.includes(course.id)} />)}

            {assignCourseAdminStore.isLoading && <>
                <div className='item white thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item white thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item white thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
            </>}
        </div>
    );
}

export default CourseListAssignCourseAdminPopup