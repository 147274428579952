import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import SectionItemAssignSectionAdminPopup from './SectionItemAssignSectionAdminPopup';
import { useTranslation } from 'react-i18next';

const SectionListAssignSectionAdminPopup = () => {
    const assignSectionAdminStore = useSelector(store => store.assignSectionAdminPopup);
    const { t } = useTranslation();

    if (!assignSectionAdminStore.isLoading && !assignSectionAdminStore.sections?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_SECTIONS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {assignSectionAdminStore.sections?.map(section => <SectionItemAssignSectionAdminPopup key={section.id} section={section} isSelected={assignSectionAdminStore.selectedSections.includes(section.id)} />)}

            {assignSectionAdminStore.isLoading && <>
                <div className='item section-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item section-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item section-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
            </>}
        </div>
    );
}

export default SectionListAssignSectionAdminPopup