import React from 'react';
import ContentLoader from 'react-content-loader';
import { loaderParams } from '../resources/constants/loader';
import propTypes from 'prop-types';

const BasicLoader = ({width, height, radius}) => {
    return (
        <ContentLoader
            {...loaderParams}
            width={width}
            height={height}
        >
            <rect x='0' y='0' rx={radius} ry={radius} width={width} height={height} />
        </ContentLoader>
    );
}

BasicLoader.propTypes = {
    width: propTypes.any.isRequired,
    height: propTypes.any.isRequired,
    radius: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired
}

export default BasicLoader;