import Axios from "axios";
import { HOME_PANEL_ADD_COURSES, HOME_PANEL_SET_COURSES, HOME_PANEL_SET_COURSES_LOADING, HOME_PANEL_SET_CURRENT_PAGE, HOME_PANEL_SET_HAS_NEXT } from "../ActionTypes";
import i18n from '../../../i18n';

export const homePanelLoadCoursesCategory = (categoryId, currentPage) => async dispatch => {
    dispatch({ type: HOME_PANEL_SET_COURSES_LOADING, payload: { isLoading: true } });

    await Axios.get(`category/${categoryId}/courses/all`, {
        params: {
            "pageIndex": currentPage,
            "pageSize": 10,
            "lang": i18n.language
        }
    }).then(({ data }) => {
        dispatch({ type: HOME_PANEL_ADD_COURSES, payload: { courses: data.paging } });
        dispatch({ type: HOME_PANEL_SET_CURRENT_PAGE, payload: { currentPage: currentPage + 1 } });
        dispatch({ type: HOME_PANEL_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
    }).finally(() => {
        dispatch({ type: HOME_PANEL_SET_COURSES_LOADING, payload: { isLoading: false } });
    });
}

export const homePanelClear = () => async dispatch => {
    dispatch({ type: HOME_PANEL_SET_COURSES, payload: { courses: [] } });
    dispatch({ type: HOME_PANEL_SET_COURSES_LOADING, payload: { isLoading: true } });
}