import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import passwordForce from 'zxcvbn';

const PasswordStrength = (props) => {
    const [strength, setStrength] = useState(0);

    useEffect(() => {
        setStrength(passwordForce(props.password).score + 1);

        if (!props.password)
            setStrength(0);
    }, [props.password])

    return (
        <div className='password-strength'>
            <div className={`transition bar ${strength >= 1 ? 'active' : ''}`}></div>
            <div className={`transition bar ${strength >= 2 ? 'active' : ''}`}></div>
            <div className={`transition bar ${strength >= 3 ? 'active' : ''}`}></div>
        </div>
    );
}

PasswordStrength.propTypes = {
    password: propTypes.string.isRequired
};

export default PasswordStrength;