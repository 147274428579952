/**
 * Fetch based loader
 * timeout / abort / onprogress not supported for now
 * timeout / abort : some ideas here : https://github.com/whatwg/fetch/issues/20#issuecomment-196113354
 * but still it is not bullet proof as it fails to avoid data waste....
*/

import AuthenticationService from "./Authentication";

class PlayerFetchLoader {
    constructor(config) {

        this.fetchSetup = config.fetchSetup;
    }

    destroy() { }

    abort() { }

    async load(context, config, callbacks) {
        let stats = {
            trequest: window.performance.now(),
            retry: 0
        };

        let targetURL = `${context.url}/${await AuthenticationService.GetBrowserId()}`;
        let request;

        const initParams = {
            method: 'GET'
        };

        const headersObj = {
            'Authorization': `Bearer ${await AuthenticationService.RefreshToken()}`
        };

        if (context.rangeEnd) {
            headersObj['Range'] = 'bytes=' + context.rangeStart + '-' + String(context.rangeEnd - 1);
        } /* jshint ignore:line */

        initParams.headers = new window.Headers(headersObj);

        if (this.fetchSetup) {
            request = this.fetchSetup(context, initParams);
        } else {
            request = new window.Request(targetURL, initParams);
        }

        let fetchPromise = window.fetch(request, initParams);

        // process fetchPromise
        let responsePromise = fetchPromise.then(function (response) {
            if (response.ok) {
                stats.tfirst = Math.max(stats.trequest, window.performance.now());
                targetURL = response.url;
                if (context.responseType === 'arraybuffer') {
                    return response.arrayBuffer();
                } else {
                    return response.text();
                }
            } else {
                switch (response.status) {
                    case 409:
                        callbacks.onError({ text: 'Conflict', type: 409 }, context);
                        break;
                    default:
                        callbacks.onError({ text: 'Une erreur est survenue. Veuillez-réessayer' }, context);
                        break;
                }
            }
        }).catch(function (error) {
            callbacks.onError({ text: error.message }, context);
        });
        // process response Promise
        responsePromise.then(function (responseData) {
            if (responseData) {
                stats.tload = Math.max(stats.tfirst, window.performance.now());
                let len;
                if (typeof responseData === 'string') {
                    len = responseData.length;
                } else {
                    len = responseData.byteLength;
                }

                stats.loaded = stats.total = len;
                let response = { url: targetURL, data: responseData };
                callbacks.onSuccess(response, stats, context);
            }
        });
    }
}

export default PlayerFetchLoader;