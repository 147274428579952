import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import CertificateUserItemAdminPopup from './CertificateUserItemAdminPopup';
import { useTranslation } from 'react-i18next';

const CertificateUserListAdminPopup = () => {
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const { t } = useTranslation();

    if (userAdminStore.isCertificatesLoading)
        return (
            <div className='list'>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
            </div>
        );

    if (!userAdminStore.certificates || !userAdminStore.certificates.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_CERTIFICATES_FOUND")}
            </div>
        )

    return (
        <div className='list'>
            {userAdminStore.certificates && userAdminStore.certificates.map((certificate) => <CertificateUserItemAdminPopup key={certificate.id} certificate={certificate} />)}
        </div>
    );
}

export default CertificateUserListAdminPopup;