import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthenticationService from '../services/Authentication';

const IsAdminHOC = (WrappedComponent) => {
    return (props) => {
        if(!AuthenticationService.IsLogged() || !AuthenticationService.IsAdmin()) {
            return <Redirect to='/' />
        }

        return (
            <WrappedComponent {...props} />
        );
    };
};

export default IsAdminHOC;