import Axios from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";
import AuthenticationService from "../../../services/Authentication";
import { createNotification, isValidPassword } from "../../../Utils";
import { PREFERENCE_PANEL_COMPANY_CHECK, PREFERENCE_PANEL_FIRSTNAME_CHECK, PREFERENCE_PANEL_LASTNAME_CHECK, PREFERENCE_PANEL_PHONE_CHECK, PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, PREFERENCE_PANEL_SET_INFORMATIONS, PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING, PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS, PREFERENCE_PANEL_SET_SECURITY_LOADING, PREFERENCE_PANEL_HAS_CURRENT_PASSWORD, PREFERENCE_PANEL_NEW_PASSWORD_CHECK, PREFERENCE_PANEL_RENEW_PASSWORD_CHECK, PREFERENCE_PANEL_SET_NEW_PASSWORD, PREFERENCE_PANEL_SET_RENEW_PASSWORD, PREFERENCE_PANEL_SET_CURRENT_PASSWORD, PREFERENCE_PANEL_SET_PICTURE_LOADING, PREFERENCE_PANEL_SET_PICTURE, PREFERENCE_PANEL_SET_SESSION_LOADING, PREFERENCE_PANEL_SET_SESSIONS, PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING, PREFERENCE_PANEL_DELETE_SESSION } from "../ActionTypes"
import i18n from 'i18next';

// *** INFORMATIONS PANEL ***
export const loadPreferenceInformation = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

    Axios.get(`user/myInformation`)
        .then(({ data }) => {
            dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS, payload: { user: data } });
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });

}

export const updatePreferenceInformation = (preferenceStore) => async dispatch => {
    const isFirstnameValid = preferenceStore.firstname && preferenceStore.firstname.length >= 3 && preferenceStore.firstname.length <= 50;
    const isLastnameValid = preferenceStore.lastname && preferenceStore.lastname.length >= 3 && preferenceStore.lastname.length <= 50;
    const isPhoneValid = !preferenceStore.phone || isValidPhoneNumber(`+${preferenceStore.phone}`);
    const isCompanyValid = !preferenceStore.company || (preferenceStore.company.length >= 3 && preferenceStore.company.length <= 50);

    dispatch({ type: PREFERENCE_PANEL_FIRSTNAME_CHECK, payload: { isValid: isFirstnameValid } });
    dispatch({ type: PREFERENCE_PANEL_LASTNAME_CHECK, payload: { isValid: isLastnameValid } });
    dispatch({ type: PREFERENCE_PANEL_PHONE_CHECK, payload: { isValid: isPhoneValid } });
    dispatch({ type: PREFERENCE_PANEL_COMPANY_CHECK, payload: { isValid: isCompanyValid } });

    if (isFirstnameValid && isLastnameValid && isPhoneValid && isCompanyValid) {
        dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        const { firstname, lastname, phone, company } = preferenceStore;

        Axios.put(`user/update/information`, {
            firstname: firstname || null,
            lastname: lastname || null,
            phone: phone || null,
            company: company || null
        }).then(({ data }) => {
            AuthenticationService.SetUserData({
                ...AuthenticationService.GetSession(),
                ...data
            });

            dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS, payload: { user: data } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_SUCCESS"), 'success');
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const sendConfirmationEmail = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING, payload: { isLoading: true } });

    Axios.post(`user/sendConfirm`)
        .then(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING, payload: { isLoading: false } });
            dispatch({ type: PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS, payload: { isSent: true } });
        });
}


// *** SECURITY PANEL ***
export const loadPreferenceHasPassword = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_SECURITY_LOADING, payload: { isLoading: true } });

    Axios.get(`user/hasPassword`)
        .then(({ data }) => {
            dispatch({ type: PREFERENCE_PANEL_HAS_CURRENT_PASSWORD, payload: { hasCurrent: data.hasPassword } });
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_SECURITY_LOADING, payload: { isLoading: false } });
        });
}

export const updatePreferencePassword = (preferenceStore) => async dispatch => {
    const isNewPasswordValid = isValidPassword(preferenceStore.newPassword);
    const isRenewPasswordValid = preferenceStore.newPassword === preferenceStore.renewPassword;

    dispatch({ type: PREFERENCE_PANEL_NEW_PASSWORD_CHECK, payload: { isValid: isNewPasswordValid } });
    dispatch({ type: PREFERENCE_PANEL_RENEW_PASSWORD_CHECK, payload: { isValid: isRenewPasswordValid } });


    if (isNewPasswordValid && isRenewPasswordValid) {
        dispatch({ type: PREFERENCE_PANEL_SET_SECURITY_LOADING, payload: { isLoading: true } });

        const { currentPassword, newPassword } = preferenceStore;
        Axios.put(`user/changePassword`, {
            password: currentPassword || null,
            newPassword,
            refreshToken: AuthenticationService.GetRefreshToken()
        }).then(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_CURRENT_PASSWORD, payload: { password: '' } })
            dispatch({ type: PREFERENCE_PANEL_SET_NEW_PASSWORD, payload: { password: '' } })
            dispatch({ type: PREFERENCE_PANEL_SET_RENEW_PASSWORD, payload: { password: '' } })
            dispatch({ type: PREFERENCE_PANEL_HAS_CURRENT_PASSWORD, payload: { hasCurrent: true } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_PASSWORD_SUCCESS"), 'success');
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_SECURITY_LOADING, payload: { isLoading: false } });
        });
    }
}

export const loadSessions = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_SESSION_LOADING, payload: { isLoading: true } });

    Axios.get(`user/session/active`, {
        params: {
            refreshToken: AuthenticationService.GetRefreshToken()
        }
    }).then(({ data }) => {
        dispatch({ type: PREFERENCE_PANEL_SET_SESSIONS, payload: { sessions: data } });
    }).finally(() => {
        dispatch({ type: PREFERENCE_PANEL_SET_SESSION_LOADING, payload: { isLoading: false } });
    });
}

export const deleteSession = (sessionId) => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING, payload: { isLoading: true } });

    Axios.delete(`user/session/${sessionId}/delete`)
        .then(({ data }) => {
            dispatch({ type: PREFERENCE_PANEL_DELETE_SESSION, payload: { id: data.id } });
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING, payload: { isLoading: false } });
        });
}

// *** PHOTO PANEL ***
export const loadPreferencePhoto = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: true } });

    Axios.get(`user/picture`)
        .then(({ data }) => {
            dispatch({ type: PREFERENCE_PANEL_SET_PICTURE, payload: { link: data.picture } });
        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: false } });
        });
}

export const deletePreferencePhoto = () => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: true } });

    Axios.delete(`user/delete/picture`)
        .then(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_PICTURE, payload: { link: null } });

            AuthenticationService.SetUserData({
                ...AuthenticationService.GetSession(),
                picture: null
            });

        }).finally(() => {
            dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: false } });
        });
}

export const uploadPreferencePhoto = (file) => async dispatch => {
    dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: true } });

    const formData = new FormData();
    formData.append('Picture', file);

    Axios.put(`user/update/picture`, formData, {
        headers: { 'content-type': 'multipart/form-data' }
    }).then(({ data }) => {
        AuthenticationService.SetUserData({
            ...AuthenticationService.GetSession(),
            ...data
        });
        dispatch({ type: PREFERENCE_PANEL_SET_PICTURE, payload: { link: data.picture } });
    }).finally(() => {
        dispatch({ type: PREFERENCE_PANEL_SET_PICTURE_LOADING, payload: { isLoading: false } });
    });
}