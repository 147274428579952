import { DASHBOARD_PANEL_ADD_COURSE, DASHBOARD_PANEL_SET_CERTIFICATES, DASHBOARD_PANEL_SET_CERTIFICATES_LOADING, DASHBOARD_PANEL_SET_COURSES, DASHBOARD_PANEL_SET_COURSES_LOADING } from "../../actions/ActionTypes";

const defaultState = {
    isCoursesLoading: true,
    courses: [],

    isCertificatesLoading: true,
    certificates: []
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case DASHBOARD_PANEL_SET_COURSES_LOADING: {
            return { ...state, isCoursesLoading: action.payload.isLoading };
        }
        case DASHBOARD_PANEL_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case DASHBOARD_PANEL_ADD_COURSE: {
            return { ...state, courses: [...state.courses, action.payload.course] };
        }

        case DASHBOARD_PANEL_SET_CERTIFICATES_LOADING: {
            return { ...state, isCertificatesLoading: action.payload.isLoading };
        }
        case DASHBOARD_PANEL_SET_CERTIFICATES: {
            return { ...state, certificates: action.payload.certificates };
        }

        default:
            return state;
    }
}