import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { PREVIEW_COURSE_TYPE, PREVIEW_COURSE_PERIOD } from '../../../resources/constants/previewCourse';
import PreviewMoreInformationButton from './PreviewMoreInformationButton';
import { formatPrice } from '../../../Utils';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS } from '../../../redux/actions/ActionTypes';
import CoursePreviewPDF from '../../../services/CoursePreviewPDF';
import { useTranslation } from 'react-i18next';
import { showInboundPopup } from '../../../redux/actions/popup/InboundActions';

// Images
import NoImage from '../../../resources/images/no-image.png';

const PreviewMoreInformation = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const previewStore = useSelector(store => store.previewPanel);
    const inboundStore = useSelector(store => store.inboundPopup);
    const [courseType, setCourseType] = useState(localStorage.getItem(`preview_course_type`));
    const [coursePeriod, setCoursePeriod] = useState(localStorage.getItem(`preview_course_period`));

    const onSelectCourseType = (courseType) => {
        localStorage.setItem('preview_course_type', courseType);
        setCourseType(courseType);
    }

    const onSelectCoursePeriod = (coursePeriod) => {
        localStorage.setItem('preview_course_period', coursePeriod);
        setCoursePeriod(coursePeriod);
    }

    const onDownload = () => {
        console.log(previewStore);
        dispatch(showInboundPopup(inboundStore, previewStore.course.title, () => {
            dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS, payload: { status: 1 } });
            CoursePreviewPDF(previewStore.course, () => {
                dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS, payload: { status: 2 } });
            });
        }))
    }

    const calculatePrice = () => {
        let discount = 0;

        if (parseInt(courseType) === PREVIEW_COURSE_TYPE.TEAM) discount += 0.1;
        if (parseInt(coursePeriod) === PREVIEW_COURSE_PERIOD.HALF_DAY) discount += 0.05;
        return formatPrice(Math.floor(previewStore.course.price - (previewStore.course.price * discount)));
    }

    return (
        <>
            <div className='more-information'>
                {!previewStore.course.section.length && previewStore.course.manualPDF && <a href={`https://resources.formation.academy/other/pdf/${previewStore.course.manualPDF}.pdf`} target='_blank' rel='noreferrer'><Button title={t("DOWNLOAD_PDF")} btnStyle={'primary download-pdf'} /></a>}
                {previewStore.course.section.length && previewStore.pdfStatus !== 2 ? <Button title={t("DOWNLOAD_CURSUS")} btnStyle={'primary download-pdf'} onClick={onDownload} isLoading={previewStore.pdfStatus === 1} /> : null}
                {previewStore.course.section.length && previewStore.pdfStatus === 2 ? <Button title={t("DOWNLOADED_CURSUS")} btnStyle={'success download-pdf'} /> : null}
                <div className='pricing shadow white rounded-l'>
                    <div className='content padding'>
                        <div className='banner rounded-m' style={{ background: `url(${previewStore.course.picture || NoImage})`, backgroundSize: 'cover' }}></div>

                        <span className='c-title'>{t("PREVIEW_TITLE")}</span>
                        <span className='c-subtitle'>
                            {t("PREVIEW_SUBTITLE")}
                        </span>

                        <div className='survey'>
                            <div className='question'>
                                <span className='title'>{t("WISH_FOLLOW_COURSE")}</span>
                                <div className='choices'>
                                    <PreviewMoreInformationButton title={t("WISH_FOLLOW_ALONE")} selected={parseInt(courseType) === PREVIEW_COURSE_TYPE.SINGLE} onClick={() => onSelectCourseType(PREVIEW_COURSE_TYPE.SINGLE)} />
                                    <PreviewMoreInformationButton title={t("WISH_FOLLOW_GROUP")} selected={parseInt(courseType) === PREVIEW_COURSE_TYPE.TEAM} onClick={() => onSelectCourseType(PREVIEW_COURSE_TYPE.TEAM)} />
                                </div>
                            </div>

                            {courseType !== null &&
                                <div className='question'>
                                    <span className='title'>{t("WISH_CUT_COURSE")}</span>
                                    <div className='choices'>
                                        <PreviewMoreInformationButton title={t("WISH_CUT_FULL_DAY")} selected={parseInt(coursePeriod) === PREVIEW_COURSE_PERIOD.DAY} onClick={() => onSelectCoursePeriod(PREVIEW_COURSE_PERIOD.DAY)} />
                                        <PreviewMoreInformationButton title={t("WISH_CUT_HALF_DAY")} selected={parseInt(coursePeriod) === PREVIEW_COURSE_PERIOD.HALF_DAY} onClick={() => onSelectCoursePeriod(PREVIEW_COURSE_PERIOD.HALF_DAY)} />
                                    </div>
                                </div>}

                            {previewStore.course.price !== null &&
                                <div className='question'>
                                    <span className='title'>{t("STARTING_PRICE")}</span>
                                    <div className='choices'>
                                        <span className='price'>{calculatePrice()}€*<sub>HTVA</sub></span>
                                    </div>

                                    <div className='notice'>{t("STARTING_PRICE_DESC")}</div>
                                </div>}

                            <Button title={t("ASK_OFFER")} btnStyle={'primary'} onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

PreviewMoreInformation.propTypes = {
    courseId: propTypes.string.isRequired
};

export default PreviewMoreInformation;