import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LectureItemContent from './LectureItemContent';
import { Link } from 'react-router-dom';
import { convertStringForUrl } from '../../../Utils';

const LectureItem = ({ lecture, admin }) => {
    const lectureStore = useSelector(store => store.lecturePanel);
    const isSelected = lectureStore.currentLectureId === lecture.id;

    return (
        <Link data-lectureId={lecture.id} className={`item transition ${isSelected ? 'selected' : ''} ${!lecture.isAvailable && !admin ? 'unavailable disabled' : ''}`} to={!lectureStore.menu ? null : `/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${lecture.id}${admin ? '/admin' : ''}`}>
            <LectureItemContent lecture={lecture} isSelected={isSelected} admin={admin} />
        </Link>
    );
}

LectureItem.propTypes = {
    lecture: propTypes.object.isRequired,
    admin: propTypes.bool
};

export default LectureItem;