import React from 'react';
import { INTERVIEW } from '../../../resources/constants/interview';
import SolutionInterviewItem from './SolutionInterviewItem';

const TransitionViewFour = ({ transitionViewRef, transitionViewPathRef, transitionViewAdvantage1Ref, transitionViewAdvantage2Ref, transitionViewAdvantage3Ref, mainPanelRef }) => {
    return (
        <div className='sub-main transition' ref={transitionViewRef} id='transition-4'>
            <div className='container' id='main'>
                <div className='story-overlay'>
                    <div className='row'>
                        <div className='advantage rounded-m transition review' ref={transitionViewAdvantage1Ref}>
                            <SolutionInterviewItem interview={INTERVIEW[0]} index={0} />
                        </div>

                        <div className='advantage rounded-m transition review' ref={transitionViewAdvantage3Ref}>
                            <SolutionInterviewItem interview={INTERVIEW[1]} index={1} />
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='advantage rounded-m transition review' ref={transitionViewAdvantage2Ref}>
                            <SolutionInterviewItem interview={INTERVIEW[2]} index={2} />
                        </div>
                    </div>
                </div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3003.6 409.2">
                    <defs>
                        <linearGradient id="gradient4" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(247, 162, 161, 1)" />
                            <stop offset="100%" stopColor="rgba(255, 237, 0, 1)" />
                        </linearGradient>
                    </defs>
                    <path ref={transitionViewPathRef} fill="none" stroke="url(#gradient4)" strokeLinecap="round" strokeWidth="8" d="M65.1,291.8C793.5-74,1206.2-21.6,1450.4,100.4c156.2,78.1,215.6,170.8,398.3,240.3c385.1,146.6,799.4-7,1089.7-160.2" />
                </svg>
            </div>
        </div>
    );
}

export default TransitionViewFour;