import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import PopupHOC from '../HOC/PopupHOC';
import Input from '../Input';
import InputPhone from '../InputPhone';
import { INBOUND_POPUP_SET_COMPANY, INBOUND_POPUP_SET_EMAIL, INBOUND_POPUP_SET_FIRSTNAME, INBOUND_POPUP_SET_JOB_STATUS, INBOUND_POPUP_SET_LASTNAME, INBOUND_POPUP_SET_PHONE } from '../redux/actions/ActionTypes';
import { downloadInboundCourse } from '../redux/actions/popup/InboundActions';
import Select from 'react-select';
import { JOB_STATUS } from '../resources/constants/job';
import { useTranslation } from 'react-i18next';

const InboundPopup = (props) => {
    const dispatch = useDispatch();
    const inboundStore = useSelector(store => store.inboundPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(downloadInboundCourse(inboundStore, inboundStore.completion))
    }

    return (
        <div className='popup white rounded-l shadow padding preview appointment'>
            <div className='header'>
                <span className='subtitle'>Entrez vos informations pour obtenir le programme</span>
                <div className='title'>
                    <div className='icon-container'></div>
                    <h1>Vous y êtes presque...</h1>
                    <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                </div>
            </div>

            <form onSubmit={onSubmit}>
                <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={inboundStore.email} onChange={(email) => dispatch({ type: INBOUND_POPUP_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={inboundStore.isLoading} error={!inboundStore.isEmailValid} helperText={t("HELPER_EMAIL")} />

                <div className='col-2 name'>
                    <Input title={`${t("FIRSTNAME")} (${t("OPTIONAL")})`} placeholder={t("FIRSTNAME")} value={inboundStore.firstname} onChange={(firstname) => dispatch({ type: INBOUND_POPUP_SET_FIRSTNAME, payload: { firstname } })} type={'text'} autocomplete={'given-name'} disabled={inboundStore.isLoading} error={!inboundStore.isFirstnameValid} helperText={t("HELPER_FIRSTNAME")} />
                    <Input title={`${t("LASTNAME")} (${t("OPTIONAL")})`} placeholder={t("LASTNAME")} value={inboundStore.lastname} onChange={(lastname) => dispatch({ type: INBOUND_POPUP_SET_LASTNAME, payload: { lastname } })} type={'text'} autocomplete={'family-name'} disabled={inboundStore.isLoading} error={!inboundStore.isLastnameValid} helperText={t("HELPER_LASTNAME")} />
                </div>

                <div>
                    <InputPhone title={`${t("PHONE")} (${t("OPTIONAL")})`} placeholder={t("PLACEHOLDER_PHONE")} value={inboundStore.phone} onChange={(phone) => dispatch({ type: INBOUND_POPUP_SET_PHONE, payload: { phone } })} error={!inboundStore.isPhoneValid} helperText={t("HELPER_PHONE")} disabled={inboundStore.isLoading} />
                    <Input title={`${t("COMPANY")} (${t("OPTIONAL")})`} placeholder={t("COMPANY")} value={inboundStore.company} onChange={(company) => dispatch({ type: INBOUND_POPUP_SET_COMPANY, payload: { company } })} type={'text'} autocomplete={'organization'} disabled={inboundStore.isLoading} error={!inboundStore.isCompanyValid} helperText={t("HELPER_COMPANY")} />

                    <Input title='Fonction (Facultatif)'>
                        <div className={`duration-picker`}>
                            <Select
                                value={inboundStore.jobStatus}
                                onChange={(option) => dispatch({ type: INBOUND_POPUP_SET_JOB_STATUS, payload: { jobStatus: option } })}
                                options={JOB_STATUS}
                                placeholder={'Fonction dans l\'entreprise'}
                                className={'selector'}
                            />
                        </div>
                    </Input>
                </div>

                <Button title={t("DOWNLOAD_CURSUS")} btnStyle={'primary'} type={'submit'} isLoading={inboundStore.isLoading} />
            </form>
        </div>
    );
}

export default PopupHOC(InboundPopup);