import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import SignPopupHOC from '../../HOC/SignPopupHOC';
import Input from '../../Input';
import { SIGN_POPUP_SET_EMAIL, SIGN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { resetPopupSendPassword } from '../../redux/actions/popup/SignActions';
import { SIGN_POPUP_POPUP_TYPE } from '../../resources/constants/signPopupType';
import { useTranslation } from 'react-i18next';

const ResetPopup = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(resetPopupSendPassword(signStore));
    }

    return (
        <form onSubmit={onSubmit} className='login-popup'>
            <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={signStore.email} onChange={(email) => dispatch({ type: SIGN_POPUP_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={signStore.isLoading} error={!signStore.isEmailValid} helperText={signStore.emailHelperText} />

            <Button title={t("RESET_PASSWORD")} btnStyle={'primary'} type={'submit'} isLoading={signStore.isLoading} />
        </form>
    );
}

const SignInSubTitle = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (<span>{t("SIGN_ALREADY_ACCESS")} <em onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.LOGIN } })}>{t("LOGIN")}</em></span>)
}

export default SignPopupHOC(ResetPopup, 'Récupération', SignInSubTitle);