import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED, ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED, ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED, CATEGORY_ADMIN_POPUP_SET_ENABLED, COURSE_ADMIN_POPUP_SET_ENABLED, INBOUND_POPUP_SET_ENABLED, PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, PREVIEW_POPUP_SET_ENABLED, SIGN_POPUP_SET_ENABLED, SURVEY_ADMIN_POPUP_SET_ENABLED, USER_ADMIN_POPUP_SET_ENABLED, VOUCHER_ADMIN_POPUP_SET_ENABLED, VOUCHER_POPUP_SET_ENABLED } from '../redux/actions/ActionTypes';
import { SIGN_POPUP_POPUP_TYPE } from '../resources/constants/signPopupType';
import AssignAdditionalAdminPopup from './admin/AssignAdditionalAdminPopup';
import AssignCategoryAdminPopup from './admin/AssignCategoryAdminPopup';
import AssignCertificateAdminPopup from './admin/AssignCertificateAdminPopup';
import AssignCourseAdminPopup from './admin/AssignCourseAdminPopup';
import AssignDocumentationAdminPopup from './admin/AssignDocumentationAdminPopup';
import AssignEmbedPageAdminPopup from './admin/AssignEmbedPageAdminPopup';
import AssignFileAdminPopup from './admin/AssignFileAdminPopup';
import AssignLectureAdminPopup from './admin/AssignLectureAdminPopup';
import AssignSectionAdminPopup from './admin/AssignSectionAdminPopup';
import AssignUserAdminPopup from './admin/AssignUserAdminPopup';
import AssignVideoAdminPopup from './admin/AssignVideoAdminPopup';
import CategoryAdminPopup from './admin/CategoryAdminPopup';
import CourseAdminPopup from './admin/CourseAdminPopup';
import SurveyAdminPopup from './admin/SurveyAdminPopup';
import UserAdminPopup from './admin/UserAdminPopup';
import VoucherAdminPopup from './admin/VoucherAdminPopup';
import AppointmentPopup from './AppointmentPopup';
import InboundPopup from './InboundPopup';
import InterviewPopup from './InterviewPopup';
import PreviewPopup from './PreviewPopup';
import LoginPopup from './sign/LoginPopup';
import RegisterPopup from './sign/RegisterPopup';
import ResetPopup from './sign/ResetPopup';
import VoucherPopup from './VoucherPopup';

const PopupManager = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const voucherStore = useSelector(store => store.voucherPopup);
    const previewStore = useSelector(store => store.previewPopup);
    const interviewStore = useSelector(store => store.interviewPopup);
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const courseAdminStore = useSelector(store => store.courseAdminPopup);
    const categoryAdminStore = useSelector(store => store.categoryAdminPopup);
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);
    const assignAdditionalAdminStore = useSelector(store => store.assignAdditionalAdminPopup);
    const assignFileAdminStore = useSelector(store => store.assignFileAdminPopup);
    const assignCategoryAdminStore = useSelector(store => store.assignCategoryAdminPopup);
    const assignSectionAdminStore = useSelector(store => store.assignSectionAdminPopup);
    const assignLectureAdminStore = useSelector(store => store.assignLectureAdminPopup);
    const assignVideoAdminStore = useSelector(store => store.assignVideoAdminPopup);
    const assignEmbedPageAdminStore = useSelector(store => store.AssignEmbedPageAdminPopup);
    const assignDocumentationAdminStore = useSelector(store => store.assignDocumentationAdminPopup);
    const assignCourseAdminStore = useSelector(store => store.assignCourseAdminPopup);
    const assignUserAdminStore = useSelector(store => store.assignUserAdminPopup);
    const assignCertificateAdminStore = useSelector(store => store.assignCertificateAdminPopup);
    const previewPanelStore = useSelector(store => store.previewPanel);
    const inboundStore = useSelector(store => store.inboundPopup);
    const surveyAdminStore = useSelector(store => store.surveyAdminPopup);

    return (
        <>
            <LoginPopup enabled={signStore.popupEnabled === SIGN_POPUP_POPUP_TYPE.LOGIN} isLoading={signStore.isLoading} onClose={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <RegisterPopup enabled={signStore.popupEnabled === SIGN_POPUP_POPUP_TYPE.REGISTER} isLoading={signStore.isLoading} onClose={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <ResetPopup enabled={signStore.popupEnabled === SIGN_POPUP_POPUP_TYPE.RESET_PASSWORD} isLoading={signStore.isLoading} onClose={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: null } })} />

            <VoucherPopup enabled={voucherStore.popupEnabled} onClose={() => dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: false } })} />
            <PreviewPopup enabled={previewStore.popupEnabled} onClose={() => dispatch({ type: PREVIEW_POPUP_SET_ENABLED, payload: { isEnabled: false } })} />

            <CourseAdminPopup enabled={courseAdminStore.popupEnabled !== null} onClose={() => dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <UserAdminPopup enabled={userAdminStore.popupEnabled !== null} onClose={() => dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <CategoryAdminPopup enabled={categoryAdminStore.popupEnabled !== null} onClose={() => dispatch({ type: CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <VoucherAdminPopup enabled={voucherAdminStore.popupEnabled !== null} onClose={() => dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } })} />
            <AssignEmbedPageAdminPopup enabled={assignEmbedPageAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignDocumentationAdminPopup enabled={assignDocumentationAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignCategoryAdminPopup enabled={assignCategoryAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignSectionAdminPopup enabled={assignSectionAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignLectureAdminPopup enabled={assignLectureAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignVideoAdminPopup enabled={assignVideoAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignCourseAdminPopup enabled={assignCourseAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignAdditionalAdminPopup enabled={assignAdditionalAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignFileAdminPopup enabled={assignFileAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignUserAdminPopup enabled={assignUserAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <AssignCertificateAdminPopup enabled={assignCertificateAdminStore.popupEnabled} onClose={() => dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />
            <SurveyAdminPopup enabled={surveyAdminStore.popupEnabled} onClose={() => dispatch({ type: SURVEY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />

            <InterviewPopup enabled={interviewStore.interviewId !== null} />
            <AppointmentPopup enabled={previewPanelStore.interviewPopupEnabled} onClose={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: false } })} />

            <InboundPopup enabled={inboundStore.popupEnabled} onClose={() => dispatch({ type: INBOUND_POPUP_SET_ENABLED, payload: { isEnabled: false } })} />
        </>
    );
}

export default PopupManager;