import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../Button';
import { openVideoAdminPopup } from '../../../popup/admin/assignVideo/VideoListAssignVideoAdminPopup';
import { deleteLectureAdmin, updateLectureFreeAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { openAdditionalAdminPopup } from '../../../redux/actions/popup/admin/AssignAdditionalPopupActions';
import { openDocumentationAdminPopup } from '../../../redux/actions/popup/admin/AssignDocumentationPopupActions';
import { openEmbedPageAdminPopup } from '../../../redux/actions/popup/admin/AssignEmbedPagePopupActions';
import { openFileAdminPopup } from '../../../redux/actions/popup/admin/AssignFilePopupActions';
import SwitchButton from '../../../SwitchButton';
import { convertStringForUrl } from '../../../Utils';

const LectureAddResource = ({ onlyDelete }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();

    const onDeleteLecture = () => {
        if (dispatch(deleteLectureAdmin(lectureStore.lecture.id))) {
            const nextLecture = lectureStore.menu.sections?.map(s => s.lectures)?.flat(1).filter(l => l.id !== lectureStore.lecture.id)[0];
            if (nextLecture) {
                history.replace(`/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${nextLecture.id}/admin`);
            }
        }
    }

    if (onlyDelete)
        return (
            <div className='add-resource rounded-m'>
                <h3>Paramètres</h3>
                <div className='danger-item'>
                    <div className='labels'>
                        <h3>Supprimer la leçon</h3>
                        <span>
                            Cette opération est irréversible.
                    </span>
                    </div>

                    <Button title={'Supprimer le questionnaire'} btnStyle={'cancel autofit'} confirmationRequired onClick={onDeleteLecture} />
                </div>
            </div>
        );

    return (
        <div className='add-resource rounded-m'>
            <h3>Ajouter une ressource</h3>

            <div className='buttons'>
                <Button btnStyle={'white'} onClick={() => dispatch(openVideoAdminPopup(lectureStore.lecture.id))}>
                    <div className='button-content'>
                        <div className='icon big big player'></div>
                        <span>Vidéo interne sécurisée</span>
                    </div>
                </Button>

                <Button btnStyle={'white'} onClick={() => dispatch(openEmbedPageAdminPopup(lectureStore.lecture.id))}>
                    <div className='button-content'>
                        <div className='icon big big labo'></div>
                        <span>Laboratoire (page externe)</span>
                    </div>
                </Button>

                <Button btnStyle={'white'} onClick={() => dispatch(openAdditionalAdminPopup(lectureStore.lecture.id))}>
                    <div className='button-content'>
                        <div className='icon image'></div>
                        <span>Image (ressources supplémentaires)</span>
                    </div>
                </Button>

                <Button btnStyle={'white'} onClick={() => dispatch(openFileAdminPopup(lectureStore.lecture.id))}>
                    <div className='button-content'>
                        <div className='icon download big'></div>
                        <span>Fichier à télécharger</span>
                    </div>
                </Button>

                <Button btnStyle={'white'} onClick={() => dispatch(openDocumentationAdminPopup(lectureStore.lecture.id))}>
                    <div className='button-content'>
                        <div className='icon link'></div>
                        <span>Documentation</span>
                    </div>
                </Button>
            </div>

            <h3>Paramètres</h3>
            <div className='danger-item'>
                <div className='labels'>
                    <h3>Leçon gratuite</h3>
                    <span>
                        Déterminez si cette leçon est accessible à toutes personnes souhaitant la visionner
                    </span>
                </div>

                <SwitchButton isWhite={true} checked={lectureStore.lecture.free} onChange={() => dispatch(updateLectureFreeAdmin(lectureStore.lecture.id, !lectureStore.lecture.free))} />
            </div>

            <div className='danger-item'>
                <div className='labels'>
                    <h3>Supprimer la leçon</h3>
                    <span>
                        Cette opération est irréversible.
                    </span>
                </div>

                <Button title={'Supprimer la leçon'} btnStyle={'cancel autofit'} confirmationRequired onClick={onDeleteLecture} />
            </div>
        </div>
    );
}

export default LectureAddResource;