import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import { openSurveyAdminPopup } from '../../../../redux/actions/popup/admin/SurveyPopupActions';

// Images
import Profile from '../../../../resources/images/profile.png';

const LectureSurveyAdminResult = () => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);

    if (lectureStore.isAdminSurveyResultsLoading)
        return (
            <div className='add-resource rounded-m blue'>
                <h3>Résultats</h3>

                <div className='user-survey-container'>
                    <BasicLoader width={'100%'} height={100} radius={10} />
                </div>
            </div>
        );

    if (!lectureStore.adminSurveyResults?.length)
        return (
            <div className='add-resource rounded-m blue'>
                <h3>Résultats</h3>

                Aucun questionnaire n'a été réalisé.
            </div>
        );

    return (
        <div className='add-resource rounded-m blue'>
            <h3>Résultats</h3>

            <div className='user-survey-container'>
                {lectureStore.adminSurveyResults.map(r => {
                    return (
                        <div className={`result white shadow rounded-s ${r.isLoading ? 'disabled' : ''}`} key={r.id} onClick={r.isLoading ? null : () => dispatch(openSurveyAdminPopup(r.id))}>
                            <div className='info'>
                                <img src={r.user?.picture || Profile} alt={`${r.user?.firstname} ${r.user?.lastname}`} />
                                <span>{r.user?.firstname} {r.user?.lastname}</span>
                            </div>

                            <div className='note'><span className='rounded-s'>{r.finalScore}/{r.finalScoreBase}</span></div>
                        </div>)
                })}
            </div>
        </div>
    );
}

export default LectureSurveyAdminResult;