import React from 'react';
import propTypes from 'prop-types';
import { convertStringForUrl } from '../../../Utils';
import { Link } from 'react-router-dom';

// Images
import NoImage from '../../../resources/images/no-image.png';

const CategoryItem = ({ category }) => {
    return (
        <Link className='white shadow rounded-m thumbnail large' to={`/category/${category.id}-${convertStringForUrl(category.title)}`}>
            <img className='img-main rounded-s' src={category.picture || NoImage} alt={category.title} />

            <div className='info'>
                <span>{category.title}</span>
            </div>
        </Link>
    );
}

CategoryItem.propTypes = {
    category: propTypes.object.isRequired
};

export default CategoryItem;