import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import InputPhone from '../../../InputPhone';
import { PREFERENCE_PANEL_SET_COMPANY, PREFERENCE_PANEL_SET_EMAIL, PREFERENCE_PANEL_SET_FIRSTNAME, PREFERENCE_PANEL_SET_LASTNAME, PREFERENCE_PANEL_SET_PHONE } from '../../../redux/actions/ActionTypes';
import { loadPreferenceInformation, sendConfirmationEmail, updatePreferenceInformation } from '../../../redux/actions/dashboard/PreferenceActions';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PreferenceInformation = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const preferenceStore = useSelector(store => store.preferencePanel);

    useEffect(() => {
        dispatch(loadPreferenceInformation());
    }, [dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(updatePreferenceInformation(preferenceStore));
    }

    return (
        <>
            <Helmet>
                <title>{t("INFORMATIONS")} · {t("PREFERENCES")} · FormationAcademy</title>
            </Helmet>

            <form onSubmit={onSubmit}>
                <div className='container-title'>
                    <h2>{t("INFORMATIONS")}</h2>
                    <span>{t("DASHBOARD_INFORMATIONS_SUBTITLE")}</span>
                </div>

                <div className='informations-panel'>
                    <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={preferenceStore.email} onChange={(email) => dispatch({ type: PREFERENCE_PANEL_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={true} error={!preferenceStore.isInformationsLoading && !preferenceStore.isConfirmedAccount} success={!preferenceStore.isInformationsLoading && preferenceStore.isConfirmedAccount} />

                    {!preferenceStore.isInformationsLoading && !preferenceStore.isConfirmedAccount && !preferenceStore.isConfirmationEmailSent &&
                        <div className='notification-banner rounded-m error'>
                            {preferenceStore.isConfirmationEmailLoading ?
                                <p>{t("SENDING")}</p> :
                                <p>
                                    {t("DASHBOARD_EMAIL_NOT_YET_CONFIRMED")}<br />
                                    {t("DASHBOARD_EMAIL_NOTICE")} <span className='redeem-confirmation-email' onClick={() => dispatch(sendConfirmationEmail())}>{t("CLICK_HERE")}</span>
                                </p>}
                        </div>}

                    {!preferenceStore.isInformationsLoading && !preferenceStore.isConfirmedAccount && preferenceStore.isConfirmationEmailSent &&
                        <div className='notification-banner rounded-m success'>
                            <p>
                                {t("DASHBOARD_EMAIL_SENT")}<br />
                                {t("DASHBOARD_FOLLOW_SENT_EMAIL")}
                            </p>
                        </div>}


                    <div className='col-2 last-margin'>
                        <Input title={t("FIRSTNAME")} placeholder={t("FIRSTNAME")} value={preferenceStore.firstname} onChange={(firstname) => dispatch({ type: PREFERENCE_PANEL_SET_FIRSTNAME, payload: { firstname } })} type={'text'} autocomplete={'given-name'} disabled={preferenceStore.isInformationsLoading} error={!preferenceStore.isFirstnameValid} helperText={t("HELPER_FIRSTNAME")} />
                        <Input title={t("LASTNAME")} placeholder={t("LASTNAME")} value={preferenceStore.lastname} onChange={(lastname) => dispatch({ type: PREFERENCE_PANEL_SET_LASTNAME, payload: { lastname } })} type={'text'} autocomplete={'family-name'} disabled={preferenceStore.isInformationsLoading} error={!preferenceStore.isLastnameValid} helperText={t("HELPER_LASTNAME")} />
                    </div>

                    <InputPhone title={`${t("PHONE")} (${t("OPTIONAL")})`} placeholder={t("PLACEHOLDER_PHONE")} value={preferenceStore.phone} onChange={(phone) => dispatch({ type: PREFERENCE_PANEL_SET_PHONE, payload: { phone } })} error={!preferenceStore.isPhoneValid} helperText={t("HELPER_PHONE")} disabled={preferenceStore.isInformationsLoading} />

                    <Input title={`${t("COMPANY")} (${t("OPTIONAL")})`} placeholder={t("COMPANY")} value={preferenceStore.company} onChange={(company) => dispatch({ type: PREFERENCE_PANEL_SET_COMPANY, payload: { company } })} type={'text'} autocomplete={'organization'} disabled={preferenceStore.isInformationsLoading} error={!preferenceStore.isCompanyValid} helperText={t("HELPER_COMPANY")} />
                </div>

                <div className='right-alignment'>
                    <Button title={t("UPDATE")} btnStyle={'primary'} isLoading={preferenceStore.isInformationsLoading} type='submit' />
                </div>
            </form>
        </>
    );
}

export default PreferenceInformation;