import React from 'react';
import Button from '../../../Button';
import propTypes from 'prop-types';
import { convertStringToDate, convertStringToTime } from '../../../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSession } from '../../../redux/actions/dashboard/PreferenceActions';

const PreferenceSecuritySessionItem = ({ session }) => {
    const dispatch = useDispatch();
    const preferenceStore = useSelector(store => store.preferencePanel);

    return (
        <div className='danger-item'>
            <div className='labels'>
                {!session.browserName && !session.osName ? <h3>Inconnu</h3> : <h3>{session.browserName || 'Navigateur inconnu'} <div className='dot'></div> {session.osName || 'OS inconnu'}</h3>}
                {session.isCurrent ? <span className='active'>Connexion actuelle</span> : <span>Dernière connexion : {`${convertStringToDate(session.latestUse)} ${convertStringToTime(session.latestUse)}`}</span>}
            </div>

            {!session.isCurrent && <Button title={'Déconnecter'} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteSession(session.id))} isLoading={preferenceStore.isSessionActionLoading} />}
        </div>
    );
}

PreferenceSecuritySessionItem.propTypes = {
    session: propTypes.object.isRequired
};

export default PreferenceSecuritySessionItem;