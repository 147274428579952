import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../Button';
import { ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';
import { addCertificateAdminPopup } from '../../../redux/actions/popup/admin/AssignCertificatePopupActions';
import { openCourseAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import { timeRemainingForNow } from '../../../Utils';
import { useTranslation } from 'react-i18next';

const CourseItemAssignCertificateAdminPopup = ({ course, isSelected }) => {
    const dispatch = useDispatch();
    const expireDate = timeRemainingForNow(course.expireDate);
    const { t } = useTranslation();

    const onEdit = () => {
        dispatch(openCourseAdminPopup(course.courseId));
        dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
    }

    return (
        <div className={`item white shadow rounded-m thumbnail ${expireDate.isExpired || course.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            {!course.isLoading && <div className='item transition' onClick={onEdit}>Modifier la formation</div>}
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                <div className='progress-bar'>
                    <div className='progression'>
                        <div className='progress shadow' style={{ width: `${(course.nbLecturesDone / course.nbLectures) * 100}%` }}></div>
                    </div>

                    <span><b>{course.nbLecturesDone} / {course.nbLectures}</b> {course.nbLecturesDone > 1 ? t("COURSES_DONE") : t("COURSE_DONE")}</span>

                    <Button title={isSelected ? 'Généré' : 'Générer'} btnStyle={`${isSelected ? 'success' : 'primary'}`} isLoading={course.isLoading} onClick={isSelected ? null : () => dispatch(addCertificateAdminPopup(course.id))} />
                </div>
            </div>
        </div>
    );
}

CourseItemAssignCertificateAdminPopup.propTypes = {
    course: propTypes.object.isRequired
};

export default CourseItemAssignCertificateAdminPopup;