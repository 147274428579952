import React from 'react';
import { useSelector } from 'react-redux';
import UserAdminPanelItemLoader from '../../../loader/UserAdminPanelItemLoader';
import UserItemAssignUserAdminPopup from './UserItemAssignUserAdminPopup';
import { useTranslation } from 'react-i18next';

const UserListAssignUserAdminPopup = () => {
    const assignUserAdminStore = useSelector(store => store.assignUserAdminPopup);
    const { t } = useTranslation();

    if (!assignUserAdminStore.isLoading && !assignUserAdminStore.users?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_USERS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {assignUserAdminStore.users.map(user => <UserItemAssignUserAdminPopup key={user.id} user={user} isSelected={assignUserAdminStore.selectedUsers.includes(user.id)} />)}

            {assignUserAdminStore.isLoading && <>
                <div className='item white user-card loading'><UserAdminPanelItemLoader /></div>
                <div className='item white user-card loading'><UserAdminPanelItemLoader /></div>
                <div className='item white user-card loading'><UserAdminPanelItemLoader /></div>
            </>}
        </div>
    );
}

export default UserListAssignUserAdminPopup