import Axios from "axios";
import { ADMIN_USER_MENU_TYPE } from "../../../../resources/constants/adminPanel";
import { createNotification } from "../../../../Utils";
import { VOUCHER_ADMIN_PANEL_ADD_VOUCHERS, VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER, VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER, VOUCHER_ADMIN_PANEL_DELETE_VOUCHER, VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER, VOUCHER_ADMIN_POPUP_ADD_USERS, VOUCHER_ADMIN_POPUP_DATES_CHECK, VOUCHER_ADMIN_POPUP_DELETE_COURSE, VOUCHER_ADMIN_POPUP_DELETE_USER, VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK, VOUCHER_ADMIN_POPUP_SET_COURSES, VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING, VOUCHER_ADMIN_POPUP_SET_ENABLED, VOUCHER_ADMIN_POPUP_SET_HAS_NEXT, VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, VOUCHER_ADMIN_POPUP_SET_LOADING, VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE, VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE, VOUCHER_ADMIN_POPUP_SET_USERS_LOADING, VOUCHER_ADMIN_POPUP_SET_USER_LOADING, VOUCHER_ADMIN_POPUP_SET_VOUCHER, VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID, VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS } from "../../ActionTypes";
import i18n from 'i18next';

const checkVoucherInformations = (voucherAdminStore) => async dispatch => {
    const isDatesValid = new Date(voucherAdminStore.startDate) && new Date(voucherAdminStore.endDate) && (new Date(voucherAdminStore.startDate) <= new Date(voucherAdminStore.endDate));
    const isPlaceAvailableValid = voucherAdminStore.placeAvailable >= 1;

    dispatch({ type: VOUCHER_ADMIN_POPUP_DATES_CHECK, payload: { isValid: isDatesValid } });
    dispatch({ type: VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK, payload: { isValid: isPlaceAvailableValid } });

    return isDatesValid && isPlaceAvailableValid;
}

export const openVoucherAdminPopup = (voucherId = null) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_USER_MENU_TYPE.OVERVIEW } });

    if (voucherId) {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID, payload: { voucherId } });
    } else {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: 'Création d\'un bon d\'accès' } });
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        dispatch({ type: VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: false } });
    }
}

export const loadVoucherAdminPopup = (voucherId) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`voucher/${voucherId}/admin`)
        .then(({ data }) => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: `Modification de ${data.code}` } });
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_VOUCHER, payload: { voucher: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const createVoucherAdminPopup = (voucherAdminStore) => async dispatch => {
    if (await dispatch(checkVoucherInformations(voucherAdminStore))) {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.post(`voucher/create/admin`, {
            placesAvailable: voucherAdminStore.placeAvailable,
            startDate: new Date(voucherAdminStore.startDate),
            endDate: new Date(voucherAdminStore.endDate)
        }).then(({ data }) => {
            dispatch({ type: VOUCHER_ADMIN_PANEL_ADD_VOUCHERS, payload: { vouchers: [data] } });
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_VOUCHER, payload: { voucher: data } });
            dispatch({ type: VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const updateVoucherAdminPopup = (voucherAdminStore) => async dispatch => {
    if (await dispatch(checkVoucherInformations(voucherAdminStore))) {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.put(`voucher/${voucherAdminStore.voucherId}/update/admin`, {
            placesAvailable: voucherAdminStore.placeAvailable,
            startDate: new Date(voucherAdminStore.startDate),
            endDate: new Date(voucherAdminStore.endDate)
        }).then(({ data }) => {
            dispatch({ type: VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER, payload: { id: voucherAdminStore.voucherId, voucher: data } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_SUCCESS_ADMIN"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const deleteVoucherAdminPopup = (voucherId) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

    Axios.delete(`voucher/${voucherId}/delete/admin`)
        .then(() => {
            dispatch({ type: VOUCHER_ADMIN_PANEL_DELETE_VOUCHER, payload: { id: voucherId } });
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
}

export const deleteUserVoucherAdminPopup = (userVoucherId) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USER_LOADING, payload: { userVoucherId, isLoading: true } });

    Axios.delete(`voucher/user/${userVoucherId}/delete/admin`)
        .then(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_DELETE_USER, payload: { userVoucherId } });
            dispatch({ type: VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER, payload: { voucherId: 41, userVoucherId } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USER_LOADING, payload: { userVoucherId, isLoading: false } });
        });
}

export const loadCourseVoucherAdminPopup = (voucherId) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: true } });

    Axios.get(`voucher/${voucherId}/course/all/admin`)
        .then(({ data }) => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_COURSES, payload: { courses: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: false } });
        });
}

export const loadUserVoucherAdminPopup = (voucherId, pageIndex = null, name = null) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USERS_LOADING, payload: { isLoading: true } });

    Axios.get(`voucher/${voucherId}/user/all/admin`, {
        params: {
            pageIndex,
            name
        }
    }).then(({ data }) => {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: VOUCHER_ADMIN_POPUP_ADD_USERS, payload: { users: data.paging } });
    }).finally(() => {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USERS_LOADING, payload: { isLoading: false } });
    });
}

export const deleteCourseVoucherAdminPopup = (courseVoucherId) => async dispatch => {
    dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, payload: { id: courseVoucherId, isLoading: true } });

    Axios.delete(`courseVoucher/${courseVoucherId}/delete/admin`)
        .then(({ data }) => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_DELETE_COURSE, payload: { id: courseVoucherId } });
            dispatch({ type: VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER, payload: { voucherId: data.id, courseVoucherId } })
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, payload: { id: courseVoucherId, isLoading: false } });
        });
}