import { MENU_SET_RESULT_VISIBLE, MENU_SET_SEARCHING_VALUE, MENU_SET_CATEGORIES_LOADING, MENU_SET_CATEGORIES, MENU_SET_MOBILE_MENU, MENU_SET_SEARCHING_RESULT, MENU_SET_SEARCHING_LOADING, MENU_SET_LOADED } from "../actions/ActionTypes";

const defaultState = {
    isResultVisible: false,
    mobileMenu: null,

    searchingValue: '',
    searchingResults: [],
    isSearchingLoading: true,

    categories: [],
    isCategoriesLoading: true,

    loaded: false
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case MENU_SET_RESULT_VISIBLE: {
            return { ...state, isResultVisible: action.payload.isVisible };
        }
        case MENU_SET_MOBILE_MENU: {
            return { ...state, mobileMenu: action.payload.menu };
        }

        case MENU_SET_SEARCHING_VALUE: {
            return { ...state, searchingValue: action.payload.value };
        }
        case MENU_SET_SEARCHING_RESULT: {
            return { ...state, searchingResults: action.payload.results };
        }
        case MENU_SET_SEARCHING_LOADING: {
            return { ...state, isSearchingLoading: action.payload.isLoading };
        }

        case MENU_SET_CATEGORIES_LOADING: {
            return { ...state, isCategoriesLoading: action.payload.isLoading };
        }
        case MENU_SET_CATEGORIES: {
            return { ...state, categories: action.payload.categories };
        }

        case MENU_SET_LOADED: {
            return { ...state, isLoaded: action.payload.isLoaded };
        }

        default:
            return state;
    }
}
