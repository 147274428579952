import propTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertSecondToAverageTime } from '../../../Utils';
import { PREVIEW_PANEL_TOGGLE_SECTION } from '../../../redux/actions/ActionTypes';
import PreviewLecture from './PreviewLecture';

const PreviewSection = ({ section, isExtended, inboundMode = false }) => {
    const dispatch = useDispatch();
    const totalMinutesSection = section.lecture.map(lecture => parseInt(lecture.secondDuration)).reduce((a, b) => a + b, 0);
    const { t } = useTranslation();

    // if (!totalMinutesSection)
    //     return (
    //         <div className={`section rounded-l part`}>
    //             <div className='section-title'>
    //                 <div className='label'>
    //                     <span className='name'>{section.name}</span>
    //                 </div>
    //             </div>
    //         </div>
    //     );

    return (
        <div className={`section rounded-l ${isExtended ? 'extended' : ''}`}>
            <div className='section-title' onClick={() => dispatch({ type: PREVIEW_PANEL_TOGGLE_SECTION, payload: { id: section.id } })}>
                <div className='label'>
                    <span className='name'>{section.name}</span>
                    {totalMinutesSection ? <>
                        <div className='dot'></div>
                        <span className='duration'>{section.lecture.length} {section.lecture.length > 1 ? t("LECTURES") : t("LECTURE")} {totalMinutesSection ? `- ${convertSecondToAverageTime(totalMinutesSection)}` : null}</span>
                    </> : null}
                </div>

                <div className='icon arrow'></div>
            </div>

            <ul>
                {section.lecture.map(lecture => <PreviewLecture key={lecture.id} lecture={lecture} inboundMode={inboundMode} />)}
            </ul>
        </div>
    );
}

PreviewSection.propTypes = {
    section: propTypes.object.isRequired,
    isExtended: propTypes.bool
};

export default PreviewSection;