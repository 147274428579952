import React from 'react'

const AdminPanelHOC = (WrappedComponent, title, subTitle) => {
    const HOC = (props) => {
        return (
            <>
                <div className='title'>
                    <h2>{title}</h2>
                    <span>{subTitle}</span>
                </div>

                <WrappedComponent {...props} />
            </>
        )
    }

    return HOC
}

export default AdminPanelHOC