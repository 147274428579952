import Axios from "axios";
import { LECTURE_TYPE } from "../../../../resources/constants/lectureType";
import { createNotification } from "../../../../Utils";
import { ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW, ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK, LECTURE_PANEL_ADMIN_ADD_EMBED_PAGE } from "../../ActionTypes";
import i18n from 'i18next';

const checkInformations = (url) => async dispatch => {
    const isUrlValid = url && url.length <= 30000;

    dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK, payload: { isValid: isUrlValid } });

    return isUrlValid;
}

export const openEmbedPageAdminPopup = (lectureId) => async dispatch => {
    dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID, payload: { id: lectureId } });
}

export const previewEmbedPageAdminPopup = (url) => async dispatch => {
    if (await dispatch(checkInformations(url)))
        dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW, payload: { preview: url } });
}

export const addEmbedPageAdminPopup = (assignEmbedPageAdminStore) => async dispatch => {
    if (await dispatch(checkInformations(assignEmbedPageAdminStore.url))) {
        dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

        let secondDuration = 0;
        if (assignEmbedPageAdminStore.duration?.hours)
            secondDuration += assignEmbedPageAdminStore.duration.hours * 3600;

        if (assignEmbedPageAdminStore.duration?.minutes)
            secondDuration += assignEmbedPageAdminStore.duration.minutes * 60;

        if (assignEmbedPageAdminStore.duration?.seconds)
            secondDuration += assignEmbedPageAdminStore.duration.seconds;

        Axios.post(`resource/add/admin`, {
            lectureId: assignEmbedPageAdminStore.lectureId,
            title: assignEmbedPageAdminStore.url.substring(0, 100),
            link: assignEmbedPageAdminStore.url,
            secondDuration,
            type: LECTURE_TYPE.EMBEDPAGE
        }).then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_ADMIN_ADD_EMBED_PAGE, payload: { embedPage: data } });
            dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_EMBEDPAGE_ADDED"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
    }
}