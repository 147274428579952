import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import CourseUserItemAdminPopup from './CourseUserItemAdminPopup';
import { useTranslation } from 'react-i18next';

const CourseUserListAdminPopup = () => {
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const { t } = useTranslation();

    if (userAdminStore.isCoursesLoading)
        return (
            <div className='list'>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
            </div>
        );

    if (!userAdminStore.courses || !userAdminStore.courses.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        )

    return (
        <div className='list'>
            {userAdminStore.courses && userAdminStore.courses.map((course) => <CourseUserItemAdminPopup key={course.id} course={course} />)}
        </div>
    );
}

export default CourseUserListAdminPopup;