import { USER_ADMIN_PANEL_ADD_USERS, USER_ADMIN_PANEL_DELETE_USER, USER_ADMIN_PANEL_SET_HAS_NEXT, USER_ADMIN_PANEL_SET_LOADING, USER_ADMIN_PANEL_SET_PAGING_PAGE, USER_ADMIN_PANEL_SET_SEARCH_VALUE, USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY, USER_ADMIN_PANEL_SET_USERS, USER_ADMIN_PANEL_UPDATE_USER, USER_ADMIN_PANEL_UPDATE_USER_PICTURE, USER_ADMIN_PANEL_UPDATE_USER_STATUS } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    users: [],
    hasNext: true,
    pagingPage: 0,

    searchValue: '',
    showActiveUsersOnly: false,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case USER_ADMIN_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case USER_ADMIN_PANEL_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case USER_ADMIN_PANEL_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        case USER_ADMIN_PANEL_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY: {
            return { ...state, showActiveUsersOnly: action.payload.showActiveUsersOnly };
        }

        case USER_ADMIN_PANEL_SET_USERS: {
            return { ...state, users: action.payload.users };
        }
        case USER_ADMIN_PANEL_ADD_USERS: {
            return { ...state, users: [...state.users.concat(action.payload.users)] };
        }
        case USER_ADMIN_PANEL_UPDATE_USER: {
            return {
                ...state, users: [...state.users.map(u => {
                    if (u.id !== action.payload.id)
                        return u;

                    return action.payload.user;
                })]
            }
        }
        case USER_ADMIN_PANEL_UPDATE_USER_STATUS: {
            const { id, confirmed } = action.payload;
            return {
                ...state, users: [...state.users.map(u => {
                    if (u.id !== id)
                        return u;

                    return { ...u, confirmed };
                })]
            }
        }
        case USER_ADMIN_PANEL_UPDATE_USER_PICTURE: {
            const { id, picture } = action.payload;
            return {
                ...state, users: [...state.users.map(u => {
                    if (u.id !== id)
                        return u;

                    return { ...u, picture };
                })]
            }
        }

        case USER_ADMIN_PANEL_DELETE_USER: {
            return { ...state, users: state.users.filter(u => u.id !== action.payload.id) };
        }

        default:
            return state;
    }
}