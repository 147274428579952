import React from 'react';
import propTypes from 'prop-types';

const MultipleStateSwitch = ({ choices, onSelect, disabled, selectedId }) => {
    return (
        <div className={`multiple-switch shadow ${disabled ? 'disabled' : ''}`}>
            {choices.map((choice) => <div key={choice.id} className={`choice transition ${selectedId === choice.id ? 'selected' : ''}`} onClick={!disabled ? () => onSelect(choice) : null}>{choice.label}</div>)}
        </div>
    );
}

MultipleStateSwitch.propTypes = {
    choices: propTypes.array.isRequired,
    onSelect: propTypes.func.isRequired,
    disabled: propTypes.bool,
    selectedId: propTypes.number
};

export default MultipleStateSwitch;