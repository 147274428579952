import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import propTypes from 'prop-types';

const Datepicker = ({ component, selected, onChange, disabled, startDate, endDate, selectsStart, selectsEnd, minDate }) => {
    registerLocale("fr", fr);

    return (
        <DatePicker
            selected={selected}
            onChange={onChange}
            customInput={component}
            calendarClassName="datepicker"
            locale="fr"
            disabled={disabled}
            startDate={startDate}
            endDate={endDate}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            minDate={minDate}
            dateFormat="dd/MM/yyyy"
        />
    );
}

Datepicker.propTypes = {
    component: propTypes.object.isRequired,
    selected: propTypes.instanceOf(Date).isRequired,
    onChange: propTypes.func.isRequired,
    disabled: propTypes.bool,
    startDate: propTypes.instanceOf(Date),
    endDate: propTypes.instanceOf(Date),
    selectsStart: propTypes.bool,
    selectsEnd: propTypes.bool,
    minDate: propTypes.instanceOf(Date)
};

export default Datepicker;