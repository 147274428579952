import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_CATEGORY_ADMIN_POPUP_RESET, ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE } from '../../redux/actions/ActionTypes';
import { loadCategoriesAdminPopup } from '../../redux/actions/popup/admin/AssignCategoryPopupActions';
import { openCategoryAdminPopup } from '../../redux/actions/popup/admin/CategoryPopupActions';
import CategoryListAssignCategoryAdminPopup from './assignCategory/CategoryListAssignCategoryAdminPopup';

const AssignCategoryAdminPopup = () => {
    const assignCategoryAdminStore = useSelector(store => store.assignCategoryAdminPopup);
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = useState(null);

    useEffect(() => {
        dispatch(loadCategoriesAdminPopup());

        return () => {
            dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const onTyping = (value) => {
        setSearchResults(assignCategoryAdminStore.categories.filter(c => c.title.includes(value)));
    }

    const openCreateCategoryPopup = () => {
        dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
        dispatch(openCategoryAdminPopup())
    }

    return (
        <div className={`assign-category-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignCategoryAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer une catégorie' btnStyle='primary' onClick={openCreateCategoryPopup} />
            </div>

            <div className='section'>
                <CategoryListAssignCategoryAdminPopup searchResults={searchResults} />
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignCategoryAdminPopup, 'Assigner une catégorie', 'Création ou assignation d\'une catégorie');