import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Button from './Button';

const CookiesPopup = () => {
    const nodeRef = useRef(null);
    const [acceptedCookies, setAcceptedCookies] = useState(localStorage.getItem(`accept_cookies`));

    const onAgree = () => {
        localStorage.setItem('accept_cookies', true);
        setAcceptedCookies(true);
    }

    const onDecline = () => {
        if (window.confirm("FormationAcademy ne peut fonctionner correctement sans les cookies. En confirmant la non utilisation de ceux-ci vous serez redirigé vers Google. Voulez-vous continuer ?")) {
            window.location.href = "https://google.com";
        }
    }

    return (
        <CSSTransition nodeRef={nodeRef} in={!acceptedCookies} mountOnEnter unmountOnExit timeout={200} classNames='fade'>
            <div className='cookies-popup-custom white shadow rounded-m' ref={nodeRef}>
                <div className='icon cookie'></div>
                <span className='title'>Nous utilisons les cookies</span>
                <p>En cliquant sur « Accepter les cookies », vous acceptez de stocker des cookies sur votre appareil afin d'améliorer votre navigation sur le site et d'analyser votre utilisation du site. <Link to='/privacy'>Politique de confidentialité</Link></p>
                <Button title='Accepter les cookies' btnStyle='primary' onClick={onAgree} />
                <span className='decline' onClick={onDecline}>Décliner</span>
            </div>
        </CSSTransition>
    );
}

export default CookiesPopup;