import jsPDF from 'jspdf';
import Logo from '../resources/images/logo/logo.png';
import Banner from '../resources/images/pdf/banner.png';
import ContactBtn from '../resources/images/pdf/contact.png';
import Check from '../resources/images/icon/check.png';
import Star from '../resources/images/icon/star.png';
import Bag from '../resources/images/icon/bag.png';
import '../resources/fonts/Raleway-Bold-normal';
import '../resources/fonts/Raleway-Light-normal';
import '../resources/fonts/Raleway-SemiBold-normal';
import { convertSecondToAverageTime, convertSecondToTime, convertStringForUrl } from '../Utils';
import i18n from 'i18next';

export default function SurveyAdminPDF(lecture, survey, completion = null) {
    let doc = new jsPDF();

    const newPage = () => {
        doc.addPage();
        CURRENT_Y = MARGIN * 2;
    }

    const MARGIN = 10;
    const MAX_WIDTH = doc.internal.pageSize.getWidth() - (2 * MARGIN);
    const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
    const PAGE_WIDTH = doc.internal.pageSize.getWidth();
    let CURRENT_Y = MARGIN;

    doc.setFont("Raleway-Bold");
    doc.setFontSize(18);

    doc.text(lecture.title, MARGIN, CURRENT_Y, { maxWidth: MAX_WIDTH });
    CURRENT_Y += doc.getTextDimensions(lecture.title, {
        fontSize: 18,
        maxWidth: MAX_WIDTH
    }).h + 4;


    for (const question of survey.surveyQuestion) {
        // Calculer si ça passe sur la page
        let questionHeightSize = doc.getTextDimensions(question.title, {
            fontSize: 10,
            maxWidth: MAX_WIDTH
        }).h + 4;
        for (const answer of question.surveyAnswer) {
            questionHeightSize += doc.getTextDimensions(answer.title, {
                fontSize: 10,
                maxWidth: MAX_WIDTH
            }).h + 3;
        }

        if (questionHeightSize + 10 > PAGE_HEIGHT) {
            newPage()
        }

        doc.setFont("Raleway-SemiBold");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);

        doc.text(question.title, MARGIN, CURRENT_Y, { maxWidth: MAX_WIDTH });
        CURRENT_Y += doc.getTextDimensions(question.title, {
            fontSize: 10,
            maxWidth: MAX_WIDTH
        }).h + 4;

        for (const answer of question.surveyAnswer) {
            doc.setFont("Raleway-Light");

            answer.correct ? doc.setTextColor(0, 120, 0) : doc.setTextColor(255, 0, 0);

            doc.text(answer.title, MARGIN, CURRENT_Y, { maxWidth: MAX_WIDTH });
            CURRENT_Y += doc.getTextDimensions(answer.title, {
                fontSize: 10,
                maxWidth: MAX_WIDTH
            }).h + 3;
        }

        CURRENT_Y += 4
    }

    doc.save(`TITRE`);
    if (completion) completion();
}