import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { LECTURE_PANEL_SURVEY_DELETE_ADMIN_RESULT, LECTURE_PANEL_SURVEY_SET_ADMIN_RESULT_LOADING, SURVEY_ADMIN_POPUP_SET_ACTION_LOADING, SURVEY_ADMIN_POPUP_SET_ENABLED, SURVEY_ADMIN_POPUP_SET_LOADING, SURVEY_ADMIN_POPUP_SET_POPUP_TITLE, SURVEY_ADMIN_POPUP_SET_RESULTS, SURVEY_ADMIN_POPUP_SET_SURVEY_ID, USER_ADMIN_POPUP_DELETE_SURVEY, USER_ADMIN_POPUP_SET_SURVEY_LOADING } from "../../ActionTypes";
import i18n from 'i18next';

export const openSurveyAdminPopup = (surveyId) => async dispatch => {
    dispatch({ type: SURVEY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: SURVEY_ADMIN_POPUP_SET_SURVEY_ID, payload: { id: surveyId } });
}

export const loadSurveyResultAdminPopup = (surveyId) => async dispatch => {
    dispatch({ type: SURVEY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });
    dispatch({ type: SURVEY_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: 'Chargement' } });

    Axios.get(`survey/user/${surveyId}/details/admin`)
        .then(({ data }) => {
            dispatch({ type: SURVEY_ADMIN_POPUP_SET_RESULTS, payload: { results: data } });
            dispatch({ type: SURVEY_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: data.title } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: SURVEY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        })
}

export const deleteUserSurveyAdminPopup = (userSurveyId) => async dispatch => {
    dispatch({ type: SURVEY_ADMIN_POPUP_SET_ACTION_LOADING, payload: { isLoading: true } });
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_ADMIN_RESULT_LOADING, payload: { id: userSurveyId, isLoading: true } });
    Axios.delete(`survey/${userSurveyId}/delete/admin`)
        .then(() => {
            dispatch({ type: USER_ADMIN_POPUP_DELETE_SURVEY, payload: { id: userSurveyId } });
            dispatch({ type: LECTURE_PANEL_SURVEY_DELETE_ADMIN_RESULT, payload: { id: userSurveyId } });
            dispatch({ type: SURVEY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_SURVEY_LOADING, payload: { id: userSurveyId, isLoading: false } });
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_ADMIN_RESULT_LOADING, payload: { id: userSurveyId, isLoading: false } });
            dispatch({ type: SURVEY_ADMIN_POPUP_SET_ACTION_LOADING, payload: { isLoading: false } });
        });
}