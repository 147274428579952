import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import Datepicker from '../../../../Datepicker';
import { deleteUserCourseAdminPopup, openUserAdminPopup, updateExpireDateUserCourseAdminPopup, updateUnitCompleteLockUserCourseAdminPopup } from '../../../../redux/actions/popup/admin/UserPopupActions';
import Profile from '../../../../resources/images/profile.png';
import SwitchButton from '../../../../SwitchButton';
import { timeRemainingForNow } from '../../../../Utils';
import { useTranslation } from 'react-i18next';

const UserItemCourseAdminPopup = ({ userCourse }) => {
    const dispatch = useDispatch();
    const { user } = userCourse;
    const expireDate = timeRemainingForNow(user.expireDate);
    const { t } = useTranslation();

    return (
        <div className={`item user-card thumbnail ${userCourse.isLoading ? 'disabled' : ''}`}>
            <div className='box shadow rounded-l white'>
                <div className='options actions-banner'>
                    <div className='more-button shadow white'>
                        <div className='icon more'></div>
                        <div className='sub'>
                            <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                                <div className='item transition' onClick={() => dispatch(openUserAdminPopup(user.id))}>Modifier l'utilisateur</div>
                                <div className='item transition'><Button title={'Supprimer le lien'} isLoading={userCourse.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteUserCourseAdminPopup(userCourse.userCourseId))} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='general-informations'>
                    <div className='main'>
                        <img className='shadow' src={user.picture || Profile} alt={`${user.firstname} ${user.lastname}`} />

                        <div className='info'>
                            <div className='name'>
                                {user.confirmed && <div className='status'><div className='icon check-bold white' /></div>}
                                <span>{user.firstname} {user.lastname}</span>
                            </div>

                            {user.business && <span className='company'>{user.business}</span>}
                        </div>
                    </div>

                    <div className='info'>
                        <div className='progress-bar'>
                            <div className='progression'>
                                <div className='progress shadow' style={{ width: `${(user.nbLecturesDone / user.nbLectures) * 100}%` }}></div>
                            </div>

                            <span><b>{user.nbLecturesDone} / {user.nbLectures}</b> {user.nbLecturesDone > 1 ? t("COURSES_DONE") : t("COURSE_DONE")}</span>
                        </div>

                        <div className='or'>
                            <hr />
                        </div>

                        <div className='quick-actions'>
                            <span className={`time-left ${expireDate.isExpired ? 'red' : 'green'}`}>{expireDate.str}</span>
                            <Datepicker component={<span className='modify'>Modifier</span>} selected={new Date(user.expireDate)} onChange={(date) => dispatch(updateExpireDateUserCourseAdminPopup(userCourse.userCourseId, date))} disabled={userCourse.isLoading} />
                        </div>

                        <div className='quick-actions line'>
                            <span className={`time-left ${!user.unitCompleteLock ? '' : 'green'}`}>Cours ordonné</span>
                            <SwitchButton checked={user.unitCompleteLock} onChange={() => dispatch(updateUnitCompleteLockUserCourseAdminPopup(userCourse.userCourseId, !user.unitCompleteLock))} zoom={0.8} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UserItemCourseAdminPopup.propTypes = {
    userCourse: propTypes.object.isRequired
};

export default UserItemCourseAdminPopup;