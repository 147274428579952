import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import VoucherAdminItem from './VoucherAdminItem';
import { useTranslation } from 'react-i18next';

const VoucherAdminList = () => {
    const voucherAdminStore = useSelector(store => store.voucherAdminPanel);
    const { t } = useTranslation()

    if (!voucherAdminStore.isLoading && !voucherAdminStore.vouchers?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_VOUCHERS_FOUND")}
            </div>
        );


    return (
        <div className='list'>
            {voucherAdminStore.vouchers?.map(voucher => <VoucherAdminItem key={voucher.id} voucher={voucher} />)}

            {voucherAdminStore.isLoading && <>
                <div className={`item thumbnail voucher-card loading`}><BasicLoader width={'100%'} height={320} radius={10} /></div>
                <div className={`item thumbnail voucher-card loading`}><BasicLoader width={'100%'} height={320} radius={10} /></div>
                <div className={`item thumbnail voucher-card loading`}><BasicLoader width={'100%'} height={320} radius={10} /></div>
            </>}
        </div>
    );
}

export default VoucherAdminList;