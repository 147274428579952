import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPanelHOC from '../../HOC/AdminPanelHOC';
import Input from '../../Input';
import { USER_ADMIN_PANEL_SET_SEARCH_VALUE, USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY, USER_ADMIN_PANEL_SET_USERS } from '../../redux/actions/ActionTypes';
import { loadUserAdmin } from '../../redux/actions/admin/UserAdminActions';
import { openUserAdminPopup } from '../../redux/actions/popup/admin/UserPopupActions';
import UserAdminList from './user/UserAdminList';

const UserAdminPanel = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPanel);
    const panelRef = useRef();
    const [reloadMore, setReloadMore] = useState(false);

    const onScroll = () => {
        const scrollFromBottom = window.scrollY + window.screen.height;
        const bottomContainerPosition = panelRef.current.offsetTop + panelRef.current.offsetHeight + 250;

        if (scrollFromBottom > bottomContainerPosition) {
            setReloadMore(true);
        }
    }

    useEffect(() => {
        // dispatch(loadUserAdmin(0, null, false));

        document.addEventListener('scroll', onScroll);

        return () => {
            dispatch({ type: USER_ADMIN_PANEL_SET_USERS, payload: { users: [] } });
            document.removeEventListener('scroll', onScroll);
        }
    }, [dispatch]);

    useEffect(() => {
        const fetch = async () => {
            if (reloadMore && !userAdminStore.isLoading && userAdminStore.hasNext) {
                await dispatch(loadUserAdmin(userAdminStore.pagingPage + 1, userAdminStore.searchValue, userAdminStore.showActiveUsersOnly));
                setReloadMore(false);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [reloadMore, userAdminStore.isLoading, userAdminStore.hasNext]);

    const onTyping = (value) => {
        dispatch({ type: USER_ADMIN_PANEL_SET_USERS, payload: { users: [] } });
        dispatch(loadUserAdmin(0, value, userAdminStore.showActiveUsersOnly));
    }

    useEffect(() => {
        dispatch({ type: USER_ADMIN_PANEL_SET_USERS, payload: { users: [] } });
        dispatch(loadUserAdmin(0, userAdminStore.searchValue, userAdminStore.showActiveUsersOnly));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAdminStore.showActiveUsersOnly])

    return (
        <div className='content user-panel' ref={panelRef}>
            <div className='search-bar'>
                <div style={{ flex: '1' }}>
                    <Input placeholder={'Recherche'} value={userAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: USER_ADMIN_PANEL_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                </div>
                <div className={`filter-button rounded-s transition ${userAdminStore.showActiveUsersOnly ? 'enabled' : ''}`} onClick={() => dispatch({ type: USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY, payload: { showActiveUsersOnly: !userAdminStore.showActiveUsersOnly } })}>
                    <span>Voir uniquement les membres actifs</span>
                </div>
                <Button title='Créer un utilisateur' btnStyle='primary' onClick={() => dispatch(openUserAdminPopup())} />
            </div>

            <UserAdminList />
        </div>
    );
}

export default AdminPanelHOC(UserAdminPanel, 'Utilisateurs', 'Gestion de la communauté');