export const DURATION_TYPE = {
    HOUR: 0,
    DAY: 1,
    MONTH: 2,
    YEAR: 3
};

export const DURATION_LBL = [
    { value: DURATION_TYPE.HOUR, label: 'heures' },
    { value: DURATION_TYPE.DAY, label: 'jours' },
    { value: DURATION_TYPE.MONTH, label: 'mois' },
    { value: DURATION_TYPE.YEAR, label: 'ans' }
];