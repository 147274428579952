import Axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { createNotification, isValidEmail } from '../../../Utils';
import { INBOUND_POPUP_COMPANY_CHECK, INBOUND_POPUP_EMAIL_CHECK, INBOUND_POPUP_FIRSTNAME_CHECK, INBOUND_POPUP_LASTNAME_CHECK, INBOUND_POPUP_PHONE_CHECK, INBOUND_POPUP_SET_COMPLETION, INBOUND_POPUP_SET_COURSE_TITLE, INBOUND_POPUP_SET_ENABLED, INBOUND_POPUP_SET_LOADING, INBOUND_POPUP_SET_SAVED } from '../ActionTypes'
import Cookies from 'js-cookie';
import i18n from 'i18next';

const hubspotParams = (state) => {
    console.log(state);
    const params = {};

    params.fields = [
        {
            name: 'email',
            value: state.email
        },
        {
            name: 'firstname',
            value: state.firstname
        },
        {
            name: 'lastname',
            value: state.lastname
        },
        {
            name: 'phone',
            value: state.phone
        },
        {
            name: 'company',
            value: state.company
        },
        {
            name: 'nom_formation',
            value: state.courseTitle
        }
    ]

    const hbToken = Cookies.get('hubspotutk');
    if (hbToken)
        params.context = {
            hutk: hbToken
        }

    if (state.isRegistration)
        params.fields.push({
            name: 'inscription',
            value: state.isRegistration
        })

    if (state.jobStatus && state.jobStatus.label)
        params.fields.push({
            name: 'job_status',
            value: state.jobStatus.label
        })

    return params
}

export const showInboundPopup = (state, courseTitle, action) => async dispatch => {
    if (!state.saved || !state.email) {
        dispatch({ type: INBOUND_POPUP_SET_COURSE_TITLE, payload: { title: courseTitle } });
        dispatch({ type: INBOUND_POPUP_SET_ENABLED, payload: { isEnabled: true } });
        dispatch({ type: INBOUND_POPUP_SET_COMPLETION, payload: { completion: action } });
    } else {
        action();

        Axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/19635892/8c9a6c9f-2321-4de6-91d0-8b3cbe91c2c4`, hubspotParams(state))
    }
}

export const downloadInboundCourse = (state, completion) => async dispatch => {
    const isEmailValid = isValidEmail(state.email);
    const isFirstnameValid = !state.firstname || (state.firstname.length >= 3 && state.firstname.length <= 50);
    const isLastnameValid = !state.lastname || (state.lastname.length >= 3 && state.lastname.length <= 50);
    const isPhoneValid = !state.phone || isValidPhoneNumber(`+${state.phone}`);
    const isCompanyValid = !state.company || (state.company.length >= 3 && state.company.length <= 50);

    dispatch({ type: INBOUND_POPUP_EMAIL_CHECK, payload: { isValid: isEmailValid } });
    dispatch({ type: INBOUND_POPUP_FIRSTNAME_CHECK, payload: { isValid: isFirstnameValid } });
    dispatch({ type: INBOUND_POPUP_LASTNAME_CHECK, payload: { isValid: isLastnameValid } });
    dispatch({ type: INBOUND_POPUP_PHONE_CHECK, payload: { isValid: isPhoneValid } });
    dispatch({ type: INBOUND_POPUP_COMPANY_CHECK, payload: { isValid: isCompanyValid } });

    if (isEmailValid && isFirstnameValid && isLastnameValid && isPhoneValid && isCompanyValid) {
        dispatch({ type: INBOUND_POPUP_SET_LOADING, payload: { isLoading: true } });

        dispatch({ type: INBOUND_POPUP_SET_SAVED, payload: { saved: true } });
        dispatch({ type: INBOUND_POPUP_SET_ENABLED, payload: { isEnabled: false } });

        if (completion) completion();
    }
}