import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { INTERVIEW_POPUP_SET_INTERVIEW_ID } from '../redux/actions/ActionTypes';
import { INTERVIEW } from '../resources/constants/interview';

const InterviewPopup = ({ enabled }) => {
    const interviewStore = useSelector(store => store.interviewPopup);
    const dispatch = useDispatch();
    const nodeRef = useRef(null);

    const interview = INTERVIEW[interviewStore.interviewId];

    return (
        <CSSTransition nodeRef={nodeRef} in={enabled} mountOnEnter unmountOnExit timeout={1000} classNames='fade'>
            <div className='video-review-container transition dark' ref={nodeRef}>
                <div className='top'>
                    <h4 className='transition'>
                        Témoignage de {interview?.name}
                                        
                        <div className='overlay transition'>
                            <h4 className='transition'>Témoignage de {interview?.name}</h4>
                        </div>
                    </h4>

                    <div className='move-up transition' onClick={() => dispatch({type: INTERVIEW_POPUP_SET_INTERVIEW_ID, payload: { interviewId: null }})}><div className='icon close'></div></div>
                </div>
                        
                <div className='player'>
                    <ReactPlayer
                        url={interview?.video}
                        width={'100%'}
                        height={'100%'}
                        playing={false}
                    />
                </div>
            </div>
        </CSSTransition>
    );
}

export default InterviewPopup;