import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import HomeCategoryItem from './HomeCategoryItem';

const HomeCategoryList = () => {
    const homeStore = useSelector(store => store.homePanel);

    if (homeStore.isCategoriesLoading)
        return (
            <div className='categories horizontal-scroller'>
                <div className='wrapper'>
                    <div className='thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                    <div className='thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                    <div className='thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                </div>
            </div>
        );

    return (
        <div className='categories horizontal-scroller'>
            <div className='wrapper'>
                {homeStore.categories.map(category => <HomeCategoryItem key={category.id} category={category} />)}
            </div>
        </div>
    );
}

export default HomeCategoryList;