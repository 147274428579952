import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPanelHOC from '../../HOC/AdminPanelHOC';
import Input from '../../Input';
import { VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE, VOUCHER_ADMIN_PANEL_SET_VOUCHERS } from '../../redux/actions/ActionTypes';
import { loadVoucherAdmin } from '../../redux/actions/admin/VoucherAdminActions';
import { openVoucherAdminPopup } from '../../redux/actions/popup/admin/VoucherPopupActions';
import VoucherAdminList from './voucher/VoucherAdminList';

const VoucherAdminPanel = () => {
    const dispatch = useDispatch();
    const voucherAdminStore = useSelector(store => store.voucherAdminPanel);
    const panelRef = useRef();
    const [reloadMore, setReloadMore] = useState(false);

    const onScroll = () => {
        const scrollFromBottom = window.scrollY + window.screen.height;
        const bottomContainerPosition = panelRef.current.offsetTop + panelRef.current.offsetHeight + 250;

        if (scrollFromBottom > bottomContainerPosition) {
            setReloadMore(true);
        }
    }

    useEffect(() => {
        dispatch(loadVoucherAdmin(0, null));

        document.addEventListener('scroll', onScroll);

        return () => {
            dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHERS, payload: { vouchers: [] } });
            document.removeEventListener('scroll', onScroll);
        }
    }, [dispatch]);

    useEffect(() => {
        const fetch = async () => {
            if (reloadMore && !voucherAdminStore.isLoading && voucherAdminStore.hasNext) {
                await dispatch(loadVoucherAdmin(voucherAdminStore.pagingPage + 1, voucherAdminStore.searchValue));
                setReloadMore(false);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [reloadMore, voucherAdminStore.isLoading, voucherAdminStore.hasNext]);

    const onTyping = (value) => {
        dispatch({ type: VOUCHER_ADMIN_PANEL_SET_VOUCHERS, payload: { vouchers: [] } });
        dispatch(loadVoucherAdmin(0, value));
    }

    return (
        <div className='content user-panel' ref={panelRef}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={voucherAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title={'Créer un bon d\'accès'} btnStyle='primary' onClick={() => dispatch(openVoucherAdminPopup())} />
            </div>

            <VoucherAdminList />
        </div>
    );
}

export default AdminPanelHOC(VoucherAdminPanel, 'Bon d\'accès', 'Gestion des vouchers');