import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { addVideoAdminPopup, deleteVideoAdminPopup } from '../../../redux/actions/popup/admin/AssignVideoPopupActions';
import { convertSecondToRemainingTime } from '../../../Utils';
import VideoPlayer from '../../../VideoPlayer';

const VideoItemAssignVideoAdminPopup = ({ video, isSelected }) => {
    const dispatch = useDispatch();
    const assignVideoAdminStore = useSelector(store => store.assignVideoAdminPopup);
    const [playerEnabled, setPlayerEnabled] = useState(false);

    return (
        <div className='item thumbnail video-card shadow white rounded-m'>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition'><Button title={'Supprimer le lien'} isLoading={video.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteVideoAdminPopup(video.guid))} /></div>
                        </div>
                    </div>
                </div>
            </div>

            <span className='title'>{video.name}</span>

            <ul className='stats'>
                <li><div className='icon time'></div>{convertSecondToRemainingTime(video.secondDuration)}</li>
            </ul>

            <div className='preview rounded-s'>
                {!playerEnabled ? <>
                    <div className='play-btn transition' onClick={video.isLoading ? null : () => setPlayerEnabled(true)}>
                        <div className='icon play white'></div>
                    </div>
                </> : <VideoPlayer isSelected={true} video={{ ...video, id: `admin/${video.guid}` }} autoPlay={true} disableKeyEvent={true} />}
            </div>

            <Button title={isSelected ? 'Importé' : 'Importer'} isLoading={video.isLoading} btnStyle={isSelected ? 'success' : 'primary'} onClick={video.isLoading ? null : () => dispatch(addVideoAdminPopup(assignVideoAdminStore.lectureId, video.guid))} />
        </div>
    );
}

VideoItemAssignVideoAdminPopup.propTypes = {
    video: propTypes.object.isRequired
};

export default VideoItemAssignVideoAdminPopup;