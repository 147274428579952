import React from 'react';
import propTypes from 'prop-types';
import CourseAdminItemPeople from './CourseAdminItemPeople';
import { useDispatch } from 'react-redux';
import { openCourseAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import { duplicateCourseAdmin } from '../../../redux/actions/admin/CourseAdminActions';
import { Link } from 'react-router-dom';
import { convertStringForUrl } from '../../../Utils';

// Images
import NoImage from '../../../resources/images/no-image.png';

const CourseAdminItem = ({ course }) => {
    const dispatch = useDispatch();

    return (
        <div className={`item white shadow rounded-m thumbnail ${!course.enabled ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                {course.model ? <span className='status important'>Modèle</span> : <div></div>}
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition' onClick={() => dispatch(openCourseAdminPopup(course.id))}>Modifier la formation</div>
                            <Link className='item transition' to={`/course/${course.id}-${convertStringForUrl(course.title)}/lecture/admin`}>Editer la structure</Link>
                            <div className='item transition' onClick={() => dispatch(duplicateCourseAdmin(course.id))}>Dupliquer</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                {course.activesUsers && course.activesUsers.length ?
                    <>
                        <div className='or'>
                            <hr />
                        </div>
                        <CourseAdminItemPeople people={course.activesUsers} />
                    </> : null}
            </div>
        </div>
    );
}

CourseAdminItem.propTypes = {
    course: propTypes.object.isRequired
};

export default CourseAdminItem;