import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import { homePanelLoadCoursesCategory } from '../../../redux/actions/shop/HomePanelActions';
import CourseItem from '../category/CourseItem';

const HomeCourseList = () => {
    const dispatch = useDispatch();
    const homeStore = useSelector(store => store.homePanel);
    const containerRef = useRef();
    const [reloadMore, setReloadMore] = useState(false);

    const onScroll = () => {
        const scrollFromBottom = window.scrollY + window.screen.height;
        const bottomContainerPosition = containerRef.current?.offsetTop + containerRef.current?.offsetHeight + 250;

        if (scrollFromBottom > bottomContainerPosition) {
            setReloadMore(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line
    }, [containerRef]);

    useEffect(() => {
        setReloadMore(false);
    }, [homeStore.selectedCategoryId]);

    useEffect(() => {
        const fetch = async () => {
            if (reloadMore && !homeStore.isCoursesLoading && homeStore.hasNext) {
                await dispatch(homePanelLoadCoursesCategory(homeStore.selectedCategoryId, homeStore.currentPage));
            }
            setReloadMore(false);
        }

        fetch();
        // eslint-disable-next-line
    }, [reloadMore])

    return (
        <div className='courses' ref={containerRef}>
            {homeStore.courses.map(course => <CourseItem key={course.id} course={course} />)}

            {homeStore.isCoursesLoading && <>
                <div className='thumbnail loading'><BasicLoader width={'100%'} height={275} radius={10} /></div>
                <div className='thumbnail loading'><BasicLoader width={'100%'} height={275} radius={10} /></div>
                <div className='thumbnail loading'><BasicLoader width={'100%'} height={275} radius={10} /></div>
            </>}
        </div>
    );
}

export default HomeCourseList;