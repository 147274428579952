import React from 'react';

const BlogPanel = () => {
    return (
        <>
        <div id="medium-widget"></div>
        </>
    );
}

export default BlogPanel;