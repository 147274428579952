import React from 'react';
import { useSelector } from 'react-redux';
import UserAdminPanelItemLoader from '../../../../loader/UserAdminPanelItemLoader';
import UserItemVoucherAdminPopup from './UserItemVoucherAdminPopup';
import { useTranslation } from 'react-i18next';

const UserListVoucherAdminPopup = () => {
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);
    const { t } = useTranslation();

    if (!voucherAdminStore.isUsersLoading && !voucherAdminStore.users?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_USERS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {voucherAdminStore.users.map(userVoucher => <UserItemVoucherAdminPopup key={userVoucher.id} userVoucher={userVoucher} />)}

            {voucherAdminStore.isUsersLoading && <>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
            </>}
        </div>
    );
}

export default UserListVoucherAdminPopup;