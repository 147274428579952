import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import { COURSE_ADMIN_POPUP_SET_USERS, COURSE_ADMIN_POPUP_SET_USER_SEARCH_VALUE } from '../../../redux/actions/ActionTypes';
import { openUserAsCourseAdminPopup } from '../../../redux/actions/popup/admin/AssignUserPopupActions';
import { loadUsersCourseAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import UserListCourseAdminPopup from './user/UserListCourseAdminPopup';

const UserCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPopup);

    useEffect(() => {
        dispatch(loadUsersCourseAdminPopup(courseAdminStore.courseId, 0, null));

        return () => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_USERS, payload: { users: [] } });
        }
    }, [dispatch, courseAdminStore.courseId]);

    const onTyping = (value) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USERS, payload: { users: [] } });
        dispatch(loadUsersCourseAdminPopup(courseAdminStore.courseId, 0, value));
    }

    return (
        <div className='overview'>
            <div>
                <div className='content-title'>
                    <h2>Etudiants</h2>
                    <Button title='Assigner un étudiant' btnStyle={'primary autofit'} onClick={() => dispatch(openUserAsCourseAdminPopup(courseAdminStore.courseId))} />
                </div>
                <Input placeholder={'Recherche'} value={courseAdminStore.userSearchValue} onChange={(userSearchValue) => dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_SEARCH_VALUE, payload: { userSearchValue } })} onTypingDelay={onTyping} />

                <div className='section'>
                    <UserListCourseAdminPopup />

                    {courseAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={courseAdminStore.isUsersLoading} onClick={() => dispatch(loadUsersCourseAdminPopup(courseAdminStore.pagingPage + 1, courseAdminStore.searchValue))} /></div>}
                </div>
            </div>
        </div>
    );
}

export default UserCourseAdminPopup;