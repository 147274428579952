import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_SECTION_ADMIN_POPUP_RESET, ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS, LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE } from '../../redux/actions/ActionTypes';
import { loadSectionsAdminPopup } from '../../redux/actions/popup/admin/AssignSectionPopupActions';
import SectionListAssignSectionAdminPopup from './assignSection/SectionListAssignSectionAdminPopup';

const AssignSectionAdminPopup = () => {
    const dispatch = useDispatch();
    const assignSectionAdminStore = useSelector(store => store.assignSectionAdminPopup);

    useEffect(() => {
        dispatch(loadSectionsAdminPopup(0, null));

        return () => {
            dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const createNewSection = () => {
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE, payload: { isEditing: true } });
    }

    const onTyping = (value) => {
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS, payload: { sections: [] } });
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: 0 } });
        dispatch(loadSectionsAdminPopup(0, value));
    }

    return (
        <div className={`assign-category-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignSectionAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer un chapitre' btnStyle='primary' onClick={createNewSection} />
            </div>

            <div className='section'>
                <SectionListAssignSectionAdminPopup />

                {assignSectionAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={assignSectionAdminStore.isLoading} onClick={() => dispatch(loadSectionsAdminPopup(assignSectionAdminStore.pagingPage + 1, assignSectionAdminStore.searchValue))} /></div>}
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignSectionAdminPopup, 'Ajouter un chapitre', 'Création ou ajout d\'un chapitre', null, null, null, {
    storeName: 'assignSectionAdminPopup',
    completion: (dispatch, store) => {
        if (!store.isLoading && store.hasNext) {
            dispatch(loadSectionsAdminPopup(store.pagingPage + 1, store.searchValue));
        }
    }
});