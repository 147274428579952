import Axios from "axios"
import { createNotification } from "../../../../Utils";
import { ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS, ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION, ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING, ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING, LECTURE_PANEL_ADMIN_ADD_MENU_SECTION, LECTURE_PANEL_TOGGLE_SECTION } from "../../ActionTypes";
import i18n from 'i18next';

export const loadSectionsAdminPopup = (pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`section/list/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS, payload: { sections: data.paging } });
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const duplicateSectionToCourseAdminPopup = (sectionId, courseId) => async dispatch => {
    dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING, payload: { id: sectionId, isLoading: true } });

    Axios.post(`section/${sectionId}/to/course/${courseId}/duplicate/admin`)
        .then(({ data }) => {
            dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION, payload: { id: sectionId } });
            dispatch({ type: LECTURE_PANEL_ADMIN_ADD_MENU_SECTION, payload: { section: data } });
            dispatch({ type: LECTURE_PANEL_TOGGLE_SECTION, payload: { open: true, id: data.id } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            return false;
        }).finally(() => {
            dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING, payload: { id: sectionId, isLoading: false } });
        });
}