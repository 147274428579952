import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import { VOUCHER_ADMIN_POPUP_SET_USERS, VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE } from '../../../redux/actions/ActionTypes';
import { loadUserVoucherAdminPopup } from '../../../redux/actions/popup/admin/VoucherPopupActions';
import UserListVoucherAdminPopup from './user/UserListVoucherAdminPopup';

const UserVoucherAdminPopup = () => {
    const dispatch = useDispatch();
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);

    useEffect(() => {
        dispatch(loadUserVoucherAdminPopup(voucherAdminStore.voucherId, 0, null));

        return () => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USERS, payload: { users: [] } });
        }
    }, [dispatch, voucherAdminStore.voucherId]);

    const onTyping = (value) => {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USERS, payload: { users: [] } });
        dispatch(loadUserVoucherAdminPopup(voucherAdminStore.voucherId, 0, value));
    }

    return (
        <div className='overview'>
            <div>
                <div className='content-title'>
                    <h2>Etudiants</h2>
                </div>
                <Input placeholder={'Recherche'} value={voucherAdminStore.userSearchValue} onChange={(userSearchValue) => dispatch({ type: VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE, payload: { userSearchValue } })} onTypingDelay={onTyping} />

                <div className='section'>
                    <UserListVoucherAdminPopup />

                    {voucherAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={voucherAdminStore.isUsersLoading} onClick={() => dispatch(loadUserVoucherAdminPopup(voucherAdminStore.voucherId, voucherAdminStore.pagingPage + 1, voucherAdminStore.searchValue))} /></div>}
                </div>
            </div>
        </div>
    );
}

export default UserVoucherAdminPopup;