import { ADMIN_PANEL } from "./adminPanel";
import { PREFERENCE_PANEL } from "./preferencePanel";

export const MOBILE_MENU_TYPE = {
    MENU: 0,
    SEARCH: 1
};

export const MENU = [
    {
        id: 'HOME',
        name: "HOME",
        link: '/',
    },
    {
        id: 'CATEGORIES',
        name: "COURSES",
        dynamicSubCategories: true
    },
    {
        id: 'METHOD',
        name: 'WHY_US',
        subCategories: [
            {
                name: "CENTER_AND_COURSES",
                link: '/centre'
            },
            {
                name: "CERTIFIED_TUTORS",
                link: '/team'
            }
        ]
    }
];

export const PREFERENCES_MENU = [
    {
        id: PREFERENCE_PANEL.INFORMATIONS,
        name: "INFORMATIONS",
        link: '/dashboard/preference/informations'
    },
    {
        id: PREFERENCE_PANEL.PHOTO,
        name: "PHOTO",
        link: '/dashboard/preference/photo'
    },
    {
        id: PREFERENCE_PANEL.SECURITY,
        name: "SECURITY",
        link: '/dashboard/preference/security'
    }
]

export const ADMIN_MENU = [
    {
        id: ADMIN_PANEL.COURSES,
        name: "COURSES",
        link: '/admin/courses'
    },
    {
        id: ADMIN_PANEL.USERS,
        name: "USERS",
        link: '/admin/users'
    },
    {
        id: ADMIN_PANEL.CATEGORIES,
        name: "CATEGORIES",
        link: '/admin/categories'
    },
    {
        id: ADMIN_PANEL.VOUCHERS,
        name: "VOUCHERS",
        link: '/admin/vouchers'
    },
    {
        id: ADMIN_PANEL.TRACER,
        name: "TRACER",
        link: '/admin/tracer'
    }
]

export const SEARCH_RESULT_TYPE = {
    COURSE: 0,
    CATEGORY: 1
};