import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_ADDITIONAL_ADMIN_POPUP_ADD_FILE, ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED, ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_ERROR, ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_PROGRESS, ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LECTURE_ID, LECTURE_PANEL_ADMIN_ADD_ADDITIONAL } from "../../ActionTypes";
import i18n from 'i18next';

export const openAdditionalAdminPopup = (lectureId) => async dispatch => {
    dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LECTURE_ID, payload: { id: lectureId } });
}

export const uploadAdditionalAdminPopup = (lectureId, files, nbUploadedFiles = 0) => async dispatch => {
    for (const iFile in files) {
        const file = files[iFile];
        if (file.size < 2000000) {

            const uploadId = parseInt(nbUploadedFiles) + parseInt(iFile);
            var reader = new FileReader();
            reader.onload = (e) => {
                var content = e.target.result;
                dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_ADD_FILE, payload: { file: { ...file, id: uploadId, link: content, progress: 0 } } });
            };

            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('Picture', file);

            Axios.put(`resource/${lectureId}/add/picture/admin`, formData, {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: (progress) => {
                    dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_PROGRESS, payload: { id: uploadId, progress: (progress.total / progress.loaded) * 100 } });
                }
            }).then(({ data }) => {
                dispatch({ type: LECTURE_PANEL_ADMIN_ADD_ADDITIONAL, payload: { picture: data } });
            }).catch(() => {
                dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_ERROR, payload: { id: uploadId, error: true } });
            });
        } else {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), `${i18n.t("IMAGE_TOO_WEIGHT")}. (Maximum 2MB)`, 'danger');
        }
    }
}