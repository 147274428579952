import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { COURSE_ADMIN_POPUP_SET_ADVERTISEMENT_VISIBILITY } from '../redux/actions/ActionTypes';
import { openCourseAdminPopup } from '../redux/actions/popup/admin/CoursePopupActions';

const LectureHeader = ({ admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);

    const onEdit = () => {
        dispatch(openCourseAdminPopup(lectureStore.menu.id))
        dispatch({ type: COURSE_ADMIN_POPUP_SET_ADVERTISEMENT_VISIBILITY, payload: { showAdvertisement: true } });
    }

    return (
        <header className={`container rounded-s lecture-header`}>
            <div className='left'>
                <div className='logo-menu'>
                    <Link to='/'><div className='logo'></div></Link>
                </div>

                <div className='separator'></div>

                {lectureStore && lectureStore.menu && lectureStore.menu.title && <h1>{lectureStore.menu.title}</h1>}
            </div>

            <div className='right'>
                {admin ? <Button title={'Modifier la formation'} btnStyle={'autofit white'} onClick={onEdit} /> : null}
                <Link className='close-btn transition' to='/dashboard'>
                    <div className='icon close'></div>
                </Link>
            </div>
        </header>
    );
}

export default LectureHeader;