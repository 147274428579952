import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import BasicLoader from '../../../../loader/BasicLoader';
import { CATEGORY_ADMIN_POPUP_SET_COURSES } from '../../../../redux/actions/ActionTypes';
import { updateCourseCategoryPositionAdmin } from '../../../../redux/actions/popup/admin/CategoryPopupActions';
import CourseItemCategoryAdminPopup from './CourseItemCategoryAdminPopup';
import { useTranslation } from 'react-i18next';

const CourseListCategoryAdminPopup = () => {
    const dispatch = useDispatch();
    const categoryAdminStore = useSelector(store => store.categoryAdminPopup);
    const { t } = useTranslation();

    const onPositionUpdate = async (e) => {
        const movedItem = categoryAdminStore.courses[e.newIndex];
        await dispatch(updateCourseCategoryPositionAdmin(movedItem.courseCategoryId, e.newIndex))
    }

    if (categoryAdminStore.isCoursesLoading)
        return (
            <div className='list'>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
            </div>
        );

    if (!categoryAdminStore.courses?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        );

    return (
        <ReactSortable className='list' chosenClass={'disabled'} delay={200} animation={200} list={categoryAdminStore.courses} setList={(courses) => dispatch({ type: CATEGORY_ADMIN_POPUP_SET_COURSES, payload: { courses } })} onEnd={onPositionUpdate}>
            {categoryAdminStore.courses.map(course => <CourseItemCategoryAdminPopup key={course.id} course={course} />)}
        </ReactSortable>
    );
}

export default CourseListCategoryAdminPopup;