import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PrivacyPanel = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Helmet>
                <title>{t("PRIVACY")} · FormationAcademy</title>
                <meta name="description" content="Retrouvez notre police vie privée." />
            </Helmet>

            <div className='page-container-wrapper basic-panel other-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations rounded-xl'>
                            <h1>{t("PRIVACY")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        <p><i>Politique de confidentialité mise à jour le 10-02-2021.</i></p>
                        <p>
                            Merci d'avoir rejoint la communauté d’apprentissage francophone ReunIT !<br /><br />
                            Chez ReunIT S.A ("ReunIT", "nous", "notre") nous respectons votre vie privée et voulons que vous compreniez comment nous recueillons, utilisons et partageons les données vous concernant. Cette politique de confidentialité couvre nos pratiques de collecte de données et décrit vos droits d'accès, de rectification ou de restriction de l'utilisation de vos données personnelles.<br />
                            Sauf lien vers une autre politique ou indication contraire, la présente Politique de confidentialité s'applique lorsque vous visitez ou utilisez le site Web de ReunIT, les applications mobiles, les API ou les services connexes (les "Services").<br />
                            En utilisant les Services, vous acceptez les termes de cette Politique de confidentialité. Vous ne devriez pas utiliser les Services si vous n'êtes pas d'accord avec cette Politique de confidentialité ou tout autre accord qui régit votre utilisation des Services.<br />
                            Pour toutes questions concernant la confidentialité et la vie privée, veuillez nous envoyer un message à l’adresse : <a href="mailto:privacy@reunit.eu">privacy@reunit.eu</a>
                        </p>

                        <h2>Identité du responsable du traitement et coordonnées</h2>
                        <p>
                            <i>Le responsable du traitement est :</i><br />
                            <b>ReunIT S.A.</b><br />
                            <b>12, Rue de Bastogne</b><br />
                            <b>Grand-duché du Luxembourg</b>
                        </p>

                        <h2>Quelles sont les données que nous collectons ?</h2>
                        <p>Nous recueillons certaines données vous concernant directement, telles que les informations que vous saisissez vous-même, les données relatives à votre participation à des cours. Nous recueillons également certaines données automatiquement, comme des informations sur votre appareil et sur les parties de nos Services avec lesquelles vous interagissez ou passez du temps à utiliser.</p>
                        <p>Nous pouvons recueillir des données à votre sujet selon la façon dont vous utilisez les Services. Lorsque vous créez un compte et utilisez les Services, y compris par l'intermédiaire d'une plate-forme tierce, nous recueillons toutes les données que vous fournissez directement, y compris :</p>
                        <ul>
                            <li>Nom, prénom</li>
                            <li>Adresse, ville, pays</li>
                            <li>Adresse e-mail, téléphone</li>
                            <li>Données sur le paiement des clients</li>
                        </ul>

                        <h2>Pourquoi nous utilisons vos données ?</h2>
                        <p>Nous utilisons vos données pour fournir nos services, communiquer avec vous, résoudre des problèmes, vous protégez contre la fraude et les abus, améliorer et mettre à jour nos services, et tel que requis par la loi ou nécessaire pour la sécurité et l'intégrité.</p>
                        <p>Nous utilisons aussi les données que nous recueillons par le biais de votre utilisation des Services :</p>
                        <ul>
                            <li>Fournir et administrer les Services, y compris pour afficher un contenu personnalisé et faciliter la communication avec les autres utilisateurs ;</li>
                            <li>Traiter vos demandes et commandes de cours, de produits, de services spécifiques, d'informations ou de fonctionnalités ;</li>
                            <li>Communiquer avec vous au sujet de votre compte pour :</li>
                            <li>Répondre à vos questions et préoccupations ;</li>
                            <li>Vous envoyez des messages administratifs et des informations, y compris des messages des instructeurs et des assistants d'enseignement, des notifications sur les changements apportés à notre service et des mises à jour de nos accords ;</li>
                            <li>Vous envoyez de l'information et des messages sur vos progrès dans les cours, les nouveaux services, les nouvelles fonctions, les promotions, les bulletins d'information et autres cours disponibles (dont vous pouvez vous retirer en tout temps) ;</li>
                            <li>Gérer les préférences de votre compte ;</li>
                            <li>Faciliter le fonctionnement technique des Services, y compris le dépannage et la résolution de problèmes, la sécurisation des Services et la prévention de la fraude et des abus ;</li>
                            <li>Solliciter les commentaires des utilisateurs ;</li>
                            <li>Identifier les utilisateurs uniques d'un appareil à l'autre ;</li>
                            <li>Améliorer nos services et développer de nouveaux produits, services et fonctionnalités ;</li>
                            <li>Analysez les tendances et le trafic, suivez les achats et suivez les données d'utilisation ;</li>
                            <li>Annoncer les Services sur les sites Web et applications de tiers.</li>
                        </ul>

                        <h2>Avec qui nous partageons vos données ?</h2>
                        <p>Nous partageons certaines données vous concernant avec des instructeurs, des entreprises qui fournissent des services pour nous, nos partenaires commerciaux, des fournisseurs d'analyse et d'enrichissement de données, vos fournisseurs de médias sociaux, des entreprises qui nous aident à faire des promotions et des sondages, et des entreprises de publicité qui nous aident à promouvoir nos services. Nous pouvons également partager vos données si nécessaires pour des raisons de sécurité, de conformité légale ou dans le cadre d'une restructuration d'entreprise. Enfin, nous pouvons partager les données d'autres façons si elles sont agrégées ou dépersonnalisées ou si nous obtenons votre consentement.</p>

                        <h2>Combien de temps nous gardons vos données ?</h2>
                        <p>Nous gardons vos données concernant la facturation, le paiement et tous autres documents comptables 10 ans comme imposé par la loi du commerce Luxembourgeoise.</p>
                        <p>Pour les données autre que comptable nous les gardons autant que cela soit nécessaire au traitement. Vous pouvez à tout moment nous demander la suppression de vos données personnelles en envoyant un message à l’adresse : <a href="mailto:privacy@reunit.eu">privacy@reunit.eu</a>.</p>

                        <h2>Sécurité</h2>
                        <p>Nous utilisons une sécurité appropriée en fonction du type et de la sensibilité des données stockées. Il est donc important de protéger votre mot de passe et de nous contacter si vous soupçonnez un accès non autorisé à votre compte.</p>

                        <h2>Quelles sont vos droits ?</h2>
                        <p>En tant que personne concernées, vous pouvez faire valoir les droits suivants :</p>
                        <p><u>Le droit à l’accès :</u> Vous avez le droit d’obtenir de notre part la confirmation que les données personnelles vous concernant font l’objet d’un traitement et, le cas échéant, l’accès aux données personnelles et à certaines informations.</p>
                        <p><u>Le droit à la rectification :</u> Vous avez le droit d’obtenir de notre part, sans retard injustifié, la rectification de données personnelles inexactes vous concernant. Compte tenu des finalités du traitement, vous avez le droit de faire compléter des données personnelles incomplètes, y compris en fournissant une déclaration supplémentaire.</p>
                        <p><u>Le droit à l’effacement :</u> Vous avez le droit d’obtenir de notre part l’effacement des données personnelles vous concernant sans retard injustifié.</p>
                        <p><u>Le droit de limiter le traitement :</u> Vous avez le droit d’obtenir de notre part la restriction de traitement.</p>
                        <p><u>Le droit de faire opposition :</u> Vous avez le droit de vous opposer, pour des motifs liés à votre situation particulière, à tout moment au traitement des données personnelles vous concernant, qui est fondé sur des intérêts légitimes ou pour l’exécution d’une mission effectuée dans l’intérêt public ou dans l’exercice de l’autorité publique dont est investi le responsable de traitement.</p>
                        <p><u>Le droit à la portabilité :</u> Vous avez le droit de recevoir les données personnelles vous concernant, que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par un ordinateur.</p>
                        <p><u>Le droit de remonté une plainte à l’autorité indépendante de contrôle :</u> Sans préjudice de tout autre recours administratifs ou judiciaire, vous avez le droit de déposer une plainte auprès de l’autorité de contrôle, notamment dans l’Etat membre de votre résidence habituelle, de votre lieu de travail ou du lieu de l’infraction alléguée, si vous estimez que le traitement de données à caractère personnel qui vous est renvoyé viole le Règlement Général sur la Protection des Données (RGPD).</p>
                        <p>Lorsque le traitement est fondé sur votre consentement éclairé, vous avez le droit de retirer votre consentement à tout moment, sans que cela n’affecte la licéité du traitement fondé sur le consentement avant son retrait. Par conséquent, vous pouvez nous renvoyer un message à <a href="mailto:privacy@reunit.eu">privacy@reunit.eu</a>.</p>

                        <h2>Votre obligation de nous fournir des données personnelles</h2>
                        <p>Vous n’avez aucune obligation légale ou contractuelle de nous fournir des données personnelles. Toutefois, nous pourrions ne pas être en mesure de vous fournir nos services si vous décidez de ne pas le faire.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPanel;