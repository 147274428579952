import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Datepicker from '../../../Datepicker';
import Input from '../../../Input';
import { VOUCHER_ADMIN_POPUP_SET_END_DATE, VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE, VOUCHER_ADMIN_POPUP_SET_START_DATE } from '../../../redux/actions/ActionTypes';
import { createVoucherAdminPopup, deleteVoucherAdminPopup, updateVoucherAdminPopup } from '../../../redux/actions/popup/admin/VoucherPopupActions';
import { useTranslation } from 'react-i18next';

const OverviewVoucherAdminPopup = () => {
    const dispatch = useDispatch();
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        if (voucherAdminStore.voucherId) {
            dispatch(updateVoucherAdminPopup(voucherAdminStore));
        } else {
            dispatch(createVoucherAdminPopup(voucherAdminStore))
        }
    }

    return (
        <div className='overview'>
            <div>
                <form onSubmit={onSubmit}>
                    <div className='content-title'>
                        <h2>Présentation</h2>
                    </div>

                    <div className='section'>
                        {voucherAdminStore.voucherId && <Input title={t("CODE")} placeholder={t("PLACEHOLDER_CODE")} value={voucherAdminStore.code} type={'text'} disabled={true} />}

                        <h3>Dates</h3>
                        <div className='dates'>
                            <Datepicker component={<Input isCalendarInput={true} error={!voucherAdminStore.isDatesValid} />} disabled={voucherAdminStore.isLoading || voucherAdminStore.isInformationsLoading} selected={new Date(voucherAdminStore.startDate)} onChange={date => dispatch({type: VOUCHER_ADMIN_POPUP_SET_START_DATE, payload: { date }})} selectsStart startDate={new Date(voucherAdminStore.startDate)} endDate={new Date(voucherAdminStore.endDate)} />
                            <Datepicker component={<Input isCalendarInput={true} error={!voucherAdminStore.isDatesValid} />} disabled={voucherAdminStore.isLoading || voucherAdminStore.isInformationsLoading} selected={new Date(voucherAdminStore.endDate)} onChange={date => dispatch({type: VOUCHER_ADMIN_POPUP_SET_END_DATE, payload: { date }})} selectsEnd startDate={new Date(voucherAdminStore.startDate)} endDate={new Date(voucherAdminStore.endDate)} minDate={new Date(voucherAdminStore.startDate)} />
                        </div>

                        <div className='danger-item'>
                            <div className='labels'>
                                <h3>Places disponibles</h3>
                                <span>Nombre de fois que ce bon peut être utilisé</span>
                            </div>

                            <div className={`duration-picker ${!voucherAdminStore.isPlaceAvailableValid ? 'error' : ''}`}>
                                <Input type={'number'} disabled={voucherAdminStore.isLoading || voucherAdminStore.isInformationsLoading} placeholder={'Valeur'} value={`${voucherAdminStore.placeAvailable}`} onChange={(placeAvailable) => dispatch({type: VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE, payload: { placeAvailable }})} />
                            </div>
                        </div>
                    </div>

                    <div className='right-alignment'>
                        {voucherAdminStore.voucherId ? <Button title={t("UPDATE")} btnStyle='primary autofit' disabled={voucherAdminStore.isLoading} isLoading={voucherAdminStore.isInformationsLoading} type={'submit'} /> : <Button title={'Créer le bon'} btnStyle='primary autofit' disabled={voucherAdminStore.isLoading} isLoading={voucherAdminStore.isInformationsLoading} type={'submit'} />}
                    </div>
                </form>
            </div>

            {voucherAdminStore.voucherId &&
                <>
                    <div className='or'>
                        <hr />
                    </div>

                    <div>
                        <div className='content-title'>
                            <h2>Zone de danger</h2>
                        </div>
                        <div className='section'>
                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Supprimer le bon d'accès</h3>
                                    <span>Cette opération est irréversible</span>
                                </div>

                                <Button title={'Supprimer le bon'} btnStyle={'cancel autofit'} isLoading={voucherAdminStore.isInformationsLoading} confirmationRequired onClick={() => dispatch(deleteVoucherAdminPopup(voucherAdminStore.voucherId))} />
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default OverviewVoucherAdminPopup;