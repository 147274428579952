import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import ReactNotification from 'react-notifications-component';
import './services/AxiosConfiguration';
import './css/main.scss';

import Header from './menu/Header';
import MobileMenu from './menu/MobileMenu';
import PopupManager from './popup/PopupManager';
import Footer from './Footer';
import HomePanel from './panel/shop/HomePanel';
import CategoryPanel from './panel/shop/CategoryPanel';
import PreviewPanel from './panel/shop/PreviewPanel';
import DashboardPanel from './panel/dashboard/DashboardPanel';
import PreferencePanel from './panel/dashboard/PreferencePanel';
import LecturePanel from './panel/dashboard/LecturePanel';
import LectureHeader from './menu/LectureHeader';
import ScrollToTop from './ScrollToTop';
import EmailConfirmationBanner from './EmailConfirmationBanner';
import UpdatePasswordPopup from './popup/sign/UpdatePasswordPopup';
import AdminHeader from './menu/AdminHeader';
import AdminPanel from './panel/admin/AdminPanel';
import TermsPanel from './panel/shop/TermsPanel';
import PrivacyPanel from './panel/shop/PrivacyPanel';
import TeamPanel from './panel/shop/TeamPanel';
import SolutionPanel from './panel/shop/SolutionPanel';
import SitemapPanel from './panel/shop/SitemapPanel';
import CookiesPopup from './CookiesPopup';
import BlogPanel from './panel/shop/BlogPanel';
import InboundModelPanel from './panel/inbound/InboundModelPanel';
import MS030 from './panel/inbound/MS030';

import './i18n';

ReactDOM.render(
	<React.StrictMode>
		<ReactNotification />
		<Provider store={store}>
			<BrowserRouter>
				<ScrollToTop />
				<div className='page-wrapper'>
					<Route path='/confirmation/:email/:code' component={EmailConfirmationBanner}></Route>

					<Switch>
						<Route path='/course/:id-:title/lecture/:lectureId/admin' component={(props) => <LectureHeader admin={true} {...props} />}></Route>
						<Route path='/course/:id-:title/lecture/:lectureId' component={LectureHeader}></Route>
						<Route path='/admin' component={AdminHeader}></Route>
						<Route path='/centre' component={() => <Header fixed />}></Route>
						<Route path='/inbound' component={null}></Route>
						<Route path='/profitez-d-une-formation-officiel-administrateur-microsoft-365' component={null}></Route>
						<Route path='/profitez-d-une-formation-officielle-administrateur-microsoft-365' component={null}></Route>
						<Header />
					</Switch>

					<MobileMenu />
					<PopupManager />
					<Route path='/recover/:token' component={() => <UpdatePasswordPopup enabled={true} onClose={() => window.location.href = '/'} />}></Route>

					<Switch>
						<Route path='/category/:id-:title' component={CategoryPanel}></Route>
						<Route path='/course/:id-:title/preview' component={PreviewPanel}></Route>

						<Route path='/admin/:menu' component={AdminPanel}></Route>
						<Route path='/course/:userCourseId-:title/lecture/:lectureId/admin' component={(props) => <LecturePanel admin={true} {...props} />}></Route>
						<Route path='/course/:userCourseId-:title/lecture/admin' component={(props) => <LecturePanel admin={true} {...props} />}></Route>

						<Route exact path='/dashboard' component={DashboardPanel}></Route>
						<Route path='/dashboard/preference/:menu' component={PreferencePanel}></Route>
						<Route path='/course/:userCourseId-:title/lecture/:lectureId' component={LecturePanel}></Route>
						<Route path='/course/:userCourseId-:title/lecture' component={LecturePanel}></Route>

						<Route path='/team' component={TeamPanel}></Route>
						<Route path='/blog' component={BlogPanel}></Route>
						<Route path='/centre' component={SolutionPanel}></Route>
						{/* <Route path='/cgu' component={CGUPanel}></Route> */}
						<Route path='/terms' component={TermsPanel}></Route>
						<Route path='/privacy' component={PrivacyPanel}></Route>
						<Route path='/sitemap' component={SitemapPanel}></Route>

						<Route path='/inbound/model' component={InboundModelPanel}></Route>
						<Route path='/profitez-d-une-formation-officiel-administrateur-microsoft-365' component={MS030}></Route>
						<Route path='/profitez-d-une-formation-officielle-administrateur-microsoft-365' component={MS030}></Route>
						{/* <Route path='/partners' component={PartnersPanel}></Route> */}

						<Route component={HomePanel}></Route>
					</Switch>

					<Switch>
						<Route path='/profitez-d-une-formation-officielle-administrateur-microsoft-365' component={null}></Route>
						<CookiesPopup />
					</Switch>

					<Switch>
						<Route path='/course/:id-:title/lecture/:lectureId' component={null}></Route>
						<Route path='/centre' component={null}></Route>
						<Footer />
					</Switch>
				</div>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
