import { CATEGORY_ADMIN_PANEL_ADD_CATEGORY, CATEGORY_ADMIN_PANEL_DELETE_CATEGORY, CATEGORY_ADMIN_PANEL_SET_CATEGORIES, CATEGORY_ADMIN_PANEL_SET_LOADING, CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY, CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    categories: [],
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case CATEGORY_ADMIN_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case CATEGORY_ADMIN_PANEL_SET_CATEGORIES: {
            return { ...state, categories: action.payload.categories };
        }

        case CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE: {
            return {
                ...state, categories: state.categories.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, picture: action.payload.picture };
                })
            }
        }
        case CATEGORY_ADMIN_PANEL_ADD_CATEGORY: {
            return { ...state, categories: [...state.categories, action.payload.category] };
        }
        case CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY: {
            return {
                ...state, categories: state.categories.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return action.payload.category;
                })
            }
        }
        case CATEGORY_ADMIN_PANEL_DELETE_CATEGORY: {
            return { ...state, categories: state.categories.filter(c => c.id !== action.payload.id) };
        }

        default:
            return state;
    }
}