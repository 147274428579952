import Axios from "axios";
import { LECTURE_SURVEY_STEP } from "../../../resources/constants/lectureSurvey";
import { createNotification } from "../../../Utils";
import { LECTURE_PANEL_SURVEY_ADD_ANSWER, LECTURE_PANEL_SURVEY_ADD_QUESTION, LECTURE_PANEL_SURVEY_DELETE_ANSWER, LECTURE_PANEL_SURVEY_DELETE_QUESTION, LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS, LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS_LOADING, LECTURE_PANEL_SURVEY_SET_ANSWER_CORRECT, LECTURE_PANEL_SURVEY_SET_ANSWER_LOADING, LECTURE_PANEL_SURVEY_SET_CREATING_LOADING, LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID, LECTURE_PANEL_SURVEY_SET_LOADING, LECTURE_PANEL_SURVEY_SET_QUESTION_DONE, LECTURE_PANEL_SURVEY_SET_QUESTION_LOADING, LECTURE_PANEL_SURVEY_SET_QUESTION_REQUIRED, LECTURE_PANEL_SURVEY_SET_RESULTS, LECTURE_PANEL_SURVEY_SET_STEP, LECTURE_PANEL_SURVEY_SET_SURVEY } from "../ActionTypes";
import i18n from 'i18next';


export const startSurvey = (surveyId, userCourseId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`survey/${surveyId}/start`, {
        params: {
            userCourseId
        }
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_SURVEY, payload: { survey: data } });
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.RUN } });
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID, payload: { id: data.surveyQuestion[0]?.id } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_LOADING, payload: { isLoading: false } });
    });
}

export const nextQuestionSurvey = (questionId, userCourseSurveyId, answersId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_LOADING, payload: { isLoading: true } });

    Axios.post(`survey/${questionId}/${userCourseSurveyId}/next`, {
        answersId
    }).then(({ data }) => {
        if (data.nextQuestionId) {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID, payload: { id: data.nextQuestionId } });
        } else {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.RESULT } });
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_RESULTS, payload: { results: data } });
        }

        if (answersId?.length)
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTION_DONE, payload: { questionId, done: true } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_LOADING, payload: { isLoading: false } });
    })
}

export const updateQuestionTitleSurvey = (questionId, title) => async dispatch => {
    Axios.put(`survey/question/${questionId}/update/admin`, {
        title: title || ''
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteQuestionSurvey = (questionId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTION_LOADING, payload: { questionId, isLoading: true } });

    Axios.delete(`survey/question/${questionId}/delete/admin`)
        .then(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_DELETE_QUESTION, payload: { questionId } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTION_LOADING, payload: { questionId, isLoading: false } });
        })
}

export const updateAnswerSurveyCorrect = (answerId, correct) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_ANSWER_CORRECT, payload: { answerId, correct } });

    Axios.put(`survey/answer/${answerId}/update/correct/admin`, {
        correct
    }).catch(({ response }) => {
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_ANSWER_CORRECT, payload: { answerId, correct: !correct } });
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteAnswerSurvey = (answerId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_ANSWER_LOADING, payload: { answerId, isLoading: true } });

    Axios.delete(`survey/answer/${answerId}/delete/admin`)
        .then(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_DELETE_ANSWER, payload: { answerId } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_ANSWER_LOADING, payload: { answerId, isLoading: false } });
        })
}

export const addNewAnswerSurvey = (questionId, title) => async dispatch => {
    return await Axios.post(`survey/question/${questionId}/new/answer/admin`, {
        title
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_SURVEY_ADD_ANSWER, payload: { questionId, answer: data } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        throw Error();
    });
}

export const updateSurveyAnswerPosition = (answerId, position) => async dispatch => {
    Axios.put('survey/answer/update/position/admin', {
        id: answerId,
        level: position
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const updateSurveyQuestionPosition = (questionId, position) => async dispatch => {
    Axios.put('survey/question/update/position/admin', {
        id: questionId,
        level: position
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const addNewQuestionSurvey = (surveyId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_CREATING_LOADING, payload: { isLoading: true } });

    Axios.post(`survey/${surveyId}/new/question/admin`)
        .then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_SURVEY_ADD_QUESTION, payload: { question: data } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_CREATING_LOADING, payload: { isLoading: false } });
        })
}

export const updateQuestionSurveyRequiredStatus = (questionId, required) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTION_REQUIRED, payload: { questionId, required } });

    Axios.put(`survey/question/${questionId}/update/required/admin`, {
        required
    }).catch(({ response }) => {
        dispatch({ type: LECTURE_PANEL_SURVEY_SET_QUESTION_REQUIRED, payload: { questionId, required: !required } });
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const loadUserCourseSurveyResults = (surveyId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS_LOADING, payload: { isLoading: true } });

    Axios.get(`survey/${surveyId}/results/admin`)
        .then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS, payload: { results: data } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS_LOADING, payload: { isLoading: false } });
        })
}