import Axios from "axios";
import { ADMIN_CATEGORY_MENU_TYPE } from "../../../../resources/constants/adminPanel";
import { createNotification } from "../../../../Utils";
import { CATEGORY_ADMIN_PANEL_ADD_CATEGORY, CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY, CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE, CATEGORY_ADMIN_POPUP_SET_CATEGORY, CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID, CATEGORY_ADMIN_POPUP_SET_COURSES, CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING, CATEGORY_ADMIN_POPUP_SET_ENABLED, CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, CATEGORY_ADMIN_POPUP_SET_LOADING, CATEGORY_ADMIN_POPUP_SET_PICTURE, CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE, CATEGORY_ADMIN_POPUP_TITLE_CHECK, CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK, CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS } from "../../ActionTypes";
import { loadCategoryAdmin } from "../../admin/CategoryAdminActions";
import i18n from 'i18next';

const checkCategoryInformations = (categoryAdminStore) => async dispatch => {
    const isTitleValid = categoryAdminStore.title && categoryAdminStore.title.length >= 3 && categoryAdminStore.title.length <= 50;
    const isTitleENValid = categoryAdminStore.titleEN && categoryAdminStore.titleEN.length >= 3 && categoryAdminStore.titleEN.length <= 50;

    dispatch({ type: CATEGORY_ADMIN_POPUP_TITLE_CHECK, payload: { isValid: isTitleValid } });
    dispatch({ type: CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK, payload: { isValid: isTitleENValid } });

    return isTitleValid && isTitleENValid;
}

export const openCategoryAdminPopup = (categoryId = null) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_CATEGORY_MENU_TYPE.OVERVIEW } });

    if (categoryId) {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID, payload: { id: categoryId } });
    } else {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: 'Création d\'une catégorie' } });
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        dispatch({ type: CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: false } });
    }
}

export const loadCategoryAdminPopup = (categoryId) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`category/${categoryId}`)
        .then(({ data }) => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_CATEGORY, payload: { category: data } });
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: `${data.title}` } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const loadCourseCategoryAdminPopup = (categoryId) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: true } });

    Axios.get(`category/${categoryId}/list/model/courses/admin`)
        .then(({ data }) => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_COURSES, payload: { courses: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: false } });
        })
}

export const createCategoryAdminPopup = (categoryAdminStore) => async dispatch => {
    if (await dispatch(checkCategoryInformations(categoryAdminStore))) {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.post(`category/add/admin`, {
            title: categoryAdminStore.title,
            titleEN: categoryAdminStore.titleEN,
            seoKeywords: categoryAdminStore.seoKeyword
        }).then(({ data }) => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID, payload: { id: data.id } });
            dispatch({ type: CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
            dispatch({ type: CATEGORY_ADMIN_PANEL_ADD_CATEGORY, payload: { category: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const updateCategoryAdminPopup = (categoryAdminStore) => async dispatch => {
    if (await dispatch(checkCategoryInformations(categoryAdminStore))) {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.put(`category/${categoryAdminStore.categoryId}/update/admin`, {
            title: categoryAdminStore.title,
            titleEN: categoryAdminStore.titleEN,
            seoKeywords: categoryAdminStore.seoKeyword
        }).then(({ data }) => {
            dispatch({ type: CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY, payload: { id: categoryAdminStore.categoryId, category: data } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_CATEGORY_UPDATED"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const uploadCategoryPictureAdminPopup = (categoryId, file) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    const formData = new FormData();
    formData.append('Picture', file);

    Axios.put(`category/${categoryId}/update/picture/admin`, formData, {
        headers: { 'content-type': 'multipart/form-data' }
    }).then(({ data }) => {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE, payload: { picture: data.picture } });
        dispatch({ type: CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE, payload: { id: categoryId, picture: data.picture } });
    }).finally(() => {
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
    });
}

export const updateCourseCategoryPositionAdmin = (courseCategoryId, position) => async dispatch => {
    Axios.put(`coursecategory/position/change/admin`, {
        id: courseCategoryId,
        level: position + 1
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteCategoryPictureAdminPopup = (categoryId) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    Axios.delete(`/category/${categoryId}/delete/picture/admin`)
        .then(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE, payload: { picture: null } });
            dispatch({ type: CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE, payload: { id: categoryId, picture: null } });
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
        });
}

export const deleteCategoryAdminPopup = (categoryId) => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

    Axios.delete(`category/${categoryId}/delete/admin`)
        .then(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
            dispatch(loadCategoryAdmin());
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
}