import Slug from 'speakingurl';
import { LECTURE_TYPE } from './resources/constants/lectureType';
import { store } from 'react-notifications-component';
import { DURATION_TYPE } from './resources/constants/durationType';
import i18n from 'i18next';

export const landingZero = int => {
    return int < 10 ? `0${int}` : int
}

export const convertStringForUrl = name => {
    return Slug(name);
}

export const getIconLecture = (lectureType) => {
    switch (lectureType) {
        case LECTURE_TYPE.Video:
            return 'play_circle_outline';
        case LECTURE_TYPE.EmbedPage:
            return 'pageview';
        case LECTURE_TYPE.Annexe:
            return 'attach_file';
        case LECTURE_TYPE.Resource:
            return 'attach_file';
        default:
            return null;
    }
}

export const createNotification = (title, message, type) => {
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceIn"],
        animationOut: ["animate__animated", "animate__bounceOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
        }
    });
}

export const isValidEmail = email => {
    return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email); //eslint-disable-line
}

export const isValidPassword = password => {
    return (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/).test(password); //eslint-disable-line
}

export const convertStringToDate = (str) => {
    const date = new Date(str);

    return `${landingZero(date.getDate())}/${landingZero(date.getMonth() + 1)}/${landingZero(date.getFullYear())}`;
}

export const convertStringToTime = (str) => {
    const date = new Date(str);

    return `${landingZero(date.getHours())}:${landingZero(date.getMinutes())}`;
}

export const convertSecondToTime = seconds => {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor(seconds % 3600 / 60);
    const second = Math.floor(seconds % 3600 % 60);

    if (!seconds)
        return '0';

    if (hour > 0)
        return `${landingZero(hour)}:${landingZero(minute)}:${landingZero(second)}`;

    if (minute > 0)
        return `${landingZero(minute)}:${landingZero(second)}`;

    return `${second} sec.`;
}

export const convertSecondToRemainingTime = (seconds) => {
    if (!seconds || seconds <= 0)
        return `--:--`;

    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor(seconds % 3600 / 60);
    const second = Math.floor(seconds % 3600 % 60);

    let str = '';
    if (hour)
        str = `${hour}:`;

    return `${str}${landingZero(minute)}:${landingZero(second)}`;
}

export const convertSecondToAverageTime = (seconds) => {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor(seconds % 3600 / 60);
    const second = Math.floor(seconds % 3600 % 60);

    if (seconds === 0)
        return '0';

    if (hour > 0)
        return `${landingZero(hour)}:${landingZero(minute)}`;

    if (minute > 0)
        return `${minute} min`;

    return `${second} sec.`;
}

export const timeRemainingForNow = (date) => {
    const currentDate = new Date(date);
    const now = new Date();
    let diffInMilliSeconds = Math.abs(currentDate - now) / 1000;

    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    const isExpired = currentDate < now;
    let str = isExpired ? `${i18n.t("EXPIRED_FOR")} ` : `${i18n.t("EXPIRES_IN")} `;

    if (days > 0) {
        str += `${days} ${days > 1 ? `${i18n.t("DAYS")}` : `${i18n.t("DAY")}`}`;
    } else {
        str += `${hours} ${hours > 1 ? `${i18n.t("HOURS")}` : `${i18n.t("HOUR")}`}`;
    }

    return {
        isExpired,
        str
    };
}

export const elemParentContainsClassNames = (elem, className) => {
    const classes = elem.path.map(e => {
        if (!e || !e.getAttribute)
            return null;

        let classes = e.getAttribute('class');
        if (!classes)
            return null;

        return classes.split(' ');
    }).flat(1);

    return classes.includes(className);
}

export const convertHourToDuration = (time) => {
    if (time % 21600 === 0) // Year
        return { type: DURATION_TYPE.YEAR, value: time / 21600 };

    if (time % 720 === 0) // Month
        return { type: DURATION_TYPE.MONTH, value: time / 720 };

    if (time % 24 === 0) // Day
        return { type: DURATION_TYPE.DAY, value: time / 24 };

    return { type: DURATION_TYPE.HOUR, value: time };
}

export const convertDurationToHour = (value, durationType) => {
    let totalExpiryHour = value;

    if (!durationType)
        return totalExpiryHour;

    if (durationType.value === DURATION_TYPE.DAY)
        totalExpiryHour *= 24;

    if (durationType.value === DURATION_TYPE.MONTH)
        totalExpiryHour *= (24 * 30);

    if (durationType.value === DURATION_TYPE.YEAR)
        totalExpiryHour *= (24 * 30 * 365);

    return totalExpiryHour;
}

export const generateArrayRandomValue = (nb = 5, min = 5, max = 10) => {
    let values = [];

    for (let i = 0; i < nb; i++) {
        values.push(Math.floor(Math.random() * max) + min);
    }

    return values;
}

export const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-BE').format(price);
}