export const COURSE_STATUS = [
    {
        id: 1,
        label: 'Nouveau'
    },
    {
        id: 0,
        label: 'Neutre'
    },
    {
        id: 2,
        label: 'Obselète'
    }
]