// MENU
export const MENU_SET_RESULT_VISIBLE = 'MENU_SET_RESULT_VISIBLE';
export const MENU_SET_SEARCHING_VALUE = 'MENU_SET_SEARCHING_VALUE';
export const MENU_SET_MOBILE_MENU = 'MENU_SET_MOBILE_MENU';

export const MENU_SET_CATEGORIES_LOADING = 'MENU_SET_CATEGORIES_LOADING';
export const MENU_SET_CATEGORIES = 'MENU_SET_CATEGORIES';

export const MENU_SET_SEARCHING_RESULT = 'MENU_SET_SEARCHING_RESULT';
export const MENU_SET_SEARCHING_LOADING = 'MENU_SET_SEARCHING_LOADING';
export const MENU_SET_LOADED = 'MENU_SET_LOADED';

// ------------- //
// *** POPUP *** //
// ------------- //

// SignPopup
export const SIGN_POPUP_SET_ENABLED = 'SIGN_POPUP_SET_ENABLED';
export const SIGN_POPUP_SET_EMAIL = 'SIGN_POPUP_SET_EMAIL';
export const SIGN_POPUP_SET_PASSWORD = 'SIGN_POPUP_SET_PASSWORD';
export const SIGN_POPUP_EMAIL_CHECK = 'SIGN_POPUP_EMAIL_CHECK';
export const SIGN_POPUP_PASSWORD_CHECK = 'SIGN_POPUP_PASSWORD_CHECK';
export const SIGN_POPUP_SET_LOADING = 'SIGN_POPUP_SET_LOADING';

export const SIGN_POPUP_SET_FIRSTNAME = 'SIGN_POPUP_SET_FIRSTNAME';
export const SIGN_POPUP_SET_LASTNAME = 'SIGN_POPUP_SET_LASTNAME';
export const SIGN_POPUP_SET_PHONE = 'SIGN_POPUP_SET_PHONE';
export const SIGN_POPUP_SET_COMPANY = 'SIGN_POPUP_SET_COMPANY';
export const SIGN_POPUP_FIRSTNAME_CHECK = 'SIGN_POPUP_FIRSTNAME_CHECK';
export const SIGN_POPUP_LASTNAME_CHECK = 'SIGN_POPUP_LASTNAME_CHECK';
export const SIGN_POPUP_PHONE_CHECK = 'SIGN_POPUP_PHONE_CHECK';
export const SIGN_POPUP_COMPANY_CHECK = 'SIGN_POPUP_COMPANY_CHECK';
export const SIGN_POPUP_SET_REGISTER_STEP = 'SIGN_POPUP_SET_REGISTER_STEP';

export const SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD = 'SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD';
export const SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD = 'SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD';
export const SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK = 'SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK';
export const SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK = 'SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK';

// VoucherPopup
export const VOUCHER_POPUP_RESET = 'VOUCHER_POPUP_RESET';
export const VOUCHER_POPUP_SET_ENABLED = 'VOUCHER_POPUP_SET_ENABLED';
export const VOUCHER_POPUP_SET_LOADING = 'VOUCHER_POPUP_SET_LOADING';
export const VOUCHER_POPUP_SET_VOUCHERS_CODE = 'VOUCHER_POPUP_SET_VOUCHERS_CODE';
export const VOUCHER_POPUP_VOUCHER_CODE_CHECK = 'VOUCHER_POPUP_VOUCHER_CODE_CHECK';
export const VOUCHER_POPUP_SET_VOUCHERS = 'VOUCHER_POPUP_SET_VOUCHERS';

// PreviewPopup
export const PREVIEW_POPUP_SET_ENABLED = 'PREVIEW_POPUP_SET_ENABLED';
export const PREVIEW_POPUP_SET_LOADING = 'PREVIEW_POPUP_SET_LOADING';
export const PREVIEW_POPUP_SET_LECTURE = 'PREVIEW_POPUP_SET_LECTURE';

// InterviewPopup
export const INTERVIEW_POPUP_SET_INTERVIEW_ID = 'INTERVIEW_POPUP_SET_INTERVIEW_ID';

// InboundPopup
export const INBOUND_POPUP_SET_ENABLED = 'INBOUND_POPUP_SET_ENABLED';
export const INBOUND_POPUP_RESET = 'INBOUND_POPUP_RESET'
export const INBOUND_POPUP_SET_EMAIL = 'INBOUND_POPUP_SET_EMAIL';
export const INBOUND_POPUP_SET_FIRSTNAME = 'INBOUND_POPUP_SET_FIRSTNAME';
export const INBOUND_POPUP_SET_LASTNAME = 'INBOUND_POPUP_SET_LASTNAME';
export const INBOUND_POPUP_SET_PHONE = 'INBOUND_POPUP_SET_PHONE';
export const INBOUND_POPUP_SET_COMPANY = 'INBOUND_POPUP_SET_COMPANY';
export const INBOUND_POPUP_SET_JOB_STATUS = 'INBOUND_POPUP_SET_JOB_STATUS';
export const INBOUND_POPUP_SET_SAVED = 'INBOUND_POPUP_SET_SAVED';
export const INBOUND_POPUP_SET_COURSE_TITLE = 'INBOUND_POPUP_SET_COURSE_TITLE';
export const INBOUND_POPUP_SET_LOADING = 'INBOUND_POPUP_SET_LOADING';
export const INBOUND_POPUP_SET_COMPLETION = 'INBOUND_POPUP_SET_COMPLETION';
export const INBOUND_POPUP_EMAIL_CHECK = 'INBOUND_POPUP_EMAIL_CHECK';
export const INBOUND_POPUP_FIRSTNAME_CHECK = 'INBOUND_POPUP_FIRSTNAME_CHECK';
export const INBOUND_POPUP_LASTNAME_CHECK = 'INBOUND_POPUP_LASTNAME_CHECK';
export const INBOUND_POPUP_PHONE_CHECK = 'INBOUND_POPUP_PHONE_CHECK';
export const INBOUND_POPUP_COMPANY_CHECK = 'INBOUND_POPUP_COMPANY_CHECK';

// UserAdminPopup
export const USER_ADMIN_POPUP_RESET = 'USER_ADMIN_POPUP_RESET';
export const USER_ADMIN_POPUP_SET_ENABLED = 'USER_ADMIN_POPUP_SET_ENABLED';
export const USER_ADMIN_POPUP_SET_POPUP_TITLE = 'USER_ADMIN_POPUP_SET_POPUP_TITLE';
export const USER_ADMIN_POPUP_SET_USER = 'USER_ADMIN_POPUP_SET_USER';
export const USER_ADMIN_POPUP_SET_SOCIAL = 'USER_ADMIN_POPUP_SET_SOCIAL';
export const USER_ADMIN_POPUP_SET_USER_ID = 'USER_ADMIN_POPUP_SET_USER_ID';
export const USER_ADMIN_POPUP_SET_LOADING = 'USER_ADMIN_POPUP_SET_LOADING';
export const USER_ADMIN_POPUP_SET_PICTURE_LOADING = 'USER_ADMIN_POPUP_SET_PICTURE_LOADING';
export const USER_ADMIN_POPUP_SET_SECURITY_LOADING = 'USER_ADMIN_POPUP_SET_SECURITY_LOADING';
export const USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING = 'USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING';
export const USER_ADMIN_POPUP_TOGGLE_MENU_STATUS = 'USER_ADMIN_POPUP_TOGGLE_MENU_STATUS';
export const USER_ADMIN_POPUP_SET_PICTURE = 'USER_ADMIN_POPUP_SET_PICTURE';

export const USER_ADMIN_POPUP_SET_EMAIL = 'USER_ADMIN_POPUP_SET_EMAIL';
export const USER_ADMIN_POPUP_SET_FIRSTNAME = 'USER_ADMIN_POPUP_SET_FIRSTNAME';
export const USER_ADMIN_POPUP_SET_LASTNAME = 'USER_ADMIN_POPUP_SET_LASTNAME';
export const USER_ADMIN_POPUP_SET_PHONE = 'USER_ADMIN_POPUP_SET_PHONE';
export const USER_ADMIN_POPUP_SET_COMPANY = 'USER_ADMIN_POPUP_SET_COMPANY';
export const USER_ADMIN_POPUP_SET_CONFIRMED = 'USER_ADMIN_POPUP_SET_CONFIRMED';

export const USER_ADMIN_POPUP_SET_NEW_PASSWORD = 'USER_ADMIN_POPUP_SET_NEW_PASSWORD';
export const USER_ADMIN_POPUP_SET_RENEW_PASSWORD = 'USER_ADMIN_POPUP_SET_RENEW_PASSWORD';

export const USER_ADMIN_POPUP_SET_COURSES_LOADING = 'USER_ADMIN_POPUP_SET_COURSES_LOADING';
export const USER_ADMIN_POPUP_SET_COURSES = 'USER_ADMIN_POPUP_SET_COURSES';
export const USER_ADMIN_POPUP_SET_COURSE_LOADING = 'USER_ADMIN_POPUP_SET_COURSE_LOADING';
export const USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE = 'USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE';
export const USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK = 'USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK';
export const USER_ADMIN_POPUP_DELETE_COURSE = 'USER_ADMIN_POPUP_DELETE_COURSE';
export const USER_ADMIN_POPUP_ADD_COURSE = 'USER_ADMIN_POPUP_ADD_COURSE';

export const USER_ADMIN_POPUP_SET_SURVEYS_LOADING = 'USER_ADMIN_POPUP_SET_SURVEYS_LOADING';
export const USER_ADMIN_POPUP_SET_SURVEYS = 'USER_ADMIN_POPUP_SET_SURVEYS';
export const USER_ADMIN_POPUP_SET_SURVEY_LOADING = 'USER_ADMIN_POPUP_SET_SURVEY_LOADING';
export const USER_ADMIN_POPUP_DELETE_SURVEY = 'USER_ADMIN_POPUP_DELETE_SURVEY';

export const USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING = 'USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING';
export const USER_ADMIN_POPUP_SET_CERTIFICATES = 'USER_ADMIN_POPUP_SET_CERTIFICATES';
export const USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING = 'USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING';
export const USER_ADMIN_POPUP_ADD_CERTIFICATE = 'USER_ADMIN_POPUP_ADD_CERTIFICATE';
export const USER_ADMIN_POPUP_DELETE_CERTIFICATE = 'USER_ADMIN_POPUP_DELETE_CERTIFICATE';

export const USER_ADMIN_POPUP_EMAIL_CHECK = 'USER_ADMIN_POPUP_EMAIL_CHECK';
export const USER_ADMIN_POPUP_FIRSTNAME_CHECK = 'USER_ADMIN_POPUP_FIRSTNAME_CHECK';
export const USER_ADMIN_POPUP_LASTNAME_CHECK = 'USER_ADMIN_POPUP_LASTNAME_CHECK';
export const USER_ADMIN_POPUP_PHONE_CHECK = 'USER_ADMIN_POPUP_PHONE_CHECK';
export const USER_ADMIN_POPUP_COMPANY_CHECK = 'USER_ADMIN_POPUP_COMPANY_CHECK';

export const USER_ADMIN_POPUP_NEW_PASSWORD_CHECK = 'USER_ADMIN_POPUP_NEW_PASSWORD_CHECK';
export const USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK = 'USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK';

// VoucherAdminPopup
export const VOUCHER_ADMIN_POPUP_RESET = 'VOUCHER_ADMIN_POPUP_RESET';
export const VOUCHER_ADMIN_POPUP_SET_ENABLED = 'VOUCHER_ADMIN_POPUP_SET_ENABLED';
export const VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE = 'VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE';
export const VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID = 'VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID';
export const VOUCHER_ADMIN_POPUP_SET_VOUCHER = 'VOUCHER_ADMIN_POPUP_SET_VOUCHER';
export const VOUCHER_ADMIN_POPUP_SET_LOADING = 'VOUCHER_ADMIN_POPUP_SET_LOADING';
export const VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING = 'VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING';
export const VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING = 'VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING';
export const VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS = 'VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS';

export const VOUCHER_ADMIN_POPUP_SET_START_DATE = 'VOUCHER_ADMIN_POPUP_SET_START_DATE';
export const VOUCHER_ADMIN_POPUP_SET_END_DATE = 'VOUCHER_ADMIN_POPUP_SET_END_DATE';
export const VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE = 'VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE';

export const VOUCHER_ADMIN_POPUP_SET_COURSES = 'VOUCHER_ADMIN_POPUP_SET_COURSES';
export const VOUCHER_ADMIN_POPUP_ADD_COURSE = 'VOUCHER_ADMIN_POPUP_ADD_COURSE';
export const VOUCHER_ADMIN_POPUP_DELETE_COURSE = 'VOUCHER_ADMIN_POPUP_DELETE_COURSE';

export const VOUCHER_ADMIN_POPUP_DATES_CHECK = 'VOUCHER_ADMIN_POPUP_DATES_CHECK';
export const VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK = 'VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK';

export const VOUCHER_ADMIN_POPUP_SET_USERS_LOADING = 'VOUCHER_ADMIN_POPUP_SET_USERS_LOADING';
export const VOUCHER_ADMIN_POPUP_SET_USER_LOADING = 'VOUCHER_ADMIN_POPUP_SET_USER_LOADING';
export const VOUCHER_ADMIN_POPUP_SET_USERS = 'VOUCHER_ADMIN_POPUP_SET_USERS';
export const VOUCHER_ADMIN_POPUP_ADD_USERS = 'VOUCHER_ADMIN_POPUP_ADD_USERS';
export const VOUCHER_ADMIN_POPUP_DELETE_USER = 'VOUCHER_ADMIN_POPUP_DELETE_USER';
export const VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE = 'VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE';
export const VOUCHER_ADMIN_POPUP_SET_HAS_NEXT = 'VOUCHER_ADMIN_POPUP_SET_HAS_NEXT';
export const VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE = 'VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE';

// CourseAdminPopup
export const COURSE_ADMIN_POPUP_RESET = 'COURSE_ADMIN_POPUP_RESET';
export const COURSE_ADMIN_POPUP_SET_POPUP_TITLE = 'COURSE_ADMIN_POPUP_SET_POPUP_TITLE';
export const COURSE_ADMIN_POPUP_SET_ENABLED = 'COURSE_ADMIN_POPUP_SET_ENABLED';
export const COURSE_ADMIN_POPUP_SET_COURSE = 'COURSE_ADMIN_POPUP_SET_COURSE';
export const COURSE_ADMIN_POPUP_SET_COURSE_ID = 'COURSE_ADMIN_POPUP_SET_COURSE_ID';
export const COURSE_ADMIN_POPUP_SET_LOADING = 'COURSE_ADMIN_POPUP_SET_LOADING';
export const COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING = 'COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING';
export const COURSE_ADMIN_POPUP_SET_PICTURE_LOADING = 'COURSE_ADMIN_POPUP_SET_PICTURE_LOADING';
export const COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS = 'COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS';

export const COURSE_ADMIN_POPUP_SET_ADVERTISEMENT_VISIBILITY = 'COURSE_ADMIN_POPUP_SET_ADVERTISEMENT_VISIBILITY';

export const COURSE_ADMIN_POPUP_SET_PICTURE = 'COURSE_ADMIN_POPUP_SET_PICTURE';
export const COURSE_ADMIN_POPUP_SET_TITLE = 'COURSE_ADMIN_POPUP_SET_TITLE';
export const COURSE_ADMIN_POPUP_SET_DESCRIPTION = 'COURSE_ADMIN_POPUP_SET_DESCRIPTION';
export const COURSE_ADMIN_POPUP_SET_PRICE = 'COURSE_ADMIN_POPUP_SET_PRICE';
export const COURSE_ADMIN_POPUP_SET_PREVIEW_VIDEO = 'COURSE_ADMIN_POPUP_SET_PREVIEW_VIDEO';
export const COURSE_ADMIN_POPUP_SET_UNIT_COMPLETE_LOCK = 'COURSE_ADMIN_POPUP_SET_UNIT_COMPLETE_LOCK';
export const COURSE_ADMIN_POPUP_SET_EXPIRY_VALUE = 'COURSE_ADMIN_POPUP_SET_EXPIRY_VALUE';
export const COURSE_ADMIN_POPUP_SET_EXPIRY_UNIT = 'COURSE_ADMIN_POPUP_SET_EXPIRY_UNIT';
export const COURSE_ADMIN_POPUP_SET_LANG = 'COURSE_ADMIN_POPUP_SET_LANG';
export const COURSE_ADMIN_POPUP_SET_OTHER_LANG_ID = 'COURSE_ADMIN_POPUP_SET_OTHER_LANG_ID';
export const COURSE_ADMIN_POPUP_SET_STATUS_ENABLED = 'COURSE_ADMIN_POPUP_SET_STATUS_ENABLED';
export const COURSE_ADMIN_POPUP_SET_STATUS = 'COURSE_ADMIN_POPUP_SET_STATUS';
export const COURSE_ADMIN_POPUP_SET_SEO_KEYWORDS = 'COURSE_ADMIN_POPUP_SET_SEO_KEYWORDS';
export const COURSE_ADMIN_POPUP_SET_SEO_LOCKED = 'COURSE_ADMIN_POPUP_SET_SEO_LOCKED';

export const COURSE_ADMIN_POPUP_TITLE_CHECK = 'COURSE_ADMIN_POPUP_TITLE_CHECK';
export const COURSE_ADMIN_POPUP_DESCRIPTION_CHECK = 'COURSE_ADMIN_POPUP_DESCRIPTION_CHECK';
export const COURSE_ADMIN_POPUP_PREVIEW_VIDEO_CHECK = 'COURSE_ADMIN_POPUP_PREVIEW_VIDEO_CHECK';
export const COURSE_ADMIN_POPUP_EXPIRY_CHECK = 'COURSE_ADMIN_POPUP_EXPIRY_CHECK';
export const COURSE_ADMIN_POPUP_PRICE_CHECK = 'COURSE_ADMIN_POPUP_PRICE_CHECK';

export const COURSE_ADMIN_POPUP_SET_SPECIFICATIONS_LOADING = 'COURSE_ADMIN_POPUP_SET_SPECIFICATIONS_LOADING';
export const COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING = 'COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING';
export const COURSE_ADMIN_POPUP_SET_OBJECTIVES = 'COURSE_ADMIN_POPUP_SET_OBJECTIVES';
export const COURSE_ADMIN_POPUP_ADD_OBJECTIVE = 'COURSE_ADMIN_POPUP_ADD_OBJECTIVE';
export const COURSE_ADMIN_POPUP_SET_PUBLICS = 'COURSE_ADMIN_POPUP_SET_PUBLICS';
export const COURSE_ADMIN_POPUP_ADD_PUBLIC = 'COURSE_ADMIN_POPUP_ADD_PUBLIC';
export const COURSE_ADMIN_POPUP_SET_PREREQUISITES = 'COURSE_ADMIN_POPUP_SET_PREREQUISITES';
export const COURSE_ADMIN_POPUP_ADD_PREREQUISITE = 'COURSE_ADMIN_POPUP_ADD_PREREQUISITE';
export const COURSE_ADMIN_POPUP_DELETE_SPECIFICATION = 'COURSE_ADMIN_POPUP_DELETE_SPECIFICATION';

export const COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE = 'COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE';
export const COURSE_ADMIN_POPUP_SET_NEW_PUBLIC = 'COURSE_ADMIN_POPUP_SET_NEW_PUBLIC';
export const COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE = 'COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE';

export const COURSE_ADMIN_POPUP_OBJECTIVE_CHECK = 'COURSE_ADMIN_POPUP_OBJECTIVE_CHECK';
export const COURSE_ADMIN_POPUP_PUBLIC_CHECK = 'COURSE_ADMIN_POPUP_PUBLIC_CHECK';
export const COURSE_ADMIN_POPUP_PREREQUISITE_CHECK = 'COURSE_ADMIN_POPUP_PREREQUISITE_CHECK';

export const COURSE_ADMIN_POPUP_SET_CATEGORIES_LOADING = 'COURSE_ADMIN_POPUP_SET_CATEGORIES_LOADING';
export const COURSE_ADMIN_POPUP_SET_CATEGORY_LOADING = 'COURSE_ADMIN_POPUP_SET_CATEGORY_LOADING';
export const COURSE_ADMIN_POPUP_SET_CATEGORIES = 'COURSE_ADMIN_POPUP_SET_CATEGORIES';
export const COURSE_ADMIN_POPUP_ADD_CATEGORY = 'COURSE_ADMIN_POPUP_ADD_CATEGORY';
export const COURSE_ADMIN_POPUP_DELETE_CATEGORY = 'COURSE_ADMIN_POPUP_DELETE_CATEGORY';

export const COURSE_ADMIN_POPUP_SET_USERS_LOADING = 'COURSE_ADMIN_POPUP_SET_USERS_LOADING';
export const COURSE_ADMIN_POPUP_SET_USER_LOADING = 'COURSE_ADMIN_POPUP_SET_USER_LOADING';
export const COURSE_ADMIN_POPUP_SET_USER_EXPIRE_DATE = 'COURSE_ADMIN_POPUP_SET_USER_EXPIRE_DATE';
export const COURSE_ADMIN_POPUP_SET_USER_UNIT_COMPLETE_LOCK = 'COURSE_ADMIN_POPUP_SET_USER_UNIT_COMPLETE_LOCK';
export const COURSE_ADMIN_POPUP_SET_USERS = 'COURSE_ADMIN_POPUP_SET_USERS';
export const COURSE_ADMIN_POPUP_ADD_USERS = 'COURSE_ADMIN_POPUP_ADD_USERS';
export const COURSE_ADMIN_POPUP_DELETE_USER = 'COURSE_ADMIN_POPUP_DELETE_USER';
export const COURSE_ADMIN_POPUP_SET_USER_SEARCH_VALUE = 'COURSE_ADMIN_POPUP_SET_USER_SEARCH_VALUE';
export const COURSE_ADMIN_POPUP_SET_HAS_NEXT = 'COURSE_ADMIN_POPUP_SET_HAS_NEXT';
export const COURSE_ADMIN_POPUP_SET_PAGING_PAGE = 'COURSE_ADMIN_POPUP_SET_PAGING_PAGE';

// CategoryAdminPopup
export const CATEGORY_ADMIN_POPUP_RESET = 'CATEGORY_ADMIN_POPUP_RESET';
export const CATEGORY_ADMIN_POPUP_SET_ENABLED = 'CATEGORY_ADMIN_POPUP_SET_ENABLED';
export const CATEGORY_ADMIN_POPUP_SET_LOADING = 'CATEGORY_ADMIN_POPUP_SET_LOADING';
export const CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE = 'CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE';
export const CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS = 'CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS';
export const CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING = 'CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING';
export const CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING = 'CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING';
export const CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING = 'CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING';
export const CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID = 'CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID';
export const CATEGORY_ADMIN_POPUP_SET_CATEGORY = 'CATEGORY_ADMIN_POPUP_SET_CATEGORY';
export const CATEGORY_ADMIN_POPUP_SEO_KEYWORDS = 'CATEGORY_ADMIN_POPUP_SEO_KEYWORDS';

export const CATEGORY_ADMIN_POPUP_SET_PICTURE = 'CATEGORY_ADMIN_POPUP_SET_PICTURE';
export const CATEGORY_ADMIN_POPUP_SET_TITLE = 'CATEGORY_ADMIN_POPUP_SET_TITLE';
export const CATEGORY_ADMIN_POPUP_SET_TITLE_EN = 'CATEGORY_ADMIN_POPUP_SET_TITLE_EN';

export const CATEGORY_ADMIN_POPUP_SET_COURSES = 'CATEGORY_ADMIN_POPUP_SET_COURSES';

export const CATEGORY_ADMIN_POPUP_TITLE_CHECK = 'CATEGORY_ADMIN_POPUP_TITLE_CHECK';
export const CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK = 'CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK';

// AddtionalAdminPopup
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_RESET = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_RESET';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LECTURE_ID = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LECTURE_ID';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LOADING = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_ADD_FILE = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_ADD_FILE';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_PROGRESS = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_PROGRESS';
export const ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_ERROR = 'ASSIGN_ADDITIONAL_ADMIN_POPUP_SET_FILE_ERROR';

// FileAdminPopup
export const ASSIGN_FILE_ADMIN_POPUP_RESET = 'ASSIGN_FILE_ADMIN_POPUP_RESET';
export const ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID = 'ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID';
export const ASSIGN_FILE_ADMIN_POPUP_SET_LOADING = 'ASSIGN_FILE_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_FILE_ADMIN_POPUP_ADD_FILE = 'ASSIGN_FILE_ADMIN_POPUP_ADD_FILE';
export const ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING = 'ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING';
export const ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME = 'ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME';
export const ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS = 'ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS';
export const ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID = 'ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID';
export const ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR = 'ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR';

// AssignCategoryAdminPopup
export const ASSIGN_CATEGORY_ADMIN_POPUP_RESET = 'ASSIGN_CATEGORY_ADMIN_POPUP_RESET';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES';
export const ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY = 'ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID';
export const ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_CATEGORY_ADMIN_POPUP_SET_SEARCH_VALUE';

// AssignSectionAdminPopup
export const ASSIGN_SECTION_ADMIN_POPUP_RESET = 'ASSIGN_SECTION_ADMIN_POPUP_RESET';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING = 'ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID = 'ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS = 'ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS';
export const ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS = 'ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS';
export const ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION = 'ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING = 'ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE';

// AssignLectureAdminPopup
export const ASSIGN_LECTURE_ADMIN_POPUP_RESET = 'ASSIGN_LECTURE_ADMIN_POPUP_RESET';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES';
export const ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES = 'ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES';
export const ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE = 'ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE';

// AssignVideoAdminPopup
export const ASSIGN_VIDEO_ADMIN_POPUP_RESET = 'ASSIGN_VIDEO_ADMIN_POPUP_RESET';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEOS';
export const ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS = 'ASSIGN_VIDEO_ADMIN_POPUP_ADD_VIDEOS';
export const ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO = 'ASSIGN_VIDEO_ADMIN_POPUP_ADD_SELECTED_VIDEO';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_VIDEO_LOADING';
export const ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO = 'ASSIGN_VIDEO_ADMIN_POPUP_DELETE_VIDEO';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_VIDEO_ADMIN_POPUP_SET_PAGING_PAGE';

// AssignEmbedPageAdminPopup
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS';

export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK';
export const ASSIGN_EMBED_PAGE_ADMIN_POPUP_DURATION_CHECK = 'ASSIGN_EMBED_PAGE_ADMIN_POPUP_DURATION_CHECK';

// AssignDocumentationAdminPopup
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE';

export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK';
export const ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK = 'ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK';

// AssignCourseAdminPopup
export const ASSIGN_COURSE_ADMIN_POPUP_RESET = 'ASSIGN_COURSE_ADMIN_POPUP_RESET';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_LOADING = 'ASSIGN_COURSE_ADMIN_POPUP_SET_LOADING';

export const ASSIGN_COURSE_ADMIN_POPUP_SET_USER_ID = 'ASSIGN_COURSE_ADMIN_POPUP_SET_USER_ID';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_VOUCHER_ID = 'ASSIGN_COURSE_ADMIN_POPUP_SET_VOUCHER_ID';

export const ASSIGN_COURSE_ADMIN_POPUP_SET_COURSES = 'ASSIGN_COURSE_ADMIN_POPUP_SET_COURSES';
export const ASSIGN_COURSE_ADMIN_POPUP_ADD_COURSES = 'ASSIGN_COURSE_ADMIN_POPUP_ADD_COURSES';
export const ASSIGN_COURSE_ADMIN_POPUP_ADD_SELECTED_COURSE = 'ASSIGN_COURSE_ADMIN_POPUP_ADD_SELECTED_COURSE';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING = 'ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING';

export const ASSIGN_COURSE_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_COURSE_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_COURSE_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE';

// AssignUserAdminPopup
export const ASSIGN_USER_ADMIN_POPUP_RESET = 'ASSIGN_USER_ADMIN_POPUP_RESET';
export const ASSIGN_USER_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_USER_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_USER_ADMIN_POPUP_SET_LOADING = 'ASSIGN_USER_ADMIN_POPUP_SET_LOADING';

export const ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID = 'ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID';

export const ASSIGN_USER_ADMIN_POPUP_SET_USERS = 'ASSIGN_USER_ADMIN_POPUP_SET_USERS';
export const ASSIGN_USER_ADMIN_POPUP_ADD_USERS = 'ASSIGN_USER_ADMIN_POPUP_ADD_USERS';
export const ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER = 'ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER';
export const ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING = 'ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING';

export const ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE';

// AssignCertificateAdminPopup
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING';

export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID';

export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_COURSES = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_COURSES';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING';

export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_SEARCH_VALUE = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_SEARCH_VALUE';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_HAS_NEXT = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_HAS_NEXT';
export const ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_PAGING_PAGE = 'ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_PAGING_PAGE';

// SurveyAdminPopup
export const SURVEY_ADMIN_POPUP_RESET = 'SURVEY_ADMIN_POPUP_RESET';
export const SURVEY_ADMIN_POPUP_SET_ENABLED = 'SURVEY_ADMIN_POPUP_SET_ENABLED';
export const SURVEY_ADMIN_POPUP_SET_LOADING = 'SURVEY_ADMIN_POPUP_SET_LOADING';
export const SURVEY_ADMIN_POPUP_SET_ACTION_LOADING = 'SURVEY_ADMIN_POPUP_SET_ACTION_LOADING';
export const SURVEY_ADMIN_POPUP_SET_POPUP_TITLE = 'SURVEY_ADMIN_POPUP_SET_POPUP_TITLE';
export const SURVEY_ADMIN_POPUP_SET_SURVEY_ID = 'SURVEY_ADMIN_POPUP_SET_SURVEY_ID';
export const SURVEY_ADMIN_POPUP_SET_RESULTS = 'SURVEY_ADMIN_POPUP_SET_RESULTS';


// ------------- //
// *** PANEL *** //
// ------------- //

// HomePanel
export const HOME_PANEL_SET_CATEGORIES_LOADING = 'HOME_PANEL_SET_CATEGORIES_LOADING';
export const HOME_PANEL_SET_CATEGORIES = 'HOME_PANEL_SET_CATEGORIES';
export const HOME_PANEL_SET_SELECTED_CATEGORY_ID = 'HOME_PANEL_SET_SELECTED_CATEGORY_ID';
export const HOME_PANEL_SET_COURSES_LOADING = 'HOME_PANEL_SET_COURSES_LOADING';
export const HOME_PANEL_SET_COURSES = 'HOME_PANEL_SET_COURSES';
export const HOME_PANEL_ADD_COURSES = 'HOME_PANEL_ADD_COURSES';
export const HOME_PANEL_SET_CURRENT_PAGE = 'HOME_PANEL_SET_CURRENT_PAGE';
export const HOME_PANEL_SET_HAS_NEXT = 'HOME_PANEL_SET_HAS_NEXT';

// CategoryPanel
export const CATEGORY_PANEL_SET_LOADING = 'CATEGORY_PANEL_SET_LOADING';
export const CATEGORY_PANEL_SET_CATEGORY = 'CATEGORY_PANEL_SET_CATEGORY';

// PreviewPanel
export const PREVIEW_PANEL_SET_LOADING = 'PREVIEW_PANEL_SET_LOADING';
export const PREVIEW_PANEL_SET_COURSE = 'PREVIEW_PANEL_SET_COURSE';
export const PREVIEW_PANEL_TOGGLE_SECTION = 'PREVIEW_PANEL_TOGGLE_SECTION';
export const PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS = 'PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS';
export const PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED = 'PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED';
export const PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS = 'PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS';

// DashboardPanel
export const DASHBOARD_PANEL_SET_COURSES_LOADING = 'DASHBOARD_PANEL_SET_COURSES_LOADING';
export const DASHBOARD_PANEL_SET_COURSES = 'DASHBOARD_PANEL_SET_COURSES';
export const DASHBOARD_PANEL_ADD_COURSE = 'DASHBOARD_PANEL_ADD_COURSE';

export const DASHBOARD_PANEL_SET_CERTIFICATES_LOADING = 'DASHBOARD_PANEL_SET_CERTIFICATES_LOADING';
export const DASHBOARD_PANEL_SET_CERTIFICATES = 'DASHBOARD_PANEL_SET_CERTIFICATES';

// PreferencePanel
export const PREFERENCE_PANEL_SET_PANEL = 'PREFERENCE_PANEL_SET_PANEL';
export const PREFERENCE_PANEL_SET_PANEL_LOADING = 'PREFERENCE_PANEL_SET_PANEL_LOADING';
export const PREFERENCE_PANEL_SET_INFORMATIONS_LOADING = 'PREFERENCE_PANEL_SET_INFORMATIONS_LOADING';
export const PREFERENCE_PANEL_SET_SECURITY_LOADING = 'PREFERENCE_PANEL_SET_SECURITY_LOADING';
export const PREFERENCE_PANEL_SET_SESSION_LOADING = 'PREFERENCE_PANEL_SET_SESSION_LOADING';
export const PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING = 'PREFERENCE_PANEL_SET_SESSION_ACTION_LOADING';

export const PREFERENCE_PANEL_SET_INFORMATIONS = 'PREFERENCE_PANEL_SET_INFORMATIONS';
export const PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING = 'PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_LOADING';
export const PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS = 'PREFERENCE_PANEL_SET_CONFIRMATION_EMAIL_STATUS';

export const PREFERENCE_PANEL_SET_EMAIL = 'PREFERENCE_PANEL_SET_EMAIL';
export const PREFERENCE_PANEL_SET_FIRSTNAME = 'PREFERENCE_PANEL_SET_FIRSTNAME';
export const PREFERENCE_PANEL_SET_LASTNAME = 'PREFERENCE_PANEL_SET_LASTNAME';
export const PREFERENCE_PANEL_SET_PHONE = 'PREFERENCE_PANEL_SET_PHONE';
export const PREFERENCE_PANEL_SET_COMPANY = 'PREFERENCE_PANEL_SET_COMPANY';

export const PREFERENCE_PANEL_HAS_CURRENT_PASSWORD = 'PREFERENCE_PANEL_HAS_CURRENT_PASSWORD';
export const PREFERENCE_PANEL_SET_CURRENT_PASSWORD = 'PREFERENCE_PANEL_SET_CURRENT_PASSWORD';
export const PREFERENCE_PANEL_SET_NEW_PASSWORD = 'PREFERENCE_PANEL_SET_NEW_PASSWORD';
export const PREFERENCE_PANEL_SET_RENEW_PASSWORD = 'PREFERENCE_PANEL_SET_RENEW_PASSWORD';

export const PREFERENCE_PANEL_SET_SESSIONS = 'PREFERENCE_PANEL_SET_SESSIONS';
export const PREFERENCE_PANEL_DELETE_SESSION = 'PREFERENCE_PANEL_DELETE_SESSION';

export const PREFERENCE_PANEL_FIRSTNAME_CHECK = 'PREFERENCE_PANEL_FIRSTNAME_CHECK';
export const PREFERENCE_PANEL_LASTNAME_CHECK = 'PREFERENCE_PANEL_LASTNAME_CHECK';
export const PREFERENCE_PANEL_PHONE_CHECK = 'PREFERENCE_PANEL_PHONE_CHECK';
export const PREFERENCE_PANEL_COMPANY_CHECK = 'PREFERENCE_PANEL_COMPANY_CHECK';

export const PREFERENCE_PANEL_CURRENT_PASSWORD_CHECK = 'PREFERENCE_PANEL_CURRENT_PASSWORD_CHECK';
export const PREFERENCE_PANEL_NEW_PASSWORD_CHECK = 'PREFERENCE_PANEL_NEW_PASSWORD_CHECK';
export const PREFERENCE_PANEL_RENEW_PASSWORD_CHECK = 'PREFERENCE_PANEL_RENEW_PASSWORD_CHECK';

export const PREFERENCE_PANEL_SET_PICTURE_LOADING = 'PREFERENCE_PANEL_SET_PICTURE_LOADING';
export const PREFERENCE_PANEL_SET_PICTURE = 'PREFERENCE_PANEL_SET_PICTURE';

// LecturePanel
export const LECTURE_PANEL_RESET = 'LECTURE_PANEL_RESET';
export const LECTURE_PANEL_SET_MENU_LOADING = 'LECTURE_PANEL_SET_MENU_LOADING';
export const LECTURE_PANEL_SET_LECTURE_LOADING = 'LECTURE_PANEL_SET_LECTURE_LOADING';
export const LECTURE_PANEL_SET_CREATING_LECTURE_LOADING = 'LECTURE_PANEL_SET_CREATING_LECTURE_LOADING';
export const LECTURE_PANEL_SET_MENU = 'LECTURE_PANEL_SET_MENU';
export const LECTURE_PANEL_SET_MENU_SECTIONS = 'LECTURE_PANEL_SET_MENU_SECTIONS';
export const LECTURE_PANEL_SET_LECTURE = 'LECTURE_PANEL_SET_LECTURE';
export const LECTURE_PANEL_SET_MENU_LECTURES = 'LECTURE_PANEL_SET_MENU_LECTURES';
export const LECTURE_PANEL_SET_CURRENT_LECTURE = 'LECTURE_PANEL_SET_CURRENT_LECTURE';
export const LECTURE_PANEL_TOGGLE_SECTION = 'LECTURE_PANEL_TOGGLE_SECTION';
export const LECTURE_PANEL_SET_LECTURE_AS_COMPLETED = 'LECTURE_PANEL_SET_LECTURE_AS_COMPLETED';
export const LECTURE_PANEL_SET_LECTURE_AS_UNLOCKED = 'LECTURE_PANEL_SET_LECTURE_AS_UNLOCKED';
export const LECTURE_PANEL_SET_LECTURE_AS_FREE = 'LECTURE_PANEL_SET_LECTURE_AS_FREE';
export const LECTURE_PANEL_SET_CURRENT_VIDEO_ID = 'LECTURE_PANEL_SET_CURRENT_VIDEO_ID';

// [SURVEY] LecturePanel
export const LECTURE_PANEL_SURVEY_SET_STEP = 'LECTURE_PANEL_SURVEY_SET_STEP';
export const LECTURE_PANEL_SURVEY_SET_LOADING = 'LECTURE_PANEL_SURVEY_SET_LOADING';
export const LECTURE_PANEL_SURVEY_SET_QUESTION_LOADING = 'LECTURE_PANEL_SURVEY_SET_QUESTION_LOADING';
export const LECTURE_PANEL_SURVEY_SET_ANSWER_LOADING = 'LECTURE_PANEL_SURVEY_SET_ANSWER_LOADING';
export const LECTURE_PANEL_SURVEY_SET_SURVEY = 'LECTURE_PANEL_SURVEY_SET_SURVEY';
export const LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID = 'LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID';
export const LECTURE_PANEL_SURVEY_TOGGLE_SELECT_ANSWER = 'LECTURE_PANEL_SURVEY_TOGGLE_SELECT_ANSWER';
export const LECTURE_PANEL_SURVEY_SET_QUESTION_DONE = 'LECTURE_PANEL_SURVEY_SET_QUESTION_DONE';
export const LECTURE_PANEL_SURVEY_SET_RESULTS = 'LECTURE_PANEL_SURVEY_SET_RESULTS';
export const LECTURE_PANEL_SURVEY_DELETE_QUESTION = 'LECTURE_PANEL_SURVEY_DELETE_QUESTION';
export const LECTURE_PANEL_SURVEY_SET_ANSWER_CORRECT = 'LECTURE_PANEL_SURVEY_SET_ANSWER_CORRECT';
export const LECTURE_PANEL_SURVEY_ADD_ANSWER = 'LECTURE_PANEL_SURVEY_ADD_ANSWER';
export const LECTURE_PANEL_SURVEY_DELETE_ANSWER = 'LECTURE_PANEL_SURVEY_DELETE_ANSWER';
export const LECTURE_PANEL_SURVEY_SET_ANSWERS = 'LECTURE_PANEL_SURVEY_SET_ANSWERS';
export const LECTURE_PANEL_SURVEY_SET_QUESTIONS = 'LECTURE_PANEL_SURVEY_SET_QUESTIONS';
export const LECTURE_PANEL_SURVEY_ADD_QUESTION = 'LECTURE_PANEL_SURVEY_ADD_QUESTION';
export const LECTURE_PANEL_SURVEY_SET_QUESTION_REQUIRED = 'LECTURE_PANEL_SURVEY_SET_QUESTION_REQUIRED';
export const LECTURE_PANEL_SURVEY_SET_CREATING_LOADING = 'LECTURE_PANEL_SURVEY_SET_CREATING_LOADING';

export const LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS = 'LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS';
export const LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS_LOADING = 'LECTURE_PANEL_SURVEY_SET_ADMIN_RESULTS_LOADING';
export const LECTURE_PANEL_SURVEY_SET_ADMIN_RESULT_LOADING = 'LECTURE_PANEL_SURVEY_SET_ADMIN_RESULT_LOADING';
export const LECTURE_PANEL_SURVEY_DELETE_ADMIN_RESULT = 'LECTURE_PANEL_SURVEY_DELETE_ADMIN_RESULT';

// [ADMIN] LecturePanel
export const LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_UPDATE = 'LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_UPDATE';
export const LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_TITLE = 'LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_TITLE';

export const LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE = 'LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE';
export const LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE = 'LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE';

export const LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS = 'LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS';
export const LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS = 'LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS';

export const LECTURE_PANEL_ADMIN_SET_LECTURE_TITLE = 'LECTURE_PANEL_ADMIN_SET_LECTURE_TITLE';
export const LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE = 'LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE';

export const LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION = 'LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION';
export const LECTURE_PANEL_ADMIN_SET_LECTURE_DESCRIPTION = 'LECTURE_PANEL_ADMIN_SET_LECTURE_DESCRIPTION';

export const LECTURE_PANEL_ADMIN_SET_MENU_VISIBLE = 'LECTURE_PANEL_ADMIN_SET_MENU_VISIBLE';

export const LECTURE_PANEL_ADMIN_SET_MENU_LECTURE_TITLE = 'LECTURE_PANEL_ADMIN_SET_MENU_LECTURE_TITLE';
export const LECTURE_PANEL_ADMIN_ADD_MENU_SECTION = 'LECTURE_PANEL_ADMIN_ADD_MENU_SECTION';
export const LECTURE_PANEL_ADMIN_DELETE_MENU_SECTION = 'LECTURE_PANEL_ADMIN_DELETE_MENU_SECTION';
export const LECTURE_PANEL_ADMIN_ADD_MENU_LECTURE = 'LECTURE_PANEL_ADMIN_ADD_MENU_LECTURE';
export const LECTURE_PANEL_ADMIN_DELETE_MENU_LECTURE = 'LECTURE_PANEL_ADMIN_DELETE_MENU_LECTURE';

export const LECTURE_PANEL_ADMIN_SET_SECTION_TITLE = 'LECTURE_PANEL_ADMIN_SET_SECTION_TITLE';
export const LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE_LOADING = 'LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE_LOADING';

export const LECTURE_PANEL_ADMIN_ADD_EMBED_PAGE = 'LECTURE_PANEL_ADMIN_ADD_EMBED_PAGE';
export const LECTURE_PANEL_ADMIN_DELETE_EMBED_PAGE = 'LECTURE_PANEL_ADMIN_DELETE_EMBED_PAGE';

export const LECTURE_PANEL_ADMIN_ADD_DOCUMENTATION = 'LECTURE_PANEL_ADMIN_ADD_DOCUMENTATION';
export const LECTURE_PANEL_ADMIN_DELETE_DOCUMENTATION = 'LECTURE_PANEL_ADMIN_DELETE_DOCUMENTATION';
export const LECTURE_PANEL_ADMIN_SET_DOCUMENTATIONS = 'LECTURE_PANEL_ADMIN_SET_DOCUMENTATIONS';

export const LECTURE_PANEL_ADMIN_ADD_DOWNLOAD_FILE = 'LECTURE_PANEL_ADMIN_ADD_DOWNLOAD_FILE';
export const LECTURE_PANEL_ADMIN_DELETE_DOWNLOAD_FILE = 'LECTURE_PANEL_ADMIN_DELETE_DOWNLOAD_FILE';
export const LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILES = 'LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILES';
export const LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILE_TITLE = 'LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILE_TITLE';

export const LECTURE_PANEL_ADMIN_ADD_ADDITIONAL = 'LECTURE_PANEL_ADMIN_ADD_ADDITIONAL';
export const LECTURE_PANEL_ADMIN_DELETE_ADDITIONAL = 'LECTURE_PANEL_ADMIN_DELETE_ADDITIONAL';
export const LECTURE_PANEL_ADMIN_SET_ADDITIONALS = 'LECTURE_PANEL_ADMIN_SET_ADDITIONALS';

export const LECTURE_PANEL_ADMIN_ADD_VIDEO = 'LECTURE_PANEL_ADMIN_ADD_VIDEO';
export const LECTURE_PANEL_ADMIN_DELETE_VIDEO = 'LECTURE_PANEL_ADMIN_DELETE_VIDEO';

export const LECTURE_PANEL_ADMIN_SECTION_TITLE_CHECK = 'LECTURE_PANEL_ADMIN_SECTION_TITLE_CHECK';
export const LECTURE_PANEL_ADMIN_NEW_SECTION_TITLE_CHECK = 'LECTURE_PANEL_ADMIN_NEW_SECTION_TITLE_CHECK';
export const LECTURE_PANEL_ADMIN_LECTURE_TITLE_CHECK = 'LECTURE_PANEL_ADMIN_LECTURE_TITLE_CHECK';
export const LECTURE_PANEL_ADMIN_LECTURE_DESCRIPTION_CHECK = 'LECTURE_PANEL_ADMIN_LECTURE_DESCRIPTION_CHECK';

// ------------- //
// *** ADMIN *** //
// ------------- //
// User
export const USER_ADMIN_PANEL_SET_LOADING = 'USER_ADMIN_PANEL_SET_LOADING';
export const USER_ADMIN_PANEL_SET_HAS_NEXT = 'USER_ADMIN_PANEL_SET_HAS_NEXT';
export const USER_ADMIN_PANEL_SET_PAGING_PAGE = 'USER_ADMIN_PANEL_SET_PAGING_PAGE';
export const USER_ADMIN_PANEL_SET_USERS = 'USER_ADMIN_PANEL_SET_USERS';
export const USER_ADMIN_PANEL_ADD_USERS = 'USER_ADMIN_PANEL_ADD_USERS';
export const USER_ADMIN_PANEL_DELETE_USER = 'USER_ADMIN_PANEL_DELETE_USER';
export const USER_ADMIN_PANEL_UPDATE_USER = 'USER_ADMIN_PANEL_UPDATE_USER';
export const USER_ADMIN_PANEL_UPDATE_USER_STATUS = 'USER_ADMIN_PANEL_UPDATE_USER_STATUS';
export const USER_ADMIN_PANEL_UPDATE_USER_PICTURE = 'USER_ADMIN_PANEL_UPDATE_USER_PICTURE';
export const USER_ADMIN_PANEL_SET_SEARCH_VALUE = 'USER_ADMIN_PANEL_SET_SEARCH_VALUE';
export const USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY = 'USER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY';

// Course
export const COURSE_ADMIN_PANEL_SET_LOADING = 'COURSE_ADMIN_PANEL_SET_LOADING';
export const COURSE_ADMIN_PANEL_SET_HAS_NEXT = 'COURSE_ADMIN_PANEL_SET_HAS_NEXT';
export const COURSE_ADMIN_PANEL_SET_PAGING_PAGE = 'COURSE_ADMIN_PANEL_SET_PAGING_PAGE';
export const COURSE_ADMIN_PANEL_SET_COURSES = 'COURSE_ADMIN_PANEL_SET_COURSES';
export const COURSE_ADMIN_PANEL_ADD_COURSES = 'COURSE_ADMIN_PANEL_ADD_COURSES';
export const COURSE_ADMIN_PANEL_DELETE_COURSE = 'COURSE_ADMIN_PANEL_DELETE_COURSE';
export const COURSE_ADMIN_PANEL_UPDATE_COURSE = 'COURSE_ADMIN_PANEL_UPDATE_COURSE';
export const COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS = 'COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS';
export const COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL = 'COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL';
export const COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE = 'COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE';
export const COURSE_ADMIN_PANEL_SET_SEARCH_VALUE = 'COURSE_ADMIN_PANEL_SET_SEARCH_VALUE';

export const COURSE_ADMIN_PANEL_ADD_USER_COURSE = 'COURSE_ADMIN_PANEL_ADD_USER_COURSE';
export const COURSE_ADMIN_PANEL_DELETE_USER_COURSE = 'COURSE_ADMIN_PANEL_DELETE_USER_COURSE';

// Category
export const CATEGORY_ADMIN_PANEL_SET_LOADING = 'CATEGORY_ADMIN_PANEL_SET_LOADING';
export const CATEGORY_ADMIN_PANEL_SET_CATEGORIES = 'CATEGORY_ADMIN_PANEL_SET_CATEGORIES';
export const CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE = 'CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY_PICTURE';
export const CATEGORY_ADMIN_PANEL_ADD_CATEGORY = 'CATEGORY_ADMIN_PANEL_ADD_CATEGORY';
export const CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY = 'CATEGORY_ADMIN_PANEL_UPDATE_CATEGORY';
export const CATEGORY_ADMIN_PANEL_DELETE_CATEGORY = 'CATEGORY_ADMIN_PANEL_DELETE_CATEGORY';

// Voucher
export const VOUCHER_ADMIN_PANEL_SET_LOADING = 'VOUCHER_ADMIN_PANEL_SET_LOADING';
export const VOUCHER_ADMIN_PANEL_SET_HAS_NEXT = 'VOUCHER_ADMIN_PANEL_SET_HAS_NEXT';
export const VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE = 'VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE';
export const VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE = 'VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE';
export const VOUCHER_ADMIN_PANEL_SET_VOUCHERS = 'VOUCHER_ADMIN_PANEL_SET_VOUCHERS';
export const VOUCHER_ADMIN_PANEL_ADD_VOUCHERS = 'VOUCHER_ADMIN_PANEL_ADD_VOUCHERS';
export const VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING = 'VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING';
export const VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE = 'VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE';
export const VOUCHER_ADMIN_PANEL_DELETE_VOUCHER = 'VOUCHER_ADMIN_PANEL_DELETE_VOUCHER';
export const VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER = 'VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER';
export const VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER = 'VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER';
export const VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER = 'VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER';
export const VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER = 'VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER';

// Tracer
export const TRACER_ADMIN_PANEL_SET_LOADING = 'TRACER_ADMIN_PANEL_SET_LOADING';
export const TRACER_ADMIN_PANEL_SET_TRACERS = 'TRACER_ADMIN_PANEL_SET_TRACERS';
export const TRACER_ADMIN_PANEL_SET_SEARCH_VALUE = 'TRACER_ADMIN_PANEL_SET_SEARCH_VALUE';
export const TRACER_ADMIN_PANEL_SET_TRACER_LOADING = 'TRACER_ADMIN_PANEL_SET_TRACER_LOADING';
export const TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED = 'TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED';
export const TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY = 'TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY';