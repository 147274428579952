import Axios from "axios";
import { ADMIN_COURSE_MENU_TYPE } from "../../../../resources/constants/adminPanel";
import { SPECIFICATION_TYPE } from "../../../../resources/constants/specificationType";
import { convertDurationToHour, createNotification } from "../../../../Utils";
import { COURSE_ADMIN_PANEL_ADD_COURSES, COURSE_ADMIN_PANEL_DELETE_COURSE, COURSE_ADMIN_PANEL_UPDATE_COURSE, COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL, COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE, COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS, COURSE_ADMIN_POPUP_ADD_OBJECTIVE, COURSE_ADMIN_POPUP_ADD_PREREQUISITE, COURSE_ADMIN_POPUP_ADD_PUBLIC, COURSE_ADMIN_POPUP_DELETE_CATEGORY, COURSE_ADMIN_POPUP_DELETE_SPECIFICATION, COURSE_ADMIN_POPUP_DESCRIPTION_CHECK, COURSE_ADMIN_POPUP_EXPIRY_CHECK, COURSE_ADMIN_POPUP_OBJECTIVE_CHECK, COURSE_ADMIN_POPUP_PREREQUISITE_CHECK, COURSE_ADMIN_POPUP_PREVIEW_VIDEO_CHECK, COURSE_ADMIN_POPUP_PRICE_CHECK, COURSE_ADMIN_POPUP_PUBLIC_CHECK, COURSE_ADMIN_POPUP_SET_CATEGORIES, COURSE_ADMIN_POPUP_SET_CATEGORIES_LOADING, COURSE_ADMIN_POPUP_SET_CATEGORY_LOADING, COURSE_ADMIN_POPUP_SET_COURSE, COURSE_ADMIN_POPUP_SET_COURSE_ID, COURSE_ADMIN_POPUP_SET_ENABLED, COURSE_ADMIN_POPUP_SET_HAS_NEXT, COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING, COURSE_ADMIN_POPUP_SET_LOADING, COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE, COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE, COURSE_ADMIN_POPUP_SET_NEW_PUBLIC, COURSE_ADMIN_POPUP_SET_OBJECTIVES, COURSE_ADMIN_POPUP_SET_PAGING_PAGE, COURSE_ADMIN_POPUP_SET_PICTURE, COURSE_ADMIN_POPUP_SET_PICTURE_LOADING, COURSE_ADMIN_POPUP_SET_POPUP_TITLE, COURSE_ADMIN_POPUP_SET_PREREQUISITES, COURSE_ADMIN_POPUP_SET_PUBLICS, COURSE_ADMIN_POPUP_SET_SEO_LOCKED, COURSE_ADMIN_POPUP_SET_SPECIFICATIONS_LOADING, COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING, COURSE_ADMIN_POPUP_SET_STATUS, COURSE_ADMIN_POPUP_SET_STATUS_ENABLED, COURSE_ADMIN_POPUP_SET_USERS, COURSE_ADMIN_POPUP_SET_USERS_LOADING, COURSE_ADMIN_POPUP_TITLE_CHECK, COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS } from "../../ActionTypes";
import i18n from 'i18next';

const checkCourseInformations = (courseAdminStore) => async dispatch => {
    const isTitleValid = courseAdminStore.title && courseAdminStore.title.length >= 3 && courseAdminStore.title.length <= 200;
    const isDescriptionValid = courseAdminStore.description && courseAdminStore.description.length >= 3 && courseAdminStore.description.length <= 30000;
    const isPreviewVideoValid = !courseAdminStore.previewVideo || (courseAdminStore.previewVideo.length >= 3 && courseAdminStore.previewVideo.length <= 30000)
    const isExpiryDurationValid = courseAdminStore.expiryDurationValue >= 1 && (courseAdminStore.expiryDurationUnit !== null);
    const isPriceValid = !courseAdminStore.price || courseAdminStore.price >= 0;

    dispatch({ type: COURSE_ADMIN_POPUP_TITLE_CHECK, payload: { isValid: isTitleValid } });
    dispatch({ type: COURSE_ADMIN_POPUP_DESCRIPTION_CHECK, payload: { isValid: isDescriptionValid } });
    dispatch({ type: COURSE_ADMIN_POPUP_PREVIEW_VIDEO_CHECK, payload: { isValid: isPreviewVideoValid } });
    dispatch({ type: COURSE_ADMIN_POPUP_EXPIRY_CHECK, payload: { isValid: isExpiryDurationValid } });
    dispatch({ type: COURSE_ADMIN_POPUP_PRICE_CHECK, payload: { isValid: isPriceValid } });

    return isTitleValid && isDescriptionValid && isPreviewVideoValid && isExpiryDurationValid && isPriceValid;
}

export const openCourseAdminPopup = (courseId = null) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_COURSE_MENU_TYPE.OVERVIEW } });

    if (courseId) {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_COURSE_ID, payload: { courseId } });
    } else {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: 'Création d\'une formation' } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        dispatch({ type: COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: false } });
    }
}

export const loadCourseAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
    dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`course/${courseId}/full`)
        .then(({ data }) => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_COURSE, payload: { course: data } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: `${data.title}` } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const createCourseAdminPopup = (courseAdminStore) => async dispatch => {
    if (await dispatch(checkCourseInformations(courseAdminStore))) {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.post(`course/add/admin`, {
            title: courseAdminStore.title,
            description: courseAdminStore.description,
            previewVideo: courseAdminStore.previewVideo || null,
            unitCompleteLock: courseAdminStore.unitCompleteLock,
            expiryDurationHour: convertDurationToHour(courseAdminStore.expiryDurationValue, courseAdminStore.expiryDurationUnit),
            price: courseAdminStore.price || null,
            seoKeywords: courseAdminStore.seoKeyword,
            lang: courseAdminStore.lang?.value,
        }).then(({ data }) => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_COURSE, payload: { course: data } });
            dispatch({ type: COURSE_ADMIN_PANEL_ADD_COURSES, payload: { courses: [data] } });
            dispatch({ type: COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const updateCourseAdminPopup = (courseAdminStore) => async dispatch => {
    if (await dispatch(checkCourseInformations(courseAdminStore))) {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.put(`course/${courseAdminStore.courseId}/update/admin`, {
            title: courseAdminStore.title,
            description: courseAdminStore.description,
            previewVideo: courseAdminStore.previewVideo || null,
            unitCompleteLock: courseAdminStore.unitCompleteLock,
            expiryDurationHour: convertDurationToHour(courseAdminStore.expiryDurationValue, courseAdminStore.expiryDurationUnit),
            price: courseAdminStore.price || null,
            seoKeywords: courseAdminStore.seoKeyword,
            lang: courseAdminStore.lang?.value,
            otherLanguageLinkedCourse: courseAdminStore.otherLangId
        }).then(({ data }) => {
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_SUCCESS_ADMIN"), 'success');
            dispatch({ type: COURSE_ADMIN_POPUP_SET_COURSE, payload: { course: data } });
            dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE, payload: { id: courseAdminStore.courseId, course: data } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const updateStatusCourseAdminPopup = (courseId, enabled) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_STATUS_ENABLED, payload: { enabled } });
    dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS, payload: { id: courseId, enabled } });

    Axios.put(`course/${courseId}/update/enabled/admin`, {
        isEnabled: enabled
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        dispatch({ type: COURSE_ADMIN_POPUP_SET_STATUS_ENABLED, payload: { enabled: !enabled } });
        dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE_STATUS, payload: { id: courseId, enabled: !enabled } });
    });
}

export const updateCourseModelAdminPopup = (courseId, model) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    return Axios.put(`course/${courseId}/update/model/admin`, {
        isModel: model
    }).then(() => {
        dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE_MODEL, payload: { id: courseId, model } });
        createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_SUCCESS_ADMIN"), 'success');
        return true;
    }).finally(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const updateCourseStatusAdminPopup = (courseId, status) => async dispatch => {
    Axios.put(`course/${courseId}/update/status/admin`, {
        status
    }).then(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_STATUS, payload: { status } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const uploadCoursePictureAdminPopup = (courseId, file) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    const formData = new FormData();
    formData.append('Picture', file);

    Axios.put(`course/${courseId}/update/picture/admin`, formData, {
        headers: { 'content-type': 'multipart/form-data' }
    }).then(({ data }) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE, payload: { picture: data.picture } });
        dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE, payload: { id: courseId, picture: data.picture } });
    }).finally(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
    });
}

export const deleteCoursePictureAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    Axios.delete(`course/${courseId}/delete/picture/admin`)
        .then(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE, payload: { picture: null } });
            dispatch({ type: COURSE_ADMIN_PANEL_UPDATE_COURSE_PICTURE, payload: { id: courseId, picture: null } });
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
        });
}

export const deleteCourseAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.delete(`course/${courseId}/delete/admin`)
        .then(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
            dispatch({ type: COURSE_ADMIN_PANEL_DELETE_COURSE, payload: { id: courseId } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_COURSE_DELETED"), 'success');
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const loadSpecificationsCourseAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATIONS_LOADING, payload: { isLoading: true } });

    Axios.get(`specification/${courseId}/list/all/admin`)
        .then(({ data }) => {
            const { objectives, publics, prerequisites } = data;

            dispatch({ type: COURSE_ADMIN_POPUP_SET_OBJECTIVES, payload: { objectives } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PUBLICS, payload: { publics } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PREREQUISITES, payload: { prerequisites } });
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATIONS_LOADING, payload: { isLoading: false } });
        });
}

export const addSpecificationCourseAdminPopup = (courseId, label, type) => async dispatch => {
    let isValid = true;

    if (type === SPECIFICATION_TYPE.OBJECTIVE) {
        isValid = label && label.length >= 3 && label.length <= 200;
        dispatch({ type: COURSE_ADMIN_POPUP_OBJECTIVE_CHECK, payload: { isValid } });
    }

    if (type === SPECIFICATION_TYPE.PUBLIC) {
        isValid = label && label.length >= 3 && label.length <= 200;
        dispatch({ type: COURSE_ADMIN_POPUP_PUBLIC_CHECK, payload: { isValid } });
    }

    if (type === SPECIFICATION_TYPE.PREREQUISITE) {
        isValid = label && label.length >= 3 && label.length <= 200;
        dispatch({ type: COURSE_ADMIN_POPUP_PREREQUISITE_CHECK, payload: { isValid } });
    }

    if (!isValid)
        return;

    dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING, payload: { isLoading: true } });
    Axios.post(`specification/add/admin`, {
        courseId,
        label,
        type
    }).then(({ data }) => {
        switch (type) {
            case SPECIFICATION_TYPE.OBJECTIVE:
                dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE, payload: { newObjective: '' } });
                dispatch({ type: COURSE_ADMIN_POPUP_ADD_OBJECTIVE, payload: { objective: data } });
                break;
            case SPECIFICATION_TYPE.PUBLIC:
                dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_PUBLIC, payload: { newPublic: '' } });
                dispatch({ type: COURSE_ADMIN_POPUP_ADD_PUBLIC, payload: { public: data } });
                break;
            case SPECIFICATION_TYPE.PREREQUISITE:
                dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE, payload: { newPrerequisite: '' } });
                dispatch({ type: COURSE_ADMIN_POPUP_ADD_PREREQUISITE, payload: { prerequisite: data } });
                break;
            default:
                break;
        }
    }).finally(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING, payload: { isLoading: false } });
    });
}

export const updatePositionSpecificationCourseAdminPopup = (specificationId, position) => async dispatch => {
    Axios.put(`specification/position/change/admin`, {
        id: specificationId,
        level: position + 1
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteSpecificationCourseAdminPopup = (specificationId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING, payload: { isLoading: true } });

    Axios.delete(`specification/${specificationId}/delete/admin`)
        .then(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_DELETE_SPECIFICATION, payload: { id: specificationId } });
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_SPECIFICATION_ACTION_LOADING, payload: { isLoading: false } });
        });
}

export const loadCategoriesCourseAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORIES_LOADING, payload: { isLoading: true } });

    Axios.get(`coursecategory/${courseId}/list/all/admin`)
        .then(({ data }) => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORIES, payload: { categories: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORIES_LOADING, payload: { isLoading: false } });
        })
}

export const deleteCategoryCourseAdminPopup = (courseCategoryId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORY_LOADING, payload: { id: courseCategoryId, isLoading: true } });

    Axios.delete(`coursecategory/${courseCategoryId}/delete/admin`)
        .then(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_DELETE_CATEGORY, payload: { id: courseCategoryId } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORY_LOADING, payload: { id: courseCategoryId, isLoading: false } });
        });
}


export const loadUsersCourseAdminPopup = (courseId, pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_USERS_LOADING, payload: { isLoading: true } });

    Axios.get(`course/${courseId}/students/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USERS, payload: { users: data.paging } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USERS_LOADING, payload: { isLoading: false } });
    });
}

export const updateCourseLock = (courseId, locked) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_SEO_LOCKED, payload: { locked } });

    Axios.put(`course/${courseId}/update/lock/admin`, {
        locked
    }).catch(() => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_SEO_LOCKED, payload: { locked: !locked } });
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}