import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import CourseAdminItem from './CourseAdminItem';
import { useTranslation } from 'react-i18next';

const CourseAdminList = () => {
    const { t } = useTranslation()
    const courseAdminStore = useSelector(store => store.courseAdminPanel);

    if (!courseAdminStore.isLoading && (!courseAdminStore.courses || !courseAdminStore.courses.length))
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {courseAdminStore.courses.map(course => <CourseAdminItem key={course.id} course={course} />)}

            {courseAdminStore.isLoading && <>
                <div className='item'><BasicLoader width={'100%'} height={250} radius={10} /></div>
                <div className='item'><BasicLoader width={'100%'} height={250} radius={10} /></div>
                <div className='item'><BasicLoader width={'100%'} height={250} radius={10} /></div>
            </>}
        </div>
    );
}

export default CourseAdminList;