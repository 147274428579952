import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Input';
import { SIGN_POPUP_SET_EMAIL, SIGN_POPUP_SET_FIRSTNAME, SIGN_POPUP_SET_LASTNAME } from '../../redux/actions/ActionTypes';
import { isValidEmail } from '../../Utils';
import { registerEmailFree } from '../../redux/actions/popup/SignActions';
import { useTranslation } from 'react-i18next';

const RegisterPopupGeneral = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    const [emailInputTimer, setEmailInputTimer] = useState(null);

    const emailChange = (email) => {
        clearTimeout(emailInputTimer);
        dispatch({ type: SIGN_POPUP_SET_EMAIL, payload: { email } });

        if (isValidEmail(email))
            setEmailInputTimer(setTimeout(() => dispatch(registerEmailFree(email)), 500));
    }

    return (
        <div>
            <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={signStore.email} onChange={emailChange} type={'text'} autocomplete={'email'} disabled={signStore.isLoading} error={!signStore.isEmailValid} helperText={signStore.emailHelperText} success={signStore.isEmailFree === true} />

            <div className='col-2 name'>
                <Input title={t("FIRSTNAME")} placeholder={t("FIRSTNAME")} value={signStore.firstname} onChange={(firstname) => dispatch({ type: SIGN_POPUP_SET_FIRSTNAME, payload: { firstname } })} type={'text'} autocomplete={'given-name'} disabled={signStore.isLoading} error={!signStore.isFirstnameValid} helperText={t("HELPER_FIRSTNAME")} />
                <Input title={t("LASTNAME")} placeholder={t("LASTNAME")} value={signStore.lastname} onChange={(lastname) => dispatch({ type: SIGN_POPUP_SET_LASTNAME, payload: { lastname } })} type={'text'} autocomplete={'family-name'} disabled={signStore.isLoading} error={!signStore.isLastnameValid} helperText={t("HELPER_LASTNAME")} />
            </div>
        </div>
    );
}

export default RegisterPopupGeneral;