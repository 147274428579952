import Axios from "axios";
import { getTreeFromFlatData } from "react-sortable-tree";
import { createNotification } from "../../../Utils";
import { CATEGORY_ADMIN_PANEL_SET_CATEGORIES, CATEGORY_ADMIN_PANEL_SET_LOADING } from "../ActionTypes"
import i18n from 'i18next';

export const loadCategoryAdmin = () => async dispatch => {
    dispatch({ type: CATEGORY_ADMIN_PANEL_SET_LOADING, payload: { isLoading: true } });
    dispatch({ type: CATEGORY_ADMIN_PANEL_SET_CATEGORIES, payload: { categories: [] } });

    return Axios.get(`category/all`)
        .then(({ data }) => {
            dispatch({
                type: CATEGORY_ADMIN_PANEL_SET_CATEGORIES, payload: {
                    categories: getTreeFromFlatData({
                        flatData: data,
                        getKey: c => c.id,
                        getParentKey: c => c.parentCategoryid,
                        rootKey: null
                    })
                }
            });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: CATEGORY_ADMIN_PANEL_SET_LOADING, payload: { isLoading: false } });
        });
}

export const updateCategoryPositionAdmin = (categoryId, position, parentCategoryId = null) => async dispatch => {
    Axios.put(`category/position/change/admin`, {
        id: categoryId,
        level: position + 1,
        parentId: parentCategoryId
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}