import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_COURSE_ADMIN_POPUP_RESET, ASSIGN_COURSE_ADMIN_POPUP_SET_COURSES, ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_COURSE_ADMIN_POPUP_SET_SEARCH_VALUE, COURSE_ADMIN_POPUP_SET_ENABLED, USER_ADMIN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { loadCourseAdminPopup } from '../../redux/actions/popup/admin/AssignCoursePopupActions';
import { ADMIN_COURSE_MENU_TYPE } from '../../resources/constants/adminPanel';
import CourseListAssignCourseAdminPopup from './assignCourse/CourseListAssignCourseAdminPopup';

const AssignCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const assignCourseAdminStore = useSelector(store => store.assignCourseAdminPopup);

    useEffect(() => {
        dispatch(loadCourseAdminPopup(0, null));

        return () => {
            dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const createNewSection = () => {
        dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_COURSE_MENU_TYPE.OVERVIEW } });
    }

    const onTyping = (value) => {
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_COURSES, payload: { courses: [] } });
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: 0 } });
        dispatch(loadCourseAdminPopup(0, value));
    }

    return (
        <div className={`assign-course-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignCourseAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer une formation' btnStyle='primary' onClick={createNewSection} />
            </div>

            <div className='section'>
                <CourseListAssignCourseAdminPopup />

                {assignCourseAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={assignCourseAdminStore.isLoading} onClick={() => dispatch(loadCourseAdminPopup(assignCourseAdminStore.pagingPage + 1, assignCourseAdminStore.searchValue))} /></div>}
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignCourseAdminPopup, 'Assigner une formation', 'Création ou ajout d\'une formation', null, null, null, {
    storeName: 'assignCourseAdminPopup',
    completion: (dispatch, store) => {
        if (!store.isLoading && store.hasNext) {
            dispatch(loadCourseAdminPopup(store.pagingPage + 1, store.searchValue));
        }
    }
});