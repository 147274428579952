import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Sitemap from '../../resources/constants/sitemap.json';
import { useTranslation } from 'react-i18next';

const SitemapPanel = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Helmet>
                <title>{t("SITEMAP")} · FormationAcademy</title>
                <meta name="description" content="Sitemap de FormationAcademy." />
            </Helmet>

            <div className='page-container-wrapper basic-panel other-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations rounded-xl'>
                            <h1>{t("SITEMAP")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        <h2>Catégories</h2>
                        <div className='sitemap-items'>
                            {Sitemap.categories.map((c, i) => <Link key={i} to={c.Link}>{c.Title}</Link>)}
                        </div>

                        <h2>Formations</h2>
                        <div className='sitemap-items'>
                            {Sitemap.courses.map((c, i) => <Link key={i} to={c.Link}>{c.Title}</Link>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SitemapPanel;