import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LECTURE_PANEL_SURVEY_TOGGLE_SELECT_ANSWER } from '../../../../redux/actions/ActionTypes';

const LectureSurveyRunAnswer = ({ answer, currentQuestion, index }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onKeyDown = (key) => {
        if(key.key === `${index}`) {
            dispatch({ type: LECTURE_PANEL_SURVEY_TOGGLE_SELECT_ANSWER, payload: { questionId: currentQuestion.id, answerId: answer.id } })
        }
    }

    return (
        <div key={answer.id} className={`answer transition rounded-m ${currentQuestion.selectedAnswers.includes(answer.id) ? 'selected' : ''}`} onClick={() => dispatch({ type: LECTURE_PANEL_SURVEY_TOGGLE_SELECT_ANSWER, payload: { questionId: currentQuestion.id, answerId: answer.id } })}>
            <div className='key-value'>
                <span>{index}</span>
            </div>

            <div className='title'>{answer.title}</div>
        </div>
    );
}

export default LectureSurveyRunAnswer;