import { ADMIN_CATEGORY_MENU } from "../../../../resources/constants/adminPanel";
import { CATEGORY_ADMIN_POPUP_RESET, CATEGORY_ADMIN_POPUP_SEO_KEYWORDS, CATEGORY_ADMIN_POPUP_SET_CATEGORY, CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID, CATEGORY_ADMIN_POPUP_SET_COURSES, CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING, CATEGORY_ADMIN_POPUP_SET_ENABLED, CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING, CATEGORY_ADMIN_POPUP_SET_LOADING, CATEGORY_ADMIN_POPUP_SET_PICTURE, CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING, CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE, CATEGORY_ADMIN_POPUP_SET_TITLE, CATEGORY_ADMIN_POPUP_SET_TITLE_EN, CATEGORY_ADMIN_POPUP_TITLE_CHECK, CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK, CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: null,
    isLoading: false,

    menu: ADMIN_CATEGORY_MENU,

    categoryId: null,
    popupTitle: 'Chargement',

    // Informations
    title: '',
    titleEN: '',
    picture: null,
    seoKeyword: [],

    isTitleValid: true,
    isTitleENValid: true,

    isInformationsLoading: false,
    isPictureLoading: false,

    // Courses
    courses: [],
    isCoursesLoading: true,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case CATEGORY_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case CATEGORY_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.type };
        }
        case CATEGORY_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case CATEGORY_ADMIN_POPUP_SET_POPUP_TITLE: {
            return { ...state, popupTitle: action.payload.title };
        }
        case CATEGORY_ADMIN_POPUP_SEO_KEYWORDS: {
            return { ...state, seoKeyword: action.payload.seoKeyword };
        }

        case CATEGORY_ADMIN_POPUP_SET_INFORMATIONS_LOADING: {
            return { ...state, isInformationsLoading: action.payload.isLoading };
        }
        case CATEGORY_ADMIN_POPUP_SET_PICTURE_LOADING: {
            return { ...state, isPictureLoading: action.payload.isLoading };
        }
        case CATEGORY_ADMIN_POPUP_SET_COURSES_LOADING: {
            return { ...state, isCoursesLoading: action.payload.isLoading };
        }
        case CATEGORY_ADMIN_POPUP_SET_CATEGORY_ID: {
            return { ...state, categoryId: action.payload.id };
        }

        case CATEGORY_ADMIN_POPUP_TOGGLE_MENU_STATUS: {
            return {
                ...state, menu: state.menu.map(i => {
                    if (i.disabled === undefined)
                        return i;

                    i.disabled = !action.payload.enable
                    return i;
                })
            };
        }

        case CATEGORY_ADMIN_POPUP_SET_PICTURE: {
            return { ...state, picture: action.payload.picture };
        }
        case CATEGORY_ADMIN_POPUP_SET_CATEGORY: {
            const { category } = action.payload;
            return {
                ...state,
                title: category.title,
                titleEN: category.titleEN,
                picture: category.picture,
                seoKeyword: category.seoKeyword.map(k => k.label)
            }
        }

        case CATEGORY_ADMIN_POPUP_SET_TITLE: {
            return { ...state, title: action.payload.title, isTitleValid: true };
        }
        case CATEGORY_ADMIN_POPUP_SET_TITLE_EN: {
            return { ...state, titleEN: action.payload.title, isTitleENValid: true };
        }

        case CATEGORY_ADMIN_POPUP_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }

        // Check
        case CATEGORY_ADMIN_POPUP_TITLE_CHECK: {
            return { ...state, isTitleValid: action.payload.isValid };
        }
        case CATEGORY_ADMIN_POPUP_TITLE_EN_CHECK: {
            return { ...state, isTitleENValid: action.payload.isValid };
        }

        default:
            return state;
    }
}