import React, { useRef } from 'react';
import propTypes from 'prop-types';
import Button from '../../../Button';
import { useDispatch } from 'react-redux';
import { deleteResource } from '../../../redux/actions/admin/LectureAdminActions';
import { LECTURE_TYPE } from '../../../resources/constants/lectureType';

const LectureEmbedPage = ({ page, admin }) => {
    const dispatch = useDispatch();
    const iFrameRef = useRef(null);

    const onFullScreen = () => {
        if (iFrameRef) {
            const elem = iFrameRef.current;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        }
    }

    return (
        <div className='lecture-dynamic-content-container rounded-xl shadow'>
            <iframe ref={iFrameRef} src={page.link} title={'labs'}></iframe>

            <div className='actions'>
                {admin && <div className='action white shadow delete'><Button title={'Supprimer le lien'} btnStyle={'autofit cancel'} confirmationRequired onClick={() => dispatch(deleteResource(page.id, LECTURE_TYPE.EMBEDPAGE))} /></div>}
                <a href={page.link} target={'_blank'} rel="noopener noreferrer" className='action white shadow' ><div className='icon external'></div></a>
                <div className='action white shadow' onClick={onFullScreen}><div className='icon full-screen'></div></div>
                {admin && <div className='action white shadow sortable'><div className='icon more'></div></div>}
            </div>
        </div>
    );
}

LectureEmbedPage.propTypes = {
    page: propTypes.object.isRequired,
    admin: propTypes.bool
};

export default LectureEmbedPage;