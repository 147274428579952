import React from 'react';
import propTypes from 'prop-types';
import Datepicker from '../../../Datepicker';
import { timeRemainingForNow } from '../../../Utils';
import { updateEndDateVoucherAdminPopup } from '../../../redux/actions/admin/VoucherAdminActions';
import { useDispatch } from 'react-redux';
import CourseAdminItemPeople from '../course/CourseAdminItemPeople';
import { openVoucherAdminPopup } from '../../../redux/actions/popup/admin/VoucherPopupActions';

const VoucherAdminItem = ({ voucher }) => {
    const dispatch = useDispatch();
    const expireDate = timeRemainingForNow(voucher.endDate);

    return (
        <div className={`item white shadow rounded-m thumbnail voucher-card ${voucher.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white' onClick={() => dispatch(openVoucherAdminPopup(voucher.id))}>
                    <div className='icon more'></div>
                </div>
            </div>
            <div className='img-main rounded-s text-shadow'>{voucher.code}</div>

            <div className='info'>
                <div className='flex-1'>
                    <div className='tiny-thumbnails'>
                        {voucher.voucherCourses?.map((voucherCourse, index) => <div key={index} className='thumb shadow'>
                            <div className='popover'>
                                <span className='name'>{voucherCourse.course.title}</span>
                                <div className='triangle'></div>
                            </div>
                            <img src={voucherCourse.course.picture} alt={voucherCourse.id} />
                        </div>)}
                    </div>

                    {voucher.usersVoucher && voucher.usersVoucher.length ? <CourseAdminItemPeople people={voucher.usersVoucher.map(uv => uv.user)} /> : null}
                </div>

                <div className='or'>
                    <hr />
                </div>

                <div className='col-2'>
                    <div className='quick-actions'>
                        <span className={`time-left ${expireDate.isExpired ? 'red' : 'green'}`}>{expireDate.str}</span>
                        <Datepicker component={<span className='modify'>Modifier</span>} selected={new Date(voucher.endDate)} onChange={(date) => dispatch(updateEndDateVoucherAdminPopup(voucher.id, date))} />
                    </div>

                    <div className='quick-actions'>
                        <span className={`time-left`}>{voucher.placesAvailable - voucher.placesLeft} / {voucher.placesAvailable}</span>
                        <span className='title'>Places utilisées</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

VoucherAdminItem.propTypes = {
    voucher: propTypes.object.isRequired
};

export default VoucherAdminItem;