import { ADMIN_VOUCHER_MENU } from "../../../../resources/constants/adminPanel";
import { VOUCHER_ADMIN_POPUP_DATES_CHECK, VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK, VOUCHER_ADMIN_POPUP_RESET, VOUCHER_ADMIN_POPUP_SET_ENABLED, VOUCHER_ADMIN_POPUP_SET_END_DATE, VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, VOUCHER_ADMIN_POPUP_SET_LOADING, VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE, VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE, VOUCHER_ADMIN_POPUP_SET_START_DATE, VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID, VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS, VOUCHER_ADMIN_POPUP_SET_VOUCHER, VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING, VOUCHER_ADMIN_POPUP_SET_COURSES, VOUCHER_ADMIN_POPUP_DELETE_COURSE, VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, VOUCHER_ADMIN_POPUP_ADD_COURSE, VOUCHER_ADMIN_POPUP_SET_USERS_LOADING, VOUCHER_ADMIN_POPUP_SET_USER_LOADING, VOUCHER_ADMIN_POPUP_SET_USERS, VOUCHER_ADMIN_POPUP_ADD_USERS, VOUCHER_ADMIN_POPUP_DELETE_USER, VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE, VOUCHER_ADMIN_POPUP_SET_HAS_NEXT, VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: null,
    isLoading: true,

    menu: ADMIN_VOUCHER_MENU,

    voucherId: null,
    popupTitle: 'Chargement',

    // Informations
    code: null,
    startDate: new Date(),
    endDate: new Date().setDate(new Date().getDate() + 1),
    placeAvailable: 1,

    isDatesValid: true,
    isPlaceAvailableValid: true,

    isInformationsLoading: false,

    // Courses
    courses: [],
    isCoursesLoading: true,

    // Users
    isUsersLoading: true,
    users: [],
    hasNext: true,
    pagingPage: 0,
    userSearchValue: ''
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case VOUCHER_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case VOUCHER_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.type };
        }
        case VOUCHER_ADMIN_POPUP_SET_POPUP_TITLE: {
            return { ...state, popupTitle: action.payload.title };
        }
        case VOUCHER_ADMIN_POPUP_TOGGLE_MENU_STATUS: {
            return {
                ...state, menu: state.menu.map(i => {
                    if (i.disabled === undefined)
                        return i;

                    i.disabled = !action.payload.enable
                    return i;
                })
            };
        }

        case VOUCHER_ADMIN_POPUP_SET_VOUCHER_ID: {
            return { ...state, voucherId: action.payload.voucherId };
        }
        case VOUCHER_ADMIN_POPUP_SET_VOUCHER: {
            const { voucher } = action.payload;
            return {
                ...state,
                voucherId: voucher.id,
                code: voucher.code,
                startDate: new Date(voucher.startDate),
                endDate: new Date(voucher.endDate),
                placeAvailable: voucher.placesAvailable
            };
        }

        case VOUCHER_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case VOUCHER_ADMIN_POPUP_SET_INFORMATIONS_LOADING: {
            return { ...state, isInformationsLoading: action.payload.isLoading };
        }
        case VOUCHER_ADMIN_POPUP_SET_COURSES_LOADING: {
            return { ...state, isCoursesLoading: action.payload.isLoading };
        }

        case VOUCHER_ADMIN_POPUP_SET_START_DATE: {
            return { ...state, startDate: action.payload.date, isDatesValid: true };
        }
        case VOUCHER_ADMIN_POPUP_SET_END_DATE: {
            return { ...state, endDate: action.payload.date, isDatesValid: true };
        }
        case VOUCHER_ADMIN_POPUP_SET_PLACE_AVAILABLE: {
            return { ...state, placeAvailable: action.payload.placeAvailable, isPlaceAvailableValid: true };
        }

        case VOUCHER_ADMIN_POPUP_DATES_CHECK: {
            return { ...state, isDatesValid: action.payload.isValid }
        }
        case VOUCHER_ADMIN_POPUP_PLACE_AVAILABLE_CHECK: {
            return { ...state, isPlaceAvailableValid: action.payload.isValid }
        }

        case VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING: {
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, isLoading: action.payload.isLoading };
                })
            }
        }
        case VOUCHER_ADMIN_POPUP_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case VOUCHER_ADMIN_POPUP_ADD_COURSE: {
            return { ...state, courses: [...state.courses, action.payload.course] };
        }
        case VOUCHER_ADMIN_POPUP_DELETE_COURSE: {
            return { ...state, courses: state.courses.filter(c => c.id !== action.payload.id) }
        }

        // Users
        case VOUCHER_ADMIN_POPUP_SET_USERS_LOADING: {
            return { ...state, isUsersLoading: action.payload.isLoading };
        }
        case VOUCHER_ADMIN_POPUP_SET_USER_LOADING: {
            return {
                ...state, users: state.users.map(u => {
                    if (u.id !== action.payload.userVoucherId)
                        return u;

                    return { ...u, isLoading: action.payload.isLoading };
                })
            }
        }
        case VOUCHER_ADMIN_POPUP_SET_USERS: {
            return { ...state, users: action.payload.users };
        }
        case VOUCHER_ADMIN_POPUP_ADD_USERS: {
            return { ...state, users: state.users.concat(action.payload.users) };
        }
        case VOUCHER_ADMIN_POPUP_DELETE_USER: {
            return { ...state, users: state.users.filter(u => u.id !== action.payload.userVoucherId) };
        }
        case VOUCHER_ADMIN_POPUP_SET_USER_SEARCH_VALUE: {
            return { ...state, userSearchValue: action.payload.userSearchValue };
        }
        case VOUCHER_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case VOUCHER_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}