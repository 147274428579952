import React from 'react';
import UserAdminPanelItemLoader from '../../../loader/UserAdminPanelItemLoader';
import { useSelector } from 'react-redux';
import UserAdminItem from './UserAdminItem';
import { useTranslation } from 'react-i18next';

const UserAdminList = () => {
    const userAdminStore = useSelector(store => store.userAdminPanel);
    const { t } = useTranslation()

    if (!userAdminStore.isLoading && !userAdminStore.users?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_USERS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {userAdminStore.users.map(user => <UserAdminItem key={user.id} user={user} />)}

            {userAdminStore.isLoading && <>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
                <div className='item user-card'><UserAdminPanelItemLoader /></div>
            </>}
        </div>
    );
}

export default UserAdminList;