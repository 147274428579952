import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import SurveyUserItemAdminPopup from './SurveyUserItemAdminPopup';
import { useTranslation } from 'react-i18next';

const SurveyUserListAdminPopup = () => {
    const userAdminStore = useSelector(store => store.userAdminPopup);
    const { t } = useTranslation();

    if (userAdminStore.isSurveysLoading)
        return (
            <div className='list'>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
                <div className='thumbnail horizontal loading'><BasicLoader width={400} height={100} radius={10} /></div>
            </div>
        );

    if (!userAdminStore.surveys || !userAdminStore.surveys.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_SURVEYS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {userAdminStore.surveys && userAdminStore.surveys.map((survey) => <SurveyUserItemAdminPopup key={survey.id} survey={survey} />)}
        </div>
    );
}

export default SurveyUserListAdminPopup;