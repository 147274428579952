import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../Button';
import PopupHOC from '../../HOC/PopupHOC';
import Input from '../../Input';
import PasswordStrength from '../../PasswordStrength';
import { SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD, SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD } from '../../redux/actions/ActionTypes';
import { resetPopupUpdatePassword } from '../../redux/actions/popup/SignActions';
import { useTranslation } from 'react-i18next';

const UpdatePasswordPopup = (props) => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(resetPopupUpdatePassword(signStore, props.match.params.token));
    }

    return (
        <div className='popup white rounded-l shadow padding voucher'>
            <div className='header'>
                <div className='title'>
                    <div className='icon-container'></div>
                    <h1>{t("SIGN_RESET")}</h1>
                    <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                </div>
                <span>{t("RESET_PASSWORD")}</span>
            </div>

            <div className='content'>
                <form onSubmit={onSubmit} className='reset-popup'>
                    <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={signStore.newPassword} onChange={(password) => dispatch({ type: SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={signStore.isLoading} error={!signStore.isNewPasswordValid} helperText={t("HELPER_PASSWORD_MUST_BE_STRONGER")} passwordToggle />
                    <PasswordStrength password={signStore.newPassword} />
                    <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={signStore.renewPassword} onChange={(password) => dispatch({ type: SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={signStore.isLoading} error={!signStore.isRenewPasswordValid} helperText={t("HELPER_PASSWORD_SAME")} passwordToggle />

                    <Button title={t("RETRIEVE_ACCOUNT")} btnStyle={'primary'} type={'submit'} isLoading={signStore.isLoading} />
                </form>
            </div>
        </div>
    );
}

export default PopupHOC(withRouter(UpdatePasswordPopup));