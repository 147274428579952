import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import DashboardCertificateItem from './DashboardCertificateItem';
import { useTranslation } from 'react-i18next';

const DashboardCertificateList = () => {
    const dashboardStore = useSelector(store => store.dashboardPanel);
    const { t } = useTranslation();

    if (dashboardStore.isCertificatesLoading)
        return (
            <div className='list certificates'>
                <div className='thumbnail loading horizontal'><BasicLoader width={'100%'} height={100} radius={10} /></div>
                <div className='thumbnail loading horizontal'><BasicLoader width={'100%'} height={100} radius={10} /></div>
                <div className='thumbnail loading horizontal'><BasicLoader width={'100%'} height={100} radius={10} /></div>
            </div>
        );

    if (!dashboardStore.certificates || !dashboardStore.certificates.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("DASHBOARD_NO_CERTIFICATES")}
            </div>
        );

    return (
        <div className='list certificates'>
            {dashboardStore.certificates.map(certificate => <DashboardCertificateItem key={certificate.id} certificate={certificate} />)}
        </div>
    );
}

export default DashboardCertificateList;