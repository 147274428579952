import React from 'react';
import propTypes from 'prop-types';
import PopupHOC from './PopupHOC';
import { useDispatch, useSelector } from 'react-redux';
import MenuAdminPopup from '../popup/admin/MenuAdminPopup';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const AdminPopupHOC = (WrappedComponent, title = null, subTitle, storeName = null, menuDispatchType = null, ExtraComponent = null, onBottom = null) => {
    const HOC = (props) => {
        const dispatch = useDispatch();
        const store = useSelector(store => store[storeName]);
        let onBottomStore = useSelector(store => store[onBottom ? onBottom.storeName : null]);
        
        let containerRef = useBottomScrollListener(onBottom ? () => {
            onBottom.completion(dispatch, onBottomStore)
        } : () => { });

        return (
            <div className='popup white rounded-l shadow padding admin' ref={containerRef}>
                <div className='header'>
                    <span>
                        {subTitle}
                    </span>

                    <div className='title'>
                        <h1>{title || store.popupTitle}</h1>
                        <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                    </div>
                </div>

                <div className='content'>
                    {store && store.menu ? <MenuAdminPopup isLoading={store.isLoading} menu={store.menu} dispatchType={menuDispatchType} selectedMenu={store.popupEnabled} ExtraComponent={ExtraComponent} /> : null}

                    <WrappedComponent />
                </div>
            </div>
        );
    }

    HOC.propTypes = {
        isLoading: propTypes.bool
    };

    return PopupHOC(HOC, 'admin');
}

export default AdminPopupHOC;