import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import IsLoggedHOC from '../../HOC/IsLoggedHOC';
import { LECTURE_PANEL_RESET } from '../../redux/actions/ActionTypes';
import { loadLectureMenu } from '../../redux/actions/dashboard/LectureActions';
import AuthenticationService from '../../services/Authentication';
import { convertStringForUrl } from '../../Utils';
import LectureContent from './lecture/LectureContent';
import LectureMenu from './lecture/LectureMenu';

const LecturePanel = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { userCourseId, title, lectureId } = props.match.params;

    useEffect(() => {
        const fetch = async () => {
            if (lectureStore.isMenuLoading || !lectureStore.menu) {
                try {
                    const data = await dispatch(loadLectureMenu(userCourseId, props.admin));

                    if (!props.admin && !props.match.params.lectureId)
                        history.replace(`/course/${userCourseId}-${convertStringForUrl(title)}/lecture/${data.lastLectureId}`);

                    if (props.admin && !props.match.params.lectureId && data.sections && data.sections[0] && data.sections[0].lectures[0] && data.sections[0].lectures[0].id) {
                        history.replace(`/course/${userCourseId}-${convertStringForUrl(title)}/lecture/${data.sections[0].lectures[0].id}/admin`);
                    }
                } catch {
                    history.replace('/dashboard');
                }
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [props.match.params, lectureStore.isMenuLoading])

    useEffect(() => {
        return () => {
            dispatch({ type: LECTURE_PANEL_RESET });
        }
    }, [dispatch])

    if (props.admin && (!AuthenticationService.IsLogged() || !AuthenticationService.IsAdmin()))
        return <Redirect to='/' />

    return (
        <div className='page-container-wrapper basic-panel lecture-panel'>
            <div className='container'>
                <LectureMenu admin={props.admin} />

                <LectureContent lectureId={lectureId} userCourseId={userCourseId} admin={props.admin} />
            </div>
        </div>
    );
}

export default IsLoggedHOC(LecturePanel);