import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_COURSE_ADMIN_POPUP_ADD_COURSES, ASSIGN_COURSE_ADMIN_POPUP_ADD_SELECTED_COURSE, ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING, ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, ASSIGN_COURSE_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_COURSE_ADMIN_POPUP_SET_LOADING, ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_COURSE_ADMIN_POPUP_SET_USER_ID, ASSIGN_COURSE_ADMIN_POPUP_SET_VOUCHER_ID, ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER, ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING, COURSE_ADMIN_PANEL_ADD_USER_COURSE, COURSE_ADMIN_POPUP_ADD_USERS, USER_ADMIN_POPUP_ADD_COURSE, VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER, VOUCHER_ADMIN_POPUP_ADD_COURSE } from "../../ActionTypes";
import i18n from 'i18next';

export const openCourseAsUserAdminPopup = (userId) => async dispatch => {
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_USER_ID, payload: { id: userId } });
}

export const openCourseAsVoucherAdminPopup = (voucherId) => async dispatch => {
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_VOUCHER_ID, payload: { id: voucherId } });
}

export const loadCourseAdminPopup = (pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`course/list/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_ADD_COURSES, payload: { courses: data.paging } });
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const addCourseUserAdminPopup = (courseId, userId) => async dispatch => {
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: courseId, isLoading: true } });
    dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userId, isLoading: true } });

    if (userId && courseId) {
        Axios.post(`usercourse/assignCourse/admin`, {
            userId,
            courseId
        }).then(({ data }) => {
            dispatch({ type: COURSE_ADMIN_PANEL_ADD_USER_COURSE, payload: { courseId, user: data.userCourse.user } });

            dispatch({ type: USER_ADMIN_POPUP_ADD_COURSE, payload: { course: data.course } });
            dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_ADD_SELECTED_COURSE, payload: { id: courseId } });

            dispatch({ type: COURSE_ADMIN_POPUP_ADD_USERS, payload: { users: [data.userCourse] } });
            dispatch({ type: ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER, payload: { id: userId } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: courseId, isLoading: false } });
            dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userId, isLoading: false } });
        });
    }
}

export const addCourseVoucherAdminPopup = (courseId, voucherId) => async dispatch => {
    dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: courseId, isLoading: true } });

    if (voucherId && courseId) {
        Axios.post(`coursevoucher/${voucherId}/course/${courseId}/add/admin`)
            .then(({ data }) => {
                dispatch({ type: VOUCHER_ADMIN_POPUP_ADD_COURSE, payload: { course: data } });
                dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_ADD_SELECTED_COURSE, payload: { id: courseId } });
                dispatch({ type: VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER, payload: { voucherId, voucherCourse: data } });
            }).catch(({ response }) => {
                createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
            }).finally(() => {
                dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: courseId, isLoading: false } });
            });
    }
}