import React from 'react';
import LectureSurveyResultAnswer from './LectureSurveyResultAnswer';

const LectureSurveyResultQuestion = ({ question }) => {
    return (
        <div className='item'>
            <span className='question'>{question.nb}. {question.title}</span>
            <ol className='check'>
                {question.surveyAnswer.map(a => <LectureSurveyResultAnswer answer={a} key={a.id} />)}
            </ol>
        </div>
    );
}

export default LectureSurveyResultQuestion;