import { ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE, ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL, ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK, ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: false,

    title: '',
    url: '',

    isTitleValid: true,
    isUrlValid: true,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_LECTURE_ID: {
            return { ...state, lectureId: action.payload.id };
        }

        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_URL: {
            return { ...state, url: action.payload.url, isUrlValid: true };
        }
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_SET_TITLE: {
            return { ...state, title: action.payload.title, isTitleValid: true };
        }


        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_URL_CHECK: {
            return { ...state, isUrlValid: action.payload.isValid };
        }
        case ASSIGN_DOCUMENTATION_ADMIN_POPUP_TITLE_CHECK: {
            return { ...state, isTitleValid: action.payload.isValid };
        }

        default:
            return state;
    }
}