import React from 'react';
import propTypes from 'prop-types';
import Input from '../../../Input';
import { useDispatch } from 'react-redux';
import { ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME } from '../../../redux/actions/ActionTypes';
import { updateFileNameAdminPopup } from '../../../redux/actions/popup/admin/AssignFilePopupActions';

const FileItemAssignFileAdminPopup = ({ file }) => {
    const dispatch = useDispatch();

    return (
        <div className='file'>
            <Input value={file.name} disabled={file.isLoading} placeholder={'Titre du fichier'} onChange={(name) => dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME, payload: { id: file.id, name } })} onTypingDelay={(name) => dispatch(updateFileNameAdminPopup(file.resourceId, name))} />

            <div className='progress-bar'>
                <div className='progression'>
                    <div className={`progress transition shadow done ${file.error ? 'error' : ''}`} style={{ width: `${file.progress}%` }}></div>
                </div>
            </div>
        </div>
    );
}

FileItemAssignFileAdminPopup.propTypes = {
    file: propTypes.object.isRequired
};

export default FileItemAssignFileAdminPopup;