import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import Datepicker from '../../../../Datepicker';
import { USER_ADMIN_POPUP_SET_ENABLED } from '../../../../redux/actions/ActionTypes';
import { openCourseAdminPopup } from '../../../../redux/actions/popup/admin/CoursePopupActions';
import { deleteUserCourseAdminPopup, updateExpireDateUserCourseAdminPopup, updateUnitCompleteLockUserCourseAdminPopup } from '../../../../redux/actions/popup/admin/UserPopupActions';
import SwitchButton from '../../../../SwitchButton';
import { timeRemainingForNow } from '../../../../Utils';
import { useTranslation } from 'react-i18next';

// Images
import NoImage from '../../../../resources/images/no-image.png';

const CourseUserItemAdminPopup = ({ course }) => {
    const dispatch = useDispatch();
    const expireDate = timeRemainingForNow(course.expireDate);
    const { t } = useTranslation();

    const onEdit = () => {
        dispatch(openCourseAdminPopup(course.courseId));
        dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
    }

    return (
        <div className={`item white shadow rounded-m thumbnail ${expireDate.isExpired || course.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            {!course.isLoading && <div className='item transition' onClick={onEdit}>Modifier la formation</div>}
                            <div className='item transition'><Button title={'Supprimer le lien'} isLoading={course.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteUserCourseAdminPopup(course.id))} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                <div className='progress-bar'>
                    <div className='progression'>
                        <div className='progress shadow' style={{ width: `${(course.nbLecturesDone / course.nbLectures) * 100}%` }}></div>
                    </div>

                    <span><b>{course.nbLecturesDone} / {course.nbLectures}</b> {course.nbLecturesDone > 1 ? t("COURSES_DONE") : t("COURSE_DONE")}</span>
                </div>

                <div className='or'>
                    <hr />
                </div>

                <div className='quick-actions'>
                    <span className={`time-left ${expireDate.isExpired ? 'red' : 'green'}`}>{expireDate.str}</span>
                    <Datepicker component={<span className='modify'>Modifier</span>} selected={new Date(course.expireDate)} onChange={(date) => dispatch(updateExpireDateUserCourseAdminPopup(course.id, date))} disabled={course.isLoading} />
                </div>

                <div className='quick-actions line'>
                    <span className={`time-left ${!course.unitCompleteLock ? '' : 'green'}`}>Cours ordonné</span>
                    <SwitchButton checked={course.unitCompleteLock} onChange={() => dispatch(updateUnitCompleteLockUserCourseAdminPopup(course.id, !course.unitCompleteLock))} zoom={0.8} />
                </div>
            </div>
        </div>
    );
}

CourseUserItemAdminPopup.propTypes = {
    course: propTypes.object.isRequired
};

export default CourseUserItemAdminPopup;