import { VOUCHER_POPUP_SET_ENABLED, VOUCHER_POPUP_SET_LOADING, VOUCHER_POPUP_SET_VOUCHERS_CODE, VOUCHER_POPUP_VOUCHER_CODE_CHECK, VOUCHER_POPUP_SET_VOUCHERS, VOUCHER_POPUP_RESET } from "../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: false,

    voucherCode: '',
    isVoucherCodeValid: true,
    voucherHelperText: '',

    vouchers: null
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case VOUCHER_POPUP_RESET: {
            return defaultState;
        }
        case VOUCHER_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.isEnabled };
        }
        case VOUCHER_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case VOUCHER_POPUP_SET_VOUCHERS_CODE: {
            return { ...state, voucherCode: action.payload.code, isVoucherCodeValid: true };
        }
        case VOUCHER_POPUP_SET_VOUCHERS: {
            return { ...state, vouchers: action.payload.vouchers };
        }

        // CHECK
        case VOUCHER_POPUP_VOUCHER_CODE_CHECK: {
            return { ...state, isVoucherCodeValid: action.payload.isValid, voucherHelperText: action.payload.text };
        }

        default:
            return state;
    }
}