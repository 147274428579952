import propTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { CSSTransition } from 'react-transition-group';
import Button from '../../../Button';
import Input from '../../../Input';
import { ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID, LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_TITLE, LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_UPDATE, LECTURE_PANEL_SET_CREATING_LECTURE_LOADING, LECTURE_PANEL_SET_MENU_LECTURES, LECTURE_PANEL_TOGGLE_SECTION } from '../../../redux/actions/ActionTypes';
import { deleteSectionAdmin, updateLecturePositionAdmin, updateSectionTitleAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { createNewLectureAdminPopup } from '../../../redux/actions/popup/admin/AssignLecturePopupActions';
import { convertStringForUrl } from '../../../Utils';
import LectureItem from './LectureItem';
import { useTranslation } from 'react-i18next';

const LectureSection = ({ section, admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const nodeRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation();

    const isEditing = lectureStore.updateExistingSection && lectureStore.updateExistingSection.id === section.id;
    const isUnavailable = section.lectures.every(l => !l.isAvailable);

    const updateTitle = async (e) => {
        e.preventDefault();
        if (await dispatch(updateSectionTitleAdmin(lectureStore.updateExistingSection.id, lectureStore.updateExistingSection.title)))
            dispatch({ type: LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_UPDATE, payload: { id: null } });
    }

    const onToggle = (e) => {
        if (e.target.tagName !== 'INPUT' && e.target.parentNode.getAttribute('class') !== 'editable' && !e.target.getAttribute('class').split(' ').includes('check'))
            dispatch({ type: LECTURE_PANEL_TOGGLE_SECTION, payload: { open: !lectureStore.selectedSectionId.includes(section.id), id: section.id } })
    }

    const onLectureUpdatePosition = (e) => {
        const itemId = e.item.getAttribute('data-lectureId');
        const newSectionId = lectureStore.menu.sections?.filter(s => s.lectures.some(l => l.id === parseInt(itemId)))[0]?.id;

        if (itemId && newSectionId)
            dispatch(updateLecturePositionAdmin(itemId, newSectionId, e.newIndex));
    }

    const onDeleteSection = async () => {
        if (dispatch(deleteSectionAdmin(section.id))) {
            const nextLecture = lectureStore.menu.sections?.filter(s => s.id !== section.id)?.map(s => s.lectures)?.flat(1)[0];
            if (nextLecture) {
                history.replace(`/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${nextLecture.id}/admin`);
            }
        }
    }

    const createNewLecture = async (isSurvey = false) => {
        dispatch({ type: LECTURE_PANEL_SET_CREATING_LECTURE_LOADING, payload: { isLoading: true } });
        const newLectureId = await dispatch(createNewLectureAdminPopup(section.id, isSurvey));

        if (newLectureId) {
            history.replace(`/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${newLectureId}/admin`);
            dispatch({ type: LECTURE_PANEL_SET_CREATING_LECTURE_LOADING, payload: { isLoading: false } });
        }
    }

    return (
        <div className={`section rounded-m ${!admin && !section.lectures?.length ? 'part' : ''} ${lectureStore.selectedSectionId.includes(section.id) ? 'extended' : ''} ${isUnavailable && !admin ? 'disabled' : ''}`}>
            <div className='section-title' onClick={onToggle}>
                <div className='label'>
                    {admin && <div className='icon-container sort'><div className='icon more'></div></div>}

                    <div className='editable'>
                        {!isEditing ?
                            <>
                                <span className='name'>{section.name}</span>
                                {admin &&
                                    <>
                                        <span className='edit' onClick={() => dispatch({ type: LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_UPDATE, payload: { id: section.id, title: section.name } })}>Modifier</span>
                                    </>}
                            </> :
                            <>
                                <form onSubmit={updateTitle}>
                                    <Input placeholder={'Titre du chapitre'} value={lectureStore.updateExistingSection.title} onChange={(title) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_EXISTING_SECTION_TITLE, payload: { title } })} error={!lectureStore.isSectionTitleValid} helperText={t("HELPER_TITLE_SECTION")} />
                                </form>
                            </>}
                    </div>

                    <CSSTransition nodeRef={nodeRef} in={isUnavailable && !admin} mountOnEnter unmountOnExit timeout={200} classNames='fade'>
                        <span ref={nodeRef} className='unavailable'>Bloqué</span>
                    </CSSTransition>
                </div>

                <div className={`icon action ${isEditing ? 'check' : 'arrow'} transition`} onClick={isEditing ? updateTitle : null}></div>
            </div>

            <div className='list'>
                <ReactSortable chosenClass={'lecture-menu-lecture-sortable-item'} disabled={!admin} animation={200} group={'shared-lectures'} list={section.lectures} setList={(lectures) => dispatch({ type: LECTURE_PANEL_SET_MENU_LECTURES, payload: { id: section.id, lectures } })} onEnd={onLectureUpdatePosition}>
                    {section.lectures.map(lecture => <LectureItem key={lecture.id} lecture={lecture} admin={admin} />)}
                </ReactSortable>

                {admin &&
                    <>
                        <div className={`item transition add-lecture ${lectureStore.isCreatingLectureLoading ? 'disabled' : ''}`} onClick={!lectureStore.isCreatingLectureLoading ? () => createNewLecture(false) : null}>
                            <div className='left add-item'>
                                <div className='icon-container'>
                                    <div className='icon plus'></div>
                                </div>
                                <span className='name'>{lectureStore.isCreatingLectureLoading ? 'Création en cours...' : 'Créer une leçon'}</span>
                            </div>
                        </div>

                        <div className={`item transition add-lecture ${lectureStore.isCreatingLectureLoading ? 'disabled' : ''}`} onClick={!lectureStore.isCreatingLectureLoading ? () => createNewLecture(true) : null}>
                            <div className='left add-item'>
                                <div className='icon-container'>
                                    <div className='icon plus'></div>
                                </div>
                                <span className='name'>{lectureStore.isCreatingLectureLoading ? 'Création en cours...' : 'Créer un questionnaire'}</span>
                            </div>
                        </div>

                        <div className='item transition add-lecture' onClick={() => {
                            dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
                            dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_SECTION_ID, payload: { id: section.id } });
                        }}>
                            <div className='left add-item'>
                                <div className='icon-container'>
                                    <div className='icon plus'></div>
                                </div>
                                <span className='name'>Ajouter une leçon</span>
                            </div>
                        </div>
                    </>}
            </div>

            {admin && <Button title={'Supprimer le chapitre'} btnStyle={'cancel'} confirmationRequired onClick={onDeleteSection} />}
        </div>
    );
}

LectureSection.propTypes = {
    section: propTypes.object.isRequired,
    admin: propTypes.bool
};

export default LectureSection;