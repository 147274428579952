import React from 'react';
import { InlineWidget } from "react-calendly";
import { useTranslation } from 'react-i18next';

const MainViewFive = ({ mainViewRef, mainTitlemainViewRef, overlayTitlemainViewRef }) => {
    const { t } = useTranslation()

    return (
        <div className='sub-main' ref={mainViewRef} id='view-5'>
            <div className='container row'>
                <div className='title special center'>
                    <div className='main-title'>
                        <h3>
                            {t("MAKE_PROGRAM")}

                            <div className='overlay' ref={mainTitlemainViewRef}>
                                <h3 ref={overlayTitlemainViewRef}>{t("MAKE_PROGRAM")}</h3>
                            </div>
                        </h3>
                    </div>

                    <p dangerouslySetInnerHTML={{ __html: t("MAKE_PROGRAM_DESC") }}></p>
                </div>

                <div className='calendly-container shadow rounded-m'>
                    <InlineWidget url="https://calendly.com/janssens/meet" />
                </div>
            </div>
        </div>
    );
}

export default MainViewFive;