import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES, ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE, ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING, ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING, ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING, ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE, LECTURE_PANEL_ADMIN_ADD_MENU_LECTURE, LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS } from "../../ActionTypes";
import i18n from 'i18next';

export const loadLecturesAdminPopup = (pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`lecture/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_ADD_LECTURES, payload: { lectures: data.paging } });
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const createNewLectureAdminPopup = (sectionId, isSurvey = false) => async dispatch => {
    dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING, payload: { isLoading: true } });

    return Axios.post(`lecture/add/admin`, {
        sectionId,
        isSurvey
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_ADMIN_ADD_MENU_LECTURE, payload: { sectionId, lecture: data } });
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS, payload: { isEditing: true } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE, payload: { title: data.title } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION, payload: { description: '' } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, payload: { isEditing: false } });

        return data.id;
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');

        return null;
    }).finally(() => {
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_ADD_NEW_LOADING, payload: { isLoading: false } });
    });
}

export const duplicateLectureToSectionAdminPopup = (lectureId, sectionId) => async dispatch => {
    dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING, payload: { id: lectureId, isLoading: true } });

    return Axios.post(`lecture/${lectureId}/to/section/${sectionId}/duplicate/admin`)
        .then(({ data }) => {
            dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_ADD_SELECTED_LECTURE, payload: { id: lectureId } });
            dispatch({ type: LECTURE_PANEL_ADMIN_ADD_MENU_LECTURE, payload: { sectionId, lecture: data } });

            return data.id;
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            return false;
        }).finally(() => {
            dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURE_LOADING, payload: { id: lectureId, isLoading: false } });
        });
}