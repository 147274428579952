import { ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER, ASSIGN_USER_ADMIN_POPUP_ADD_USERS, ASSIGN_USER_ADMIN_POPUP_RESET, ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_USER_ADMIN_POPUP_SET_LOADING, ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_USER_ADMIN_POPUP_SET_USERS, ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING } from "../../../actions/ActionTypes";


const defaultState = {
    popupEnabled: false,
    isLoading: true,

    courseId: null,

    users: [],
    selectedUsers: [],

    hasNext: true,
    pagingPage: 0,

    searchValue: '',
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_USER_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_USER_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_USER_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID: {
            return { ...state, courseId: action.payload.id };
        }

        case ASSIGN_USER_ADMIN_POPUP_SET_USERS: {
            return { ...state, users: action.payload.users };
        }
        case ASSIGN_USER_ADMIN_POPUP_ADD_USERS: {
            return { ...state, users: state.users.concat(action.payload.users) };
        }

        case ASSIGN_USER_ADMIN_POPUP_ADD_SELECTED_USER: {
            return { ...state, selectedUsers: [...state.selectedUsers, action.payload.id] };
        }
        case ASSIGN_USER_ADMIN_POPUP_SET_USER_LOADING: {
            return {
                ...state, users: state.users.map(u => {
                    if (u.id !== action.payload.id)
                        return u;

                    return { ...u, isLoading: action.payload.isLoading };
                })
            }
        }

        case ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}