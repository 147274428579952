import { PREVIEW_POPUP_SET_ENABLED, PREVIEW_POPUP_SET_LECTURE, PREVIEW_POPUP_SET_LOADING } from "../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,

    isLoading: true,
    lecture: null
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case PREVIEW_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.isEnabled };
        }

        case PREVIEW_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case PREVIEW_POPUP_SET_LECTURE: {
            return { ...state, lecture: action.payload.lecture };
        }

        default:
            return state;
    }
}