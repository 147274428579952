import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_USER_ADMIN_POPUP_RESET, ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_USER_ADMIN_POPUP_SET_USERS } from '../../redux/actions/ActionTypes';
import { loadUsersAdminPopup } from '../../redux/actions/popup/admin/AssignUserPopupActions';
import { openUserAdminPopup } from '../../redux/actions/popup/admin/UserPopupActions';
import UserListAssignUserAdminPopup from './assignUser/UserListAssignUserAdminPopup';

const AssignCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const assignUserAdminStore = useSelector(store => store.assignUserAdminPopup);

    useEffect(() => {
        dispatch(loadUsersAdminPopup(0, null));

        return () => {
            dispatch({ type: ASSIGN_USER_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const createNewUser = () => {
        dispatch(openUserAdminPopup());
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
    }

    const onTyping = (value) => {
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_USERS, payload: { users: [] } });
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: 0 } });
        dispatch(loadUsersAdminPopup(0, value));
    }

    return (
        <div className={`assign-course-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignUserAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer un utilisateur' btnStyle='primary' onClick={createNewUser} />
            </div>

            <div className='section'>
                <UserListAssignUserAdminPopup />

                {assignUserAdminStore.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={assignUserAdminStore.isLoading} onClick={() => dispatch(loadUsersAdminPopup(assignUserAdminStore.pagingPage + 1, assignUserAdminStore.searchValue))} /></div>}
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignCourseAdminPopup, 'Assigner un étudiant', 'Création ou ajout d\'un étudiant', null, null, null, {
    storeName: 'assignUserAdminPopup',
    completion: (dispatch, store) => {
        if (!store.isLoading && store.hasNext) {
            dispatch(loadUsersAdminPopup(store.pagingPage + 1, store.searchValue));
        }
    }
});