import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import { MENU_SET_MOBILE_MENU, SIGN_POPUP_SET_ENABLED, VOUCHER_POPUP_SET_ENABLED } from '../redux/actions/ActionTypes';
import { MOBILE_MENU_TYPE, PREFERENCES_MENU } from '../resources/constants/menu';
import { SIGN_POPUP_POPUP_TYPE } from '../resources/constants/signPopupType';
import MenuList from './MenuList';
import AuthenticationService from '../services/Authentication';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

// Images
import Profile from '../resources/images/profile.png';

const MobileMenu = () => {
    const { t } = useTranslation()
    
    const dispatch = useDispatch();
    const menuStore = useSelector(store => store.menu);
    let connectedUser = null;
    const LANGUAGES = [
        { value: 'fr', label: 'Français' },
        { value: 'en', label: 'English' },
    ]

    if (AuthenticationService.IsLogged())
        connectedUser = AuthenticationService.GetSession();

    if (menuStore.mobileMenu !== MOBILE_MENU_TYPE.MENU)
        return null;

    return (
        <>
            <div className='mobile-menu'>
                <div className='container'>
                    {connectedUser &&
                        <div className='logged rounded-m'>
                            <div className='info'>
                                <img src={Profile} alt='profile' />
                                <div className='labels'>
                                    <span className='hello'>{t("HELLO")}, </span>
                                    <span className='name'>{`${connectedUser.firstname} ${connectedUser.lastname}`}</span>
                                </div>
                            </div>

                            <div className='menu'>
                                <Link to='/dashboard' className='item' onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: null } })}>Mes formations</Link>

                                <div className='item'>
                                    <div className='cell'>
                                        {t("PREFERENCES")}
                                        <div className='icon arrow transition'></div>
                                    </div>
                                    <div className='sub'>
                                        <div className='dropdown-list shadow rounded-m white transition'>
                                            {PREFERENCES_MENU.map(item => <Link key={item.id} to={item.link} className='item transition' onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: null } })}>{item.name}</Link>)}
                                        </div>
                                    </div>
                                </div>

                                <div className='item' onClick={() => dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: true } })}>{t("USE_CODE")}</div>

                                {AuthenticationService.IsAdmin() && <Link to='/admin/courses' className='item'>{t("ADMINISTRATION")} <span className='emoji' role={'img'} aria-label={'star'}>✨</span></Link>}
                            </div>
                        </div>}

                    <MenuList />

                    <div className='or'>
                        <hr />
                    </div>

                    {!connectedUser ?
                        <div className='login-button'>
                            <Button title={t("LOGIN")} btnStyle={'primary'} onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.LOGIN } })} />
                            <Button title={t("REGISTER")} btnStyle={'secondary'} onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.REGISTER } })} />
                        </div> : <Button title={t("LOGOUT")} btnStyle={'cancel'} onClick={() => AuthenticationService.Logout()} />}

                    <div className='lang-select'>
                        <div className={`duration-picker`}>
                            <Select
                                value={null}
                                // onChange={(option) => dispatch({ type: INBOUND_POPUP_SET_JOB_STATUS, payload: { jobStatus: option } })}
                                options={LANGUAGES}
                                className={'selector'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileMenu;