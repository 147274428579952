import React from 'react';
import propTypes from 'prop-types';

// Images
import NoImage from '../../../../resources/images/no-image.png';
import { useDispatch } from 'react-redux';
import { openCourseAdminPopup } from '../../../../redux/actions/popup/admin/CoursePopupActions';
import { CATEGORY_ADMIN_POPUP_SET_ENABLED } from '../../../../redux/actions/ActionTypes';

const CourseItemCategoryAdminPopup = ({ course }) => {
    const dispatch = useDispatch();

    const onEdit = () => {
        dispatch(openCourseAdminPopup(course.id));
        dispatch({ type: CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
    }

    return (
        <div className={`item white shadow rounded-m thumbnail ${!course.enabled ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                {course.model ? <span className='status important'>Modèle</span> : <div></div>}
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition' onClick={onEdit}>Modifier la formation</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>
            </div>
        </div>
    );
}

CourseItemCategoryAdminPopup.propTypes = {
    course: propTypes.object.isRequired
};

export default CourseItemCategoryAdminPopup;