export const SIGN_POPUP_POPUP_TYPE = {
    LOGIN: 0,
    REGISTER: 1,
    RESET_PASSWORD: 2
};

export const REGISTER_POPUP_STEP = {
    GENERAL: 0,
    PASSWORD: 1,
    EXTRA: 2
};