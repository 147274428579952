import React from 'react';
import propTypes from 'prop-types';
import generatePDF from '../../../services/CertificatePDF';
import AuthenticationService from '../../../services/Authentication';
import { useTranslation } from 'react-i18next';

// Images
import NoImage from '../../../resources/images/no-image.png';

const DashboardCertificateItem = ({ certificate }) => {
    const { t } = useTranslation();
    
    return (
        <div className='white shadow rounded-m thumbnail horizontal'>
            <img className='img-main rounded-s' src={certificate.picture || NoImage} alt={certificate.title} />

            <div className='info'>
                <span>{certificate.title}</span>

                <div className='or'>
                    <hr />
                </div>

                <span className='link' onClick={() => generatePDF(certificate, AuthenticationService.GetSession())}>{t("DOWNLOAD")}</span>
            </div>
        </div>
    );
}

DashboardCertificateItem.propTypes = {
    certificate: propTypes.object.isRequired
};

export default DashboardCertificateItem;