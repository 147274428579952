import React from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { INTERVIEW_POPUP_SET_INTERVIEW_ID } from '../../../redux/actions/ActionTypes';

const SolutionInterviewItem = ({ interview, index }) => {
    const dispatch = useDispatch();

    const onShowPopup = () => {
        dispatch({ type: INTERVIEW_POPUP_SET_INTERVIEW_ID, payload: { interviewId: index } });
    }

    return (
        <>
            <div className='face'>
                <div className='profile'>
                    <img src={interview.picture} alt={interview.name} />
                </div>
            </div>

            <div className='comment'>
                <p>{interview.comment}</p>
                <span className='name'>{interview.name}</span>

            </div>
            <div onClick={onShowPopup} className='watch rounded-s transition'>
                <div className='icon player'></div>
                <span>Visionner le témoignage</span>
            </div>
        </>
    );
}

SolutionInterviewItem.propTypes = {
    interview: propTypes.object.isRequired
};

export default SolutionInterviewItem;