import { ASSIGN_FILE_ADMIN_POPUP_ADD_FILE, ASSIGN_FILE_ADMIN_POPUP_RESET, ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID, ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID, ASSIGN_FILE_ADMIN_POPUP_SET_LOADING } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: false,

    lectureId: null,

    files: []
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_FILE_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID: {
            return { ...state, lectureId: action.payload.id };
        }
        case ASSIGN_FILE_ADMIN_POPUP_ADD_FILE: {
            return { ...state, files: [...state.files, action.payload.file] }
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING: {
            return {
                ...state, files: state.files?.map(f => {
                    if (f.id !== action.payload.id)
                        return f;

                    return { ...f, isLoading: action.payload.isLoading };
                })
            }
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_FILE_NAME: {
            return {
                ...state, files: state.files?.map(f => {
                    if (f.id !== action.payload.id)
                        return f;

                    return { ...f, name: action.payload.name };
                })
            }
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS: {
            return {
                ...state, files: state.files.map(f => {
                    if (f.id !== action.payload.id)
                        return f;

                    return { ...f, progress: action.payload.progress };
                })
            }
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID: {
            return {
                ...state, files: state.files.map(f => {
                    if (f.id !== action.payload.id)
                        return f;

                    return { ...f, resourceId: action.payload.resourceId };
                })
            }
        }
        case ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR: {
            return {
                ...state, files: state.files.map(f => {
                    if (f.id !== action.payload.id)
                        return f;

                    return { ...f, error: action.payload.error };
                })
            }
        }

        default:
            return state;
    }
}