import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../../Button';
import Input from '../../../../Input';
import { LECTURE_PANEL_SURVEY_SET_ANSWERS } from '../../../../redux/actions/ActionTypes';
import { deleteQuestionSurvey, updateQuestionSurveyRequiredStatus, updateQuestionTitleSurvey, updateSurveyAnswerPosition } from '../../../../redux/actions/dashboard/LectureSurveyActions';
import SwitchButton from '../../../../SwitchButton';
import LectureSurveyAdminAnswer from './LectureSurveyAdminAnswer';
import LectureSurveyAdminNewAnswer from './LectureSurveyAdminNewAnswer';

const LectureSurveyAdminQuestion = ({ index, question }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState(question.title);

    const onTyping = (title) => {
        dispatch(updateQuestionTitleSurvey(question.id, title));
    }

    const onUpdatePosition = (e) => {
        const movedItem = question.surveyAnswer[e.newIndex];
        dispatch(updateSurveyAnswerPosition(movedItem.id, e.newIndex));
    }

    return (
        <div className={`question content shadow rounded-l ${question.isLoading ? 'disabled' : ''}`}>
            <div className='question'>
                <div className='num'>
                    <span>{index + 1}.</span>
                </div>

                <Input placeholder={'Titre de la question'} value={title} onChange={(title) => setTitle(title)} onTypingDelay={onTyping} disabled={question.isLoading} maxLength={2000} />
            </div>

            <div className='answers-container'>
                <ReactSortable className='answers' chosenClass={'disabled'} delay={200} animation={200} list={question.surveyAnswer} setList={(answers) => dispatch({ type: LECTURE_PANEL_SURVEY_SET_ANSWERS, payload: { questionId: question.id, answers } })} onEnd={onUpdatePosition}>
                    {question.surveyAnswer?.map((a, index) => <LectureSurveyAdminAnswer key={a.id} index={index} answer={a} />)}
                </ReactSortable>

                <LectureSurveyAdminNewAnswer index={question.surveyAnswer?.length} questionId={question.id} />
            </div>

            <div className='required-switch'>
                <SwitchButton checked={question.required} className='switch-btn' onChange={(required) => dispatch(updateQuestionSurveyRequiredStatus(question.id, required))} />
                {question.required ? <span className='green'>Question obligatoire</span> : <span>Question non obligatoire</span>}
            </div>

            <div className='right-alignment'>
                <Button title={'Supprimer la question'} btnStyle={'cancel autofit'} onClick={() => dispatch(deleteQuestionSurvey(question.id))} isLoading={question.isLoading} confirmationRequired/>
            </div>
        </div>
    );
}

export default LectureSurveyAdminQuestion;