import { CATEGORY_PANEL_SET_CATEGORY, CATEGORY_PANEL_SET_LOADING } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    category: null
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case CATEGORY_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case CATEGORY_PANEL_SET_CATEGORY: {
            return { ...state, category: action.payload.category };
        }

        default:
            return state;
    }
}