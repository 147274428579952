import React, { useEffect, useState } from 'react'
import { Link as ScrollLink } from 'react-scroll';
import Button from '../../Button';
import PreviewSection from '../shop/preview/PreviewSection';
import { useDispatch, useSelector } from 'react-redux';
import { INBOUND_POPUP_SET_COMPANY, INBOUND_POPUP_SET_EMAIL, INBOUND_POPUP_SET_FIRSTNAME, INBOUND_POPUP_SET_JOB_STATUS, INBOUND_POPUP_SET_LASTNAME, INBOUND_POPUP_SET_PHONE, PREVIEW_PANEL_TOGGLE_SECTION } from '../../redux/actions/ActionTypes';
import SolutionInterviewItem from '../shop/solution/SolutionInterviewItem';
import { INTERVIEW } from '../../resources/constants/interview';
import CoursePreviewPDF from '../../services/CoursePreviewPDF';
import { Element } from 'react-scroll';
import ReactPlayer from 'react-player';
import { downloadInboundCourse, showInboundPopup } from '../../redux/actions/popup/InboundActions';
import Input from '../../Input';
import InputPhone from '../../InputPhone';
import { convertStringForUrl } from '../../Utils';
import Select from 'react-select';
import { JOB_STATUS } from '../../resources/constants/job';
import { useTranslation } from 'react-i18next';

import data from '../../resources/inbound/ms-030.json'

// Images
import { ReactComponent as Arrow1 } from '../../resources/images/home/arrow-1.svg';
// import { ReactComponent as Arrow2 } from '../../resources/images/home/arrow-2.svg';
import Computer from '../../resources/images/home/computer.png';
import Whistle from '../../resources/images/solution/key.png';
import Clock from '../../resources/images/solution/clock.png';

const MS030 = () => {
    const dispatch = useDispatch();
    const previewStore = useSelector(store => store.previewPanel);
    const inboundStore = useSelector(store => store.inboundPopup);
    const [detailExtended, setDetailExtended] = useState(false);
    const [isRegistered, setRegistered] = useState(false);
    const courseTitle = 'MS-030 : Administrateur Microsoft 365';
    const { t } = useTranslation();

    useEffect(() => {
        console.log(convertStringForUrl("Profitez d’une formation officielle Administrateur Microsoft 365"));
        if (!previewStore.selectedSectionId.length) {
            const sectionsId = data.section.slice(0, 4).map(s => s.id);
            sectionsId.forEach(id => {
                dispatch({ type: PREVIEW_PANEL_TOGGLE_SECTION, payload: { id } });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showPopup = (action) => {
        dispatch(showInboundPopup(inboundStore, courseTitle, action))
    }

    const onRegister = async (e) => {
        e.preventDefault();
        dispatch(downloadInboundCourse({ ...inboundStore, courseTitle, isRegistration: 'Inscription au cours' }, () => setRegistered(true)));
    }

    const askAnotherDate = async () => {
        dispatch(downloadInboundCourse({ ...inboundStore, courseTitle, isRegistration: 'Demande une autre date' }, () => setRegistered(true)));
    }

    return (
        <div className='page-container-wrapper home-panel inbound-panel'>
            <div className='bg-banner rounded-xl'></div>

            <div className='container'>
                <div className='content'>
                    <div className='title big'>
                        <h2>Profitez d’une formation officielle Administrateur Microsoft 365 </h2>
                        <span>Une formation clé en main pour les <b>administrateurs</b> et <b>consultants</b> qui migrent sur Microsoft 365</span>

                        <div className="cto-btn">
                            <Button title={t("DOWNLOAD_CURSUS")} btnStyle={'secondary autofit'} onClick={() => showPopup(() => CoursePreviewPDF(data))} />

                            <ScrollLink to="calendly" smooth={true} offset={-50}>
                                <Button title="Réserver ma place" btnStyle={'primary autofit'} />
                            </ScrollLink>
                        </div>
                    </div>

                    <div className='yt-player'>
                        <ReactPlayer
                            url={'https://www.youtube.com/watch?v=YYqbDg32x0Q'}
                            playing={false}
                        />
                    </div>

                    <div className='center'>
                        <div className='start rounded-m'>
                            <span className='m-title'>Début de la formation</span>
                            <span className='time'>17 mai 2021 10:00</span>
                            <span className='duration'>Durée: 5 jours</span>

                            <div>
                                <div className='pricing'>
                                    <span className='current'>1.950€</span>
                                    <span className='old'>2.525€</span>
                                </div>
                            </div>

                            <ScrollLink to="calendly" smooth={true} offset={-50}>
                                <Button title={'Réserver ma place'} btnStyle='primary autofit' />
                            </ScrollLink>

                            <ScrollLink to="calendly" smooth={true} offset={-50} className='new-date'>
                                Organiser une autre date
                            </ScrollLink>
                        </div>
                    </div>

                    <div className='title'>
                        <h2>Une formation qui vous convient</h2>
                        <span>L’idéal pour les administrateurs système et consultants Microsoft 365.</span>
                    </div>
                    <div className='advantages'>
                        <div className='item'>
                            <div className='icon rounded-l'>
                                <img src={Whistle} alt='telescope' />
                            </div>

                            <h3>Un expert à vos cotés</h3>
                            <p>Un formateur expérimenté de terrain et certifié est dédié à votre apprentissage pendant toute la durée de la formation.</p>
                        </div>

                        <div className='item'>
                            <div className='icon rounded-l'>
                                <img src={Computer} alt='computer' />
                            </div>

                            <h3>Ateliers inclus</h3>
                            <p>Chaque module est joint d'un atelier vous permettant de directement appliquer la théorie.</p>
                        </div>

                        <div className='item'>
                            <div className='icon rounded-l'>
                                <img src={Clock} alt='clock' />
                            </div>

                            <h3>1 an d'accompagnement</h3>
                            <p>Le coach reste disponible durant 365 jours pour vous épauler lors de la mise en place de votre migration.</p>
                        </div>
                    </div>
                    <div className='center'>
                        <ScrollLink to="calendly" smooth={true} offset={-50}>
                            <Button title={'Réserver ma place'} btnStyle='primary autofit' />
                        </ScrollLink>
                    </div>

                    <div className='title'>
                        <h2>Un coach dédié</h2>
                        <span>Cette formation est animéé par un des formateurs francophones les plus expérimentés qui vous divulguera ses expériences du terrain.</span>
                    </div>
                    <div className='center'>
                        <div className='coach shadow white rounded-l'>
                            <div className='picture rounded-m'></div>
                            <div className='info'>
                                <span className='name'>Freddy Janssens</span>
                                <span className='status'>Senior System Engineer Solution Architect</span>

                                <ul>
                                    <li>🏆 Expert certifié Microsoft &amp; VMWare</li>
                                    <li>💪🏻 30 ans d’expérience</li>
                                    <li>🧠 7 années d'expérience dans les neurosciences et la neuropédagogie</li>
                                </ul>

                                <div className="cto-btn">
                                    <a href="https://www.linkedin.com/in/freddy-janssens/" target="_blank" rel='noreferrer'>
                                        <Button title={'Voir le profil complet'} btnStyle='secondary autofit' />
                                    </a>

                                    <a href="https://calendly.com/janssens/meet" target="_blank" rel='noreferrer'>
                                        <Button title="Discuter avec le coach" btnStyle={'primary autofit'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='title'>
                        <h2>Accédez au contenu de la formation</h2>
                        <span>Découvrez ou téléchargez le contenu de la formation</span>
                    </div>

                    <div className="able-to rounded-xl">
                        <span>A la fin de cette formation, vous serez capable de :</span>

                        <ul>
                            <li>✅ Paramétrer AD connect</li>
                            <li>✅ Savoir pourquoi et comment utiliser AD connect avec ADFS</li>
                            <li>✅ Appliquer les bonnes pratiques du terrain</li>
                            <li>✅ Gagner du temps dans vos migrations ou dans l’automatisation des tâches dans Microsoft 365</li>
                            <li>✅ Planifier et configurer les services Exchange Online, sharepoint et Teams</li>
                            <li>✅ Surveiller et résoudre les problèmes liés à Microsoft 365</li>
                        </ul>
                    </div>

                    <div className={`inbound detail ${detailExtended ? 'extended' : ''}`}>
                        {data.section.slice(0, detailExtended ? data.section.length : 4).map(section => <PreviewSection key={section.id} section={section} isExtended={previewStore.selectedSectionId.includes(section.id)} inboundMode={true} />)}

                        {!detailExtended && <div className="overlay"></div>}
                    </div>
                    {!detailExtended &&
                        <div className='center'>
                            <Button title={'Voir le programme complet'} btnStyle='primary autofit' onClick={() => showPopup(() => {
                                setDetailExtended(true)
                            })} />
                        </div>}
                    <div className='center'>
                        <span className='download-course' onClick={() => showPopup(() => CoursePreviewPDF(data))}>Télécharger en PDF</span>
                    </div>

                    <div className='title'>
                        <h2>Ils ont réussi la certification</h2>
                        <span>Découvrez les avis des participants qui vont ont précédés</span>
                    </div>
                    <div className='reviews'>
                        <div className='review-container'>
                            <div className='review'>
                                <SolutionInterviewItem interview={INTERVIEW[0]} index={0} />
                                <div className='arrow one'><Arrow1 /></div>
                            </div>
                        </div>

                        <div className='review-container'>
                            <div className='review'>
                                <SolutionInterviewItem interview={INTERVIEW[1]} index={1} />
                                <div className='arrow two'><Arrow1 /></div>
                            </div>
                        </div>

                        <div className='review-container'>
                            <div className='review'>
                                <SolutionInterviewItem interview={INTERVIEW[2]} index={2} />
                            </div>
                        </div>
                    </div>

                    {!isRegistered ?
                        <>
                            <Element name='calendly'>
                                <div className='title' style={{ marginBottom: '0' }}>
                                    <h2>Réservez votre place</h2>
                                    <span>
                                        Vous souhaitez assister à cette formation ?<br />
                                Il vous manque des informations pratiques pour être totalement convaincu ?<br />
                                Contactez-nous ou prenez rendez-vous avec le mentor
                        </span>
                                </div>
                            </Element>
                            <div className='contact'>
                                <form className='contact-form' onSubmit={onRegister}>
                                    <Input title={`${t("EMAIL")} (Obligatoire)`} placeholder={t("PLACEHOLDER_EMAIL")} value={inboundStore.email} onChange={(email) => dispatch({ type: INBOUND_POPUP_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={inboundStore.isLoading} error={!inboundStore.isEmailValid} helperText={t("HELPER_EMAIL")} />

                                    <div className='col-2 name'>
                                        <Input title={`${t("FIRSTNAME")}`} placeholder={t("FIRSTNAME")} value={inboundStore.firstname} onChange={(firstname) => dispatch({ type: INBOUND_POPUP_SET_FIRSTNAME, payload: { firstname } })} type={'text'} autocomplete={'given-name'} disabled={inboundStore.isLoading} error={!inboundStore.isFirstnameValid} helperText={t("HELPER_FIRSTNAME")} />
                                        <Input title={`${t("LASTNAME")}`} placeholder={t("LASTNAME")} value={inboundStore.lastname} onChange={(lastname) => dispatch({ type: INBOUND_POPUP_SET_LASTNAME, payload: { lastname } })} type={'text'} autocomplete={'family-name'} disabled={inboundStore.isLoading} error={!inboundStore.isLastnameValid} helperText={t("HELPER_LASTNAME")} />
                                    </div>

                                    <div>
                                        <InputPhone title={`${t("PHONE")}`} placeholder={t("PLACEHOLDER_PHONE")} value={inboundStore.phone} onChange={(phone) => dispatch({ type: INBOUND_POPUP_SET_PHONE, payload: { phone } })} error={!inboundStore.isPhoneValid} helperText={t("HELPER_PHONE")} disabled={inboundStore.isLoading} />
                                        <Input title={`${t("COMPANY")}`} placeholder={t("COMPANY")} value={inboundStore.company} onChange={(company) => dispatch({ type: INBOUND_POPUP_SET_COMPANY, payload: { company } })} type={'text'} autocomplete={'organization'} disabled={inboundStore.isLoading} error={!inboundStore.isCompanyValid} helperText={t("HELPER_COMPANY")} />

                                        <Input title='Fonction'>
                                            <div className={`duration-picker`}>
                                                <Select
                                                    value={inboundStore.jobStatus}
                                                    onChange={(option) => dispatch({ type: INBOUND_POPUP_SET_JOB_STATUS, payload: { jobStatus: option } })}
                                                    options={JOB_STATUS}
                                                    placeholder={'Fonction dans l\'entreprise'}
                                                    className={'selector'}
                                                />
                                            </div>
                                        </Input>
                                    </div>

                                    <div className='submit-btn'>
                                        <div className=''>
                                            <Button title={'Demander une autre date'} btnStyle={'secondary'} type={'button'} isLoading={inboundStore.isLoading} onClick={askAnotherDate} />
                                        </div>

                                        <div className='max'>
                                            <Button title={'Réserver ma place'} btnStyle={'primary'} type={'submit'} isLoading={inboundStore.isLoading} />
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </> : <div className='title'>
                            <h2>Félicitation ! 🎉</h2>
                            <span>
                                Votre candidature a bien été prise en compte.<br />
                                Le coach prendra bientôt contact avec vous afin de finaliser votre inscription.<br />
                                A bientôt ! 👋
                            </span>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default MS030