import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthenticationService from '../services/Authentication';
import { VOUCHER_POPUP_SET_ENABLED } from '../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';

// Images
import Profile from '../resources/images/profile.png';

const AdminHeader = () => {
    const { t } = useTranslation()
    
    const dispatch = useDispatch();
    let connectedUser = null;

    if (AuthenticationService.IsLogged())
        connectedUser = AuthenticationService.GetSession();

    return (
        <header className={`container shadow rounded-s white admin-header`}>
            <div className='left'>
                <div className='logo-menu'>
                    <Link to='/'><div className='logo'></div></Link>
                    <Link className='go-back' to='/'>Revenir à la plateforme</Link>
                </div>
            </div>

            <div className='login-menu logged'>
                <div className='button'>
                    <div className='info'><img src={connectedUser.picture || Profile} alt='profile' />{`${connectedUser.firstname} ${connectedUser.lastname}`}</div>

                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <Link to={'/dashboard'} className='item'>{t("DASHBOARD")}</Link>
                            <Link to={'/dashboard/preference/informations'} className='item'>{t("PREFERENCES")}</Link>
                            <div className='item' onClick={() => dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: true } })}>{t("USE_CODE")}</div>
                            <Link to={'/admin/courses'} className='item'>{t("ADMINISTRATION")} <span className='emoji' role={'img'} aria-label={'star'}>✨</span></Link>
                            <div className='item red' onClick={() => AuthenticationService.Logout()}>{t("LOGOUT")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default AdminHeader;