import { ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS, ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK, ASSIGN_EMBED_PAGE_ADMIN_POPUP_DURATION_CHECK, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: false,
    isLoading: false,

    lectureId: null,
    url: '',
    duration: {
        hours: null,
        minutes: null,
        seconds: null,

        isDurationValid: true
    },
    isUrlValid: true,

    preview: null
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_LECTURE_ID: {
            return { ...state, lectureId: action.payload.id };
        }

        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL: {
            return { ...state, url: action.payload.url, isUrlValid: true };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS: {
            return {
                ...state, duration: {
                    ...state.duration,
                    hours: action.payload.hours,
                    isDurationValid: true
                }
            };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES: {
            return {
                ...state, duration: {
                    ...state.duration,
                    minutes: action.payload.minutes,
                    isDurationValid: true
                }
            };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS: {
            return {
                ...state, duration: {
                    ...state.duration,
                    seconds: action.payload.seconds,
                    isDurationValid: true
                }
            };
        }

        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_PREVIEW: {
            return { ...state, preview: action.payload.preview };
        }

        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_URL_CHECK: {
            return { ...state, isUrlValid: action.payload.isValid };
        }
        case ASSIGN_EMBED_PAGE_ADMIN_POPUP_DURATION_CHECK: {
            return {
                ...state, duration: {
                    ...state.duration,
                    isDurationValid: action.payload.isValid
                }
            }
        }

        default:
            return state;
    }
}