import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicLoader from '../../loader/BasicLoader';
import { categoryPanelLoadCategory } from '../../redux/actions/shop/CategoryPanelActions';
import CategoryItem from './category/CategoryItem';
import CourseItem from './category/CourseItem';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const CategoryPanel = (props) => {
    const dispatch = useDispatch();
    const categoryStore = useSelector(store => store.categoryPanel);
    const { t } = useTranslation();

    useEffect(() => {
        const fetch = async () => {
            try {
                await dispatch(categoryPanelLoadCategory(props.match.params.id));
            } catch {
                props.history.push('/');
            }
        }
        fetch();
    }, [dispatch, props.history, props.match.params.id, i18n.language]);


    if (categoryStore.isLoading || !categoryStore.category)
        return (
            <div className='page-container-wrapper category-panel'>
                <div className='container'>
                    <BasicLoader width={'100%'} height={150} radius={10} />
                </div>

                <div className='container page margin-top-loading'>
                    <BasicLoader width={'100%'} height={500} radius={10} />
                </div>
            </div>
        );

    const { category, subcategories, courses } = categoryStore.category;
    return (
        <>
            <Helmet>
                <title>{category.title} · {t("COURSES")} · FormationAcademy</title>
                <meta name="description" content={`Découvrez toutes nos formations à propos de ${category.title} - ${category.seoKeyword?.map(k => k.label).join(', ')}. Formations en ligne et à distance personnalisées & coachées par des experts de l'IT. Passez & réussissez une certification ${category.title}.`} />
            </Helmet>
            <div className='page-container-wrapper category-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl' style={{ background: `url(${category.picture})`, backgroundSize: 'cover' }}>
                        <div className='informations blur rounded-xl'>
                            <h1>{category.title}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='section'>
                        {subcategories && subcategories.length ?
                            <>
                                <div className='title'>
                                    <h1>{t("SUB_CATEGORIES")}</h1>
                                    <h2>{t("CATEGORY_ABOUT")} {category.title}</h2>
                                </div>

                                <div className='categories'>
                                    {subcategories.map(category => <CategoryItem key={category.id} category={category} />)}
                                </div>
                            </> : null}

                        {courses && courses.length ?
                            <>
                                <div className='title'>
                                    <h1>{t("COURSES")}</h1>
                                    <h2>{t("COURSE_ABOUT")} {category.title}</h2>
                                </div>

                                <div className='categories'>
                                    {courses.map(course => <CourseItem key={course.id} course={course} />)}
                                </div>
                            </> : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryPanel;