import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import BasicLoader from '../../loader/BasicLoader';
import LectureSurveyResultQuestion from '../../panel/dashboard/lecture/survey/LectureSurveyResultQuestion';
import { SURVEY_ADMIN_POPUP_RESET } from '../../redux/actions/ActionTypes';
import { deleteUserSurveyAdminPopup, loadSurveyResultAdminPopup } from '../../redux/actions/popup/admin/SurveyPopupActions';
import Button from '../../Button';

const SurveyAdminPopup = () => {
    const dispatch = useDispatch();
    const surveyAdminStore = useSelector(store => store.surveyAdminPopup);

    useEffect(() => {
        if (surveyAdminStore.surveyId)
            dispatch(loadSurveyResultAdminPopup(surveyAdminStore.surveyId));

        return () => {
            dispatch({ type: SURVEY_ADMIN_POPUP_RESET });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (surveyAdminStore.isLoading)
        return (
            <BasicLoader width={'100%'} height={400} radius={10} />
        );

    return (
        <div className={`survey-admin`}>
            <div className='survey-result-container shadow white rounded-m'>
                <div className='progress-circle center'>
                    <SemiCircleProgressBar percentage={(surveyAdminStore.results.finalScore / surveyAdminStore.results.finalScoreBase) * 100} diameter={250} background={'#e2e6ea'} stroke={'#2ED573'} />
                    <span className='note'>{surveyAdminStore.results.finalScore}/{surveyAdminStore.results.finalScoreBase}</span>
                </div>

                <h4>Rapport</h4>
                <div>
                    {surveyAdminStore.results.wrong?.length ?
                        <div className='summary-container wrong'>
                            <span className='category'>Mauvaises réponses</span>
                            <div className='bar'></div>
                            <div className='content'>
                                {surveyAdminStore.results.wrong.map(q => <LectureSurveyResultQuestion question={q} key={q.id} />)}
                            </div>
                        </div> : null}

                    {surveyAdminStore.results.correct?.length ?
                        <div className='summary-container correct'>
                            <span className='category'>Bonnes réponses</span>
                            <div className='bar'></div>
                            <div className='content'>
                                {surveyAdminStore.results.correct.map(q => <LectureSurveyResultQuestion question={q} key={q.id} />)}
                            </div>
                        </div> : null}
                </div>
            </div>

            <div style={{ marginTop: '80px' }}>
                <div className='content-title'>
                    <h2>Zone de danger</h2>
                </div>
                <div className='section'>
                    <div className='danger-item'>
                        <div className='labels'>
                            <h3>Supprimer la formation</h3>
                            <span>Cette opération est irréversible.</span>
                        </div>

                        <Button title={'Supprimer la formation'} btnStyle={'cancel autofit'} confirmationRequired isLoading={surveyAdminStore.isActionLoading} onClick={() => dispatch(deleteUserSurveyAdminPopup(surveyAdminStore.surveyId))} />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AdminPopupHOC(SurveyAdminPopup, null, 'Détails du questionnaire', 'surveyAdminPopup');