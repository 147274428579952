import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useInterval } from './hook/UseInterval';
import { useTranslation } from 'react-i18next';

// Images
import { ReactComponent as Loading } from './resources/images/loading.svg';

const Button = (props) => {
    const buttonRef = useRef(null);
    const [buttonHeight, setButtonHeight] = useState(null);
    const [currentLoading, setCurrentLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [isConfirmationInProgress, setConfirmationInProgress] = useState(false);
    const [confirmationDelay, setConfirmationDelay] = useState(3);
    const [isConfirmed, setConfirmed] = useState(false);
    const { t } = useTranslation()

    useInterval(() => {
        const newTime = confirmationDelay - 1;
        setConfirmationDelay(newTime);
        setTitle(`${t("CONFIRMATION")} dans ${newTime}...`);

        if (!newTime) {
            setTitle(`Confirmer`);
            setConfirmed(true);
            setConfirmationInProgress(false);
        }
    }, 1000, isConfirmationInProgress && confirmationDelay)

    useEffect(() => {
        if (props.isLoading && buttonRef && buttonRef.current) {
            setButtonHeight(buttonRef.current.offsetHeight);
        }

        setCurrentLoading(props.isLoading);
    }, [props.isLoading]);

    const onClick = () => {
        if (!props.confirmationRequired || isConfirmed) {
            if (props.onClick)
                props.onClick();
        } else {
            setTitle(`${t("CONFIRMATION")} dans 3...`);
            setConfirmationInProgress(true);
        }
    }

    return (
        <button ref={buttonRef} style={buttonHeight ? { height: `${buttonHeight}px` } : null} className={`default rounded-s shadow transition ${props.btnStyle || ''}`} onClick={onClick} disabled={currentLoading || props.disabled || isConfirmationInProgress} type={props.type || 'button'} >
            {currentLoading ? <Loading /> : (!isConfirmationInProgress && props.children) || (title || props.title)}
        </button>
    );
}

Button.propTypes = {
    title: propTypes.string,
    onClick: propTypes.func,
    isLoading: propTypes.bool,
    disabled: propTypes.bool,
    btnStyle: propTypes.string,
    type: propTypes.string,
    confirmationRequired: propTypes.bool
};

export default Button;