import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { confirmAccount } from './redux/actions/MainActions';
import { useTranslation } from 'react-i18next';

const EmailConfirmationBanner = (props) => {
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const { email, code } = props.match.params;
    const [isSent, setSentStatus] = useState(true);
    const { t } = useTranslation()

    useEffect(() => {
        const fetch = async () => {
            setSentStatus(await dispatch(confirmAccount(email, code)));
        }

        fetch();
    }, [dispatch, code, email]);

    if (isSent === false)
        return <Redirect to='/'></Redirect>

    if (isSent === true)
        return (
            <CSSTransition nodeRef={nodeRef} in={isSent} mountOnEnter unmountOnExit timeout={200} classNames='slideDownBanner'>
                <div ref={nodeRef} className='email-confirmation-banner container shadow rounded-s'>
                    <div className='icon-empty'></div>

                    <div className='message'>
                        {t("CONGRULATION")} <span role='img' aria-label='enjoy'>🎉</span><br />
                        {t("YOUR_ACCOUNT_HAS_BEEN_ACTIVATED")}
                    </div>

                    <div className='icon-container' onClick={() => setSentStatus(false)}><div className='icon close white'></div></div>
                </div>
            </CSSTransition>
        );
}

export default EmailConfirmationBanner;