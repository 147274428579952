import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS } from '../../../redux/actions/ActionTypes';
import PreviewSection from './PreviewSection';
import { useTranslation } from 'react-i18next';

const PreviewDetail = () => {
    const dispatch = useDispatch();
    const previewStore = useSelector(store => store.previewPanel);
    const { t } = useTranslation();

    if (previewStore.course.section.length)
        return (
            <div className='detail'>
                <span className='expand' onClick={() => dispatch({ type: PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS, payload: { extend: !previewStore.isAllSectionExtended } })}>{previewStore.isAllSectionExtended ? t("PREVIEW_REDUCE_SECTIONS") : t("PREVIEW_EXTEND_SECTIONS")}</span>
                { previewStore.course.section.map(section => <PreviewSection key={section.id} section={section} isExtended={previewStore.selectedSectionId.includes(section.id)} />)}
            </div>
        );

    if (previewStore.course.manualPDF)
        return <a href={`https://resources.formation.academy/other/pdf/${previewStore.course.manualPDF}.pdf`} target='_blank' rel='noreferrer'><Button title={t("DOWNLOAD_COURSE_CONTENT")} btnStyle={'primary autofit'} /></a>;

    return (
        <div className='detail'>
            <div className='notification-banner rounded-m' style={{ marginTop: '30px' }}>
                <p>
                    Ce cours est actuellement en cours de création.<br />
                                                    Revenez ultérieurement ou contactez-nous pour plus d'informations
                                                </p>
            </div>
        </div>
    )
}

export default PreviewDetail