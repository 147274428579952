import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import IsLoggedHOC from '../../HOC/IsLoggedHOC';
import { loadDashboardCertificates, loadDashboardCourses } from '../../redux/actions/dashboard/DashboardActions';
import DashboardCertificateList from './home/DashboardCertificateList';
import DashboardCourseList from './home/DashboardCourseList';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const DashboardPanel = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(loadDashboardCourses());
        dispatch(loadDashboardCertificates());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>{t("DASHBOARD")} · FormationAcademy</title>
            </Helmet>

            <div className='page-container-wrapper basic-panel dashboard-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations rounded-xl'>
                            <h1>{t("DASHBOARD")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        <div className='container-title'>
                            <h2>{t("COURSES")}</h2>
                            <span>{t("DASHBOARD_COURSES_SUBTITLE")}</span>
                        </div>

                        <DashboardCourseList />

                        <div className='container-title'>
                            <h2>{t("CERTIFICATES")}</h2>
                            <span>{t("DASHBOARD_CERTIFICATES_SUBTITLE")}</span>
                        </div>

                        <DashboardCertificateList />
                    </div>
                </div>
            </div>
        </>
    );
}

export default IsLoggedHOC(DashboardPanel);