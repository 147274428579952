import React from 'react';
import { useTranslation } from 'react-i18next';

const LectureSurveyDescription = () => {
    const { t } = useTranslation();

    return (
        <div className='notification-banner rounded-xl large'>
            <span className='title'>{t("BEFORE_STARTING")}</span>

            <div className='content'>
                <ul dangerouslySetInnerHTML={{ __html: t("SURVEY_RULES") }}>
                </ul>
            </div>

            <span className='title center' style={{ marginTop: '20px', marginBottom: '0' }}>{t("GOOD_LUCK")}</span>
        </div>
    );
}

export default LectureSurveyDescription;