import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import { COURSE_ADMIN_POPUP_SET_CATEGORIES, COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE, COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE, COURSE_ADMIN_POPUP_SET_NEW_PUBLIC, COURSE_ADMIN_POPUP_SET_OBJECTIVES, COURSE_ADMIN_POPUP_SET_PREREQUISITES, COURSE_ADMIN_POPUP_SET_PUBLICS } from '../../../redux/actions/ActionTypes';
import { openAssignCategoryAdminPopup } from '../../../redux/actions/popup/admin/AssignCategoryPopupActions';
import { addSpecificationCourseAdminPopup, loadCategoriesCourseAdminPopup, loadSpecificationsCourseAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import { SPECIFICATION_TYPE } from '../../../resources/constants/specificationType';
import CategoryListCourseAdminPopup from './detailedInformation/CategoryListCourseAdminPopup';
import SpecificationListCourseAdminPopup from './detailedInformation/SpecificationListCourseAdminPopup';
import { useTranslation } from 'react-i18next';

const DetailedInformationCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPopup);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(loadSpecificationsCourseAdminPopup(courseAdminStore.courseId));
        dispatch(loadCategoriesCourseAdminPopup(courseAdminStore.courseId));

        return () => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_OBJECTIVES, payload: { objectives: [] } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PUBLICS, payload: { publics: [] } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_PREREQUISITES, payload: { prerequisites: [] } });

            dispatch({ type: COURSE_ADMIN_POPUP_SET_CATEGORIES, payload: { categories: [] } });
        }
    }, [dispatch, courseAdminStore.courseId]);

    const onObjectiveSubmit = (e) => {
        e.preventDefault();

        dispatch(addSpecificationCourseAdminPopup(courseAdminStore.courseId, courseAdminStore.newObjective, SPECIFICATION_TYPE.OBJECTIVE));
    }

    const onPublicSubmit = (e) => {
        e.preventDefault();

        dispatch(addSpecificationCourseAdminPopup(courseAdminStore.courseId, courseAdminStore.newPublic, SPECIFICATION_TYPE.PUBLIC));
    }

    const onPrerequisiteSubmit = (e) => {
        e.preventDefault();

        dispatch(addSpecificationCourseAdminPopup(courseAdminStore.courseId, courseAdminStore.newPrerequisite, SPECIFICATION_TYPE.PREREQUISITE));
    }

    return (
        <div className='overview'>
            <div>
                <div className='content-title'>
                    <h2>Caractéristiques</h2>
                </div>

                <div className='section'>
                    <div className='specs rounded-m'>
                        <div className='col'>
                            <h4>{t("PREVIEW_OBJECTIVES")}</h4>
                            <SpecificationListCourseAdminPopup icon={'check'} type={SPECIFICATION_TYPE.OBJECTIVE} isLoading={courseAdminStore.isSpecificationsLoading} isActionLoading={courseAdminStore.isSpecificationActionLoading} items={courseAdminStore.objectives} />

                            <form onSubmit={onObjectiveSubmit}>
                                <Input placeholder={'Ajouter une compétence'} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading} value={courseAdminStore.newObjective} onChange={(newObjective) => dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_OBJECTIVE, payload: { newObjective } })} error={!courseAdminStore.isNewObjectiveValid} helperText={t("HELPER_SPECIFICATION")} />
                                <Button type={'submit'} btnStyle={`primary ${!courseAdminStore.isNewObjectiveValid ? 'error' : ''}`} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading}><div className='icon plus white'></div></Button>
                            </form>
                        </div>

                        <div className='separator'></div>

                        <div className='col'>
                            <h4>{t("PREVIEW_PUBLIC")}</h4>
                            <SpecificationListCourseAdminPopup icon={'star'} type={SPECIFICATION_TYPE.PUBLIC} isLoading={courseAdminStore.isSpecificationsLoading} isActionLoading={courseAdminStore.isSpecificationActionLoading} items={courseAdminStore.publics} />

                            <form onSubmit={onPublicSubmit}>
                                <Input placeholder={'Ajouter un type de public'} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading} value={courseAdminStore.newPublic} onChange={(newPublic) => dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_PUBLIC, payload: { newPublic } })} error={!courseAdminStore.isNewPublicValid} helperText={t("HELPER_SPECIFICATION")} />
                                <Button type={'submit'} btnStyle={`primary ${!courseAdminStore.isNewPublicValid ? 'error' : ''}`} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading}><div className='icon plus white'></div></Button>
                            </form>

                            <h4 style={{ marginTop: '20px' }}>{t("PREVIEW_PREREQUISITES")}</h4>
                            <SpecificationListCourseAdminPopup icon={'bag'} type={SPECIFICATION_TYPE.PREREQUISITE} isLoading={courseAdminStore.isSpecificationsLoading} isActionLoading={courseAdminStore.isSpecificationActionLoading} items={courseAdminStore.prerequisites} />

                            <form onSubmit={onPrerequisiteSubmit}>
                                <Input placeholder={'Ajouter un prérequis'} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading} value={courseAdminStore.newPrerequisite} onChange={(newPrerequisite) => dispatch({ type: COURSE_ADMIN_POPUP_SET_NEW_PREREQUISITE, payload: { newPrerequisite } })} error={!courseAdminStore.isNewPrerequisiteValid} helperText={t("HELPER_SPECIFICATION")} />
                                <Button type={'submit'} btnStyle={`primary ${!courseAdminStore.isNewPrerequisiteValid ? 'error' : ''}`} disabled={courseAdminStore.isSpecificationsLoading || courseAdminStore.isSpecificationActionLoading}><div className='icon plus white'></div></Button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='content-title'>
                    <h2>Catégories parentes</h2>
                    <Button title='Assigner une catégorie' btnStyle={'primary autofit'} onClick={() => dispatch(openAssignCategoryAdminPopup(courseAdminStore.courseId, courseAdminStore.categories.map(c => c.id)))} />
                </div>

                <div className='section'>
                    <CategoryListCourseAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default DetailedInformationCourseAdminPopup;