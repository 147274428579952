import { ASSIGN_SECTION_ADMIN_POPUP_RESET, ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING, ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS, ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS, ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION, ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING } from "../../../actions/ActionTypes";


const defaultState = {
    popupEnabled: false,
    isLoading: true,

    courseId: null,
    sections: [],
    selectedSections: [],

    hasNext: true,
    pagingPage: 0,

    searchValue: '',
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_SECTION_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_SECTION_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID: {
            return { ...state, courseId: action.payload.id };
        }

        case ASSIGN_SECTION_ADMIN_POPUP_SET_SECTIONS: {
            return { ...state, sections: action.payload.sections };
        }
        case ASSIGN_SECTION_ADMIN_POPUP_ADD_SECTIONS: {
            return { ...state, sections: state.sections.concat(action.payload.sections) };
        }

        case ASSIGN_SECTION_ADMIN_POPUP_ADD_SELECTED_SECTION: {
            return { ...state, selectedSections: [...state.selectedSections, action.payload.id] };
        }
        case ASSIGN_SECTION_ADMIN_POPUP_SET_SECTION_LOADING: {
            return {
                ...state, sections: state.sections.map(s => {
                    if(s.id !== action.payload.id)
                        return s;

                    return {...s, isLoading: action.payload.isLoading};
                })
            }
        }

        case ASSIGN_SECTION_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case ASSIGN_SECTION_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case ASSIGN_SECTION_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}