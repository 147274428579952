import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { convertSecondToAverageTime, convertStringForUrl } from '../../../Utils';
import { COURSE_STATUS } from '../../../resources/constants/courseStatus';
import { useTranslation } from 'react-i18next';

// Images
import NoImage from '../../../resources/images/no-image.png';

const CourseItem = ({ course }) => {
    const { t } = useTranslation();
    
    return (
        <Link to={`/course/${course.id}-${convertStringForUrl(course.title)}/preview`} className='white shadow rounded-m thumbnail'>
            {course.status ? <div className='actions-banner only-status'>
                {course.status === COURSE_STATUS[0].id ? <span className='status success'>{t("NEW")}</span> : null}
                {course.status === COURSE_STATUS[2].id ? <span className='status warning'>{t("SOON_EXPIRED_SHORT")}</span> : null}
            </div> : null}

            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                {course.totalTime || course.nbLectures ? <div className='or'><hr /></div> : null}

                <ul className='stats'>
                    {course.totalTime ? <li><div className='icon time'></div>{convertSecondToAverageTime(course.totalTime)}</li> : null}
                    {course.nbLectures > 0 ? <li><div className='icon player'></div>{course.nbLectures} {course.nbLectures > 1 ? t("LECTURES") : t("LECTURE")}</li> : null}
                </ul>
            </div>
        </Link>
    );
}

CourseItem.propTypes = {
    course: propTypes.object.isRequired
};

export default CourseItem;