import Axios from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ADMIN_USER_MENU_TYPE } from '../../../../resources/constants/adminPanel';
import { createNotification, isValidEmail, isValidPassword } from "../../../../Utils";
import { ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING, COURSE_ADMIN_PANEL_DELETE_USER_COURSE, COURSE_ADMIN_POPUP_DELETE_USER, COURSE_ADMIN_POPUP_SET_USER_EXPIRE_DATE, COURSE_ADMIN_POPUP_SET_USER_LOADING, COURSE_ADMIN_POPUP_SET_USER_UNIT_COMPLETE_LOCK, USER_ADMIN_PANEL_ADD_USERS, USER_ADMIN_PANEL_DELETE_USER, USER_ADMIN_PANEL_UPDATE_USER, USER_ADMIN_PANEL_UPDATE_USER_PICTURE, USER_ADMIN_PANEL_UPDATE_USER_STATUS, USER_ADMIN_POPUP_COMPANY_CHECK, USER_ADMIN_POPUP_DELETE_CERTIFICATE, USER_ADMIN_POPUP_DELETE_COURSE, USER_ADMIN_POPUP_EMAIL_CHECK, USER_ADMIN_POPUP_FIRSTNAME_CHECK, USER_ADMIN_POPUP_LASTNAME_CHECK, USER_ADMIN_POPUP_NEW_PASSWORD_CHECK, USER_ADMIN_POPUP_PHONE_CHECK, USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK, USER_ADMIN_POPUP_SET_CERTIFICATES, USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING, USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING, USER_ADMIN_POPUP_SET_CONFIRMED, USER_ADMIN_POPUP_SET_COURSES, USER_ADMIN_POPUP_SET_COURSES_LOADING, USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE, USER_ADMIN_POPUP_SET_COURSE_LOADING, USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK, USER_ADMIN_POPUP_SET_ENABLED, USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, USER_ADMIN_POPUP_SET_LOADING, USER_ADMIN_POPUP_SET_PICTURE, USER_ADMIN_POPUP_SET_PICTURE_LOADING, USER_ADMIN_POPUP_SET_POPUP_TITLE, USER_ADMIN_POPUP_SET_SECURITY_LOADING, USER_ADMIN_POPUP_SET_SOCIAL, USER_ADMIN_POPUP_SET_SURVEYS, USER_ADMIN_POPUP_SET_SURVEYS_LOADING, USER_ADMIN_POPUP_SET_USER, USER_ADMIN_POPUP_SET_USER_ID, USER_ADMIN_POPUP_TOGGLE_MENU_STATUS } from "../../ActionTypes";
import i18n from 'i18next';

const checkUserInformations = (userAdminStore) => async dispatch => {
    const isEmailValid = isValidEmail(userAdminStore.email);
    const isFirstnameValid = userAdminStore.firstname && userAdminStore.firstname.length >= 3 && userAdminStore.firstname.length <= 50;
    const isLastnameValid = userAdminStore.lastname && userAdminStore.lastname.length >= 3 && userAdminStore.lastname.length <= 50;
    const isPhoneValid = !userAdminStore.phone || isValidPhoneNumber(`+${userAdminStore.phone}`);
    const isCompanyValid = !userAdminStore.company || (userAdminStore.company.length >= 3 && userAdminStore.company.length <= 50);

    dispatch({ type: USER_ADMIN_POPUP_EMAIL_CHECK, payload: { isValid: isEmailValid } });
    dispatch({ type: USER_ADMIN_POPUP_FIRSTNAME_CHECK, payload: { isValid: isFirstnameValid } });
    dispatch({ type: USER_ADMIN_POPUP_LASTNAME_CHECK, payload: { isValid: isLastnameValid } });
    dispatch({ type: USER_ADMIN_POPUP_PHONE_CHECK, payload: { isValid: isPhoneValid } });
    dispatch({ type: USER_ADMIN_POPUP_COMPANY_CHECK, payload: { isValid: isCompanyValid } });

    return isEmailValid && isFirstnameValid && isLastnameValid && isPhoneValid && isCompanyValid;
}

export const openUserAdminPopup = (userId = null) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_USER_MENU_TYPE.OVERVIEW } });

    if (userId) {
        dispatch({ type: USER_ADMIN_POPUP_SET_USER_ID, payload: { userId } });
    } else {
        dispatch({ type: USER_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: 'Création d\'un utilisateur' } });
        dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        dispatch({ type: USER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: false } });
    }
}

export const loadUserAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
    dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`user/${userId}/infos/admin`)
        .then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: `Modification de ${data.firstname} ${data.lastname}` } });
            dispatch({ type: USER_ADMIN_POPUP_SET_USER, payload: { user: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const createUserAdminPopup = (userAdminStore) => async dispatch => {
    if (await dispatch(checkUserInformations(userAdminStore))) {
        dispatch({ type: USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        Axios.post(`user/create/admin`, {
            email: userAdminStore.email,
            firstname: userAdminStore.firstname,
            lastname: userAdminStore.lastname,
            phone: userAdminStore.phone || null,
            company: userAdminStore.company || null
        }).then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_SET_USER, payload: { user: data } });
            dispatch({ type: USER_ADMIN_PANEL_ADD_USERS, payload: { users: [data] } });
            dispatch({ type: USER_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        })
    }
}

export const uploadUserPictureAdminPopup = (userId, file) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    const formData = new FormData();
    formData.append('Picture', file);

    Axios.put(`user/${userId}/update/picture/admin`, formData, {
        headers: { 'content-type': 'multipart/form-data' }
    }).then(({ data }) => {
        dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE, payload: { link: data.picture } });
        dispatch({ type: USER_ADMIN_PANEL_UPDATE_USER_PICTURE, payload: { id: userId, picture: data.picture } });
    }).finally(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
    });
}

export const deleteUserPictureAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: true } });

    Axios.delete(`user/${userId}/delete/picture/admin`)
        .then(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE, payload: { link: null } });
            dispatch({ type: USER_ADMIN_PANEL_UPDATE_USER_PICTURE, payload: { id: userId, picture: null } });
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_PICTURE_LOADING, payload: { isLoading: false } });
        });
}

export const updatePasswordUserAdminPopup = (userAdminStore) => async dispatch => {
    const isNewPasswordValid = isValidPassword(userAdminStore.newPassword);
    const isRenewPasswordValid = userAdminStore.newPassword === userAdminStore.renewPassword;

    dispatch({ type: USER_ADMIN_POPUP_NEW_PASSWORD_CHECK, payload: { isValid: isNewPasswordValid } });
    dispatch({ type: USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK, payload: { isValid: isRenewPasswordValid } });

    if (isNewPasswordValid && isRenewPasswordValid) {
        dispatch({ type: USER_ADMIN_POPUP_SET_SECURITY_LOADING, payload: { isLoading: true } });

        const { newPassword } = userAdminStore;
        Axios.put(`user/${userAdminStore.userId}/password/change/admin`, {
            newPassword
        }).then(() => {
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_PASSWORD_SUCCESS_ADMIN"), 'success');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_SECURITY_LOADING, payload: { isLoading: false } });
        });
    }
}

export const unlinkSocialAdminPopup = (userId, facebook = null, microsoft = null) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_SECURITY_LOADING, payload: { isLoading: true } });

    Axios.put(`user/${userId}/social/unlink/admin`, {
        facebook,
        microsoft
    }).then(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_SOCIAL, payload: { facebook, microsoft } });
    }).finally(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_SECURITY_LOADING, payload: { isLoading: false } });
    });
}

export const updateUserAdminPopup = (userAdminStore) => async dispatch => {
    if (await dispatch(checkUserInformations(userAdminStore))) {
        dispatch({ type: USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: true } });

        const { email, firstname, lastname, phone, company } = userAdminStore;

        Axios.put(`user/${userAdminStore.userId}/update/information/admin`, {
            email: email || null,
            firstname: firstname || null,
            lastname: lastname || null,
            phone: phone || null,
            company: company || null,
            password: 'Passw0rd'
        }).then(({ data }) => {
            dispatch({ type: USER_ADMIN_PANEL_UPDATE_USER, payload: { id: userAdminStore.userId, user: data } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_UPDATED_SUCCESS_ADMIN"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, payload: { isLoading: false } });
        });
    }
}

export const confirmUserAdminPopup = (userId, confirmed = true) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    return Axios.put(`user/${userId}/manage/confirmed/admin`, {
        confirmed
    }).then(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_CONFIRMED, payload: { confirmed } });
        dispatch({ type: USER_ADMIN_PANEL_UPDATE_USER_STATUS, payload: { id: userId, confirmed } });
        createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("PREFERENCES_CONFIRM_SUCCESS_ADMIN"), 'success');
        return true;
    }).finally(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}

export const loadUserCoursesAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: true } });
    dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`usercourse/courses/${userId}/admin`)
        .then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_SET_COURSES, payload: { courses: data } });
            dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES, payload: { courses: data } });
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_COURSES_LOADING, payload: { isLoading: false } });
            dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const updateExpireDateUserCourseAdminPopup = (userCourseId, date) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: true } });
    dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userCourseId, isLoading: true } });

    Axios.put(`usercourse/changeExpiryDate/${userCourseId}/admin`, {
        endDate: date
    }).then(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE, payload: { id: userCourseId, date } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_EXPIRE_DATE, payload: { id: userCourseId, date } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: false } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userCourseId, isLoading: false } });
    });
}

export const updateUnitCompleteLockUserCourseAdminPopup = (userCourseId, unitCompleteLock) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK, payload: { id: userCourseId, unitCompleteLock } });
    dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_UNIT_COMPLETE_LOCK, payload: { id: userCourseId, unitCompleteLock } });
    // Mettre loading carte

    Axios.put(`usercourse/${userCourseId}/change/locked`, {
        unitCompleteLock
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK, payload: { id: userCourseId, unitCompleteLock: !unitCompleteLock } });
        dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_UNIT_COMPLETE_LOCK, payload: { id: userCourseId, unitCompleteLock: !unitCompleteLock } });
    });
}

export const deleteUserCourseAdminPopup = (userCourseId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: true } });
    dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userCourseId, isLoading: true } });

    Axios.delete(`usercourse/unassign/${userCourseId}/admin`)
        .then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_DELETE_COURSE, payload: { id: userCourseId } });
            dispatch({ type: COURSE_ADMIN_POPUP_DELETE_USER, payload: { id: userCourseId } });
            dispatch({ type: COURSE_ADMIN_PANEL_DELETE_USER_COURSE, payload: { courseId: data.courseId, userId: data.userId } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: false } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_USER_LOADING, payload: { id: userCourseId, isLoading: false } });
        });
}

export const loadUserCertificatesAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING, payload: { isLoading: true } });

    Axios.get(`user/${userId}/certificates/admin`)
        .then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATES, payload: { certificates: data } });
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING, payload: { isLoading: false } });
        });
}

export const deleteUserCertificateAdminPopup = (certificateId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING, payload: { id: certificateId, isLoading: true } });

    Axios.delete(`certificate/${certificateId}/delete/admin`)
        .then(() => {
            dispatch({ type: USER_ADMIN_POPUP_DELETE_CERTIFICATE, payload: { id: certificateId } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING, payload: { id: certificateId, isLoading: false } });
        });
}

export const deleteUserAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.delete(`user/${userId}/delete/admin`)
        .then(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
            dispatch({ type: USER_ADMIN_PANEL_DELETE_USER, payload: { id: userId } });
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_USER_DELETED"), 'success');
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });
        })
}

export const loadUserSurveysAdminPopup = (userId) => async dispatch => {
    dispatch({ type: USER_ADMIN_POPUP_SET_SURVEYS_LOADING, payload: { isLoading: true } });

    Axios.get(`survey/user/${userId}/admin`)
        .then(({ data }) => {
            dispatch({ type: USER_ADMIN_POPUP_SET_SURVEYS, payload: { surveys: data } });
        }).finally(() => {
            dispatch({ type: USER_ADMIN_POPUP_SET_SURVEYS_LOADING, payload: { isLoading: false } });
        });
}