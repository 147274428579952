import React from 'react';
import { Helmet } from 'react-helmet';
import { TEAM } from '../../resources/constants/team';
import TeamItem from './team/TeamItem';
import { useTranslation } from 'react-i18next';

const TeamPanel = (props) => {
    const { t } = useTranslation();
    
    return (
        <>
            <Helmet>
                <title>{t("TEAM")} · FormationAcademy</title>
                <meta name="description" content="Découvrez l'équipe FormationAcademy composée d'experts de l'IT." />
            </Helmet>
            <div className='page-container-wrapper basic-panel team-panel'>
                <div className='container'>
                    <div className='top-banner rounded-xl'>
                        <div className='informations blur rounded-xl'>
                            <h1>{t("TEAM")}</h1>
                        </div>
                    </div>
                </div>

                <div className='container page'>
                    <div className='container-content main white shadow rounded-l'>
                        <div className='members'>
                            {TEAM.map((member, index) => <TeamItem key={index} member={member} />)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeamPanel;