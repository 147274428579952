import Guillaume from '../images/solution/interview/guillaume-2.png';
import Patrice from '../images/solution/interview/patrice-2.png';
import Sarah from '../images/solution/interview/sarah-2.png';

export const INTERVIEW = [
    {
        'name': "Guillaume",
        'picture': Guillaume,
        'comment': 'Quand tu n’as pas compris certains points, tu peux revenir en arrière quand tu as envie et ça, c’est top !',
        'video': 'https://www.youtube.com/watch?v=5aO4p5t_qOY'
    },
    {
        'name': "Patrice",
        'picture': Patrice,
        'comment': 'Ça donne la niac! Les méthodes données par le formateur sont plus que des clés. Les trucs se sont clarifiés et j’aurai désormais plus facile à mettre en place les concepts.',
        'video': 'https://www.youtube.com/watch?v=CDrqFDv7fEo'
    },
    {
        'name': "Sarah",
        'picture': Sarah,
        'comment': 'Si il y a des jours où j’ai plus de travail, je peux décaler la formation & m’y remettre plus tard. C’est important que le formateur soit disponible comme il l’a été.',
        'video': 'https://www.youtube.com/watch?v=ZRYcFErJaug'
    }
]