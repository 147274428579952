import React from 'react';
import propTypes from 'prop-types';
import Button from '../../../Button';
import { useDispatch, useSelector } from 'react-redux';
import { openCourseAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import { ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';
import { addCourseUserAdminPopup, addCourseVoucherAdminPopup } from '../../../redux/actions/popup/admin/AssignCoursePopupActions';

// Images
import NoImage from '../../../resources/images/no-image.png';

const CourseItemAssignCourseAdminPopup = ({ course, isSelected }) => {
    const dispatch = useDispatch();
    const assignCourseAdminStore = useSelector(store => store.assignCourseAdminPopup);

    const onEdit = () => {
        dispatch(openCourseAdminPopup(course.id));
        dispatch({ type: ASSIGN_COURSE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: false } });
    }

    const onAdd = () => {
        if(assignCourseAdminStore.voucherId) {
            dispatch(addCourseVoucherAdminPopup(course.id, assignCourseAdminStore.voucherId));
        } else {
            dispatch(addCourseUserAdminPopup(course.id, assignCourseAdminStore.userId))
        }
    }

    return (
        <div className={`item white shadow rounded-m thumbnail ${!course.enabled ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                {course.model ? <span className='status important'>Modèle</span> : <div></div>}
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            <div className='item transition' onClick={onEdit}>Modifier la formation</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                <Button title={isSelected ? 'Ajouté' : 'Ajouter'} btnStyle={`${isSelected ? 'success' : 'primary'}`} isLoading={course.isLoading} onClick={isSelected ? null : onAdd} />
            </div>
        </div>
    );
}

CourseItemAssignCourseAdminPopup.propTypes = {
    course: propTypes.object.isRequired,
    isSelected: propTypes.bool
};

export default CourseItemAssignCourseAdminPopup;