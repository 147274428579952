import { REGISTER_POPUP_STEP } from "../../../resources/constants/signPopupType";
import { SIGN_POPUP_SET_EMAIL, SIGN_POPUP_SET_PASSWORD, SIGN_POPUP_SET_LOADING, SIGN_POPUP_EMAIL_CHECK, SIGN_POPUP_PASSWORD_CHECK, SIGN_POPUP_SET_ENABLED, SIGN_POPUP_SET_FIRSTNAME, SIGN_POPUP_SET_LASTNAME, SIGN_POPUP_SET_PHONE, SIGN_POPUP_SET_COMPANY, SIGN_POPUP_FIRSTNAME_CHECK, SIGN_POPUP_LASTNAME_CHECK, SIGN_POPUP_PHONE_CHECK, SIGN_POPUP_COMPANY_CHECK, SIGN_POPUP_SET_REGISTER_STEP, SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD, SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD, SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK, SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK } from "../../actions/ActionTypes";

const defaultState = {
    popupEnabled: null,
    isLoading: false,

    email: '',
    password: '',

    isEmailValid: true,
    emailHelperText: '',
    isPasswordValid: true,
    passwordHelperText: '',

    // Register
    registerStep: REGISTER_POPUP_STEP.GENERAL,
    firstname: '',
    lastname: '',
    phone: '',
    company: '',

    isFirstnameValid: true,
    isLastnameValid: true,
    isPhoneValid: true,
    isCompanyValid: true,
    isEmailFree: null,

    // Update Password
    newPassword: '',
    renewPassword: '',

    isNewPasswordValid: true,
    isRenewPasswordValid: true
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case SIGN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.type };
        }
        case SIGN_POPUP_SET_REGISTER_STEP: {
            return { ...state, registerStep: action.payload.step };
        }

        case SIGN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case SIGN_POPUP_SET_EMAIL: {
            return { ...state, email: action.payload.email, isEmailValid: true };
        }
        case SIGN_POPUP_SET_PASSWORD: {
            return { ...state, password: action.payload.password, isPasswordValid: true };
        }

        case SIGN_POPUP_SET_FIRSTNAME: {
            return { ...state, firstname: action.payload.firstname, isFirstnameValid: true };
        }
        case SIGN_POPUP_SET_LASTNAME: {
            return { ...state, lastname: action.payload.lastname, isLastnameValid: true };
        }
        case SIGN_POPUP_SET_PHONE: {
            return { ...state, phone: action.payload.phone, isPhoneValid: true };
        }
        case SIGN_POPUP_SET_COMPANY: {
            return { ...state, company: action.payload.company, isCompanyValid: true };
        }

        case SIGN_POPUP_UPDATE_PASSWORD_SET_NEW_PASSWORD: {
            return { ...state, newPassword: action.payload.password, isNewPasswordValid: true };
        }
        case SIGN_POPUP_UPDATE_PASSWORD_SET_RENEW_PASSWORD: {
            return { ...state, renewPassword: action.payload.password, isRenewPasswordValid: true };
        }

        // CHECK
        case SIGN_POPUP_EMAIL_CHECK: {
            return { ...state, isEmailValid: action.payload.isValid, emailHelperText: action.payload.text, isEmailFree: action.payload.isEmailFree };
        }
        case SIGN_POPUP_PASSWORD_CHECK: {
            return { ...state, isPasswordValid: action.payload.isValid, passwordHelperText: action.payload.text };
        }
        case SIGN_POPUP_FIRSTNAME_CHECK: {
            return { ...state, isFirstnameValid: action.payload.isValid };
        }
        case SIGN_POPUP_LASTNAME_CHECK: {
            return { ...state, isLastnameValid: action.payload.isValid };
        }
        case SIGN_POPUP_PHONE_CHECK: {
            return { ...state, isPhoneValid: action.payload.isValid };
        }
        case SIGN_POPUP_COMPANY_CHECK: {
            return { ...state, isCompanyValid: action.payload.isValid };
        }
        case SIGN_POPUP_UPDATE_PASSWORD_NEW_PASSWORD_CHECK: {
            return { ...state, isNewPasswordValid: action.payload.isValid };
        }
        case SIGN_POPUP_UPDATE_PASSWORD_RENEW_PASSWORD_CHECK: {
            return { ...state, isRenewPasswordValid: action.payload.isValid };
        }

        default:
            return state;
    }
}