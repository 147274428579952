import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import { ASSIGN_LECTURE_ADMIN_POPUP_RESET, ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES, ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE } from '../../redux/actions/ActionTypes';
import { createNewLectureAdminPopup, loadLecturesAdminPopup } from '../../redux/actions/popup/admin/AssignLecturePopupActions';
import { convertStringForUrl } from '../../Utils';
import LectureListAssignLectureAdminPopup from './assignLecture/LectureListAssignLectureAdminPopup';

const AssignLectureAdminPopup = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const assignLectureAdminPopup = useSelector(store => store.assignLectureAdminPopup);
    const lectureStore = useSelector(store => store.lecturePanel);

    useEffect(() => {
        dispatch(loadLecturesAdminPopup(0, null));

        return () => {
            dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const createNewLecture = async () => {
        const newLectureId = await dispatch(createNewLectureAdminPopup(assignLectureAdminPopup.sectionId));

        if (newLectureId)
            history.replace(`/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${newLectureId}/admin`);
    }

    const onTyping = (value) => {
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_LECTURES, payload: { lectures: [] } });
        dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: 0 } });
        dispatch(loadLecturesAdminPopup(0, value));
    }

    return (
        <div className={`assign-category-admin`}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={assignLectureAdminPopup.searchValue} onChange={(searchValue) => dispatch({ type: ASSIGN_LECTURE_ADMIN_POPUP_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer une leçon' btnStyle='primary' onClick={createNewLecture} isLoading={assignLectureAdminPopup.isAddNewLoading} />
            </div>

            <div className='section'>
                <LectureListAssignLectureAdminPopup />

                {assignLectureAdminPopup.hasNext && <div className='center'><Button btnStyle={'secondary autofit'} title={'Charger plus de résultats'} isLoading={assignLectureAdminPopup.isLoading} onClick={() => dispatch(loadLecturesAdminPopup(assignLectureAdminPopup.pagingPage + 1, assignLectureAdminPopup.searchValue))} /></div>}
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignLectureAdminPopup, 'Ajouter une leçon', 'Création ou ajout d\'une leçon', null, null, null, {
    storeName: 'assignLectureAdminPopup',
    completion: (dispatch, store) => {
        if (!store.isLoading && store.hasNext) {
            dispatch(loadLecturesAdminPopup(store.pagingPage + 1, store.searchValue));
        }
    }
});