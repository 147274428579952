import React from 'react';
import ContentLoader from 'react-content-loader';
import { loaderParams } from '../resources/constants/loader';

const HeaderResultItemLoader = () => {
    return (
        <ContentLoader
            {...loaderParams}
            width={'100%'}
            height={40}
        >
            <rect x='0' y='0' rx='5' ry='5' width='40' height='40' />
            <rect x='50' y='0' rx='5' ry='5' width={'calc(100% - 50px)'} height='40' />
        </ContentLoader>
    );
}

export default HeaderResultItemLoader;