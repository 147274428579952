import React from 'react';
import propTypes from 'prop-types';

const AdditionalItemAssignAdditionalAdminPopup = ({ file }) => {
    return (
        <div className='item white shadow rounded-m thumbnail'>
            <img className='img-main rounded-s' src={file.link} alt={'additional'} />

            <div className='info'>
                <div className='progress-bar'>
                    <div className='progression'>
                        <div className={`progress transition shadow done ${file.error ? 'error' : ''}`} style={{ width: `${file.progress}%` }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AdditionalItemAssignAdditionalAdminPopup.propTypes = {
    file: propTypes.object.isRequired
};

export default AdditionalItemAssignAdditionalAdminPopup;