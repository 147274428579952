import React from 'react';
import ContentLoader from 'react-content-loader';
import { loaderParams } from '../resources/constants/loader';

const UserAdminPanelItemLoader = () => {
    return (
        <ContentLoader
            {...loaderParams}
            width={'100%'}
            height={385}
        >
            <rect x='calc((100% - 150px) / 2)' y='0' rx='75' ry='75' width='150' height='150' />
            <rect x='0' y='75' rx='15' ry='15' width='100%' height='310' />
        </ContentLoader>
    );
}

export default UserAdminPanelItemLoader;