import Axios from "axios";
import { ADMIN_COURSE_MENU_TYPE } from "../../../resources/constants/adminPanel";
import { createNotification } from "../../../Utils";
import { COURSE_ADMIN_PANEL_ADD_COURSES, COURSE_ADMIN_PANEL_SET_HAS_NEXT, COURSE_ADMIN_PANEL_SET_LOADING, COURSE_ADMIN_PANEL_SET_PAGING_PAGE, COURSE_ADMIN_POPUP_SET_COURSE, COURSE_ADMIN_POPUP_SET_ENABLED, COURSE_ADMIN_POPUP_SET_LOADING, COURSE_ADMIN_POPUP_SET_POPUP_TITLE, COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS, LECTURE_PANEL_ADMIN_ADD_MENU_SECTION, LECTURE_PANEL_ADMIN_NEW_SECTION_TITLE_CHECK, LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE_LOADING, LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE, LECTURE_PANEL_TOGGLE_SECTION } from "../ActionTypes";
import i18n from 'i18next';

export const loadCourseAdmin = (pageIndex = null, name = null) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_PANEL_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`course/list/all/admin`, {
        params: {
            pageIndex,
            name
        }
    }).then(({ data }) => {
        dispatch({ type: COURSE_ADMIN_PANEL_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
        dispatch({ type: COURSE_ADMIN_PANEL_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: COURSE_ADMIN_PANEL_ADD_COURSES, payload: { courses: data.paging } });
    }).finally(() => {
        dispatch({ type: COURSE_ADMIN_PANEL_SET_LOADING, payload: { isLoading: false } });
    });
}

export const duplicateCourseAdmin = (courseId) => async dispatch => {
    dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: ADMIN_COURSE_MENU_TYPE.OVERVIEW } });
    dispatch({ type: COURSE_ADMIN_POPUP_TOGGLE_MENU_STATUS, payload: { enable: true } });
    dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.post(`course/${courseId}/duplicate/admin`)
        .then(({ data }) => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_COURSE, payload: { course: data } });
            dispatch({ type: COURSE_ADMIN_POPUP_SET_POPUP_TITLE, payload: { title: `${data.title}` } });
            dispatch({ type: COURSE_ADMIN_PANEL_ADD_COURSES, payload: { courses: [data] } });
        }).catch((e) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            dispatch({ type: COURSE_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        }).finally(() => {
            dispatch({ type: COURSE_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const addNewSectionCourseAdmin = (courseId, title) => async dispatch => {
    const isTitleValid = title && title.length >= 3 && title.length <= 500;
    dispatch({ type: LECTURE_PANEL_ADMIN_NEW_SECTION_TITLE_CHECK, payload: { isValid: isTitleValid } });

    if (!isTitleValid)
        return false;

    dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE_LOADING, payload: { isLoading: true } });
    Axios.post(`section/add/admin`, {
        courseId,
        name: title
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_ADMIN_ADD_MENU_SECTION, payload: { section: { ...data, lectures: [] } } });
        dispatch({ type: LECTURE_PANEL_TOGGLE_SECTION, payload: { open: true, id: data.id } });
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE, payload: { isEditing: false } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE_LOADING, payload: { isLoading: false } });
    });
}