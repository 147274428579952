import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import CategoryItemAssignCategoryAdminPopup from './CategoryItemAssignCategoryAdminPopup';
import { useTranslation } from 'react-i18next';

const CategoryListAssignCategoryAdminPopup = ({ searchResults }) => {
    const assignCategoryAdminStore = useSelector(store => store.assignCategoryAdminPopup);
    const items = searchResults || assignCategoryAdminStore.categories;
    const { t } = useTranslation();

    if (assignCategoryAdminStore.isLoading)
        return (
            <div className='list'>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item white thumbnail large loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
            </div>
        );

    if (!items.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_CATEGORIES_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {items.map(category => <CategoryItemAssignCategoryAdminPopup key={category.id} category={category} isSelected={assignCategoryAdminStore.selectedCategories.includes(category.id)} />)}
        </div>
    );
}

export default CategoryListAssignCategoryAdminPopup