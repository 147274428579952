import React from 'react';
import MicrosoftLogin from 'react-microsoft-login';
import FacebookLogin from 'react-facebook-login';
import propTypes from 'prop-types';
import PopupHOC from './PopupHOC';
import { useDispatch } from 'react-redux';
import { SIGN_POPUP_SET_LOADING } from '../redux/actions/ActionTypes';
import { connectWithFacebook, connectWithMicrosoft } from '../redux/actions/popup/SignActions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images
import FacebookIcon from '../resources/images/authentication/facebook.png';
import MicrosoftIcon from '../resources/images/authentication/microsoft.png';

const SignPopupHOC = (WrappedComponent, title, SubTitleComponent) => {
    const HOC = (props) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const { t } = useTranslation()

        return (
            <div className='popup white rounded-l shadow padding sign'>
                <div className='header'>
                    <div className='title'>
                        <div className='icon-container'></div>
                        <h1>{t(title)}</h1>
                        <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                    </div>
                    <SubTitleComponent />
                </div>

                <div className='content'>
                    <WrappedComponent />
                </div>

                <div className='or'>
                    <hr />
                    <span>OU</span>
                    <hr />
                </div>

                <div className='social-login col-2'>
                    <FacebookLogin
                        appId='633716783787947'
                        cssClass={'button default shadow rounded-s facebook transition'}
                        icon={<img src={FacebookIcon} alt='login with facebook' />}
                        textButton={'Facebook'}
                        callback={(authData) => dispatch(connectWithFacebook(authData))}
                        isDisabled={props.isLoading}
                        onClick={() => dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } })}
                        onFailure={() => dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: false } })}
                        isMobile={false}
                    />

                    <div onClick={() => history.push('/')}>
                        <MicrosoftLogin
                            clientId='03996cae-f8f0-46da-bee7-07063bdc81b0'
                            authCallback={(err, authData) => dispatch(connectWithMicrosoft(err, authData))}
                            children={<button onClick={() => dispatch({ type: SIGN_POPUP_SET_LOADING, payload: { isLoading: true } })} disabled={props.isLoading} className='button default shadow rounded-s microsoft transition'><img src={MicrosoftIcon} alt='login with microsoft' />Microsoft</button>}
                        />
                    </div>
                </div>
            </div>
        );
    }

    HOC.propTypes = {
        isLoading: propTypes.bool
    };

    return PopupHOC(HOC);
}

export default SignPopupHOC;