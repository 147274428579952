import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { VOUCHER_ADMIN_POPUP_SET_COURSES } from '../../../redux/actions/ActionTypes';
import { openCourseAsVoucherAdminPopup } from '../../../redux/actions/popup/admin/AssignCoursePopupActions';
import { loadCourseVoucherAdminPopup } from '../../../redux/actions/popup/admin/VoucherPopupActions';
import CourseVoucherListAdminPopup from './course/CourseVoucherListAdminPopup';

const CourseVoucherAdminPopup = () => {
    const dispatch = useDispatch();
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);

    useEffect(() => {
        dispatch(loadCourseVoucherAdminPopup(voucherAdminStore.voucherId));

        return () => {
            dispatch({ type: VOUCHER_ADMIN_POPUP_SET_COURSES, payload: { courses: null } });
        }
    }, [dispatch, voucherAdminStore.voucherId]);

    return (
        <div className='courses-users'>
            <div>
                <div className='content-title'>
                    <h2>Formations</h2>
                    <Button title='Assigner une formation' btnStyle={'primary autofit'} onClick={() => dispatch(openCourseAsVoucherAdminPopup(voucherAdminStore.voucherId))} />
                </div>

                <div className='section'>
                    <CourseVoucherListAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default CourseVoucherAdminPopup;