import Axios from "axios";
import { createNotification } from "../../../Utils";
import { PREVIEW_POPUP_SET_ENABLED, PREVIEW_POPUP_SET_LECTURE, PREVIEW_POPUP_SET_LOADING } from "../ActionTypes"
import i18n from 'i18next';

export const loadPreviewLecture = (lectureId) => async dispatch => {
    dispatch({ type: PREVIEW_POPUP_SET_ENABLED, payload: { isEnabled: true } });
    dispatch({ type: PREVIEW_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`lecture/${lectureId}/preview`)
        .then(({ data }) => {
            dispatch({ type: PREVIEW_POPUP_SET_LECTURE, payload: { lecture: data } });
        }).catch(() => {
            dispatch({ type: PREVIEW_POPUP_SET_ENABLED, payload: { isEnabled: false } });
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("PREVIEW_LECTURE_NOT_FOUND"), 'danger');
        }).finally(() => {
            dispatch({ type: PREVIEW_POPUP_SET_LOADING, payload: { isLoading: false } });
        })
}