import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS, PREVIEW_PANEL_SET_COURSE, PREVIEW_PANEL_SET_LOADING, PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS, PREVIEW_PANEL_TOGGLE_SECTION } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    course: null,
    selectedSectionId: [],
    isAllSectionExtended: false,

    // Need more information
    name: '',
    isNameValid: true,
    email: '',
    isEmailValid: true,
    isFormLoading: false,
    isFormSent: false,

    interviewPopupEnabled: false,
    pdfStatus: 0 // 0 : Not downloaded | 1 : Downloading | 2 : Downloaded
}

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case PREVIEW_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case PREVIEW_PANEL_SET_COURSE: {
            return { ...state, course: action.payload.course, pdfStatus: 0 };
        }
        case PREVIEW_PANEL_TOGGLE_SECTION: {
            if (state.selectedSectionId.includes(action.payload.id))
                return { ...state, selectedSectionId: state.selectedSectionId.filter(id => id !== action.payload.id) };

            return { ...state, selectedSectionId: [...state.selectedSectionId, action.payload.id] };
        }
        case PREVIEW_PANEL_TOGGLE_EXTEND_SECTIONS: {
            return {
                ...state,
                selectedSectionId: action.payload.extend ? state.course.section.map(s => s.id) : [],
                isAllSectionExtended: !state.isAllSectionExtended
            };
        }

        case PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED: {
            return { ...state, interviewPopupEnabled: action.payload.popupEnabled };
        }

        case PREVIEW_PANEL_INTERVIEW_POPUP_SET_PDF_STATUS: {
            return { ...state, pdfStatus: action.payload.status };
        }


        default:
            return state;
    }
}