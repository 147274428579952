import Axios from "axios";
import { CATEGORY_PANEL_SET_CATEGORY, CATEGORY_PANEL_SET_LOADING } from "../ActionTypes";
import i18n from 'i18next';

export const categoryPanelLoadCategory = (categoryId) => async dispatch => {
    dispatch({ type: CATEGORY_PANEL_SET_LOADING, payload: { isLoading: true } });

    return Axios.get(`category/${categoryId}/details?lang=${i18n.language}`)
        .then(({ data }) => {
            dispatch({ type: CATEGORY_PANEL_SET_CATEGORY, payload: { category: data } });
        }).catch(() => {
            throw Error();
        }).finally(() => {
            dispatch({ type: CATEGORY_PANEL_SET_LOADING, payload: { isLoading: false } });
        })
}