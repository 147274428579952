import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import { ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED, ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID } from '../../../redux/actions/ActionTypes';
import VideoItemAssignVideoAdminPopup from './VideoItemAssignVideoAdminPopup';
import { useTranslation } from 'react-i18next';

export const openVideoAdminPopup = (lectureId) => async dispatch => {
    dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_VIDEO_ADMIN_POPUP_SET_LECTURE_ID, payload: { id: lectureId } });
}

const VideoListAssignVideoAdminPopup = () => {
    const assignVideoAdminStore = useSelector(store => store.assignVideoAdminPopup);
    const { t } = useTranslation();

    if (!assignVideoAdminStore.isLoading && !assignVideoAdminStore.videos?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_VIDEOS_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {assignVideoAdminStore.videos?.map(video => <VideoItemAssignVideoAdminPopup key={video.guid} video={video} isSelected={assignVideoAdminStore.selectedVideos.includes(video.guid)} />)}

            {assignVideoAdminStore.isLoading && <>
                <div className='item video-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item video-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
                <div className='item video-card rounded-m loading'><BasicLoader width={'100%'} height={300} radius={10} /></div>
            </>}
        </div>
    );
}

export default VideoListAssignVideoAdminPopup