import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Button from '../../../Button';
import Input from '../../../Input';
import BasicLoader from '../../../loader/BasicLoader';
import MultipleStateSwitch from '../../../MultipleStateSwitch';
import { COURSE_ADMIN_POPUP_SET_DESCRIPTION, COURSE_ADMIN_POPUP_SET_EXPIRY_UNIT, COURSE_ADMIN_POPUP_SET_EXPIRY_VALUE, COURSE_ADMIN_POPUP_SET_LANG, COURSE_ADMIN_POPUP_SET_OTHER_LANG_ID, COURSE_ADMIN_POPUP_SET_PREVIEW_VIDEO, COURSE_ADMIN_POPUP_SET_PRICE, COURSE_ADMIN_POPUP_SET_SEO_KEYWORDS, COURSE_ADMIN_POPUP_SET_TITLE, COURSE_ADMIN_POPUP_SET_UNIT_COMPLETE_LOCK } from '../../../redux/actions/ActionTypes';
import { createCourseAdminPopup, deleteCourseAdminPopup, deleteCoursePictureAdminPopup, updateCourseAdminPopup, updateCourseLock, updateCourseModelAdminPopup, updateCourseStatusAdminPopup, uploadCoursePictureAdminPopup } from '../../../redux/actions/popup/admin/CoursePopupActions';
import { COURSE_STATUS } from '../../../resources/constants/courseStatus';
import { DURATION_LBL } from '../../../resources/constants/durationType';
import { LANGUAGE_LBL } from '../../../resources/constants/lang';
import SwitchButton from '../../../SwitchButton';
import { createNotification } from '../../../Utils';
import ReactTagInput from "@pathofdev/react-tag-input";
import AuthenticationService from '../../../services/Authentication';
import { useTranslation } from 'react-i18next';

const OverviewCourseAdminPopup = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPopup);
    const [isHiddenModelButton, setHiddenModelButton] = useState(false);
    const filePickerRef = useRef(null);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        if (courseAdminStore.courseId) {
            dispatch(updateCourseAdminPopup(courseAdminStore))
        } else {
            dispatch(createCourseAdminPopup(courseAdminStore));
        }
    }

    const onImageChoosen = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            reader.onloadend = () => {
                if (file.size < 2000000) {
                    dispatch(uploadCoursePictureAdminPopup(courseAdminStore.courseId, file));
                } else {
                    createNotification(t("NOTIFICATION_ERROR_TITLE"), `${t("IMAGE_TOO_WEIGHT")}. (Maximum 2MB)`, 'danger');
                }
            }

            reader.readAsDataURL(file);
        }
    }

    const onCourseStatusSelect = (choice) => {
        dispatch(updateCourseStatusAdminPopup(courseAdminStore.courseId, choice.id));
    }

    return (
        <div className='overview'>
            <div>
                <form onSubmit={onSubmit}>
                    <div className='content-title'>
                        <h2>Présentation</h2>
                    </div>

                    <div className='section'>
                        {courseAdminStore.courseId && <>
                            <h3>Image</h3>

                            <div className={`picture rounded-xl ${courseAdminStore.isLoading || courseAdminStore.isPictureLoading ? 'disabled' : ''}`} style={!courseAdminStore.isLoading && courseAdminStore.picture ? { backgroundImage: `url(${courseAdminStore.picture})` } : null}>
                                {courseAdminStore.isLoading ? <BasicLoader width={'100%'} height={'100%'} radius={0} /> :
                                    <div className={`overlay transition ${!courseAdminStore.picture ? 'empty' : ''}`}>
                                        <Button title={courseAdminStore.picture ? 'Modifier l\'image' : 'Ajouter une image'} btnStyle={`autofit ${!courseAdminStore.picture ? 'secondary' : ''}`} disabled={courseAdminStore.isLoading} isLoading={courseAdminStore.isPictureLoading} onClick={() => filePickerRef.current.click()} />
                                        <input style={{ display: 'none' }} accept='image/*' type='file' ref={filePickerRef} onChange={onImageChoosen} />
                                        {courseAdminStore.picture && <Button title={t("DELETE_IMAGE")} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteCoursePictureAdminPopup(courseAdminStore.courseId))} isLoading={courseAdminStore.isPictureLoading} />}
                                    </div>}
                            </div>
                        </>}

                        <Input title={'Titre'} placeholder={'Titre'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} value={courseAdminStore.title} onChange={(title) => dispatch({ type: COURSE_ADMIN_POPUP_SET_TITLE, payload: { title } })} error={!courseAdminStore.isTitleValid} helperText={t("HELPER_TITLE_COURSE")} />
                        <Input title={'Description'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} error={!courseAdminStore.isDescriptionValid} helperText={t("HELPER_DESCRIPTION")}>
                            <textarea placeholder={'Aa'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} value={courseAdminStore.description} onChange={(e) => dispatch({ type: COURSE_ADMIN_POPUP_SET_DESCRIPTION, payload: { description: e.target.value } })}></textarea>
                        </Input>
                        <Input title={'Lien de la vidéo de témoignage (Youtube)'} placeholder={'https://youtube.com/watch?v=xxx'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} value={courseAdminStore.previewVideo} onChange={(previewVideo) => dispatch({ type: COURSE_ADMIN_POPUP_SET_PREVIEW_VIDEO, payload: { previewVideo } })} error={!courseAdminStore.isPreviewVideoValid} helperText={t("HELPER_URL")} />
                        <Input title={'Prix de la formation (EUR)'} type='number' placeholder={'2000'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} value={courseAdminStore.price} onChange={(price) => dispatch({ type: COURSE_ADMIN_POPUP_SET_PRICE, payload: { price } })} error={!courseAdminStore.isPriceValid} helperText={t("HELPER_PRICE")} />
                        <Input title={'Keywords de référencement & recherche (en complément du titre)'} placeholder='SSCM, 2020, SC'>
                            <div className='keywords-container'>
                                <ReactTagInput
                                    tags={courseAdminStore.seoKeyword}
                                    onChange={(seoKeyword) => dispatch({ type: COURSE_ADMIN_POPUP_SET_SEO_KEYWORDS, payload: { seoKeyword } })}
                                    placeholder={'Mot-clé'}
                                />
                            </div>
                        </Input>

                        <div className='content-title'>
                            <h2>Configuration par défaut</h2>
                        </div>
                        <div className='section'>
                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Formation ordonnée</h3>
                                    <span>Ce cours doit être suivi leçon par leçon</span>
                                </div>

                                <div className={`switch ${courseAdminStore.isLoading || courseAdminStore.isInformationsLoading ? 'disabled' : ''}`}>
                                    <SwitchButton disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} onChange={(unitCompleteLock) => dispatch({ type: COURSE_ADMIN_POPUP_SET_UNIT_COMPLETE_LOCK, payload: { unitCompleteLock } })} checked={courseAdminStore.unitCompleteLock} />
                                </div>
                            </div>

                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Durée d'expiration</h3>
                                    <span>Durée par défaut de disponibilité</span>
                                </div>

                                <div className={`duration-picker ${!courseAdminStore.isExpiryDurationValid ? 'error' : ''}`}>
                                    <Input type={'number'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} placeholder={'Valeur'} value={`${courseAdminStore.expiryDurationValue}`} onChange={(expiryDurationValue) => dispatch({ type: COURSE_ADMIN_POPUP_SET_EXPIRY_VALUE, payload: { expiryDurationValue } })} />
                                    <Select
                                        value={courseAdminStore.expiryDurationUnit}
                                        onChange={(option) => dispatch({ type: COURSE_ADMIN_POPUP_SET_EXPIRY_UNIT, payload: { expiryDurationUnit: option } })}
                                        options={DURATION_LBL}
                                        placeholder={'Durée...'}
                                        className={'selector'}
                                        isDisabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading}
                                    />
                                </div>
                            </div>

                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Langue</h3>
                                </div>

                                <div className={`duration-picker`}>
                                    <Input type={'number'} disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} placeholder={'ID autre langue'} value={`${courseAdminStore.otherLangId}`} onChange={(otherLangId) => dispatch({ type: COURSE_ADMIN_POPUP_SET_OTHER_LANG_ID, payload: { otherLangId } })} />
                                    <Select
                                        value={courseAdminStore.lang}
                                        onChange={(option) => dispatch({ type: COURSE_ADMIN_POPUP_SET_LANG, payload: { lang: option } })}
                                        options={LANGUAGE_LBL}
                                        placeholder={'Langue...'}
                                        className={'selector'}
                                        isDisabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='right-alignment'>
                        {courseAdminStore.courseId ? <Button title={t("UPDATE")} btnStyle='primary autofit' disabled={courseAdminStore.isLoading} isLoading={courseAdminStore.isInformationsLoading} type={'submit'} /> : <Button title={'Créer la formation'} btnStyle='primary autofit' disabled={courseAdminStore.isLoading} isLoading={courseAdminStore.isInformationsLoading} type={'submit'} />}
                    </div>
                </form>
            </div>

            {courseAdminStore.courseId &&
                <>
                    <div className='or'>
                        <hr />
                    </div>

                    <div>
                        <div className='content-title'>
                            <h2>Zone de danger</h2>
                        </div>
                        <div className='section'>
                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Statut de la formation</h3>
                                    <span>Un badge affiché en fonction du statut</span>
                                </div>

                                <MultipleStateSwitch choices={COURSE_STATUS} onSelect={onCourseStatusSelect} selectedId={courseAdminStore.status} />
                            </div>

                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Marquer comme modèle</h3>
                                    <span>La formation sera affichée dans le catalogue public</span>
                                </div>

                                {!isHiddenModelButton && <Button title={courseAdminStore.model ? 'Définir comme non modèle' : 'Définir comme modèle'} btnStyle={'cancel autofit'} confirmationRequired disabled={courseAdminStore.isLoading} isLoading={courseAdminStore.isInformationsLoading} onClick={async () => {
                                    if (await dispatch(updateCourseModelAdminPopup(courseAdminStore.courseId, !courseAdminStore.model)))
                                        setHiddenModelButton(true);
                                }} />}
                            </div>

                            {!courseAdminStore.locked || AuthenticationService.IsSuperAdmin() ?
                                <div className='danger-item'>
                                    <div className='labels'>
                                        <h3>Supprimer la formation</h3>
                                        <span>
                                            Cette opération est irréversible.<br />
                                            Les élèves associés perdront l'accès à ce cours : {courseAdminStore.activeUsers && <><b>{courseAdminStore.activeUsers.nbActiveUsers}</b> actif{courseAdminStore.activeUsers.nbActiveUsers > 1 ? 's' : ''} | <b>{courseAdminStore.activeUsers.nbInactiveUsers}</b> inactif{courseAdminStore.activeUsers.nbInactiveUsers > 1 ? 's' : ''}</>}
                                        </span>
                                    </div>

                                    <Button title={'Supprimer la formation'} btnStyle={'cancel autofit'} confirmationRequired disabled={courseAdminStore.isLoading} isLoading={courseAdminStore.isInformationsLoading} onClick={() => dispatch(deleteCourseAdminPopup(courseAdminStore.courseId))} />
                                </div> : null}

                            {AuthenticationService.IsSuperAdmin() ?
                                <div className='danger-item'>
                                    <div className='labels'>
                                        <h3>Formation bloquée</h3>
                                        <span>N'autorise que les super-admin a supprimer la formation</span>
                                    </div>

                                    <div className={`switch ${courseAdminStore.isLoading || courseAdminStore.isInformationsLoading ? 'disabled' : ''}`}>
                                        <SwitchButton disabled={courseAdminStore.isLoading || courseAdminStore.isInformationsLoading} onChange={(locked) => dispatch(updateCourseLock(courseAdminStore.courseId, locked))} checked={courseAdminStore.locked} />
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default OverviewCourseAdminPopup;