import jsPDF from 'jspdf';
import Logo from '../resources/images/logo/logo.png';
import Reunit from '../resources/images/reunit.png';
import Signature from '../resources/images/signature.png';
import '../resources/fonts/Raleway-Bold-normal';
import '../resources/fonts/Raleway-Light-normal';
import '../resources/fonts/Raleway-SemiBold-normal';
import { convertHourToDuration, convertStringToDate } from '../Utils';
import { DURATION_LBL } from '../resources/constants/durationType';

export default function CertificatePDF(certificate, user) {
    const doc = new jsPDF('landscape');

    doc.addImage(Logo, 'png', (doc.internal.pageSize.getWidth() - 75) / 2, 15, 75, 28);

    doc.setDrawColor(33, 166, 193);
    doc.setLineWidth(5);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), "S");

    doc.setFont("Raleway-Bold");
    doc.setFontSize(40);
    doc.text("Certificat de formation", doc.internal.pageSize.getWidth() / 2, 60, "center");

    doc.setFont("Raleway-Light");
    doc.setFontSize(14);
    doc.text("Certificat attribué à ", doc.internal.pageSize.getWidth() / 2, 80, "center");

    doc.setFont("Raleway-Bold");
    doc.setFontSize(30);
    doc.setTextColor(33, 166, 193);
    doc.text(doc.splitTextToSize(`${user.firstname} ${user.lastname}`, doc.internal.pageSize.getWidth() - 30), doc.internal.pageSize.getWidth() / 2, 95, "center");

    doc.setFont("Raleway-Light");
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text("Pour avoir suivi la totalité de la formation", doc.internal.pageSize.getWidth() / 2, 110, "center");

    doc.setFont("Raleway-Bold");
    doc.setFontSize(30);
    doc.setTextColor(33, 166, 193);
    doc.text(doc.splitTextToSize(certificate.title, doc.internal.pageSize.getWidth() - 30), doc.internal.pageSize.getWidth() / 2, 125, "center");



    doc.addImage(Signature, 'png', 50, 150, 20, 16.13);
    doc.setFillColor(33, 166, 193);
    doc.roundedRect(10, 170, 100, 1, 0.5, 0.5, "F");
    doc.roundedRect(doc.internal.pageSize.getWidth() - 110, 170, 100, 1, 0.5, 0.5, "F");

    doc.setFont("Raleway-SemiBold");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text("Coach", 60, 180, "center");

    doc.setFont("Raleway-Light");
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(doc.splitTextToSize("Freddy Janssens", 100), 60, 188, "center");

    if(certificate.durationHour) {
        const duration = convertHourToDuration(certificate.durationHour);
        doc.text(`Durée : ${duration.value} ${DURATION_LBL.filter(lbl => lbl.value === duration.type)[0].label}`, doc.internal.pageSize.getWidth() - 60, 165, "center");
    }

    doc.setFont("Raleway-SemiBold");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text("Date de délivrance", doc.internal.pageSize.getWidth() - 60, 180, "center");

    doc.setFont("Raleway-Light");
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(doc.splitTextToSize(`Le ${convertStringToDate(certificate.issueDate)}`, 100), doc.internal.pageSize.getWidth() - 60, 188, "center");

    doc.addImage(Reunit, 'png', (doc.internal.pageSize.getWidth() - 20) / 2, doc.internal.pageSize.getHeight() - 20, 20, 5.8);
    doc.setFont("Raleway-SemiBold");
    doc.setFontSize(6);
    doc.text("N° agrégation : 00131707", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, "center");
    doc.text("RCSL B 91.973", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 7, "center");

    doc.save(`Certificat ${certificate.title} - ${user.firstname} ${user.lastname}`);
}