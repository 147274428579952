import { VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER, VOUCHER_ADMIN_PANEL_ADD_VOUCHERS, VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER, VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER, VOUCHER_ADMIN_PANEL_DELETE_VOUCHER, VOUCHER_ADMIN_PANEL_SET_HAS_NEXT, VOUCHER_ADMIN_PANEL_SET_LOADING, VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE, VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE, VOUCHER_ADMIN_PANEL_SET_VOUCHERS, VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE, VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING, VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    vouchers: [],
    pagingPage: 0,
    hasNext: true,

    searchValue: ''
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case VOUCHER_ADMIN_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case VOUCHER_ADMIN_PANEL_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case VOUCHER_ADMIN_PANEL_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }
        case VOUCHER_ADMIN_PANEL_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }

        case VOUCHER_ADMIN_PANEL_SET_VOUCHERS: {
            return { ...state, vouchers: action.payload.vouchers };
        }
        case VOUCHER_ADMIN_PANEL_ADD_VOUCHERS: {
            return { ...state, vouchers: [...state.vouchers.concat(action.payload.vouchers)] };
        }

        case VOUCHER_ADMIN_PANEL_SET_VOUCHER_LOADING: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.id)
                        return v;

                    return { ...v, isLoading: action.payload.isLoading };
                })
            }
        }
        case VOUCHER_ADMIN_PANEL_SET_VOUCHER_END_DATE: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.id)
                        return v;

                    return { ...v, endDate: action.payload.date };
                })
            }
        }
        case VOUCHER_ADMIN_PANEL_DELETE_VOUCHER: {
            return { ...state, vouchers: state.vouchers.filter(v => v.id !== action.payload.id) };
        }
        case VOUCHER_ADMIN_PANEL_UPDATE_VOUCHER: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.id)
                        return v;

                    return action.payload.voucher;
                })
            }
        }
        case VOUCHER_ADMIN_PANEL_ADD_COURSE_VOUCHER: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.voucherId)
                        return v;

                    return { ...v, voucherCourses: [...v.voucherCourses, action.payload.voucherCourse] };
                })
            }
        }
        case VOUCHER_ADMIN_PANEL_DELETE_COURSE_VOUCHER: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.voucherId)
                        return v;

                    return { ...v, voucherCourses: v.voucherCourses.filter(vc => vc.id !== action.payload.courseVoucherId) };
                })
            }
        }
        case VOUCHER_ADMIN_PANEL_DELETE_USER_VOUCHER: {
            return {
                ...state, vouchers: state.vouchers.map(v => {
                    if (v.id !== action.payload.voucherId)
                        return v;

                    return { ...v, usersVoucher: v.usersVoucher.filter(vc => vc.id !== action.payload.userVoucherId) };
                })
            }
        }

        default:
            return state;
    }
}