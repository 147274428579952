import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openCategoryAdminPopup } from '../../../redux/actions/popup/admin/CategoryPopupActions';

// Images
import NoImage from '../../../resources/images/no-image.png';

const CategoryItemAdminTree = ({ category }) => {
    const dispatch = useDispatch();

    return (
        <div className='tree-item thumbnail'>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white' onClick={() => dispatch(openCategoryAdminPopup(category.id))}>
                    <div className='icon more'></div>
                </div>
            </div>
            <div className='image' style={{ background: `url('${category.picture || NoImage}')` }}></div>
            <span>{category.title}</span>
        </div>
    )
}

CategoryItemAdminTree.propTypes = {
    category: propTypes.object.isRequired
};

export default CategoryItemAdminTree;