import React from 'react';
import propTypes from 'prop-types';

// Images
import Profile from '../../../resources/images/profile.png';

const CourseAdminItemPeople = ({ people }) => {
    const firstFour = people.slice(0, 4);
    const extraUsers = people.slice(4, 24);

    return (
        <>
            <div className='people'>
                {firstFour.map(user =>
                    <div className='face' key={user.id}>
                        <div className='popover'>
                            <span className='name'>{user.firstname} {user.lastname}</span>
                            <div className='triangle'></div>
                        </div>
                        <img className='shadow' src={user.picture || Profile} alt={`${user.firstname} ${user.lastname}`} />
                    </div>
                )}

                {extraUsers && extraUsers.length ?
                    <div className='face'>
                        <div className='popover'>
                            <div className='names'>
                                {extraUsers.map(user => <span key={user.id}>{user.firstname} {user.lastname}</span>)}
                            </div>
                            <div className='triangle'></div>
                        </div>
                        <div className='empty-face'><span>+{extraUsers.length}</span></div>
                    </div> : null}
            </div>
        </>
    );
}

CourseAdminItemPeople.propTypes = {
    people: propTypes.array.isRequired
}

export default CourseAdminItemPeople;