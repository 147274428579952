export const ADMIN_PANEL = {
    COURSES: 'courses',
    USERS: 'users',
    CATEGORIES: 'categories',
    VOUCHERS: 'vouchers',
    TRACER: 'tracer'
};

// USERS
export const ADMIN_USER_MENU_TYPE = {
    OVERVIEW: 0,
    SECURITY: 1,
    COURSES: 2,
    CERTIFICATES: 3,
    SURVEYS: 4
};

export const ADMIN_USER_MENU = [
    {
        id: ADMIN_USER_MENU_TYPE.OVERVIEW,
        title: 'Vue générale',
    },
    {
        id: ADMIN_USER_MENU_TYPE.SECURITY,
        title: 'Sécurité',
        disabled: true
    },
    {
        id: ADMIN_USER_MENU_TYPE.COURSES,
        title: 'Formations assignées',
        disabled: true
    },
    {
        id: ADMIN_USER_MENU_TYPE.CERTIFICATES,
        title: 'Certificats',
        disabled: true
    },
    {
        id: ADMIN_USER_MENU_TYPE.SURVEYS,
        title: 'Questionnaires',
        disabled: true
    }
];

// COURSES
export const ADMIN_COURSE_MENU_TYPE = {
    OVERVIEW: 0,
    DETAILS: 1,
    USERS: 2
};

export const ADMIN_COURSE_MENU = [
    {
        id: ADMIN_COURSE_MENU_TYPE.OVERVIEW,
        title: 'Vue générale'
    },
    {
        id: ADMIN_COURSE_MENU_TYPE.DETAILS,
        title: 'Informations détaillées',
        disabled: true
    },
    {
        id: ADMIN_COURSE_MENU_TYPE.USERS,
        title: 'Etudiants assignés',
        disabled: true
    }
];

// CATEGORY
export const ADMIN_CATEGORY_MENU_TYPE = {
    OVERVIEW: 0,
    COURSES: 1,
};

export const ADMIN_CATEGORY_MENU = [
    {
        id: ADMIN_CATEGORY_MENU_TYPE.OVERVIEW,
        title: 'Vue générale'
    },
    {
        id: ADMIN_CATEGORY_MENU_TYPE.COURSES,
        title: 'Formations',
        disabled: true
    }
];

// VOUCHER
export const ADMIN_VOUCHER_MENU_TYPE = {
    OVERVIEW: 0,
    COURSES: 1,
    USERS: 2
};

export const ADMIN_VOUCHER_MENU = [
    {
        id: ADMIN_VOUCHER_MENU_TYPE.OVERVIEW,
        title: 'Vue générale'
    },
    {
        id: ADMIN_VOUCHER_MENU_TYPE.COURSES,
        title: 'Formations',
        disabled: true
    },
    {
        id: ADMIN_VOUCHER_MENU_TYPE.USERS,
        title: 'Etudiants assignés',
        disabled: true
    }
];