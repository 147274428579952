import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import Button from '../../../Button';
import { deleteResource, updateResourcePositionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { LECTURE_TYPE } from '../../../resources/constants/lectureType';
import { ReactSortable } from 'react-sortablejs';
import { LECTURE_PANEL_ADMIN_SET_DOCUMENTATIONS } from '../../../redux/actions/ActionTypes';

const LectureDocumentation = ({ admin }) => {
    const lectureStore = useSelector(store => store.lecturePanel);
    const dispatch = useDispatch();

    const onPositionUpdate = (e) => {
        const movedItem = lectureStore.lecture.documentations[e.newIndex];
        dispatch(updateResourcePositionAdmin(movedItem.id, e.newIndex));
    }

    return (
        <div className='resource'>
            <h5>Documentation</h5>

            <ul className='links'>
                <ReactSortable chosenClass={'disabled'} disabled={!admin} delay={200} animation={200} list={lectureStore.lecture.documentations} setList={(documentations) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_DOCUMENTATIONS, payload: { documentations } })} onEnd={onPositionUpdate}>
                    {lectureStore.lecture.documentations.map(documentation =>
                        <li key={documentation.id}>
                            <a href={documentation.link} target="_blank" rel="noopener noreferrer">
                                <div className={`icon ${admin ? 'more' : 'link blue'}`}></div>
                                {documentation.title}
                            </a>

                            {admin && <Button title={'Supprimer le lien'} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteResource(documentation.id, LECTURE_TYPE.DOCUMENTATION))} />}
                        </li>)}
                </ReactSortable>
            </ul>
        </div>
    );
}

LectureDocumentation.propTypes = {
    admin: propTypes.bool
};

export default LectureDocumentation;