import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { CATEGORY_ADMIN_POPUP_RESET, CATEGORY_ADMIN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { loadCategoryAdminPopup } from '../../redux/actions/popup/admin/CategoryPopupActions';
import { ADMIN_CATEGORY_MENU_TYPE } from '../../resources/constants/adminPanel';
import CourseCategoryAdminPopup from './category/CourseCategoryAdminPopup';
import OverviewCategoryAdminPopup from './category/OverviewCategoryAdminPopup';

const CategoryAdminPopup = () => {
    const dispatch = useDispatch();
    const categoryAdminStore = useSelector(store => store.categoryAdminPopup);

    useEffect(() => {
        if (categoryAdminStore.categoryId)
            dispatch(loadCategoryAdminPopup(categoryAdminStore.categoryId));

        return () => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_RESET });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={`course-admin ${categoryAdminStore.isLoading ? 'disabled' : ''}`}>
            {categoryAdminStore.popupEnabled === ADMIN_CATEGORY_MENU_TYPE.OVERVIEW && <OverviewCategoryAdminPopup />}
            {categoryAdminStore.popupEnabled === ADMIN_CATEGORY_MENU_TYPE.COURSES && <CourseCategoryAdminPopup />}
        </div>
    );
}

export default AdminPopupHOC(CategoryAdminPopup, null, 'Opération sur une categorie', 'categoryAdminPopup', CATEGORY_ADMIN_POPUP_SET_ENABLED);