import React from 'react';
import { useTranslation } from 'react-i18next';

const TransitionViewOne = ({ transitionViewRef, transitionViewPathRef, transitionViewAdvantage1Ref, transitionViewAdvantage2Ref, transitionViewAdvantage3Ref }) => {
    const { t } = useTranslation()

    return (
        <div className='sub-main transition' ref={transitionViewRef} id='transition-1'>
            <div className='container'>
                <div className='story-overlay'>
                    <div className='row'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage1Ref}>
                            <div className='left'>
                                <div className='icon folder'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("GIVE_SKILLS")}</span>
                                <p>{t("GIVE_SKILLS_DESC")}</p>
                            </div>
                        </div>

                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage3Ref}>
                            <div className='left'>
                                <div className='icon star'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("EASY_CERTIFICATION")}</span>
                                <p>{t("EASY_CERTIFICATION_DESC")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage2Ref}>
                            <div className='left'>
                                <div className='icon calendar'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("365DAYS_TEACHER")}</span>
                                <p>{t("365DAYS_TEACHER_DESC")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3003.1 370.4">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(255, 15, 123, 1)" />
                            <stop offset="100%" stopColor="rgba(248, 155, 41, 1)" />
                        </linearGradient>
                    </defs>
                    <path ref={transitionViewPathRef} fill="none" stroke="url(#gradient)" strokeLinecap="round" strokeWidth="8" d="M12.5,366.8C280,176.2,672.7-31.9,1129.3,8.8c223.3,19.9,327.6,87.4,562.9,139c292.7,64.2,722.3,89.4,1298.4-104" />
                </svg>
            </div>
        </div >
    );
}

export default TransitionViewOne;