import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../Button';
import { addCertificateAdminPopup } from '../../../redux/actions/popup/admin/AssignCertificatePopupActions';
import { convertStringToDate, convertStringToTime } from '../../../Utils';
import { useTranslation } from 'react-i18next';

// Images
import NoImage from '../../../resources/images/no-image.png';
import Profile from '../../../resources/images/profile.png';

const TracerAdminItem = ({ tracer }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const progression = (tracer.nbLecturesDone / tracer.nbLectures) * 100;

    return (
        <div className={`item white shadow rounded-m thumbnail tracer-card ${tracer.isLoading ? 'disabled' : ''}`}>
            <img className='img-main rounded-s' src={tracer.course?.picture || NoImage} alt={tracer.course?.title} />

            <div className='main-info'>
                <div className='img'>
                    <img className='shadow' src={tracer.user?.picture || Profile} alt={`${tracer.user?.firstname} ${tracer.user?.lastname}`} />
                    <div className={`dot shadow ${tracer.userOnline ? 'green' : 'red'}`}></div>
                    {!tracer.userOnline && <span className='last-connection'>{convertStringToDate(tracer.lastConnection)} {convertStringToTime(tracer.lastConnection)}</span>}
                </div>
                <div className='titles'>
                    <span className='name'>{tracer.user?.firstname} {tracer.user?.lastname}</span>
                    <span className='title'>{tracer.course?.title}</span>
                </div>
            </div>

            <div className='progress-bar'>
                <div className='progression'>
                    <div className='progress shadow' style={{ width: `${progression}%` }}></div>
                </div>

                <span><b>{tracer.nbLecturesDone} / {tracer.nbLectures}</b> {tracer.nbLecturesDone > 1 ? t("COURSES_DONE") : t("COURSE_DONE")} <b>({Math.floor(progression)}%)</b></span>
            </div>

            <div className='detail rounded-s'>
                <span className='subtitle'>Section</span>
                <span className='e-title'>{tracer.sectionTitle}</span>

                <span className='subtitle'>Leçon actuelle</span>
                <span className='e-title'>{tracer.lectureTitle}</span>
            </div>

            <Button title={tracer.certificateReceived ? 'Certificat distribué' : 'Donner le certificat'} btnStyle={tracer.certificateReceived ? 'success' : 'primary'} isLoading={tracer.isLoading} disabled={tracer.certificateReceived} onClick={() => dispatch(addCertificateAdminPopup(tracer.id))} />
        </div>
    );
}

TracerAdminItem.propTypes = {
    tracer: propTypes.object.isRequired
};

export default TracerAdminItem;