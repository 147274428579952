import React from 'react';
import propTypes from 'prop-types';
import { timeRemainingForNow } from '../../Utils';

const VoucherAddedItem = ({ voucher }) => {
    return (
        <div className='white shadow rounded-m thumbnail horizontal'>
            <img className='img-main rounded-s' src={voucher.picture} alt={voucher.title} />

            <div className='info'>
                <span>{voucher.title}</span>

                <div className='or'>
                    <hr />
                </div>

                <ul className='stats'>
                    <li><div className='icon time-left'></div>{timeRemainingForNow(voucher.expireDate).str}</li>
                </ul>
            </div>
        </div>
    );
}

VoucherAddedItem.propTypes = {
    voucher: propTypes.object.isRequired
};

export default VoucherAddedItem;