import React from 'react';
import Switch from 'react-switch';
import propTypes from 'prop-types';

const SwitchButton = (props) => {
    return (
        <Switch onChange={props.onChange} disabled={props.disabled} checked={props.checked} uncheckedIcon={false} checkedIcon={false} className={`shadow corner white ${props.className}`} offColor={!props.isWhite ? '#e2e6ea' : '#fff'} onColor='#2FCE71' offHandleColor='#4c6580' handleDiameter={props.zoom ? 20 * props.zoom : 20} height={props.zoom ? 28 * props.zoom : 28} width={props.zoom ? 55 * props.zoom : 55} />
    );
}

SwitchButton.propTypes = {
    disabled: propTypes.bool,
    onChange: propTypes.func,
    checked: propTypes.bool,
    className: propTypes.string,
    isWhite: propTypes.bool,
    zoom: propTypes.number
}

export default SwitchButton;