import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import BasicLoader from '../../../loader/BasicLoader';
import { VOUCHER_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';
import DashboardCourseItem from './DashboardCourseItem';
import { useTranslation } from 'react-i18next';

const DashboardCourseList = () => {
    const dashboardStore = useSelector(store => store.dashboardPanel);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    if (dashboardStore.isCoursesLoading)
        return (
            <div className='list'>
                <div className='thumbnail loading'>
                    <BasicLoader width={'100%'} height={300} radius={10} />
                </div>

                <div className='thumbnail loading'>
                    <BasicLoader width={'100%'} height={300} radius={10} />
                </div>

                <div className='thumbnail loading'>
                    <BasicLoader width={'100%'} height={300} radius={10} />
                </div>
            </div>
        );

    if (!dashboardStore.courses || !dashboardStore.courses.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("DASHBOARD_NO_COURSES")}

                <Button title={t("USE_CODE")} btnStyle='primary autofit no-margin-top margin-left' onClick={() => dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: true } })} />
            </div>
        );

    return (
        <div className='list'>
            {dashboardStore.courses.map(course => <DashboardCourseItem key={course.id} course={course} />)}
        </div>
    );
}

export default DashboardCourseList;