import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Input';
import PasswordStrength from '../../PasswordStrength';
import { SIGN_POPUP_SET_PASSWORD } from '../../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';

const RegisterPopupPassword = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    return (
        <div>
            <Input title={t("PASSWORD")} placeholder={t("PASSWORD")} value={signStore.password} onChange={(password) => dispatch({ type: SIGN_POPUP_SET_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={signStore.isLoading} error={!signStore.isPasswordValid} helperText={signStore.passwordHelperText} passwordToggle />

            <PasswordStrength password={signStore.password} />
        </div>
    );
}

export default RegisterPopupPassword;