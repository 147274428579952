import React from 'react';
import Button from '../../../Button';
import propTypes from 'prop-types';
import { convertSecondToAverageTime, convertStringForUrl } from '../../../Utils';

// Images
import NoImage from '../../../resources/images/no-image.png';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateLectureToSectionAdminPopup } from '../../../redux/actions/popup/admin/AssignLecturePopupActions';
import { useHistory } from 'react-router-dom';

const LectureItemAssignLectureAdminPopup = ({ lecture, isSelected }) => {
    const dispatch = useDispatch();
    const assignLectureAdminStore = useSelector(store => store.assignLectureAdminPopup);
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();

    const onDuplicate = async () => {
        if (!isSelected) {
            const lectureId = await dispatch(duplicateLectureToSectionAdminPopup(lecture.id, assignLectureAdminStore.sectionId));

            if (lectureId)
                history.replace(`/course/${lectureStore.menu.id}-${convertStringForUrl(lectureStore.menu.title)}/lecture/${lectureId}/admin`);
        }
    }

    return (
        <div className='item section-card lecture-card shadow white rounded-m'>
            <span className='title'>{lecture.name}</span>

            <div className='included-course rounded-s'>
                <div className='actions-banner'>
                    <label className='sub-title'>inclus dans le chapitre</label>
                    {lecture.courseIdModel && <span className='status important'>Modèle</span>}
                </div>

                <div className='lecture'>
                    <span className='lecture-name'>{lecture.sectionName}</span>
                </div>

                <label className='sub-title course'>de la formation</label>
                <div className='course'>
                    <img className='rounded-s' src={lecture.coursePicture || NoImage} alt={lecture.courseTitle} />
                    <span className='course-name'>{lecture.courseTitle}</span>
                </div>
            </div>

            <div className='or'>
                <hr />
            </div>

            <ul className='stats'>
                <li><div className='icon time'></div>{lecture.totalTime ? convertSecondToAverageTime(lecture.totalTime) : '00:00'}</li>
                <li><div className='icon player'></div>{lecture.nbVideos} vidéo{lecture.nbVideos > 1 ? 's' : ''}</li>
            </ul>

            <Button title={isSelected ? 'Importé' : 'Importer'} isLoading={lecture.isLoading} btnStyle={isSelected ? 'success' : 'primary'} onClick={onDuplicate} />
        </div>
    );
}

LectureItemAssignLectureAdminPopup.propTypes = {
    lecture: propTypes.object.isRequired,
    isSelected: propTypes.bool
};

export default LectureItemAssignLectureAdminPopup;