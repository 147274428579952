import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import { VOUCHER_ADMIN_POPUP_SET_ENABLED } from '../../../../redux/actions/ActionTypes';
import { openCourseAdminPopup } from '../../../../redux/actions/popup/admin/CoursePopupActions';
import { deleteCourseVoucherAdminPopup } from '../../../../redux/actions/popup/admin/VoucherPopupActions';

const CourseVoucherItemAdminPopup = ({ courseVoucher }) => {
    const { course } = courseVoucher;
    const dispatch = useDispatch();

    const onEdit = () => {
        dispatch(openCourseAdminPopup(course.id));
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
    }

    return (
        <div className={`item white shadow rounded-m thumbnail ${courseVoucher.isLoading ? 'disabled' : ''}`}>
            <div className='actions-banner'>
                <div></div>
                <div className='more-button shadow white'>
                    <div className='icon more'></div>
                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                            {!course.isLoading && <div className='item transition' onClick={onEdit}>Modifier la formation</div>}
                            <div className='item transition'><Button title={'Supprimer le lien'} isLoading={courseVoucher.isLoading} btnStyle={'secondary'} confirmationRequired onClick={() => dispatch(deleteCourseVoucherAdminPopup(courseVoucher.id))} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-main rounded-s' src={course.picture} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>
            </div>
        </div>
    );
}

CourseVoucherItemAdminPopup.propTypes = {
    courseVoucher: propTypes.object.isRequired
};

export default CourseVoucherItemAdminPopup;