import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import Input from '../../Input';
import LectureEmbedPage from '../../panel/dashboard/lecture/LectureEmbedPage';
import { ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS, ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL } from '../../redux/actions/ActionTypes';
import { addEmbedPageAdminPopup, previewEmbedPageAdminPopup } from '../../redux/actions/popup/admin/AssignEmbedPagePopupActions';
import { useTranslation } from 'react-i18next';

const AssignEmbedPageAdminPopup = () => {
    const dispatch = useDispatch();
    const assignEmbedPageAdminStore = useSelector(store => store.AssignEmbedPageAdminPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(previewEmbedPageAdminPopup(assignEmbedPageAdminStore.url));
    }

    useEffect(() => {
        return () => {
            dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    return (
        <div className={`embed-admin`}>
            <div className='section'>
                <form onSubmit={onSubmit}>
                    <Input title={'Lien du laboratoire'} placeholder={'https://labs.reunit.net'} disabled={assignEmbedPageAdminStore.isLoading} value={assignEmbedPageAdminStore.url} onChange={(url) => dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_URL, payload: { url } })} type={'text'} error={!assignEmbedPageAdminStore.isUrlValid} helperText={t("HELPER_URL")} />

                    <div className='duration-inputs'>
                        <Input title={'Durée du laboratoire'} type={'number'} placeholder={'Heures'} disabled={assignEmbedPageAdminStore.isLoading} value={assignEmbedPageAdminStore.duration.hours} onChange={(hours) => dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_HOURS, payload: { hours } })} error={!assignEmbedPageAdminStore.duration.isDurationValid} />
                        <Input type={'number'} placeholder={'Minutes'} disabled={assignEmbedPageAdminStore.isLoading} value={assignEmbedPageAdminStore.duration.minutes} onChange={(minutes) => dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_MINUTES, payload: { minutes } })} error={!assignEmbedPageAdminStore.duration.isDurationValid} />
                        <Input type={'number'} placeholder={'Secondes'} disabled={assignEmbedPageAdminStore.isLoading} value={assignEmbedPageAdminStore.duration.seconds} onChange={(seconds) => dispatch({ type: ASSIGN_EMBED_PAGE_ADMIN_POPUP_SET_SECONDS, payload: { seconds } })} error={!assignEmbedPageAdminStore.duration.isDurationValid} />
                    </div>

                    <div className='right-alignment'>
                        <Button title={'Prévisualiser'} type={'submit'} btnStyle='secondary autofit' disabled={assignEmbedPageAdminStore.isLoading} />
                        <Button title={'Créer le laboratoire'} btnStyle='primary autofit' isLoading={assignEmbedPageAdminStore.isLoading} onClick={() => dispatch(addEmbedPageAdminPopup(assignEmbedPageAdminStore))} />
                    </div>
                </form>
            </div>

            {assignEmbedPageAdminStore.preview &&
                <div className='section'>
                    <LectureEmbedPage page={{ link: assignEmbedPageAdminStore.preview }} admin={false} />
                </div>}
        </div>
    );
}

export default AdminPopupHOC(AssignEmbedPageAdminPopup, 'Ajouter un laboratoire', 'Création d\'une page imbriquée');