import Axios from "axios"
import { createNotification } from "../../../Utils";
import { TRACER_ADMIN_PANEL_SET_LOADING, TRACER_ADMIN_PANEL_SET_TRACERS } from "../ActionTypes";
import i18n from 'i18next';

export const loadTracerAdmin = (ShowOnlyUsers, codes = []) => async dispatch => {
    console.log();
    dispatch({ type: TRACER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: true } });

    const codesStr = codes.map((c, i) => `CourseIds[${i}]=${c}`).join('&')

    Axios.get(`usercourse/tracer?ShowOnlyUsers=${ShowOnlyUsers}&${codesStr}`)
        .then(({ data }) => {
            dispatch({ type: TRACER_ADMIN_PANEL_SET_TRACERS, payload: { tracers: data } });
        }).catch(({ response }) => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: TRACER_ADMIN_PANEL_SET_LOADING, payload: { isLoading: false } });
        });
}