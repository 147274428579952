import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_FILE_ADMIN_POPUP_ADD_FILE, ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS, ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID, ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID, LECTURE_PANEL_ADMIN_ADD_DOWNLOAD_FILE, LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILE_TITLE } from "../../ActionTypes";
import i18n from 'i18next';

export const openFileAdminPopup = (lectureId) => async dispatch => {
    dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_LECTURE_ID, payload: { id: lectureId } });
}

export const uploadFileAdminPopup = (lectureId, files, nbUploadedFiles = 0) => async dispatch => {
    for (const iFile in files) {
        const file = files[iFile];

        const uploadId = parseInt(nbUploadedFiles) + parseInt(iFile);
        var reader = new FileReader();
        reader.onload = (e) => {
            var content = e.target.result;
            dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_ADD_FILE, payload: { file: { ...file, name: file.name, id: uploadId, link: content, progress: 0, isLoading: true, resourceId: null } } });
        };

        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('File', file);
        formData.append('title', file.name)

        Axios.put(`resource/${lectureId}/add/file/admin`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (progress) => {
                dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_FILE_PROGRESS, payload: { id: uploadId, progress: (progress.total / progress.loaded) * 100 } });
            }
        }).then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_ADMIN_ADD_DOWNLOAD_FILE, payload: { download: data } });
            dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_FILE_RESOURCE_ID, payload: { id: uploadId, resourceId: data.id } });
        }).catch(() => {
            dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_FILE_ERROR, payload: { id: uploadId, error: true } });
        }).finally(() => {
            dispatch({ type: ASSIGN_FILE_ADMIN_POPUP_SET_FILE_LOADING, payload: { id: uploadId, isLoading: false } });
        });
    }
}

export const updateFileNameAdminPopup = (resourceId, name) => async dispatch => {
    Axios.put(`resource/${resourceId}/change/name/admin`, {
        title: name
    }).then(() => {
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_DOWNLOAD_FILE_TITLE, payload: { id: resourceId, title: name } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    });
}