import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_ADMIN_POPUP_SET_COURSES } from '../../../redux/actions/ActionTypes';
import { loadCourseCategoryAdminPopup } from '../../../redux/actions/popup/admin/CategoryPopupActions';
import CourseListCategoryAdminPopup from './course/CourseListCategoryAdminPopup';

const CourseCategoryAdminPopup = () => {
    const dispatch = useDispatch();
    const categoryAdminStore = useSelector(store => store.categoryAdminPopup);

    useEffect(() => {
        dispatch(loadCourseCategoryAdminPopup(categoryAdminStore.categoryId));

        return () => {
            dispatch({ type: CATEGORY_ADMIN_POPUP_SET_COURSES, payload: { courses: null } });
        }
    }, [dispatch, categoryAdminStore.categoryId]);

    return (
        <div className='overview'>
            <div>
                <div className='content-title'>
                    <h2>Formations modèles</h2>
                </div>
                <p>
                    Vous voyez apparaitre l'ensemble des formations <b>modèle</b> présent dans cette catégorie. C'est-à-dire, les formations qui seront visible dans la partie <i>vitrine</i> de l'application.<br />
                    Pour ajouter une formation dans la vitrine, rendez-vous dans <b>Formations</b> &#187; <b>Modifier une formation</b> &#187; <b>Marquer comme modèle</b>.<br />
                    Assurez-vous que celui est bien inclus dans cette catégorie en vous rendant dans l'onglet <b>Informations détaillées</b> &#187; <b>Catégories parentes</b>.<br />
                    Vous pouvez organiser l'ordre en maintenant appuyez et en déplaçant les vignettes.
                </p>

                <div className='section'>
                    <CourseListCategoryAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default CourseCategoryAdminPopup;