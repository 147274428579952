import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import AdminPanelHOC from '../../HOC/AdminPanelHOC';
import Input from '../../Input';
import { COURSE_ADMIN_PANEL_SET_COURSES, COURSE_ADMIN_PANEL_SET_SEARCH_VALUE } from '../../redux/actions/ActionTypes';
import { loadCourseAdmin } from '../../redux/actions/admin/CourseAdminActions';
import { openCourseAdminPopup } from '../../redux/actions/popup/admin/CoursePopupActions';
import CourseAdminList from './course/CourseAdminList';

const CourseAdminPanel = () => {
    const dispatch = useDispatch();
    const courseAdminStore = useSelector(store => store.courseAdminPanel);
    const panelRef = useRef();
    const [reloadMore, setReloadMore] = useState(false);

    const onScroll = () => {
        if (panelRef) {
            const scrollFromBottom = window.scrollY + window.screen.height;
            const bottomContainerPosition = panelRef.current.offsetTop + panelRef.current.offsetHeight + 250;

            if (scrollFromBottom > bottomContainerPosition) {
                setReloadMore(true);
            }
        }
    }

    useEffect(() => {
        dispatch(loadCourseAdmin(0, null));

        document.addEventListener('scroll', onScroll);

        return () => {
            dispatch({ type: COURSE_ADMIN_PANEL_SET_COURSES, payload: { courses: [] } });
            document.removeEventListener('scroll', onScroll);
        }
    }, [dispatch]);

    useEffect(() => {
        const fetch = async () => {
            if (reloadMore && !courseAdminStore.isLoading && courseAdminStore.hasNext) {
                await dispatch(loadCourseAdmin(courseAdminStore.pagingPage + 1, courseAdminStore.searchValue));
                setReloadMore(false);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [reloadMore, courseAdminStore.isLoading, courseAdminStore.hasNext]);

    const onTyping = (value) => {
        dispatch({ type: COURSE_ADMIN_PANEL_SET_COURSES, payload: { courses: [] } });
        dispatch(loadCourseAdmin(0, value));
    }

    return (
        <div className='content' ref={panelRef}>
            <div className='search-bar'>
                <Input placeholder={'Recherche'} value={courseAdminStore.searchValue} onChange={(searchValue) => dispatch({ type: COURSE_ADMIN_PANEL_SET_SEARCH_VALUE, payload: { searchValue } })} onTypingDelay={onTyping} />
                <Button title='Créer une formation' btnStyle='primary' onClick={() => dispatch(openCourseAdminPopup())} />
            </div>

            <CourseAdminList />
        </div>
    );
}

export default AdminPanelHOC(CourseAdminPanel, 'Formations', 'Gestion du catalogue de formations');