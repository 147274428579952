import { INTERVIEW_POPUP_SET_INTERVIEW_ID } from "../../actions/ActionTypes";

const defaultState = {
    interviewId: null
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case INTERVIEW_POPUP_SET_INTERVIEW_ID: {
            return { ...state, interviewId: action.payload.interviewId };
        }
        default:
            return state;
    }
}