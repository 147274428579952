import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../Button';
import { updateResourcePositionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { markLectureAsDone } from '../../../redux/actions/dashboard/LectureActions';
import { LECTURE_TYPE } from '../../../resources/constants/lectureType';
import { createNotification } from '../../../Utils';
import VideoPlayer from '../../../VideoPlayer';
import LectureAdditional from './LectureAdditional';
import LectureAddResource from './LectureAddResource';
import LectureDocumentation from './LectureDocumentation';
import LectureDownloadFile from './LectureDownloadFile';
import LectureEmbedPage from './LectureEmbedPage';
import { useTranslation } from 'react-i18next';

const LectureDefaultContent = ({ admin, lectureId, userCourseId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();
    const [groupedMainResources, setGroupedMainResources] = useState([]);
    const lectures = lectureStore.menu?.sections?.map(s => s.lectures).flat(1);
    let isLast;
    if (lectures) {
        const lectureIndex = lectures.findIndex(l => l.id === parseInt(lectureId))
        isLast = lectureIndex < 0 || !lectures[lectureIndex + 1];
    }

    useEffect(() => {
        if (lectureStore.lecture)
            setGroupedMainResources(lectureStore.lecture.videos.concat(lectureStore.lecture.embedPages).sort((a, b) => (a.level > b.level) ? 1 : -1));
    }, [lectureStore.lecture]);

    const onDone = async () => {
        const nextLectureId = await dispatch(markLectureAsDone(userCourseId, lectureStore.currentLectureId));

        if (nextLectureId) {
            history.push(`${nextLectureId}`);
        } else {
            history.push('/dashboard');
            createNotification(t("NOTIFICATION_SUCCESS_TITLE"), t("LECTURE_DONE"), 'success');
        }
    }

    const onPositionUpdate = (e) => {
        const movedItem = groupedMainResources[e.newIndex];
        dispatch(updateResourcePositionAdmin(movedItem.id, e.newIndex));
    }

    return (
        <>
            <div className='right-alignment'>
                {!admin && !lectureStore.lecture.isDone && <Button onClick={onDone} title={!isLast ? t("NEXT_LECTURE") : t("FINISH_COURSE")} btnStyle={'primary autofit'} />}
            </div>

            <ReactSortable chosenClass={'disabled'} disabled={!admin} delay={200} animation={200} forceFallback={true} list={groupedMainResources} setList={(e) => setGroupedMainResources(e)} onEnd={onPositionUpdate}>
                {groupedMainResources.map(r => {
                    if (r.type === LECTURE_TYPE.VIDEO)
                        return <VideoPlayer key={r.id} isSelected={lectureStore.currentVideoId === r.id} video={r} disableKeyEvent={admin} admin={admin} />

                    if (r.type === LECTURE_TYPE.EMBEDPAGE)
                        return <LectureEmbedPage key={r.id} page={r} admin={admin} />;

                    return null
                })}
            </ReactSortable>

            {lectureStore.lecture.pictures && lectureStore.lecture.pictures.length ? <LectureAdditional admin={admin} /> : null}
            {lectureStore.lecture.downloads && lectureStore.lecture.downloads.length ? <LectureDownloadFile admin={admin} /> : null}
            {lectureStore.lecture.documentations && lectureStore.lecture.documentations.length ? <LectureDocumentation admin={admin} /> : null}

            {admin && <LectureAddResource />}

            <div className='right-alignment'>
                {!admin && !lectureStore.lecture.isDone && <Button onClick={onDone} title={!isLast ? t("NEXT_LECTURE") : t("FINISH_COURSE")} btnStyle={'primary autofit'} />}
            </div>
        </>
    )
}

export default LectureDefaultContent;