import React from 'react';
import Button from '../../../Button';
import propTypes from 'prop-types';
import { convertSecondToAverageTime } from '../../../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateSectionToCourseAdminPopup } from '../../../redux/actions/popup/admin/AssignSectionPopupActions';

// Images
import NoImage from '../../../resources/images/no-image.png';

const SectionItemAssignSectionAdminPopup = ({ section, isSelected }) => {
    const dispatch = useDispatch();
    const assignSectionAdminStore = useSelector(store => store.assignSectionAdminPopup);

    return (
        <div className='item section-card shadow white rounded-m'>
            <span className='title'>{section.name}</span>

            <div className='included-course rounded-s'>
                <div className='actions-banner'>
                    <label className='sub-title'>inclus dans la formation</label>
                    {section.courseIsModel && <span className='status important'>Modèle</span>}
                </div>

                <div className='course'>
                    <img className='rounded-s' src={section.coursePicture || NoImage} alt={section.courseTitle} />
                    <span className='course-name'>{section.courseTitle}</span>
                </div>
            </div>

            <div className='or'>
                <hr />
            </div>

            <ul className='stats'>
                <li><div className='icon time'></div>{section.totalTime ? convertSecondToAverageTime(section.totalTime) : '00:00'}</li>
                <li><div className='icon player'></div>{section.nbVideos} vidéo{section.nbVideos > 1 ? 's' : ''}</li>
            </ul>

            <Button title={isSelected ? 'Importé' : 'Importer'} isLoading={section.isLoading} btnStyle={isSelected ? 'success' : 'primary'} onClick={isSelected ? null : () => dispatch(duplicateSectionToCourseAdminPopup(section.id, assignSectionAdminStore.courseId))} />
        </div>
    );
}

SectionItemAssignSectionAdminPopup.propTypes = {
    section: propTypes.object.isRequired
};

export default SectionItemAssignSectionAdminPopup;