import Axios from "axios";
import { LECTURE_TYPE } from "../../../resources/constants/lectureType";
import { createNotification } from "../../../Utils";
import { LECTURE_PANEL_ADMIN_DELETE_ADDITIONAL, LECTURE_PANEL_ADMIN_DELETE_DOCUMENTATION, LECTURE_PANEL_ADMIN_DELETE_DOWNLOAD_FILE, LECTURE_PANEL_ADMIN_DELETE_EMBED_PAGE, LECTURE_PANEL_ADMIN_DELETE_MENU_LECTURE, LECTURE_PANEL_ADMIN_DELETE_MENU_SECTION, LECTURE_PANEL_ADMIN_DELETE_VIDEO, LECTURE_PANEL_ADMIN_LECTURE_DESCRIPTION_CHECK, LECTURE_PANEL_ADMIN_LECTURE_TITLE_CHECK, LECTURE_PANEL_ADMIN_SECTION_TITLE_CHECK, LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, LECTURE_PANEL_ADMIN_SET_LECTURE_DESCRIPTION, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS, LECTURE_PANEL_ADMIN_SET_LECTURE_TITLE, LECTURE_PANEL_ADMIN_SET_MENU_LECTURE_TITLE, LECTURE_PANEL_ADMIN_SET_SECTION_TITLE, LECTURE_PANEL_SET_LECTURE_AS_FREE, LECTURE_PANEL_SET_LECTURE_LOADING } from "../ActionTypes";
import i18n from 'i18next';

export const updateSectionTitleAdmin = (sectionId, title) => async dispatch => {
    const isTitleValid = title && title.length >= 3 && title.length <= 500;
    dispatch({ type: LECTURE_PANEL_ADMIN_SECTION_TITLE_CHECK, payload: { isValid: isTitleValid } });

    if (!isTitleValid)
        return false;

    dispatch({ type: LECTURE_PANEL_ADMIN_SET_SECTION_TITLE, payload: { id: sectionId, title } });

    return Axios.put(`section/${sectionId}/change/name/admin`, {
        name: title
    }).then(() => {
        return true;
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        return false;
    });
}

export const updateLectureTitleAdmin = (lectureId, title) => async dispatch => {
    const isTitleValid = title && title.length >= 3 && title.length <= 500;
    dispatch({ type: LECTURE_PANEL_ADMIN_LECTURE_TITLE_CHECK, payload: { isValid: isTitleValid } });

    if (!isTitleValid)
        return false;

    dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_TITLE, payload: { title } });
    dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_TITLE_STATUS, payload: { isEditing: false } });
    dispatch({ type: LECTURE_PANEL_ADMIN_SET_MENU_LECTURE_TITLE, payload: { id: lectureId, title } });

    Axios.put(`lecture/${lectureId}/update/title/admin`, {
        title
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const updateLectureDescriptionAdmin = (lectureId, description) => async dispatch => {
    const isDescriptionValid = !description || (description.length >= 3 && description.length <= 3000);

    dispatch({ type: LECTURE_PANEL_ADMIN_LECTURE_DESCRIPTION_CHECK, payload: { isValid: isDescriptionValid } });

    if (!isDescriptionValid)
        return false;

    dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_DESCRIPTION, payload: { description } });
    dispatch({ type: LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, payload: { isEditing: false } });
    Axios.put(`lecture/${lectureId}/update/description/admin`, {
        description: description || null
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const updateSectionPositionAdmin = (sectionId, position) => async dispatch => {
    Axios.put(`section/position/change/admin`, {
        id: sectionId,
        level: position + 1
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteSectionAdmin = (sectionId) => async dispatch => {
    await dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_MENU_SECTION, payload: { id: sectionId } });

    return Axios.delete(`section/${sectionId}/delete/admin`)
        .then(() => {
            return true;
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            return false;
        });
}

export const updateLecturePositionAdmin = (lectureId, sectionId, position) => async dispatch => {
    Axios.put(`lecture/position/change/admin`, {
        id: lectureId,
        parentId: sectionId,
        level: position + 1
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const updateLectureFreeAdmin = (lectureId, isFree) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SET_LECTURE_AS_FREE, payload: { isFree } });

    Axios.put(`lecture/${lectureId}/update/free/admin`, {
        free: isFree
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}

export const deleteLectureAdmin = (lectureId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SET_LECTURE_LOADING, payload: { isLoading: true } });

    return Axios.delete(`lecture/${lectureId}/delete/admin`)
        .then(() => {
            dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_MENU_LECTURE, payload: { id: lectureId } });
            return true;
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            return false;
        });
}

export const deleteResource = (resourceId, type) => async dispatch => {
    Axios.delete(`resource/${resourceId}/delete/admin`)
        .then(() => {
            createNotification(i18n.t("NOTIFICATION_SUCCESS_TITLE"), i18n.t("ADMIN_RESOURCE_DELETED"), 'success');

            switch (type) {
                case LECTURE_TYPE.EMBEDPAGE:
                    dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_EMBED_PAGE, payload: { id: resourceId } });
                    break;
                case LECTURE_TYPE.DOCUMENTATION:
                    dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_DOCUMENTATION, payload: { id: resourceId } });
                    break;
                case LECTURE_TYPE.DOWNLOAD:
                    dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_DOWNLOAD_FILE, payload: { id: resourceId } });
                    break;
                case LECTURE_TYPE.ADDITIONAL:
                    dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_ADDITIONAL, payload: { id: resourceId } });
                    break;
                case LECTURE_TYPE.VIDEO:
                    dispatch({ type: LECTURE_PANEL_ADMIN_DELETE_VIDEO, payload: { id: resourceId } });
                    break;
                default:
                    break;
            }

        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        });
}

export const updateResourcePositionAdmin = (resourceId, position) => async dispatch => {
    Axios.put(`resource/position/change/admin`, {
        id: resourceId,
        level: position + 1
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    });
}