import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import { deleteAnswerSurvey, updateAnswerSurveyCorrect } from '../../../../redux/actions/dashboard/LectureSurveyActions';

const LectureSurveyAdminAnswer = ({ index, answer }) => {
    const dispatch = useDispatch();

    return (
        <div className={`answer transition rounded-m ${answer.correct ? 'correct' : ''} ${answer.isLoading ? 'disabled' : ''}`} onClick={answer.isLoading ? null : () => dispatch(updateAnswerSurveyCorrect(answer.id, !answer.correct))}>
            <div className='key-value'>
                <span>{index + 1}</span>
            </div>

            <div className='title'>{answer.title}</div>

            <div className='remove-btn transition'>
                <Button btnStyle={'cancel'} confirmationRequired onClick={() => dispatch(deleteAnswerSurvey(answer.id))} isLoading={answer.isLoading}>
                    <div className='icon close white'></div>
                </Button>
            </div>
        </div>
    );
}

export default LectureSurveyAdminAnswer;