import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import { USER_ADMIN_POPUP_SET_COURSES } from '../../../redux/actions/ActionTypes';
import { openCourseAsUserAdminPopup } from '../../../redux/actions/popup/admin/AssignCoursePopupActions';
import { loadUserCoursesAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import CourseUserListAdminPopup from './course/CourseUserListAdminPopup';

const CourseUserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);

    useEffect(() => {
        dispatch(loadUserCoursesAdminPopup(userAdminStore.userId));

        return () => {
            dispatch({ type: USER_ADMIN_POPUP_SET_COURSES, payload: { courses: null } });
        }
    }, [dispatch, userAdminStore.userId]);

    return (
        <div className='courses-users'>
            <div>
                <div className='content-title'>
                    <h2>Formations</h2>
                    <Button title='Assigner une formation' btnStyle={'primary autofit'} onClick={() => dispatch(openCourseAsUserAdminPopup(userAdminStore.userId))} />
                </div>

                <div className='section'>
                    <CourseUserListAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default CourseUserAdminPopup;