import React from 'react';
import propTypes from 'prop-types';
import Button from '../../../Button';
import { useDispatch } from 'react-redux';
import { deleteResource } from '../../../redux/actions/admin/LectureAdminActions';
import { LECTURE_TYPE } from '../../../resources/constants/lectureType';

const LectureAdditionalItem = ({ image, admin }) => {
    const dispatch = useDispatch();

    return (
        <div className='item rounded-m shadow white'>
            {admin && <Button title='Supprimer la ressource' btnStyle={'delete autofit cancel'} confirmationRequired onClick={() => dispatch(deleteResource(image.id, LECTURE_TYPE.ADDITIONAL))} />}

            <a href={image.link} target={'_blank'} rel="noopener noreferrer">
                <img className='transition' src={image.link} alt={image.title} />
            </a>
        </div>
    );
}

LectureAdditionalItem.propTypes = {
    image: propTypes.object.isRequired,
    admin: propTypes.bool
};

export default LectureAdditionalItem;