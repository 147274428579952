import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LectureAdditionalItem from './LectureAdditionalItem';
import propTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import { LECTURE_PANEL_ADMIN_SET_ADDITIONALS } from '../../../redux/actions/ActionTypes';
import { updateResourcePositionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { useTranslation } from 'react-i18next';

const LectureAdditional = ({ admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { t } = useTranslation();

    const onPositionUpdate = (e) => {
        const movedItem = lectureStore.lecture.pictures[e.newIndex];
        dispatch(updateResourcePositionAdmin(movedItem.id, e.newIndex));
    }

    return (
        <div className='resource'>
            <h5>{t("EXTRA_RESOURCES")}</h5>

            <ReactSortable className='images' chosenClass={'disabled'} disabled={!admin} delay={200} animation={200} list={lectureStore.lecture.pictures} setList={(pictures) => dispatch({type: LECTURE_PANEL_ADMIN_SET_ADDITIONALS, payload: { pictures }})} onEnd={onPositionUpdate}>
                {lectureStore.lecture.pictures.map(image => <LectureAdditionalItem key={image.id} image={image} admin={admin} />)}
            </ReactSortable>
        </div>
    );
}

LectureAdditional.propTypes = {
    admin: propTypes.bool
};

export default LectureAdditional;