import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET } from '../../redux/actions/ActionTypes';
import { loadUserCoursesAdminPopup } from '../../redux/actions/popup/admin/UserPopupActions';
import CourseListAssignCertificateAdminPopup from './assignCertificate/CourseListAssignCertificateAdminPopup';

const AssignCertificateAdminPopup = () => {
    const dispatch = useDispatch();
    const assignCertificateAdminStore = useSelector(store => store.assignCertificateAdminPopup);

    useEffect(() => {
        dispatch(loadUserCoursesAdminPopup(assignCertificateAdminStore.userId));

        return () => {
            dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={`assign-certificate-admin`}>
            <div className='section'>
                <CourseListAssignCertificateAdminPopup />
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignCertificateAdminPopup, 'Générer un certificat', 'Génération d\'un certificat', null, null, null);