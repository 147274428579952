import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundPopup from '../../../BackgroundPopup';
import BasicLoader from '../../../loader/BasicLoader';
import LectureSection from './LectureSection';
import propTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import { ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, LECTURE_PANEL_ADMIN_SET_MENU_VISIBLE, LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE, LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE, LECTURE_PANEL_SET_MENU_SECTIONS } from '../../../redux/actions/ActionTypes';
import Input from '../../../Input';
import { addNewSectionCourseAdmin } from '../../../redux/actions/admin/CourseAdminActions';
import { updateSectionPositionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import { useTranslation } from 'react-i18next';

const LectureMenu = ({ admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const sectionTitleInputRef = useRef(null);
    const sectionPanelRef = useRef();
    const [rightMarginSectionPanel, setRightMarginSectionPanel] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (lectureStore.updateNewSection.isEditing && sectionTitleInputRef) {
            sectionTitleInputRef.current.focus();
        }
    }, [lectureStore.updateNewSection.isEditing]);

    const onToggleMenu = () => {
        if (!lectureStore.isMenuVisible && sectionPanelRef?.current) {
            setRightMarginSectionPanel(sectionPanelRef.current.getBoundingClientRect().width + 10);
        }
        dispatch({ type: LECTURE_PANEL_ADMIN_SET_MENU_VISIBLE, payload: { isVisible: !lectureStore.isMenuVisible } });
    }

    const onAddSection = (e) => {
        e.preventDefault();

        dispatch(addNewSectionCourseAdmin(lectureStore.menu.id, lectureStore.updateNewSection.title));
    }

    const onSectionUpdatePosition = (e) => {
        const movedItem = lectureStore.menu.sections[e.newIndex];
        dispatch(updateSectionPositionAdmin(movedItem.id, e.newIndex));
    }

    if (lectureStore.isMenuLoading)
        return (
            <div className='course-list loading'>
                <BasicLoader width='100%' radius={20} height={500} />
            </div>
        );

    return (
        <>
            <BackgroundPopup enabled={lectureStore.isMenuVisible} translucid level={1} maxLevel={'l'} />
            <div className={`course-list transition ${lectureStore.isMenuVisible ? 'enabled' : ''}`} ref={sectionPanelRef} style={lectureStore.isMenuVisible && rightMarginSectionPanel ? { right: `calc(100% - ${rightMarginSectionPanel}px)` } : null}>
                <div className={`no-extended-btn`} onClick={() => dispatch({ type: LECTURE_PANEL_ADMIN_SET_MENU_VISIBLE, payload: { isVisible: !lectureStore.isMenuVisible } })}>
                    <div>
                        <div className={`icon arrow transition ${lectureStore.isMenuVisible ? 'enabled' : ''}`}></div>
                        <span>{!lectureStore.isMenuVisible ? t("COURSE_CONTENT") : t("CLICK_TO_CLOSE")}</span>
                    </div>
                </div>

                <div className='detail white shadow rounded-m'>
                    {lectureStore.menu?.sections &&
                        <ReactSortable chosenClass={'lecture-menu-section-sortable-item'} disabled={!admin} animation={200} delay={200} group={'shared-sections'} list={lectureStore.menu.sections} setList={(sections) => dispatch({ type: LECTURE_PANEL_SET_MENU_SECTIONS, payload: { sections } })} onEnd={onSectionUpdatePosition}>
                            {lectureStore.menu.sections.map(section => <LectureSection key={section.id} section={section} admin={admin} />)}
                        </ReactSortable>}

                    {admin &&
                        <div className={`section rounded-m transition add-section ${lectureStore.updateNewSection.isEditing ? 'filling' : ''}`} onClick={!lectureStore.updateNewSection.isEditing ? () => {
                            dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } })
                            dispatch({ type: ASSIGN_SECTION_ADMIN_POPUP_SET_COURSE_ID, payload: { id: lectureStore.menu.id } });
                        } : null}>
                            <div className='section-title'>
                                <div className='label add-item'>
                                    {!lectureStore.updateNewSection.isEditing &&
                                        <div className='icon-container'>
                                            <div className='icon plus'></div>
                                        </div>}

                                    <div className='editable section'>
                                        {!lectureStore.updateNewSection.isEditing ?
                                            <span className='name'>Ajouter un chapitre</span> :
                                            <>
                                                <form onSubmit={onAddSection}>
                                                    <Input inputRef={sectionTitleInputRef} placeholder={'Titre du chapitre'} value={lectureStore.updateNewSection.title} onChange={(title) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_TITLE, payload: { title } })} error={!lectureStore.updateNewSection.isValid} helperText={t("HELPER_TITLE_SECTION")} disabled={lectureStore.updateNewSection.isLoading} />
                                                </form>
                                                <span className={`cancel-button ${lectureStore.updateNewSection.isLoading ? 'disabled' : ''}`} onClick={lectureStore.updateNewSection.isLoading ? null : () => dispatch({ type: LECTURE_PANEL_ADMIN_SET_NEW_SECTION_UPDATE, payload: { isEditing: false } })}>Annuler</span>
                                            </>}
                                    </div>
                                </div>

                                {lectureStore.updateNewSection.isEditing && <div className={`icon action check hover-to-blue transition ${lectureStore.updateNewSection.isLoading ? 'disabled' : ''}`} onClick={lectureStore.updateNewSection.isLoading ? null : onAddSection}></div>}
                            </div>
                        </div>}
                </div>

                <div className='extend-trigger transition shadow rounded-l' onClick={onToggleMenu}>
                    <div className='icon arrow bold white transition'></div>
                    <span>{lectureStore.isMenuVisible ? t("CLOSE_MENU") : t("OPEN_MENU")}</span>
                    <div className='icon arrow bold white transition'></div>
                </div>
            </div>
        </>
    );
}

LectureMenu.propTypes = {
    admin: propTypes.bool
};

export default LectureMenu;