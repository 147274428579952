import React from 'react';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../Button';
import { LECTURE_PANEL_SURVEY_SET_STEP } from '../../../../redux/actions/ActionTypes';
import { markLectureAsDone } from '../../../../redux/actions/dashboard/LectureActions';
import { LECTURE_SURVEY_STEP } from '../../../../resources/constants/lectureSurvey';
import { createNotification } from '../../../../Utils';
import LectureSurveyResultQuestion from './LectureSurveyResultQuestion';
import { useTranslation } from 'react-i18next';

const LectureSurveyResult = ({ admin, lectureId, userCourseId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const history = useHistory();
    const results = lectureStore.surveyResults;
    const lectures = lectureStore.menu?.sections?.map(s => s.lectures).flat(1);
    let isLast;
    if (lectures) {
        const lectureIndex = lectures.findIndex(l => l.id === parseInt(lectureId))
        isLast = lectureIndex < 0 || !lectures[lectureIndex + 1];
    }

    const onDone = async () => {
        const nextLectureId = await dispatch(markLectureAsDone(userCourseId, lectureStore.currentLectureId));

        if (nextLectureId) {
            history.push(`${nextLectureId}`);
        } else {
            history.push('/dashboard');
            createNotification(t("NOTIFICATION_SUCCESS_TITLE"), t("LECTURE_DONE"), 'success');
        }
    }

    if (!results)
        return null;

    return (
        <div className='step-result'>
            <div className='survey-result-container shadow white rounded-m'>
                <h3>{t("RESULTS")}</h3>

                <div className='progress-circle center'>
                    <SemiCircleProgressBar percentage={(results.finalScore / results.finalScoreBase) * 100} diameter={250} background={'#e2e6ea'} stroke={'#2ED573'} />
                    <span className='note'>{results.finalScore}/{results.finalScoreBase}</span>
                </div>

                <h4>{t("REPORT")}</h4>
                <div>
                    {results.wrong?.length ?
                        <div className='summary-container wrong'>
                            <span className='category'>{t("WRONG_ANSWERS")}</span>
                            <div className='bar'></div>
                            <div className='content'>
                                {results.wrong.map(q => <LectureSurveyResultQuestion question={q} key={q.id} />)}
                            </div>
                        </div> : null}

                    {results.correct?.length ?
                        <div className='summary-container correct'>
                            <span className='category'>{t("RIGHT_ANSWERS")}</span>
                            <div className='bar'></div>
                            <div className='content'>
                                {results.correct.map(q => <LectureSurveyResultQuestion question={q} key={q.id} />)}
                            </div>
                        </div> : null}
                </div>
            </div>

            <div className='go-next'>
                <span className='transition' onClick={() => dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.START } })}>{t("RESTART_TEST")}</span>
                {!admin && !lectureStore.lecture.isDone && <Button onClick={onDone} title={!isLast ? t("CONTINUE_COURSE") : t("FINISH_COURSE")} btnStyle={'primary autofit'} />}
            </div>
        </div>
    );
}

export default LectureSurveyResult;