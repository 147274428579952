import React from 'react';
import propTypes from 'prop-types';

const PreviewMoreInformationButton = ({ title, selected, onClick }) => {
    return (
        <div className={`choice transition rounded-s ${selected ? 'selected' : ''}`} onClick={onClick}>{title}</div>
    );
}

PreviewMoreInformationButton.propTypes = {
    title: propTypes.string.isRequired,
    selected: propTypes.bool,
    onClick: propTypes.func.isRequired,
}

export default PreviewMoreInformationButton