import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import Input from '../../../../Input';
import { addNewAnswerSurvey } from '../../../../redux/actions/dashboard/LectureSurveyActions';

const LectureSurveyAdminNewAnswer = ({ index, questionId }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            await dispatch(addNewAnswerSurvey(questionId, title));
            setTitle('');
        } catch { }
        setLoading(false);
    }

    return (
        <div className={`answer transition rounded-m new focus ${isLoading ? 'disabled' : ''}`}>
            <div className='key-value'>
                <span>{index + 1}</span>
            </div>

            <form className='field' onSubmit={onSubmit}>
                <Input type='text' placeholder='Nouvelle réponse' value={title} onChange={(value) => setTitle(value)} disabled={isLoading} maxLength={2000} />
                <Button title={'Ajouter'} btnStyle={'primary autofit'} type={'submit'} isLoading={isLoading} />
            </form>
        </div>
    );
}

export default LectureSurveyAdminNewAnswer;