import React from 'react';
import { useTranslation } from 'react-i18next';

const TransitionViewThree = ({ transitionViewRef, transitionViewPathRef, transitionViewAdvantage1Ref, transitionViewAdvantage2Ref, transitionViewAdvantage3Ref }) => {
    const { t } = useTranslation()

    return (
        <div className='sub-main transition' ref={transitionViewRef} id='transition-3'>
            <div className='container'>
                <div className='story-overlay'>
                    <div className='row'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage1Ref}>
                            <div className='left'>
                                <div className='icon computer'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("TRAIN_ON_MACHINE")}</span>
                                <p>{t("TRAIN_ON_MACHINE_DESC")}</p>
                            </div>
                        </div>

                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage3Ref}>
                            <div className='left'>
                                <div className='icon safe'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("HIGH_YOUR_BRAIN")}</span>
                                <p>{t("HIGH_YOUR_BRAIN_DESC")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage2Ref}>
                            <div className='left'>
                                <div className='icon rocket'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("HIGH_PRODUCTIVITY")}</span>
                                <p>{t("HIGH_PRODUCTIVITY_DESC")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3003.1 370.4">
                    <defs>
                        <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(67, 156, 251, 1)" />
                            <stop offset="100%" stopColor="rgba(241, 135, 251, 1)" />
                        </linearGradient>
                    </defs>
                    <path ref={transitionViewPathRef} fill="none" stroke="url(#gradient3)" strokeLinecap="round" strokeWidth="8" d="M75.3,184.5c580.2,160.7,1032.4,126.2,1386.3,6.6c37-12.5,52.8-19.1,88.4-30.9C2013.4,6.4,2491,83.5,2927.8,252.9" />
                </svg>
            </div>
        </div>
    );
}

export default TransitionViewThree;