import React from 'react';
import { Link } from 'react-scroll';

const SolutionFixedView = ({ mainIllustrationRef, objectBookRef, objectClockRef, objectGamepadRef, objectKeyRef, objectBatteryRef, objectSpiral1Ref }) => {
    return (
        <div className='main fixed-panel'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920.002 1080.526" preserveAspectRatio='none' className='wave'>
                <path id="Path_642" data-name="Path 642" d="M-14820.131,15609V14528.474s-164.343,460.826-940.459,533.669S-16740.133,15609-16740.133,15609Z" transform="translate(16740.133 -14528.474)" fill="#fff" />
            </svg>

            <div className='container page'>
                <h1>Jamais une formation n'a été aussi
                    <div className='roll-animate-container'>
                        <div className='item'>efficace</div>
                        <div className='item'>adaptée à vos besoins</div>
                        <div className='item'>personalisée</div>
                        <div className='item'>rythmée</div>
                    </div>
                </h1>
                <h2>Conçue selon vos besoins, rythmée selon votre disponibilité et adaptée selon vos connaissances</h2>

                <div className='cta'>
                    <Link to="discover-start" smooth={true} duration={500}>
                        <div className='button rounded-s'>
                            <span className='main-text'>En savoir plus</span>
                        </div>
                    </Link>

                    <a href='#view-5'>
                        <span className='second'>
                            Prendre rendez-vous maintenant
                            <div className='underline transition'></div>
                        </span>
                    </a>
                </div>
            </div>

            <div className='main-illustration transition' ref={mainIllustrationRef}></div>
            <div className='objects-container'>
                <div className='object book transition' ref={objectBookRef}></div>
                <div className='object clock transition' ref={objectClockRef}></div>
                <div className='object gamepad transition' ref={objectGamepadRef}></div>

                <div className='object key transition' ref={objectKeyRef}></div>
                <div className='object battery transition' ref={objectBatteryRef}></div>
                <div className='object spiral-1 transition' ref={objectSpiral1Ref}></div>
            </div>
        </div>
    );
}

export default SolutionFixedView;