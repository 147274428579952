import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../Button';
import { VOUCHER_ADMIN_POPUP_SET_ENABLED } from '../../../../redux/actions/ActionTypes';
import { openUserAdminPopup } from '../../../../redux/actions/popup/admin/UserPopupActions';
import { deleteUserVoucherAdminPopup } from '../../../../redux/actions/popup/admin/VoucherPopupActions';
// Images
import Facebook from '../../../../resources/images/authentication/facebook-blue.png';
import Key from '../../../../resources/images/authentication/key.png';
import Microsoft from '../../../../resources/images/authentication/microsoft.png';
import Profile from '../../../../resources/images/profile.png';
import { convertStringToDate } from '../../../../Utils';


const UserItemVoucherAdminPopup = ({ userVoucher }) => {
    const dispatch = useDispatch();
    const { user } = userVoucher;

    const showUser = () => {
        dispatch({ type: VOUCHER_ADMIN_POPUP_SET_ENABLED, payload: { type: null } });
        dispatch(openUserAdminPopup(user.id));
    }

    return (
        <div className={`item user-card thumbnail ${userVoucher.isLoading ? 'disabled' : ''}`}>
            <img className='shadow' src={user.picture || Profile} alt={`${user.firstname} ${user.lastname}`} />
            <div className='box shadow rounded-l white'>
                <div className='options actions-banner'>
                    <div className='more-button shadow white'>
                        <div className='icon more'></div>
                        <div className='sub'>
                            <div className={`dropdown-list shadow rounded-m white`} role='listbox'>
                                <div className='item transition' onClick={showUser}>Modifier l'utilisateur</div>
                                <div className='item transition'><Button title={'Supprimer le lien'} isLoading={userVoucher.isLoading} btnStyle={'secondary'} onClick={() => dispatch(deleteUserVoucherAdminPopup(userVoucher.id))} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='general-informations'>
                    <div className='name'>
                        {user.confirmed && <div className='status'><div className='icon check-bold white' /></div>}
                        <span>{user.firstname} {user.lastname}</span>
                    </div>

                    {user.business && <span className='company'>{user.business}</span>}

                    <div className='login-method'>
                        {user.facebook && <img src={Facebook} alt='facebook' />}
                        {user.microsoft && <img src={Microsoft} alt='microsoft' />}
                        {user.formationAcademy && <img src={Key} alt='email' />}
                    </div>

                    <ul className='informations'>
                        {user.email && <li><div className='icon mail'></div><span>{user.email}</span></li>}
                        {user.phone && <li><div className='icon phone'></div><span>{user.phone}</span></li>}
                        <li><div className='icon datetime'></div><span>Inscrit le {convertStringToDate(user.creationDate)}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

UserItemVoucherAdminPopup.propTypes = {
    userVoucher: propTypes.object.isRequired
};

export default UserItemVoucherAdminPopup;