import { ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_COURSES, ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE, ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_PAGING_PAGE, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_SEARCH_VALUE, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID } from "../../../actions/ActionTypes";


const defaultState = {
    popupEnabled: false,
    isLoading: true,

    userId: 1,

    courses: [],
    selectedCourses: [],

    hasNext: true,
    pagingPage: 0,

    searchValue: '',
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.popupEnabled };
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID: {
            return { ...state, userId: action.payload.id };
        }

        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_COURSES: {
            return { ...state, courses: state.courses.concat(action.payload.courses) };
        }

        case ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE: {
            return { ...state, selectedCourses: [...state.selectedCourses, action.payload.id] };
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING: {
            return {
                ...state, courses: state.courses.map(c => {
                    if(c.id !== action.payload.id)
                        return c;

                    return {...c, isLoading: action.payload.isLoading};
                })
            }
        }

        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_HAS_NEXT: {
            return { ...state, hasNext: action.payload.hasNext };
        }
        case ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_PAGING_PAGE: {
            return { ...state, pagingPage: action.payload.pagingPage };
        }

        default:
            return state;
    }
}