import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import Input from '../../Input';
import { VOUCHER_POPUP_SET_VOUCHERS_CODE } from '../../redux/actions/ActionTypes';
import { voucherPopupApplyCode } from '../../redux/actions/popup/VoucherActions';
import { useTranslation } from 'react-i18next';

const VoucherForm = () => {
    const dispatch = useDispatch();
    const voucherStore = useSelector(store => store.voucherPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(voucherPopupApplyCode(voucherStore.voucherCode));
    }

    return (
        <form onSubmit={onSubmit} className='login-popup'>
            <Input title={t("CODE")} placeholder={t("PLACEHOLDER_CODE")} value={voucherStore.voucherCode} onChange={(code) => dispatch({ type: VOUCHER_POPUP_SET_VOUCHERS_CODE, payload: { code } })} type={'text'} disabled={voucherStore.isLoading} error={!voucherStore.isVoucherCodeValid} helperText={voucherStore.voucherHelperText} />

            <Button title={t("GET_COURSE")} btnStyle={'primary'} type={'submit'} isLoading={voucherStore.isLoading} />
        </form>
    );
}

export default VoucherForm;