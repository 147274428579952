import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import PasswordStrength from '../../../PasswordStrength';
import { PREFERENCE_PANEL_SET_CURRENT_PASSWORD, PREFERENCE_PANEL_SET_NEW_PASSWORD, PREFERENCE_PANEL_SET_RENEW_PASSWORD } from '../../../redux/actions/ActionTypes';
import { loadPreferenceHasPassword, loadSessions, updatePreferencePassword } from '../../../redux/actions/dashboard/PreferenceActions';
import { Helmet } from 'react-helmet';
import PreferenceSecuritySession from './PreferenceSecuritySession';
import AuthenticationService from '../../../services/Authentication';
import { useTranslation } from 'react-i18next';

const PreferenceSecurity = () => {
    AuthenticationService.GetDeviceInformations();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const preferenceStore = useSelector(store => store.preferencePanel);

    useEffect(() => {
        dispatch(loadPreferenceHasPassword());
        dispatch(loadSessions());
    }, [dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(updatePreferencePassword(preferenceStore));
    }

    return (
        <>
            <Helmet>
                <title>{t("SECURITY")} · {t("PREFERENCES")} · FormationAcademy</title>
            </Helmet>

            <div className='security-panel'>
                <div className='container-title'>
                    <h2>{t("SECURITY")}</h2>
                    <span>{t("DASHBOARD_SECURITY_SUBTITLE")}</span>
                </div>

                <form onSubmit={onSubmit}>
                    <div>
                        {!preferenceStore.isSecurityLoading && preferenceStore.hasCurrentPassword && <>
                            <Input title={t("CURRENT_PASSWORD")} placeholder={t("PASSWORD")} value={preferenceStore.currentPassword} onChange={(password) => dispatch({ type: PREFERENCE_PANEL_SET_CURRENT_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={preferenceStore.isSecurityLoading} error={!preferenceStore.isCurrentPasswordValid} helperText={t("HELPER_PASSWORD_WRONG")} />

                            <div className='or'>
                                <hr />
                            </div>
                        </>}

                        <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={preferenceStore.newPassword} onChange={(password) => dispatch({ type: PREFERENCE_PANEL_SET_NEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={preferenceStore.isSecurityLoading} error={!preferenceStore.isNewPasswordValid} helperText={t("HELPER_PASSWORD_MUST_BE_STRONGER")} passwordToggle />
                        <PasswordStrength password={preferenceStore.newPassword} />

                        <Input title={t("NEW_PASSWORD")} placeholder={t("NEW_PASSWORD")} value={preferenceStore.renewPassword} onChange={(password) => dispatch({ type: PREFERENCE_PANEL_SET_RENEW_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={preferenceStore.isSecurityLoading} error={!preferenceStore.isRenewPasswordValid} helperText={t("HELPER_PASSWORD_SAME")} passwordToggle />
                    </div>

                    <div className='right-alignment'>
                        <Button title={t("UPDATE_PASSWORD")} btnStyle={'primary'} isLoading={preferenceStore.isSecurityLoading} type={'submit'} />
                    </div>
                </form>

                <div className='container-title'>
                    <h2>{t("SESSION")}</h2>
                    <span>{t("DASHBOARD_SECURITY_SESSION_SUBTITLE")}</span>
                </div>

                <PreferenceSecuritySession />
            </div>
        </>
    );
}

export default PreferenceSecurity;