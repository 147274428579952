import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID, TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED, TRACER_ADMIN_PANEL_SET_TRACER_LOADING, USER_ADMIN_POPUP_ADD_CERTIFICATE } from "../../ActionTypes";
import i18n from 'i18next';

export const openAssignCertificateAdminPopup = (userId) => async dispatch => {
    dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_USER_ID, payload: { id: userId } });
    dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
}


export const addCertificateAdminPopup = (userCourseId) => async dispatch => {
    dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: true } });
    dispatch({ type: TRACER_ADMIN_PANEL_SET_TRACER_LOADING, payload: { id: userCourseId, isLoading: true } });

    Axios.post(`certificate/assign/admin`, {
        userCourseId
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_ADD_SELECTED_COURSE, payload: { id: userCourseId } });
        dispatch({ type: USER_ADMIN_POPUP_ADD_CERTIFICATE, payload: { certificate: data } });
        dispatch({ type: TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED, payload: { id: userCourseId, certificateReceived: true } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_CERTIFICATE_ADMIN_POPUP_SET_COURSE_LOADING, payload: { id: userCourseId, isLoading: false } });
        dispatch({ type: TRACER_ADMIN_PANEL_SET_TRACER_LOADING, payload: { id: userCourseId, isLoading: false } });
    });
}