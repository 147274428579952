import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Button from '../../../../Button';
import BasicLoader from '../../../../loader/BasicLoader';
import { COURSE_ADMIN_POPUP_SET_OBJECTIVES, COURSE_ADMIN_POPUP_SET_PREREQUISITES, COURSE_ADMIN_POPUP_SET_PUBLICS } from '../../../../redux/actions/ActionTypes';
import { deleteSpecificationCourseAdminPopup, updatePositionSpecificationCourseAdminPopup } from '../../../../redux/actions/popup/admin/CoursePopupActions';
import { SPECIFICATION_TYPE } from '../../../../resources/constants/specificationType';

const SpecificationListCourseAdminPopup = ({ type, isLoading, isActionLoading, icon, items }) => {
    const dispatch = useDispatch();

    const onUpdatePosition = (e) => {
        const movedItem = items[e.newIndex];
        dispatch(updatePositionSpecificationCourseAdminPopup(movedItem.id, e.newIndex));
    }

    const onObjectivesSort = (objectives) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_OBJECTIVES, payload: { objectives } });
    }

    const onPublicsSort = (publics) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_PUBLICS, payload: { publics } });
    }

    const onPrerequisitesSort = (prerequisites) => {
        dispatch({ type: COURSE_ADMIN_POPUP_SET_PREREQUISITES, payload: { prerequisites } });
    }

    const onSort = (e) => {
        switch (type) {
            case SPECIFICATION_TYPE.OBJECTIVE:
                onObjectivesSort(e);
                break;
            case SPECIFICATION_TYPE.PUBLIC:
                onPublicsSort(e);
                break;
            case SPECIFICATION_TYPE.PREREQUISITE:
                onPrerequisitesSort(e);
                break;
            default:
                break;
        }
    }

    if (isLoading)
        return (
            <ul>
                <li><BasicLoader width={'100%'} height={25} radius={5} /></li>
                <li><BasicLoader width={'100%'} height={25} radius={5} /></li>
                <li><BasicLoader width={'100%'} height={25} radius={5} /></li>
            </ul>
        );

    return (
        <ul>
            <ReactSortable chosenClass={'disabled'} delay={200} animation={200} list={items} setList={onSort} onEnd={onUpdatePosition}>
                {items.map(i => <li key={i.id} className={`${isActionLoading ? 'disabled' : ''}`}>
                    <div className={`icon ${icon}`}></div>
                    {i.label}
                    <Button title={'Supprimer'} btnStyle={'cancel autofit'} confirmationRequired onClick={() => dispatch(deleteSpecificationCourseAdminPopup(i.id))} />
                </li>)}
            </ReactSortable>
        </ul>
    );
}

SpecificationListCourseAdminPopup.propTypes = {
    type: propTypes.number.isRequired,
    icon: propTypes.string.isRequired,
    items: propTypes.array.isRequired,
    isLoading: propTypes.bool,
    isActionLoading: propTypes.bool
}

export default SpecificationListCourseAdminPopup;