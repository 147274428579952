import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation()
    
    return (
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='logo' alt='formation academy'></div>
                    </div>

                    <div className='col'>
                        <ul>
                            <li><Link to='/terms'>{t("LEGAL_MENTIONS")}</Link></li>
                            <li><Link to='/privacy'>{t("PRIVACY")}</Link></li>
                            <li><Link to='/sitemap'>{t("SITEMAP")}</Link></li>
                        </ul>
                    </div>

                    <div className='col'>
                        <span className='title'>{t("COMPANY")}</span>

                        <ul>
                            <li><Link to='/centre'>{t("CENTER_AND_COURSES")}</Link></li>
                            <li><Link to='/team'>{t("CERTIFIED_TUTORS")}</Link></li>
                            {/* <li><Link to='/partners'>{t("PARTNERS")}</Link></li> */}
                        </ul>
                    </div>

                    <div className='col'>
                        <span className='title'>{t("FOLLOW_US")}</span>

                        <ul>
                            <li><a href='https://www.facebook.com/formationacademy/' target='_blank' rel="noopener noreferrer">Facebook</a></li>
                            <li><a href='https://www.linkedin.com/in/freddy-janssens-3622b630/' target='_blank' rel="noopener noreferrer">LinkedIn</a></li>
                            <li><a href='https://www.youtube.com/channel/UCyUC7VX6KgL2VVzt90Wy95g' target='_blank' rel="noopener noreferrer">Youtube</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='extra'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            © {new Date().getFullYear()} FormationAcademy
                        </div>

                        <div className='col'>
                            Made with <span role={'img'} aria-label={'heart'}>❤️</span> by <b>FormationAcademy</b>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;