import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const MenuAdminPopup = ({ menu, dispatchType, selectedMenu, isLoading, ExtraComponent }) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className='top-menu'>
                <div className='menu'>
                    {menu.map(item => <div key={item.id} className='btn-container'>
                        <div className={`btn white shadow rounded-s ${selectedMenu === item.id ? 'selected' : ''} ${isLoading || item.disabled ? 'disabled' : ''}`} onClick={isLoading || item.disabled ? null : () => dispatch({ type: dispatchType, payload: { type: item.id } })}>
                            {item.title}
                        </div>
                    </div>)}
                </div>

                {ExtraComponent && <div className='extra-component'>
                    <ExtraComponent />
                </div>}
            </div>

            <div className='or'>
                <hr />
            </div>
        </>
    );
}

MenuAdminPopup.propTypes = {
    menu: propTypes.array.isRequired,
    dispatchType: propTypes.string.isRequired,
    selectedMenu: propTypes.number,
    isLoading: propTypes.bool,
    extraComponent: propTypes.object
};

export default MenuAdminPopup;