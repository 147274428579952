import React from 'react';
import propTypes from 'prop-types';
import { convertSecondToTime } from '../../../Utils';
import { useDispatch } from 'react-redux';
import { loadPreviewLecture } from '../../../redux/actions/popup/PreviewActions';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const PreviewLecture = ({ lecture, inboundMode = false }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onClick = () => {
        if (lecture.free)
            dispatch(loadPreviewLecture(lecture.id));
    }

    return (
        <li className={`${lecture.free ? 'free' : ''}`} onClick={onClick}>
            <div className='left'>
                {!inboundMode && <div className='icon-container'><div className='icon play white'></div></div>}
                <span className='name'>{lecture.title}</span>

                {lecture.description && inboundMode && <div className='description'><ReactMarkdown>{lecture.description}</ReactMarkdown></div>}
            </div>

            <div className='right'>
                {lecture.free && <span className='free'>{t("FREE")}</span>}
                {lecture.secondDuration ? <span className='time'>{convertSecondToTime(lecture.secondDuration)}</span> : null}
            </div>
        </li>
    );
}

PreviewLecture.propTypes = {
    lecture: propTypes.object.isRequired
};

export default PreviewLecture;