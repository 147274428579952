import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types'
import { SEARCH_RESULT_TYPE } from '../resources/constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import { convertStringForUrl } from '../Utils';
import { MENU_SET_RESULT_VISIBLE, MENU_SET_SEARCHING_VALUE } from '../redux/actions/ActionTypes';
import { COURSE_STATUS } from '../resources/constants/courseStatus';
import { useTranslation } from 'react-i18next';

const HeaderResultItem = ({ type, result }) => {
    const dispatch = useDispatch();
    const menuStore = useSelector(store => store.menu);
    const { t } = useTranslation();

    const onSelect = () => {
        dispatch({ type: MENU_SET_RESULT_VISIBLE, payload: { isVisible: false } });
        dispatch({ type: MENU_SET_SEARCHING_VALUE, payload: { value: result.title } });
    }

    return (
        <Link className='item transition' onClick={onSelect} to={type === SEARCH_RESULT_TYPE.COURSE ? `/course/${result.id}-${convertStringForUrl(result.title)}/preview` : `/category/${result.id}-${convertStringForUrl(result.title)}`}>
            <div className={`icon ${type === SEARCH_RESULT_TYPE.COURSE ? 'course' : 'category'}`}><div className='sub'></div></div>

            <div className='infos'>
                <span className='title' dangerouslySetInnerHTML={{ __html: result.title.replace(menuStore.searchingValue, `<b>${menuStore.searchingValue}</b>`) }}></span>
                {type === SEARCH_RESULT_TYPE.COURSE && result.status === COURSE_STATUS[0].id ? <span className='status success'>{t("NEW")}</span> : null}
                {type === SEARCH_RESULT_TYPE.COURSE && result.status === COURSE_STATUS[2].id ? <span className='status warning'>{t("SOON_EXPIRED_SHORT")}</span> : null}
            </div>
        </Link>
    );
}

HeaderResultItem.propTypes = {
    type: propTypes.number.isRequired,
    result: propTypes.object.isRequired
};

export default HeaderResultItem;