import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../loader/BasicLoader';
import LectureItemAssignLectureAdminPopup from './LectureItemAssignLectureAdminPopup';
import { useTranslation } from 'react-i18next';

const LectureListAssignLectureAdminPopup = () => {
    const assignLectureAdminStore = useSelector(store => store.assignLectureAdminPopup);
    const { t } = useTranslation();

    if (!assignLectureAdminStore.isLoading && !assignLectureAdminStore.lectures?.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_LECTURES_FOUND")}
            </div>
        );

    return (
        <div className='list'>
            {assignLectureAdminStore.lectures?.map(lecture => <LectureItemAssignLectureAdminPopup key={lecture.id} lecture={lecture} isSelected={assignLectureAdminStore.selectedLectures?.includes(lecture.id)} />)}

            {assignLectureAdminStore.isLoading && <>
                <div className='item section-card lecture-card rounded-m loading'><BasicLoader width={'100%'} height={350} radius={10} /></div>
                <div className='item section-card lecture-card rounded-m loading'><BasicLoader width={'100%'} height={350} radius={10} /></div>
                <div className='item section-card lecture-card rounded-m loading'><BasicLoader width={'100%'} height={350} radius={10} /></div>
            </>}
        </div>
    );
}

export default LectureListAssignLectureAdminPopup