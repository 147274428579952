import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openSurveyAdminPopup } from '../../../../redux/actions/popup/admin/SurveyPopupActions';

// Images
import NoImage from '../../../../resources/images/no-image.png';


const SurveyUserItemAdminPopup = ({ survey }) => {
    const dispatch = useDispatch();

    return (
        <div className={`white shadow rounded-m thumbnail horizontal survey-card ${survey.isLoading ? 'disabled' : ''}`} onClick={() => dispatch(openSurveyAdminPopup(survey.id))}>
            <div className='left'>
                <img className='img-main rounded-s' src={survey.course?.picture || NoImage} alt={survey.course?.title} />

                <div className='info'>
                    <span className='survey-title'>{survey?.title}</span>
                    <div className='course-info'>{survey?.course?.title}</div>
                    <div className='note'><span className='rounded-s'>{survey.finalScore}/{survey.finalScoreBase}</span></div>
                </div>
            </div>

            <div className='actions-banner'></div>
        </div>
    );
}

SurveyUserItemAdminPopup.propTypes = {
    survey: propTypes.object.isRequired
};

export default SurveyUserItemAdminPopup;