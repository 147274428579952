import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import Input from '../../../Input';
import BasicLoader from '../../../loader/BasicLoader';
import { CATEGORY_ADMIN_POPUP_SEO_KEYWORDS, CATEGORY_ADMIN_POPUP_SET_TITLE, CATEGORY_ADMIN_POPUP_SET_TITLE_EN } from '../../../redux/actions/ActionTypes';
import { createCategoryAdminPopup, deleteCategoryAdminPopup, deleteCategoryPictureAdminPopup, updateCategoryAdminPopup, uploadCategoryPictureAdminPopup } from '../../../redux/actions/popup/admin/CategoryPopupActions';
import { createNotification } from '../../../Utils';
import ReactTagInput from "@pathofdev/react-tag-input";
import { useTranslation } from 'react-i18next';

const OverviewCategoryAdminPopup = () => {
    const dispatch = useDispatch();
    const categoryAdminStore = useSelector(store => store.categoryAdminPopup);
    const filePickerRef = useRef(null);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        if (categoryAdminStore.categoryId) {
            dispatch(updateCategoryAdminPopup(categoryAdminStore))
        } else {
            dispatch(createCategoryAdminPopup(categoryAdminStore));
        }
    }

    const onImageChoosen = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            reader.onloadend = () => {
                if (file.size < 2000000) {
                    dispatch(uploadCategoryPictureAdminPopup(categoryAdminStore.categoryId, file));
                } else {
                    createNotification(t("NOTIFICATION_ERROR_TITLE"), `${t("IMAGE_TOO_WEIGHT")}. (Maximum 2MB)`, 'danger');
                }
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <div className='overview'>
            <div>
                <form onSubmit={onSubmit}>
                    <div className='content-title'>
                        <h2>Présentation</h2>
                    </div>

                    <div className='section'>
                        {categoryAdminStore.categoryId && <>
                            <h3>Image</h3>

                            <div className={`picture rounded-xl ${categoryAdminStore.isLoading || categoryAdminStore.isPictureLoading ? 'disabled' : ''}`} style={!categoryAdminStore.isLoading && categoryAdminStore.picture ? { backgroundImage: `url(${categoryAdminStore.picture})` } : null}>
                                {categoryAdminStore.isLoading ? <BasicLoader width={'100%'} height={'100%'} radius={0} /> :
                                    <div className={`overlay transition ${!categoryAdminStore.picture ? 'empty' : ''}`}>
                                        <Button title={categoryAdminStore.picture ? 'Modifier l\'image' : 'Ajouter une image'} btnStyle={`autofit ${!categoryAdminStore.picture ? 'secondary' : ''}`} disabled={categoryAdminStore.isLoading} isLoading={categoryAdminStore.isPictureLoading} onClick={() => filePickerRef.current.click()} />
                                        <input style={{ display: 'none' }} accept='image/*' type='file' ref={filePickerRef} onChange={onImageChoosen} />
                                        {categoryAdminStore.picture && <Button title={t("DELETE_IMAGE")} btnStyle={'cancel autofit'} confirmationRequired isLoading={categoryAdminStore.isPictureLoading} onClick={() => dispatch(deleteCategoryPictureAdminPopup(categoryAdminStore.categoryId))} />}
                                    </div>}
                            </div>
                        </>}

                        <Input title={'Titre'} placeholder={'Titre'} disabled={categoryAdminStore.isLoading || categoryAdminStore.isInformationsLoading} value={categoryAdminStore.title} onChange={(title) => dispatch({ type: CATEGORY_ADMIN_POPUP_SET_TITLE, payload: { title } })} error={!categoryAdminStore.isTitleValid} helperText={t("HELPER_TITLE_SECTION")} />
                        <Input title={'Titre anglais'} placeholder={'Titre anglais'} disabled={categoryAdminStore.isLoading || categoryAdminStore.isInformationsLoading} value={categoryAdminStore.titleEN} onChange={(title) => dispatch({ type: CATEGORY_ADMIN_POPUP_SET_TITLE_EN, payload: { title } })} error={!categoryAdminStore.isTitleENValid} helperText={t("HELPER_TITLE_SECTION")} />
                        <Input title={'Keywords de référencement & recherche (en complément du titre)'} placeholder='SSCM, 2020, SC'>
                            <div className='keywords-container'>
                                <ReactTagInput
                                    tags={categoryAdminStore.seoKeyword}
                                    onChange={(seoKeyword) => dispatch({ type: CATEGORY_ADMIN_POPUP_SEO_KEYWORDS, payload: { seoKeyword } })}
                                    placeholder={'Mot-clé'}
                                />
                            </div>
                        </Input>
                    </div>

                    <div className='right-alignment'>
                        {categoryAdminStore.categoryId ? <Button title={t("UPDATE")} btnStyle='primary autofit' disabled={categoryAdminStore.isLoading} isLoading={categoryAdminStore.isInformationsLoading} type={'submit'} /> : <Button title={'Créer la formation'} btnStyle='primary autofit' disabled={categoryAdminStore.isLoading} isLoading={categoryAdminStore.isInformationsLoading} type={'submit'} />}
                    </div>
                </form>
            </div>

            {categoryAdminStore.categoryId &&
                <>
                    <div className='or'>
                        <hr />
                    </div>

                    <div>
                        <div className='content-title'>
                            <h2>Zone de danger</h2>
                        </div>
                        <div className='section'>
                            <div className='danger-item'>
                                <div className='labels'>
                                    <h3>Supprimer la formation</h3>
                                    <span>
                                        Toutes les catégories enfants deviendront sans parent et deviendront donc une categorie principale<br />
                                        Cette opération est irréversible.
                                    </span>
                                </div>

                                <Button title={'Supprimer la catégorie'} btnStyle={'cancel autofit'} confirmationRequired disabled={categoryAdminStore.isLoading} isLoading={categoryAdminStore.isInformationsLoading} onClick={() => dispatch(deleteCategoryAdminPopup(categoryAdminStore.categoryId))} />
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default OverviewCategoryAdminPopup;