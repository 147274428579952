import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import UseAnimations from 'react-useanimations';
import menu4 from 'react-useanimations/lib/menu4';
import searchToX from 'react-useanimations/lib/searchToX';
import BackgroundPopup from '../BackgroundPopup';
import { MENU_SET_MOBILE_MENU, MENU_SET_RESULT_VISIBLE, MENU_SET_SEARCHING_VALUE, SIGN_POPUP_SET_ENABLED, VOUCHER_POPUP_SET_ENABLED } from '../redux/actions/ActionTypes';
import MenuList from './MenuList';
import HeaderResultList from './HeaderResultList';
import { loadCategories, loadSearchBarResult } from '../redux/actions/MainActions';
import { SIGN_POPUP_POPUP_TYPE } from '../resources/constants/signPopupType';
import AuthenticationService from '../services/Authentication';
import { MOBILE_MENU_TYPE } from '../resources/constants/menu';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

// Images
import Profile from '../resources/images/profile.png';

const Header = ({ fixed }) => {
    const { t } = useTranslation()

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
    const dispatch = useDispatch();
    const menuStore = useSelector(store => store.menu);
    let connectedUser = null;
    const [searchInputTimer, setSearchInputTimer] = useState(null);

    if (AuthenticationService.IsLogged())
        connectedUser = AuthenticationService.GetSession();

    useEffect(() => {
        if (!menuStore.isLoaded)
            dispatch(loadCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(loadCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const onSearching = (e) => {
        clearTimeout(searchInputTimer);
        dispatch({ type: MENU_SET_SEARCHING_VALUE, payload: { value: e.target.value } });
        dispatch({ type: MENU_SET_RESULT_VISIBLE, payload: { isVisible: Boolean(e.target.value && e.target.value.length >= 3) } });

        if (e.target.value && e.target.value.length >= 3) {
            const value = e.target.value;
            setSearchInputTimer(setTimeout(() => dispatch(loadSearchBarResult(value)), 500));
        }
    }

    const changeLanguage = (lng) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng)
    }

    return (
        <>
            <BackgroundPopup level={2} enabled={menuStore.isResultVisible} onClick={() => dispatch({ type: MENU_SET_RESULT_VISIBLE, payload: { isVisible: false } })} />

            <header className={`container shadow rounded-s white ${fixed ? 'fixed' : ''} ${menuStore.mobileMenu === MOBILE_MENU_TYPE.SEARCH ? 'mobile-search' : ''}`}>
                <div className='left'>
                    <div className='logo-menu'>
                        <UseAnimations animation={menu4} className='icon menu' reverse={menuStore.mobileMenu === MOBILE_MENU_TYPE.MENU} onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: menuStore.mobileMenu === MOBILE_MENU_TYPE.MENU ? null : MOBILE_MENU_TYPE.MENU } })} />
                        <Link to='/'><div className='logo'></div></Link>
                        <UseAnimations animation={searchToX} className='icon' reverse={menuStore.mobileMenu === MOBILE_MENU_TYPE.SEARCH} onClick={() => dispatch({ type: MENU_SET_MOBILE_MENU, payload: { menu: menuStore.mobileMenu === MOBILE_MENU_TYPE.SEARCH ? null : MOBILE_MENU_TYPE.SEARCH } })} />
                    </div>

                    <MenuList />
                </div>

                <div className='search-bar'>
                    <div className='icon search'></div>
                    <input type='text' placeholder={t("SEARCH")} value={menuStore.searchingValue} onChange={onSearching} />

                    {menuStore.isResultVisible && <HeaderResultList />}
                </div>

                <div className='lang'>
                    <div className='flag'>
                        <div className={`country ${selectedLanguage}`}></div>
                        <div className='icon arrow'></div>
                    </div>

                    <div className='sub'>
                        <div className={`dropdown-list shadow rounded-m white ${menuStore.isCategoriesLoading ? 'loading' : ''}`} role='listbox'>
                            <div className='item' onClick={() => changeLanguage('fr')}>
                                <div className='country fr'></div>
                                <span>Français</span>
                            </div>

                            <div className='item' onClick={() => changeLanguage('en')}>
                                <div className='country en'></div>
                                <span>English</span>
                            </div>
                        </div>
                    </div>
                </div>

                {!AuthenticationService.IsLogged() ? <div className='login-menu'>
                    <div className='button' onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.REGISTER } })}>{t("REGISTRATION")}</div>
                    <div className='button' onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.LOGIN } })}>{t("CONNECTION")}</div>
                </div> : <div className='login-menu logged'>
                    <Link to={'/dashboard'} className='my-learning-button rounded-s transition'>{t("MY_COURSES")}</Link>
                    <div className='button'>
                        <div className='info'><img src={connectedUser.picture || Profile} alt='profile' />{`${connectedUser.firstname} ${connectedUser.lastname}`}</div>

                        <div className='sub'>
                            <div className={`dropdown-list shadow rounded-m white ${menuStore.isCategoriesLoading ? 'loading' : ''}`} role='listbox'>
                                <Link to={'/dashboard/preference/informations'} className='item'>{t("PREFERENCES")}</Link>
                                <div className='item' onClick={() => dispatch({ type: VOUCHER_POPUP_SET_ENABLED, payload: { isEnabled: true } })}>{t("USE_CODE")}</div>
                                {AuthenticationService.IsAdmin() && <Link to={'/admin/courses'} className='item'>{t("ADMINISTRATION")} <span className='emoji' role={'img'} aria-label={'star'}>✨</span></Link>}
                                <div className='item red' onClick={() => AuthenticationService.Logout()}>{t("LOGOUT")}</div>
                            </div>
                        </div>
                    </div>
                </div>}
            </header>
        </>
    );
}

export default Header;