import React from 'react';
import { useTranslation } from 'react-i18next';

const TransitionViewTwo = ({ transitionViewRef, transitionViewPathRef, transitionViewAdvantage1Ref, transitionViewAdvantage2Ref, transitionViewAdvantage3Ref }) => {
    const { t } = useTranslation()

    return (
        <div className='sub-main transition' ref={transitionViewRef} id='transition-2'>
            <div className='container'>
                <div className='story-overlay'>
                    <div className='row'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage1Ref}>
                            <div className='left'>
                                <div className='icon marker'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("ACCORD_YOUR_AGENDA")}</span>
                                <p dangerouslySetInnerHTML={{ __html: t("ACCORD_YOUR_AGENDA_DESC") }}></p>
                            </div>
                        </div>

                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage3Ref}>
                            <div className='left'>
                                <div className='icon ruler'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("WORTH_TAILORED")}</span>
                                <p>{t("WORTH_TAILORED_DESC")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row center'>
                        <div className='advantage rounded-m transition' ref={transitionViewAdvantage2Ref}>
                            <div className='left'>
                                <div className='icon cup'></div>
                            </div>

                            <div className='right'>
                                <span className='title'>{t("LEARN_RYTHME")}</span>
                                <p>{t("LEARN_RYTHME_DESC")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3003.1 370.4">
                    <defs>
                        <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(87, 235, 222, 1)" />
                            <stop offset="100%" stopColor="rgba(174, 251, 42, 1)" />
                        </linearGradient>
                    </defs>
                    <path ref={transitionViewPathRef} fill="none" stroke="url(#gradient2)" strokeLinecap="round" strokeWidth="8" d="M82.6,10.2c318.1,181,772.5,362.7,1227.5,218.7c110.7-35,154-68,259.3-103.3c269.8-90.5,693.9-111.7,1351,234.6" />
                </svg>
            </div>
        </div>
    );
}

export default TransitionViewTwo;