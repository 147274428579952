import Axios from "axios"
import { createNotification } from "../../../../Utils";
import { ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY, ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES, ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING, ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING, ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES, COURSE_ADMIN_POPUP_ADD_CATEGORY } from "../../ActionTypes";
import i18n from 'i18next';

export const openAssignCategoryAdminPopup = (courseId, selectedCategories) => async dispatch => {
    dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_COURSE_ID, payload: { id: courseId } });
    dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_SELECTED_CATEGORIES, payload: { categories: selectedCategories } });
    dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
}

export const loadCategoriesAdminPopup = () => async dispatch => {
    dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`category/all`)
        .then(({ data }) => {
            dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORIES, payload: { categories: data } });
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
        }).finally(() => {
            dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
        });
}

export const addCourseCategoryAdminPopup = (courseId, categoryId) => async dispatch => {
    dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING, payload: { id: categoryId, isLoading: true } });

    Axios.post(`coursecategory/add/admin`, {
        courseId,
        categoryId
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_ADD_SELECTED_CATEGORY, payload: { id: categoryId } });
        dispatch({ type: COURSE_ADMIN_POPUP_ADD_CATEGORY, payload: { category: data } });
    }).catch(({ response }) => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_CATEGORY_ADMIN_POPUP_SET_CATEGORY_LOADING, payload: { id: categoryId, isLoading: false } });
    });
}