import Axios from "axios";
import { createNotification } from "../../../../Utils";
import { ASSIGN_USER_ADMIN_POPUP_ADD_USERS, ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID, ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT, ASSIGN_USER_ADMIN_POPUP_SET_LOADING, ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE } from "../../ActionTypes";
import i18n from 'i18next';

export const openUserAsCourseAdminPopup = (courseId) => async dispatch => {
    dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_ENABLED, payload: { popupEnabled: true } });
    dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_COURSE_ID, payload: { id: courseId } });
}

export const loadUsersAdminPopup = (pagingPage = 0, value = null) => async dispatch => {
    dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: true } });

    Axios.get(`user/list/all/admin`, {
        params: {
            pageIndex: pagingPage,
            name: value
        }
    }).then(({ data }) => {
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_ADD_USERS, payload: { users: data.paging } });
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_HAS_NEXT, payload: { hasNext: data.hasNext } });
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_PAGING_PAGE, payload: { pagingPage: data.pageIndex } });
    }).catch(() => {
        createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
    }).finally(() => {
        dispatch({ type: ASSIGN_USER_ADMIN_POPUP_SET_LOADING, payload: { isLoading: false } });
    });
}