import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import SignPopupHOC from '../../HOC/SignPopupHOC';
import Input from '../../Input';
import { SIGN_POPUP_SET_EMAIL, SIGN_POPUP_SET_PASSWORD, SIGN_POPUP_SET_ENABLED } from '../../redux/actions/ActionTypes';
import { connectWithEmail } from '../../redux/actions/popup/SignActions';
import { SIGN_POPUP_POPUP_TYPE } from '../../resources/constants/signPopupType';
import { useTranslation } from 'react-i18next';

const LoginPopup = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(connectWithEmail(signStore));
    }

    return (
        <form onSubmit={onSubmit} className='login-popup'>
            <Input title={t("EMAIL")} placeholder={t("PLACEHOLDER_EMAIL")} value={signStore.email} onChange={(email) => dispatch({ type: SIGN_POPUP_SET_EMAIL, payload: { email } })} type={'text'} autocomplete={'email'} disabled={signStore.isLoading} error={!signStore.isEmailValid} helperText={signStore.emailHelperText} />
            <Input title={t("PASSWORD")} placeholder={t("PASSWORD")} value={signStore.password} onChange={(password) => dispatch({ type: SIGN_POPUP_SET_PASSWORD, payload: { password } })} type={'password'} autocomplete={'current-password'} disabled={signStore.isLoading} error={!signStore.isPasswordValid} helperText={signStore.passwordHelperText} passwordToggle />

            <Button title={t("LOGIN")} btnStyle={'primary'} type={'submit'} isLoading={signStore.isLoading} />

            <span className='notice'>{t("SIGN_FORGOTTEN_PASSWORD")} <em onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.RESET_PASSWORD } })}>{t("RESET_PASSWORD")}</em></span>
        </form>
    );
}

const SignInSubTitle = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (<span>{t("SIGN_NEW_ON_APP")} <em onClick={() => dispatch({ type: SIGN_POPUP_SET_ENABLED, payload: { type: SIGN_POPUP_POPUP_TYPE.REGISTER } })}>{t("SIGN_CREATE_ACCOUNT")}</em></span>)
}

export default SignPopupHOC(LoginPopup, 'CONNECTION', SignInSubTitle);