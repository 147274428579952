import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PreviewSpecification = ({ title, icon, items }) => {
    const descriptionRef = useRef();
    const [seeMoreAvailable, setSeeMoreAvailable] = useState(false);
    const [extended, setExtended] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (descriptionRef.current.scrollHeight > descriptionRef.current.offsetHeight)
            setSeeMoreAvailable(true);
    }, [descriptionRef]);

    return (
        <div className={`col transition ${extended ? 'extended' : ''}`} ref={descriptionRef}>
            <h4>{title}</h4>
            <ul>
                {items.map(item => <li key={item.id}><div className={`icon ${icon}`}></div>{item.label}</li>)}
            </ul>
            {seeMoreAvailable ? <div className='see-more-toggle' onClick={() => setExtended(!extended)}>{extended ? t("SEE_LESS") : t("SEE_MORE")}</div> : null}
        </div>
    );
}

PreviewSpecification.propTypes = {
    title: propTypes.string.isRequired,
    icon: propTypes.string.isRequired,
    items: propTypes.array.isRequired
};

export default PreviewSpecification;