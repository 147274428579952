import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../Button';
import { nextQuestionSurvey } from '../../../../redux/actions/dashboard/LectureSurveyActions';
import LectureSurveyRunAnswer from './LectureSurveyRunAnswer';
import { useTranslation } from 'react-i18next';

const LectureSurveyRun = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lectureStore = useSelector(store => store.lecturePanel);
    const positionCurrentQuestion = lectureStore.survey.surveyQuestion.findIndex(q => q.id === lectureStore.currentQuestionId);
    const currentQuestion = lectureStore.survey.surveyQuestion[positionCurrentQuestion];
    const isLastQuestion = positionCurrentQuestion === lectureStore.survey.surveyQuestion.length - 1 && lectureStore.survey.surveyQuestion.filter(q => !q.done).length <= 1;

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion?.selectedAnswers]);

    const onKeyDown = (key) => {
        if (key.key === 'Enter') {
            dispatch(nextQuestionSurvey(currentQuestion.id, lectureStore.survey.userCourseSurveyId, currentQuestion.selectedAnswers));
        }
    }

    const onNext = (ignore = false) => {
        dispatch(nextQuestionSurvey(currentQuestion.id, lectureStore.survey.userCourseSurveyId, !ignore ? currentQuestion.selectedAnswers : []));
    }

    if (!currentQuestion)
        return null;

    return (
        <div className='step-run'>
            <div className='progress-container'>
                {lectureStore.survey.surveyQuestion.map(q => <div key={q.id} className={`progress ${q.id === lectureStore.currentQuestionId ? 'current' : ''} ${q.done ? 'done' : ''}`}></div>)}
            </div>

            <div className='content'>
                <div className='question'>
                    <div className='num'>
                        <span>{positionCurrentQuestion + 1}.</span>
                    </div>

                    <span className='title'>{currentQuestion.title}</span>
                </div>

                <div className='answers-container'>
                    <div className='answers'>
                        {currentQuestion.surveyAnswer.map((a, index) => <LectureSurveyRunAnswer key={a.id} index={index + 1} currentQuestion={currentQuestion} answer={a} />)}
                    </div>
                </div>

                <div className='go-next'>
                    {!currentQuestion.required && <span className='transition' onClick={() => onNext(true)}>{t("SKIP_QUESTION")}</span>}
                    <Button title={isLastQuestion ? t("FINISH_TEST") : t("NEXT_QUESTION")} btnStyle={'primary autofit'} isLoading={lectureStore.isSurveyLoading} disabled={currentQuestion.surveyAnswer?.length && !currentQuestion.selectedAnswers?.length} onClick={() => onNext()} />
                </div>
            </div>
        </div>
    );
}

export default LectureSurveyRun;