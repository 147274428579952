import React from 'react';
import { InlineWidget } from "react-calendly";
import PopupHOC from '../HOC/PopupHOC';

const AppointmentPopup = (props) => {
    return (
        <div className='popup white rounded-l shadow padding large preview appointment'>
            <div className='header'>
                <span className='subtitle'>Rencontrez le coach pour créer votre parcours</span>
                <div className='title'>
                    <div className='icon-container'></div>
                    <h1>Il est l'heure de se rencontrer</h1>
                    <div className='icon-container close transition' onClick={props.onClose}><div className='icon close'></div></div>
                </div>
            </div>

            <div className='calendly-container shadow white rounded-m'>
                <InlineWidget url="https://calendly.com/janssens/meet" />
            </div>
        </div>
    );
}

export default PopupHOC(AppointmentPopup);