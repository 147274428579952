import React from 'react';

// Images
import Telindus from '../../../resources/images/solution/company/telindus.png';
import NextMedia from '../../../resources/images/solution/company/next-media.png';
import Aelion from '../../../resources/images/solution/company/aelion.png';
import Arrow from '../../../resources/images/solution/company/arrow.png';
import { useDispatch } from 'react-redux';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';

const MainViewFour = ({ mainViewRef, mainTitlemainViewRef, overlayTitlemainViewRef }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    return (
        <div className='sub-main' ref={mainViewRef} id='view-4'>
            <div className='container'>
                <div className='animate trust'></div>
                <div className='title'>
                    <div className='main-title'>
                        <h3>
                            {t("TRUST_US")}

                            <div className='overlay' ref={mainTitlemainViewRef}>
                                <h3 ref={overlayTitlemainViewRef}>{t("TRUST_US")}</h3>
                            </div>
                        </h3>
                    </div>
                    <div className='companies'>
                        <img className={'transition'} src={Telindus} alt='telindus' />
                        <img className={'transition'} src={NextMedia} alt='next media' />
                        <img className={'transition'} src={Aelion} alt='aelion' />
                        <img className={'transition'} src={Arrow} alt='arrow' style={{ height: '18px' }} />
                    </div>

                    <div onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })} className='cto-button-container'>
                        <div className='btn'>{t("DISCOVER_EXP")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainViewFour;