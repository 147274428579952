import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../Input';
import propTypes from 'prop-types';
import { LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION } from '../../../redux/actions/ActionTypes';
import { updateLectureDescriptionAdmin } from '../../../redux/actions/admin/LectureAdminActions';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

const LectureDescription = ({ admin }) => {
    const dispatch = useDispatch();
    const lectureStore = useSelector(store => store.lecturePanel);
    const { t } = useTranslation();

    const onLectureDescriptionEditing = () => {
        if (!lectureStore.isEditingDescription)
            dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION, payload: { description: lectureStore.lecture.description } });

        dispatch({ type: LECTURE_PANEL_ADMIN_SET_EDITING_DESCRIPTION_STATUS, payload: { isEditing: !lectureStore.isEditingDescription } });
    }

    const updateLectureDescription = (e) => {
        e.preventDefault();

        dispatch(updateLectureDescriptionAdmin(lectureStore.lecture.id, lectureStore.editingLectureDescription));
    }

    if (!admin && (!lectureStore.lecture || !lectureStore.lecture.description))
        return null;

    return (
        <div className='notification-banner rounded-xl large'>
            <span className='title'>{t("GOAL")}</span>
            {admin && <span className='sub-title'>En laissant ce champ vide, le bloc 'Avant-propos' ne sera pas visible</span>}

            {admin && lectureStore.isEditingDescription ?
                <div className='admin-lecture-input-editor'>
                    <div className='editor'>
                        <Input error={!lectureStore.isEditingLectureDescriptionValid} helperText={t("HELPER_DESCRIPTION")}>
                            <textarea placeholder={'Aa'} value={lectureStore.editingLectureDescription} onChange={(e) => dispatch({ type: LECTURE_PANEL_ADMIN_SET_LECTURE_EDITING_DESCRIPTION, payload: { description: e.target.value } })}></textarea>
                        </Input>
                        <div className='icon check hover-to-blue transition' onClick={updateLectureDescription}></div>
                    </div>
                </div> : <div className='content'><ReactMarkdown>{lectureStore.lecture.description}</ReactMarkdown></div>}

            {admin && <>
                {lectureStore.isEditingDescription && <a href="https://www.markdownguide.org/cheat-sheet/" target='_blank' rel='noreferrer'><span className='cancel-button black'>Besoin d'aide pour le markdown ? Cliquez ici</span></a>}
                <span className='cancel-button' onClick={onLectureDescriptionEditing}>{lectureStore.isEditingDescription ? 'Annuler' : 'Modifier'}</span>
            </>}
        </div>
    );
}

LectureDescription.propTypes = {
    admin: propTypes.bool
}

export default LectureDescription;