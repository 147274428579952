import { TRACER_ADMIN_PANEL_SET_LOADING, TRACER_ADMIN_PANEL_SET_SEARCH_VALUE, TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY, TRACER_ADMIN_PANEL_SET_TRACERS, TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED, TRACER_ADMIN_PANEL_SET_TRACER_LOADING } from "../../actions/ActionTypes";

const defaultState = {
    isLoading: true,

    tracers: [],

    searchValue: '',
    showActiveUsersOnly: true,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case TRACER_ADMIN_PANEL_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }

        case TRACER_ADMIN_PANEL_SET_SEARCH_VALUE: {
            return { ...state, searchValue: action.payload.searchValue };
        }

        case TRACER_ADMIN_PANEL_SET_TRACERS: {
            return { ...state, tracers: action.payload.tracers };
        }

        case TRACER_ADMIN_PANEL_SET_TRACER_LOADING: {
            return {
                ...state, tracers: state.tracers.map(t => {
                    if (t.id === action.payload.id)
                        return { ...t, isLoading: action.payload.isLoading };

                    return t;
                })
            }
        }
        case TRACER_ADMIN_PANEL_SET_TRACER_CERTIFICATE_RECEIVED: {
            return {
                ...state, tracers: state.tracers.map(t => {
                    if (t.id === action.payload.id)
                        return { ...t, certificateReceived: action.payload.certificateReceived };

                    return t;
                })
            }
        }
        case TRACER_ADMIN_PANEL_SET_SHOW_ACTIVE_USERS_ONLY: {
            return { ...state, showActiveUsersOnly: action.payload.showActiveUsersOnly };
        }

        default:
            return state;
    }
}