import Axios from "axios";
import { LECTURE_SURVEY_STEP } from "../../../resources/constants/lectureSurvey";
import { createNotification } from "../../../Utils";
import { LECTURE_PANEL_SET_CURRENT_LECTURE, LECTURE_PANEL_SET_LECTURE, LECTURE_PANEL_SET_LECTURE_AS_COMPLETED, LECTURE_PANEL_SET_LECTURE_AS_UNLOCKED, LECTURE_PANEL_SET_LECTURE_LOADING, LECTURE_PANEL_SET_MENU, LECTURE_PANEL_SET_MENU_LOADING, LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID, LECTURE_PANEL_SURVEY_SET_RESULTS, LECTURE_PANEL_SURVEY_SET_STEP, LECTURE_PANEL_SURVEY_SET_SURVEY, LECTURE_PANEL_TOGGLE_SECTION } from "../ActionTypes"
import i18n from 'i18next';

export const loadLectureMenu = (userCourseId, admin = false) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SET_MENU_LOADING, payload: { isLoading: true } });

    let url = `usercourse/${userCourseId}/menu`;
    if (admin)
        url = `course/${userCourseId}/menu/admin`;

    return Axios.get(url)
        .then(({ data }) => {
            dispatch({ type: LECTURE_PANEL_SET_MENU, payload: { menu: data } });

            const section = data.lastLectureId ? data.sections.filter(s => s.lectures.some(l => l.id === data.lastLectureId))[0] : data.sections[0];
            if (section)
                dispatch({ type: LECTURE_PANEL_TOGGLE_SECTION, payload: { open: true, id: section.id } });

            return data;
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            throw Error();
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SET_MENU_LOADING, payload: { isLoading: false } });
        });
}

export const loadLecture = (lectureStore, userCourseId = null, lectureId, admin = false) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SET_LECTURE_LOADING, payload: { isLoading: true } });
    dispatch({ type: LECTURE_PANEL_SET_CURRENT_LECTURE, payload: { id: lectureId } });

    let url = `lecture/learn/usercourse/${userCourseId}/lecture/${lectureId}`;
    if (admin)
        url = `/lecture/learn/${lectureId}/admin`;

    return Axios.get(url)
        .then(async ({ data }) => {
            dispatch({ type: LECTURE_PANEL_SET_LECTURE, payload: { lecture: data } });

            if (data.surveyId) {
                if (!admin) {
                    await Axios.get(`survey/${data.surveyId}/current`)
                        .then((response) => {
                            const { data } = response;
                            if (response.status === 206) {
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_SURVEY, payload: { survey: data } });
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.RUN } });
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_CURRENT_QUESTION_ID, payload: { id: data.currentQuestionId || data.surveyQuestion[0]?.id } });
                            } else if (response.status === 200) {
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_RESULTS, payload: { results: data } });
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.RESULT } });
                            } else {
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.START } });
                                dispatch({ type: LECTURE_PANEL_SURVEY_SET_SURVEY, payload: { survey: null } });
                            }
                        })
                } else {
                    await Axios.get(`survey/${data.surveyId}/admin`)
                        .then(({ data }) => {
                            dispatch({ type: LECTURE_PANEL_SURVEY_SET_SURVEY, payload: { survey: data } });
                            dispatch({ type: LECTURE_PANEL_SURVEY_SET_STEP, payload: { step: LECTURE_SURVEY_STEP.ADMIN } });
                        });
                }
            }
        }).catch(() => {
            createNotification(i18n.t("NOTIFICATION_ERROR_TITLE"), i18n.t("ERROR_MESSAGE"), 'danger');
            throw Error();
        }).finally(() => {
            dispatch({ type: LECTURE_PANEL_SET_LECTURE_LOADING, payload: { isLoading: false } });
        });
}

export const markLectureAsDone = (userCourseId, lectureId) => async dispatch => {
    dispatch({ type: LECTURE_PANEL_SET_LECTURE_LOADING, payload: { isLoading: true } });

    return Axios.post(`usercourselecture/done`, {
        lectureId,
        userCourseId
    }).then(({ data }) => {
        dispatch({ type: LECTURE_PANEL_SET_LECTURE_AS_COMPLETED, payload: { id: lectureId } });
        dispatch({ type: LECTURE_PANEL_SET_CURRENT_LECTURE, payload: { id: data.id } });
        dispatch({ type: LECTURE_PANEL_TOGGLE_SECTION, payload: { open: true, id: data.sectionId } });
        dispatch({ type: LECTURE_PANEL_SET_LECTURE_AS_UNLOCKED, payload: { id: data.id } });

        return data.id;
    }).finally(() => {
        dispatch({ type: LECTURE_PANEL_SET_LECTURE_LOADING, payload: { isLoading: false } });
    });
}