import Axios from "axios";
import { DASHBOARD_PANEL_ADD_COURSE, VOUCHER_POPUP_SET_LOADING, VOUCHER_POPUP_SET_VOUCHERS, VOUCHER_POPUP_VOUCHER_CODE_CHECK } from "../ActionTypes"
import i18n from 'i18next';

export const voucherPopupApplyCode = (voucherCode) => async dispatch => {
    const isVoucherValid = (/[a-zA-Z]{3}[0-9]{3}/).test(voucherCode.toUpperCase()); //eslint-disable-line

    dispatch({ type: VOUCHER_POPUP_VOUCHER_CODE_CHECK, payload: { isValid: isVoucherValid, text: i18n.t("HELPER_CODE") } });

    if (isVoucherValid) {
        dispatch({ type: VOUCHER_POPUP_SET_LOADING, payload: { isLoading: true } });

        Axios.post(`voucher/assign/course`, {
            code: voucherCode
        }).then(({ data }) => {
            dispatch({ type: VOUCHER_POPUP_SET_VOUCHERS, payload: { vouchers: data } });

            for (const course of data) {
                dispatch({ type: DASHBOARD_PANEL_ADD_COURSE, payload: { course } });
            }
        }).catch(({ response }) => {
            dispatch({ type: VOUCHER_POPUP_VOUCHER_CODE_CHECK, payload: { isValid: false, text: (response && response.data && response.data.Message) || i18n.t("ERROR_MESSAGE") } });
        }).finally(() => {
            dispatch({ type: VOUCHER_POPUP_SET_LOADING, payload: { isLoading: false } });
        })
    }
}