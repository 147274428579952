import { ADMIN_USER_MENU } from "../../../../resources/constants/adminPanel";
import { USER_ADMIN_POPUP_COMPANY_CHECK, USER_ADMIN_POPUP_EMAIL_CHECK, USER_ADMIN_POPUP_FIRSTNAME_CHECK, USER_ADMIN_POPUP_LASTNAME_CHECK, USER_ADMIN_POPUP_PHONE_CHECK, USER_ADMIN_POPUP_RESET, USER_ADMIN_POPUP_SET_COMPANY, USER_ADMIN_POPUP_SET_EMAIL, USER_ADMIN_POPUP_SET_ENABLED, USER_ADMIN_POPUP_SET_FIRSTNAME, USER_ADMIN_POPUP_SET_LASTNAME, USER_ADMIN_POPUP_SET_LOADING, USER_ADMIN_POPUP_SET_NEW_PASSWORD, USER_ADMIN_POPUP_SET_SECURITY_LOADING, USER_ADMIN_POPUP_SET_PHONE, USER_ADMIN_POPUP_SET_RENEW_PASSWORD, USER_ADMIN_POPUP_SET_USER, USER_ADMIN_POPUP_SET_USER_ID, USER_ADMIN_POPUP_TOGGLE_MENU_STATUS, USER_ADMIN_POPUP_NEW_PASSWORD_CHECK, USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK, USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING, USER_ADMIN_POPUP_SET_COURSES, USER_ADMIN_POPUP_SET_COURSES_LOADING, USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE, USER_ADMIN_POPUP_SET_COURSE_LOADING, USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING, USER_ADMIN_POPUP_SET_CERTIFICATES, USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING, USER_ADMIN_POPUP_DELETE_CERTIFICATE, USER_ADMIN_POPUP_DELETE_COURSE, USER_ADMIN_POPUP_SET_SOCIAL, USER_ADMIN_POPUP_SET_PICTURE_LOADING, USER_ADMIN_POPUP_SET_PICTURE, USER_ADMIN_POPUP_SET_CONFIRMED, USER_ADMIN_POPUP_SET_POPUP_TITLE, USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK, USER_ADMIN_POPUP_ADD_COURSE, USER_ADMIN_POPUP_ADD_CERTIFICATE, USER_ADMIN_POPUP_SET_SURVEYS_LOADING, USER_ADMIN_POPUP_SET_SURVEYS, USER_ADMIN_POPUP_SET_SURVEY_LOADING, USER_ADMIN_POPUP_DELETE_SURVEY } from "../../../actions/ActionTypes";

const defaultState = {
    popupEnabled: null,
    isLoading: true,

    menu: ADMIN_USER_MENU,

    userId: null,
    popupTitle: 'Chargement',

    // Informations
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    company: '',
    facebook: false,
    microsoft: false,
    confirmed: false,

    isEmailValid: true,
    isFirstnameValid: true,
    isLastnameValid: true,
    isPhoneValid: true,
    isCompanyValid: true,

    isInformationsLoading: false,

    // Password
    newPassword: '',
    renewPassword: '',

    isNewPasswordValid: true,
    isRenewPasswordValid: true,

    isSecurityLoading: false,

    // Photo
    isPictureLoading: true,
    photoLink: null,

    // course
    courses: [],
    isCoursesLoading: true,

    // certificates
    certificates: [],
    isCertificatesLoading: true,

    // Surveys
    surveys: [],
    isSurveysLoading: true,
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case USER_ADMIN_POPUP_RESET: {
            return defaultState;
        }
        case USER_ADMIN_POPUP_SET_ENABLED: {
            return { ...state, popupEnabled: action.payload.type };
        }
        case USER_ADMIN_POPUP_SET_POPUP_TITLE: {
            return { ...state, popupTitle: action.payload.title };
        }
        case USER_ADMIN_POPUP_TOGGLE_MENU_STATUS: {
            return {
                ...state, menu: state.menu.map(i => {
                    if (i.disabled === undefined)
                        return i;

                    i.disabled = !action.payload.enable
                    return i;
                })
            };
        }

        case USER_ADMIN_POPUP_SET_USER_ID: {
            return { ...state, userId: action.payload.userId };
        }

        case USER_ADMIN_POPUP_SET_LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        }
        case USER_ADMIN_POPUP_SET_SECURITY_LOADING: {
            return { ...state, isSecurityLoading: action.payload.isLoading }
        }
        case USER_ADMIN_POPUP_SET_INFORMATIONS_LOADING: {
            return { ...state, isInformationsLoading: action.payload.isLoading }
        }
        case USER_ADMIN_POPUP_SET_COURSES_LOADING: {
            return { ...state, isCoursesLoading: action.payload.isLoading };
        }
        case USER_ADMIN_POPUP_SET_CERTIFICATES_LOADING: {
            return { ...state, isCertificatesLoading: action.payload.isLoading };
        }
        case USER_ADMIN_POPUP_SET_SURVEYS_LOADING: {
            return { ...state, isSurveysLoading: action.payload.isLoading };
        }
        case USER_ADMIN_POPUP_SET_PICTURE_LOADING: {
            return { ...state, isPictureLoading: action.payload.isLoading };
        }

        case USER_ADMIN_POPUP_SET_USER: {
            const { user } = action.payload;
            return {
                ...state,
                userId: user.id,
                isPictureLoading: false,
                photoLink: user.picture,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                phone: user.phone,
                company: user.company,
                facebook: user.facebook,
                microsoft: user.microsoft,
                confirmed: user.confirmed
            };
        }
        case USER_ADMIN_POPUP_SET_PICTURE: {
            return { ...state, photoLink: action.payload.link };
        }

        // Data
        case USER_ADMIN_POPUP_SET_EMAIL: {
            return { ...state, email: action.payload.email, isEmailValid: true };
        }
        case USER_ADMIN_POPUP_SET_FIRSTNAME: {
            return { ...state, firstname: action.payload.firstname, isFirstnameValid: true };
        }
        case USER_ADMIN_POPUP_SET_LASTNAME: {
            return { ...state, lastname: action.payload.lastname, isLastnameValid: true };
        }
        case USER_ADMIN_POPUP_SET_PHONE: {
            return { ...state, phone: action.payload.phone, isPhoneValid: true };
        }
        case USER_ADMIN_POPUP_SET_COMPANY: {
            return { ...state, company: action.payload.company, isCompanyValid: true };
        }
        case USER_ADMIN_POPUP_SET_CONFIRMED: {
            return { ...state, confirmed: action.payload.confirmed };
        }

        case USER_ADMIN_POPUP_SET_NEW_PASSWORD: {
            return { ...state, newPassword: action.payload.password, isNewPasswordValid: true };
        }
        case USER_ADMIN_POPUP_SET_RENEW_PASSWORD: {
            return { ...state, renewPassword: action.payload.password, isRenewPasswordValid: true };
        }
        case USER_ADMIN_POPUP_SET_SOCIAL: {
            return {
                ...state,
                facebook: action.payload.facebook === null ? state.facebook : action.payload.facebook,
                microsoft: action.payload.microsoft === null ? state.microsoft : action.payload.microsoft
            };
        }

        case USER_ADMIN_POPUP_SET_COURSES: {
            return { ...state, courses: action.payload.courses };
        }
        case USER_ADMIN_POPUP_SET_COURSE_LOADING: {
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, isLoading: action.payload.isLoading };
                })
            }
        }
        case USER_ADMIN_POPUP_SET_COURSE_EXPIRY_DATE: {
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, expireDate: action.payload.date };
                })
            }
        }
        case USER_ADMIN_POPUP_SET_COURSE_UNIT_COMPLETE_LOCK: {
            return {
                ...state, courses: state.courses.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, unitCompleteLock: action.payload.unitCompleteLock };
                })
            }
        }
        case USER_ADMIN_POPUP_DELETE_COURSE: {
            return { ...state, courses: state.courses.filter(c => c.id !== action.payload.id) };
        }
        case USER_ADMIN_POPUP_ADD_COURSE: {
            return { ...state, courses: [...state.courses, action.payload.course] };
        }

        case USER_ADMIN_POPUP_SET_CERTIFICATES: {
            return { ...state, certificates: action.payload.certificates };
        }
        case USER_ADMIN_POPUP_SET_CERTIFICATE_LOADING: {
            return {
                ...state, certificates: state.certificates.map(c => {
                    if (c.id !== action.payload.id)
                        return c;

                    return { ...c, isLoading: action.payload.isLoading };
                })
            }
        }
        case USER_ADMIN_POPUP_ADD_CERTIFICATE: {
            return { ...state, certificates: [...state.certificates, action.payload.certificate] };
        }
        case USER_ADMIN_POPUP_DELETE_CERTIFICATE: {
            return { ...state, certificates: state.certificates.filter(c => c.id !== action.payload.id) }
        }

        case USER_ADMIN_POPUP_SET_SURVEYS: {
            return { ...state, surveys: action.payload.surveys };
        }
        case USER_ADMIN_POPUP_SET_SURVEY_LOADING: {
            return {
                ...state, surveys: state.surveys.map(s => {
                    if (s.id !== action.payload.id)
                        return s;

                    return { ...s, isLoading: action.payload.isLoading };
                })
            }
        }
        case USER_ADMIN_POPUP_DELETE_SURVEY: {
            return { ...state, surveys: state.surveys.filter(s => s.id !== action.payload.id) }
        }



        // Check
        case USER_ADMIN_POPUP_EMAIL_CHECK: {
            return { ...state, isEmailValid: action.payload.isValid };
        }
        case USER_ADMIN_POPUP_FIRSTNAME_CHECK: {
            return { ...state, isFirstnameValid: action.payload.isValid };
        }
        case USER_ADMIN_POPUP_LASTNAME_CHECK: {
            return { ...state, isLastnameValid: action.payload.isValid };
        }
        case USER_ADMIN_POPUP_PHONE_CHECK: {
            return { ...state, isPhoneValid: action.payload.isValid };
        }
        case USER_ADMIN_POPUP_COMPANY_CHECK: {
            return { ...state, isCompanyValid: action.payload.isValid };
        }

        case USER_ADMIN_POPUP_NEW_PASSWORD_CHECK: {
            return { ...state, isNewPasswordValid: action.payload.isValid };
        }
        case USER_ADMIN_POPUP_RENEW_PASSWORD_CHECK: {
            return { ...state, isRenewPasswordValid: action.payload.isValid };
        }


        default:
            return state;
    }
}