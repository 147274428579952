import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import AdminPopupHOC from '../../HOC/AdminPopupHOC';
import { ASSIGN_ADDITIONAL_ADMIN_POPUP_RESET } from '../../redux/actions/ActionTypes';
import { uploadAdditionalAdminPopup } from '../../redux/actions/popup/admin/AssignAdditionalPopupActions';
import AdditionalItemAssignAdditionalAdminPopup from './assignAdditional/AdditionalItemAssignAdditionalAdminPopup';

const AssignAdditionalAdminPopup = () => {
    const dispatch = useDispatch();
    const assignAdditionalAdminStore = useSelector(store => store.assignAdditionalAdminPopup);

    const onDrop = useCallback(acceptedFiles => {
        dispatch(uploadAdditionalAdminPopup(assignAdditionalAdminStore.lectureId, acceptedFiles, assignAdditionalAdminStore.files?.length));
    }, [dispatch, assignAdditionalAdminStore.lectureId, assignAdditionalAdminStore.files]);

    useEffect(() => {
        return () => {
            dispatch({ type: ASSIGN_ADDITIONAL_ADMIN_POPUP_RESET });
        }
    }, [dispatch]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/jpg, image/png, image/gif, image/heic' });

    return (
        <div className={`additional-admin ${assignAdditionalAdminStore.isLoading ? 'disabled' : ''}`}>
            <div className='section'>
                <div className='file-dropzone rounded-m' {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div className='content'>
                        <span className='transition'>{!isDragActive ? 'Cliquez ou glissez les éléments à uploader' : 'Lachez...'}</span>
                    </div>
                </div>
            </div>

            <div className='section'>
                <div className='list'>
                    {assignAdditionalAdminStore.files?.map((file) => <AdditionalItemAssignAdditionalAdminPopup key={file.id} file={file} />)}
                </div>
            </div>
        </div>
    );
}

export default AdminPopupHOC(AssignAdditionalAdminPopup, 'Ajouter des ressources', 'Création d\'images supplémentaires');