import React from 'react';
import propTypes from 'prop-types';
import { convertStringForUrl, timeRemainingForNow } from '../../../Utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images
import NoImage from '../../../resources/images/no-image.png';

const DashboardCourseItem = ({ course }) => {
    const progression = (course.nbLecturesDone / course.nbLectures) * 100;
    const { t } = useTranslation();

    return (
        <Link to={`course/${course.id}-${convertStringForUrl(course.title)}/lecture`} className='white shadow rounded-m thumbnail'>
            <img className='img-main rounded-s' src={course.picture || NoImage} alt={course.title} />

            <div className='info'>
                <span>{course.title}</span>

                <div className='progress-bar'>
                    <div className='progression'>
                        <div className='progress shadow' style={{ width: `${progression}%` }}></div>
                    </div>

                    <span><b>{course.nbLecturesDone} / {course.nbLectures}</b> {course.nbLecturesDone > 1 ? t("COURSES_DONE") : t("COURSE_DONE")}</span>
                </div>

                {course.expireDate && <>
                    <div className='or'>
                        <hr />
                    </div>

                    <ul className='stats'>
                        <li><div className='icon time-left'></div>{timeRemainingForNow(course.expireDate).str}</li>
                    </ul>
                </>}
            </div>
        </Link>
    );
}

DashboardCourseItem.propTypes = {
    course: propTypes.object.isRequired
};

export default DashboardCourseItem;