import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ADMIN_POPUP_SET_SURVEYS } from '../../../redux/actions/ActionTypes';
import { loadUserSurveysAdminPopup } from '../../../redux/actions/popup/admin/UserPopupActions';
import SurveyUserListAdminPopup from './survey/SurveyUserListAdminPopup';

const SurveyUserAdminPopup = () => {
    const dispatch = useDispatch();
    const userAdminStore = useSelector(store => store.userAdminPopup);

    useEffect(() => {
        dispatch(loadUserSurveysAdminPopup(userAdminStore.userId));

        return () => {
            dispatch({ type: USER_ADMIN_POPUP_SET_SURVEYS, payload: { courses: null } });
        }
    }, [dispatch, userAdminStore.userId]);

    return (
        <div className='certificates-users'>
            <div>
                <div className='content-title'>
                    <h2>Questionnaires</h2>
                </div>

                <div className='section'>
                    <SurveyUserListAdminPopup />
                </div>
            </div>
        </div>
    );
}

export default SurveyUserAdminPopup;