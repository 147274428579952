import Axios from 'axios';
import { ADMIN, SUPER_ADMIN } from '../resources/constants/role';
import Parser from 'ua-parser-js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
const UAData = new Parser.UAParser().getResult();

class AuthenticationService {
    static BROWSER_ID = null;

    static SetAuthenticationStorage = (accessToken, expireIn, refreshToken = null) => {
        const currentDate = new Date();
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('expire_date', currentDate.setTime(currentDate.getTime() + expireIn * 1000));
        if (refreshToken) localStorage.setItem('refresh_token', refreshToken);
    }

    static IsLogged = () => {
        return localStorage.getItem('user') !== null
    }

    static SetUserData = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    }

    static SetToken = (token) => {
        const { accessToken, expireIn, refreshToken } = token;
        this.SetAuthenticationStorage(accessToken, expireIn, refreshToken);
    }

    static Login = (user, token) => {
        this.SetToken(token);
        this.SetUserData(user);
    }

    static Logout = () => {
        // no response required
        const refreshToken = localStorage.getItem('refresh_token');
        Axios.post('user/logout', { refreshToken: refreshToken })
            .finally(() => {
                this.ClearSessionLocaleStorage();
                window.location.href = '/';
            });
    }

    static GetToken = () => {
        return localStorage.getItem('access_token');
    }

    static RefreshToken = async () => {
        const expireDate = localStorage.getItem('expire_date');
        const refreshToken = localStorage.getItem('refresh_token');
        const user = localStorage.getItem('user');
        const accessToken = localStorage.getItem('access_token');

        if (!refreshToken || !user) {
            this.ClearSessionLocaleStorage();
            return false;
        }

        if (!expireDate || new Date(parseInt(expireDate) - 10000).getTime() <= new Date().getTime()) {
            // Must use fetch to avoid axios interceptors constraint (infinite loop)
            const request = await fetch(`${process.env.REACT_APP_API_URL}/user/refresh`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refreshToken: refreshToken })
            });
            const response = await request.json();

            if (request.status === 200) {
                const { token } = response;
                this.SetAuthenticationStorage(token.accessToken, token.expireIn);
                return token.accessToken;
            } else {
                this.ClearSessionLocaleStorage();
                return false;
            }
        }

        return accessToken;
    }


    static GetSession = () => {
        return JSON.parse(localStorage.getItem('user'));
    }

    static GetRefreshToken = () => {
        return localStorage.getItem('refresh_token');
    }

    static IsAdmin = () => {
        return this.GetSession().role.includes(ADMIN);
    }

    static IsSuperAdmin = () => {
        return this.GetSession().role.includes(SUPER_ADMIN);
    }

    static GetDeviceInformations = () => {
        const { browser, os } = UAData;

        return ({
            browser: `${browser.name} ${browser.major}`,
            os: `${os.name} ${os.version}`
        });
    }

    static GetBrowserId = async () => {
        if (this.BROWSER_ID === null)
            this.BROWSER_ID = (await (await FingerprintJS.load()).get()).visitorId;

        return this.BROWSER_ID;
    }

    static ClearSessionLocaleStorage = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expire_date');
        localStorage.removeItem('user');
    }
}

export default AuthenticationService