import React from 'react';
import { useSelector } from 'react-redux';
import BasicLoader from '../../../../loader/BasicLoader';
import CourseVoucherItemAdminPopup from './CourseVoucherItemAdminPopup';
import { useTranslation } from 'react-i18next';

const CourseVoucherListAdminPopup = () => {
    const voucherAdminStore = useSelector(store => store.voucherAdminPopup);
    const { t } = useTranslation();

    if (voucherAdminStore.isCoursesLoading)
        return (
            <div className='list'>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
                <div className='item thumbnail loading'><BasicLoader width={'100%'} height={180} radius={10} /></div>
            </div>
        );

    if (!voucherAdminStore.courses || !voucherAdminStore.courses.length)
        return (
            <div className='notification-banner rounded-m'>
                {t("NO_COURSES_FOUND")}
            </div>
        )

    return (
        <div className='list'>
            {voucherAdminStore.courses && voucherAdminStore.courses.map((course) => <CourseVoucherItemAdminPopup key={course.id} courseVoucher={course} />)}
        </div>
    );
}

export default CourseVoucherListAdminPopup;