import propTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../Button';
import { PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED } from '../../../redux/actions/ActionTypes';

const TeamItem = ({ member }) => {
    const dispatch = useDispatch();

    return (
        <div className='member rounded-l' style={member.style}>
            <div className='profile-container'>
                <div className='profile rounded-m' style={{ background: `url(${member.picture})` }}></div>
            </div>

            <div className='details'>
                <span className='name'>{member.name}</span>
                <span className='status'>{member.status}</span>

                <ul className='description'>
                    {member.description?.map((d, index) => <li key={index}>{d}</li>)}
                </ul>

                <div className='cto'>
                    <Button title={'Contacter ce coach'} btnStyle={'no-shadow'} onClick={() => dispatch({ type: PREVIEW_PANEL_INTERVIEW_POPUP_SET_ENABLED, payload: { popupEnabled: true } })} />
                    {member.linkedin && <a href={member.linkedin} target='_blank' rel='noreferrer'>Découvrir son parcours</a>}
                </div>
            </div>
        </div>
    );
}

TeamItem.propTypes = {
    member: propTypes.object.isRequired
};

export default TeamItem;