import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HOME_PANEL_SET_SELECTED_CATEGORY_ID } from '../../../redux/actions/ActionTypes';

const HomeTabbar = () => {
    const dispatch = useDispatch();
    const homeStore = useSelector(store => store.homePanel);

    const onSelectCategory = (categoryId) => {
        dispatch({ type: HOME_PANEL_SET_SELECTED_CATEGORY_ID, payload: { id: categoryId } });
    }

    if (homeStore.isCategoriesLoading)
        return null;

    return (
        <div className='tab-bar horizontal-scroller'>
            <div className='wrapper'>
                {homeStore.categories.map(category => <div key={category.id} className={`item transition ${homeStore.selectedCategoryId === category.id ? 'selected' : ''}`} onClick={() => onSelectCategory(category.id)}>{category.title}</div>)}
            </div>
        </div>
    );
}

export default HomeTabbar;