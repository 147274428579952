import propTypes from 'prop-types';
import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { convertSecondToRemainingTime } from '../../../Utils';

const LectureItemContent = ({ lecture, isSelected, admin }) => {
    const nodeRef = useRef(null);

    return (
        <>
            <div className='left'>
                {!admin ? <div className={`icon-container ${lecture.isDone ? 'done' : ''}`}><div className='icon check-bold white'></div></div> : <div className='icon-container sort'><div className='icon more'></div></div>}
                <span className='name'>{lecture.title}</span>
            </div>

            <div className='right'>
                <CSSTransition nodeRef={nodeRef} in={!lecture.isAvailable && !admin} mountOnEnter unmountOnExit timeout={200} classNames='fade'>
                    <span ref={nodeRef} className='unavailable'>Bloqué</span>
                </CSSTransition>


                {lecture.secondDuration ? <span className='duration'>{convertSecondToRemainingTime(lecture.secondDuration)}</span> : null}
            </div>
        </>
    );
}

LectureItemContent.propTypes = {
    lecture: propTypes.object.isRequired,
    isSelected: propTypes.bool.isRequired,
    admin: propTypes.bool
};

export default LectureItemContent;