import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Input';
import InputPhone from '../../InputPhone';
import { SIGN_POPUP_SET_COMPANY, SIGN_POPUP_SET_PHONE } from '../../redux/actions/ActionTypes';
import { useTranslation } from 'react-i18next';

const RegisterPopupExtra = () => {
    const dispatch = useDispatch();
    const signStore = useSelector(store => store.signPopup);
    const { t } = useTranslation();

    return (
        <div>
            <InputPhone title={`${t("PHONE")} (${t("OPTIONAL")})`} placeholder={t("PLACEHOLDER_PHONE")} value={signStore.phone} onChange={(phone) => dispatch({ type: SIGN_POPUP_SET_PHONE, payload: { phone } })} error={!signStore.isPhoneValid} helperText={t("HELPER_PHONE")} disabled={signStore.isLoading} />
            <Input title={`${t("COMPANY")} (${t("OPTIONAL")})`} placeholder={t("COMPANY")} value={signStore.company} onChange={(company) => dispatch({ type: SIGN_POPUP_SET_COMPANY, payload: { company } })} type={'text'} autocomplete={'organization'} disabled={signStore.isLoading} error={!signStore.isCompanyValid} helperText={t("HELPER_COMPANY")} />
        </div>
    );
}

export default RegisterPopupExtra;